import { all, fork } from "redux-saga/effects";
import accountSaga from "./account/saga";
import editKonnector from "./edit-workflow/saga";
import authSaga from "./auth/saga";
import createKonnector from "./create-konnector/saga";
import templateSaga from "./edit-template-settings/saga";
import konnectorSaga from "./konnector-list/saga";
import configurationv2Saga from "./configurationv2/saga";
import creaKonnectorV2 from "./data-mapping/saga";

export function* rootSaga() {
  yield all([
    fork(accountSaga),
    fork(editKonnector),
    fork(authSaga),
    fork(createKonnector),
    fork(templateSaga),
    fork(konnectorSaga),
    fork(creaKonnectorV2),
    fork(configurationv2Saga),
  ]);
}
