import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AccountName, Password, TextField } from "common/components/FormFields";
import { useAppDispatch, useAppSelector } from "state/store";
import { StatusType } from "state/new-konnector/types";
import { generateJSONForAccountValidations, parseOauthUrl } from "utils/config";
import {
  setAccountLoading,
  setOAuthAccountLoading,
  setReuseAppdetails,
  setReusevalidation,
  setReusevalue,
  setTriggerInputSchema,
  setUpdateLoading,
  setUpdateOAuthAccountLoading,
  switchTab,
} from "state/account/slices";
import { generateJSONForAccountValidation } from "utils/config";
import { Loader } from "components/icons/Loader";
import { AppsDetails, CurrentState, TabType } from "state/account/types";
import { ToastError, ToastSucess } from "common/components/toast/Toast";
import DynamicFieldMapping from "./DynamicFieldMapping";
interface CredTab {
  appDetails: AppsDetails;
}

const CredTab: React.FC<CredTab> = ({ appDetails }) => {
  const tabs = useAppSelector((state) => state.account.tabItems);
  const currentState = useAppSelector((state) => state.account.currentState);
  const accCred = useAppSelector((state) => state.auth.accCred);
  const authDetails = useAppSelector((state) => state.auth);
  const app = appDetails.data;
  const accountValidated = appDetails?.tabValidated;
  const currentSate = useAppSelector((state) => state.account.currentState);
  const [updateStatus, setUpdateStatus] = useState(false);
  const apiStatus = appDetails.status;
  const [accessToken, setAccessToken] = useState(null);
  const [authData, setAuthData] = useState(null);
  const dispatch = useAppDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const hastDynamicInputfields = app?.triggers?.find((t) => t.id === appDetails.eventId)?.dynamicInputFields;
  const hasActionDynamicInputfields = app?.actions?.find((t) => t.id === appDetails.eventId)?.dynamicInputFields;
  const validatetabbool = useAppSelector((state) => state.account.validatetabboolean);

  const checkDisabletrue = useAppSelector((state) => state.account.disablevalidation);
  const validateobj = useAppSelector((state) => state.account.validateTabobject);
  let reusedata = validateobj?.connectionConfig?.fields;

  const getDefaultState = (data) => {
    const defaultState = {};
    data.filter((field) => {
      defaultState[field.name] = field.value;
    });
    return defaultState;
  };

  useEffect(() => {
    dispatch(setReuseAppdetails(appDetails));
  }, [appDetails]);

  useEffect(() => {
    dispatch(setReusevalue(accountValidated));
  }, [accountValidated]);

  useEffect(() => {
    dispatch(setReusevalue(accountValidated));
  }, [validatetabbool]);
  const onSubmit = (data) => {
    dispatch(setReusevalidation(data));
    if (app.appAuthType == "OAuth") {
      const { ["Account name"]: connectionName, ...fields } = data;
      const requestAccountBody = {
        connectionName,
        fields,
        ...app,
      };

      let oauthUrl = parseOauthUrl(app.connectionConfig.authUrl, data);
      const handleMessage = (event) => {
        if (event.data.access_token) {
          setAccessToken(event.data.access_token);
          setAuthData(event.data);
          var windowFunc;
          if (!accessToken) {
            windowFunc = window.open(oauthUrl, "OAuth Window", "height=600,width=800");
          }
          const requestAccountData = generateJSONForAccountValidation(requestAccountBody, event.data);

          if (accountValidated) {
            return dispatch(setUpdateOAuthAccountLoading());
          }
          dispatch(
            setOAuthAccountLoading({
              accountDetails: requestAccountData,
              currentActionId: appDetails.id,
              eventId: appDetails.eventId,
              appId: appDetails.data.id,
            })
          );
          window.removeEventListener("message", handleMessage);
          windowFunc.close();
        }
      };
      window.addEventListener("message", handleMessage);
      window.addEventListener("message", handleMessage);
      if (!accessToken) {
        window.open(oauthUrl, "OAuth Window", "height=600,width=800");
      }
    } else {
      const { ["Account name"]: connectionName, ...fields } = data;
      const requestAccountBody = {
        connectionName,
        fields,
        ...app,
      };
      const requestAccountData = generateJSONForAccountValidation(requestAccountBody);

      if (accountValidated) {
        return dispatch(
          setUpdateLoading({
            accountDetails: requestAccountData,
            currentActionId: appDetails.id,
            eventId: appDetails.eventId,
            appId: appDetails.data.id,
            accountId: appDetails.connectionConfig.id,
          })
        );
      }
      dispatch(
        setAccountLoading({
          accountDetails: requestAccountData,
          currentActionId: appDetails.id,
          eventId: appDetails.eventId,
          appId: appDetails.data.id,
        })
      );
    }
  };
  useEffect(() => {
    if (
      appDetails.appId == authDetails.currentUser.admin.staticApp &&
      !accountValidated &&
      currentSate === CurrentState.Templates &&
      app.appAuthType == "OAuth" &&
      accCred.length > 0
    ) {
      const accDetails = {};
      accCred.filter((field) => {
        if (field.name == "accessToken" || field.name == "refreshToken") {
          accDetails[field.name] = field.value;
        }
      });
      const requestAccountBody = {
        connectionName: "",
        accDetails,
        ...app,
      };
      const requestAccountData = generateJSONForAccountValidations(requestAccountBody);
      dispatch(
        setOAuthAccountLoading({
          accountDetails: requestAccountData,
          currentActionId: appDetails?.id,
          eventId: appDetails?.eventId,
          appId: appDetails?.data.id,
        })
      );
    }
  });

  useEffect(() => {
    if (
      appDetails.appId == authDetails.currentUser.admin.staticApp &&
      !accountValidated &&
      currentSate === CurrentState.Templates &&
      app.appAuthType != "OAuth" &&
      accCred[0]?.value != ""
    ) {
      const accDetails = {};
      accCred.filter((field) => {
        accDetails[field.name] = field.value;
      });
      const requestAccountBody = {
        connectionName: "",
        accDetails,
        ...app,
      };
      const requestAccountData = generateJSONForAccountValidations(requestAccountBody);

      dispatch(
        setAccountLoading({
          accountDetails: requestAccountData,
          currentActionId: appDetails.id,
          eventId: appDetails.eventId,
          appId: appDetails.data.id,
        })
      );
    }
  }, [accountValidated]);

  useEffect(() => {
    if (!accountValidated && appDetails.appId == validateobj.appId) {
      // const fields = validateobj?.connectionConfig.fields.reduce((previousObject, currentObject) => {
      //   return Object.assign(previousObject, currentObject);
      // }, {});
      const fields = validateobj?.connectionConfig?.fields.reduce((result, currentObject) => {
        result[currentObject.name] = currentObject.value;
        return result;
      }, {});
      // let fields = validateobj?.connectionConfig.fields;

      const requestAccountBodyss = {
        connectionName: "",
        fields,
        ...app,
      };
      const requestAccountDatas = generateJSONForAccountValidation(requestAccountBodyss);

      dispatch(
        setAccountLoading({
          accountDetails: requestAccountDatas,
          currentActionId: appDetails?.id,
          eventId: appDetails?.eventId,
          appId: appDetails?.data.id,
        })
      );

      if (apiStatus === StatusType.success && accountValidated && appDetails.tabValidated) {
        dispatch(switchTab(appDetails.id));
      }
    }
  }, [!accountValidated && appDetails.appId == validateobj.appId]);
  const [onChnagestate, setonChangestate] = useState("");

  const [onPasswordChange, setonPasswordChange] = useState("");
  const handleOnchangeCapture = (event) => {
    setonChangestate(event.target.value);
  };

  const handlepasswordchange = (event) => {
    setonPasswordChange(event.target.value);
  };
  const renderField = (field) => {
    switch (field.type) {
      case "textbox | textarea":
      case "textbox": {
        return (
          <TextField
            {...field}
            key={field.name}
            register={register}
            isDisabled={checkDisabletrue && apiStatus != StatusType.failed}
            errors={errors}
            placeholder={field.placeholder}
            onChangeCapture={handleOnchangeCapture}
            // onChange={onChange}
            // onChange={handleChange}
          />
        );
      }
      case "Password": {
        return (
          <Password
            {...field}
            key={field.name}
            register={register}
            isDisabled={checkDisabletrue && apiStatus != StatusType.failed}
            errors={errors}
            onChangeCapture={handlepasswordchange}
          />
        );
      }
      case "accountName": {
        return <AccountName {...field} key={field.name} register={register} isDisabled={true} errors={errors} />;
      }
      default:
        // return "<p>Field not found</p>";
        return <></>;
    }
  };

  const renderForm = () => {
    const fields = [...app?.connectionConfig?.fields];
    return fields.map((field) => renderField(field));
  };
  useEffect(() => {
    if (apiStatus === StatusType.success) {
      if (hastDynamicInputfields || hasActionDynamicInputfields) {
        dispatch(setTriggerInputSchema({ currentActionId: appDetails.id }));
      }

      if (
        appDetails.appId === authDetails.currentUser.admin.staticApp &&
        accountValidated &&
        currentSate === CurrentState.Templates
      ) {
      }

      if (!hastDynamicInputfields && !hasActionDynamicInputfields && appDetails.tabValidated) {
        ToastSucess("Account validated successfully");

        setTimeout(() => {
          dispatch(switchTab(appDetails.id));
        }, 3000);
      }
    }

    if (apiStatus === StatusType.failed) {
      ToastError("Please enter valid credentials");
    }
  }, [
    apiStatus,
    hastDynamicInputfields,
    hasActionDynamicInputfields,
    accountValidated,
    appDetails,
    authDetails,
    currentSate,
  ]);

  useEffect(() => {
    if (currentState === "konnectors") {
      const hastDynamicInputfields = app.triggers?.find((t) => t.id === appDetails.eventId)?.dynamicInputFields;
      const hasActionDynamicInputfields = app?.actions?.find((t) => t.id === appDetails.eventId)?.dynamicInputFields;
      setUpdateStatus(true);
    }
  });
  useEffect(() => {
    if (currentState === "konnectors") {
      const hastDynamicInputfields = app.triggers?.find((t) => t.id === appDetails.eventId)?.dynamicInputFields;
      const hasActionDynamicInputfields = app?.actions?.find((t) => t.id === appDetails.eventId)?.dynamicInputFields;
      setUpdateStatus(true);
    }
  });
  useEffect(() => {
    if (appDetails.connectionConfig?.fields) {
      const accountNameField = {
        name: "Account name",
        value: appDetails.connectionConfig.connectionName,
      };
      reset(getDefaultState([accountNameField, ...appDetails.connectionConfig?.fields]));
    }
    if (
      validateobj.connectionConfig?.fields &&
      validatetabbool &&
      // validateobj.connectionConfig?.fields
      appDetails.appId == validateobj.appId &&
      appDetails.appId != authDetails.currentUser.admin.staticApp &&
      apiStatus != StatusType.failed
    ) {
      const accountnewfields = {
        name: "Account name",
        value: validateobj.connectionConfig.connectionName,
      };
      reset(getDefaultState(validateobj.connectionConfig?.fields));
    } else {
      if (appDetails.appId == authDetails.currentUser.admin.staticApp) {
        if (appDetails.connectionConfig?.fields) {
          const accountNameField = {
            name: "Account name",
            value: appDetails.connectionConfig.connectionName,
          };
          reset(getDefaultState([accountNameField, ...appDetails.connectionConfig?.fields]));
        } else {
          reset(getDefaultState(accCred));
        }
      } else {
        reset({});
      }
    }
    // return () => {
    //   reset(getDefaultState(accCred));
    // };
  }, [
    validateobj.connectionConfig?.fields &&
      validatetabbool &&
      appDetails.appId == validateobj.appId &&
      appDetails.appId != authDetails.currentUser.admin.staticApp,
    apiStatus != StatusType.failed,
  ]);

  return (
    <div className="p-4 h-[calc(80vh-5rem)] w-full">
      <div className="flex flex-col space-y-3">
        <form className="space-y-6 flex flex-col items-start" onSubmit={handleSubmit(onSubmit)}>
          {renderForm()}
          {apiStatus === StatusType.failed && (
            <p className="text-md  text-red-500 text-center">Please enter valid credentials</p>
          )}
          {
            <button
              disabled={
                (appDetails.appId == authDetails.currentUser.admin.staticApp &&
                  accountValidated &&
                  currentSate === CurrentState.Templates &&
                  app.appAuthType != "OAuth" &&
                  accCred[0]?.value != "") ||
                (checkDisabletrue && apiStatus != StatusType.failed)
                // (accountValidated && appDetails.appSelectorType == "lookup" && compare_response)
                // (validatetabbool &&
                //   validateobj.connectionConfig?.fields &&
                //   appDetails.appId == validateobj.appId &&
                //   appDetails.appId != authDetails.currentUser.admin.staticApp)
              }
              // type="submit"
              // className={`w-auto text-white ${
              //   accountValidated ? "bg-gray-300 cursor-not-allowed" : "hover:bg-[#0e1a2e] disabled:opacity-45 "
              // } hover:transition-all focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 text-center`}
              // disabled={accountValidated}
              className="w-auto text-white hover:bg-[#0e1a2e] bg-komp-secondary-color hover:transition-all focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 text-center disabled:opacity-45 disabled:cursor-not-allowed"
            >
              {apiStatus === StatusType.loading ? (
                <>
                  <Loader />
                  Loading...
                </>
              ) : (
                <>{accountValidated && !onChnagestate && !onPasswordChange ? "Validated" : "Validate"}</>
              )}
            </button>
          }
        </form>
        {/* {hasActionDynamicInputfields || hastDynamicInputfields ? <DynamicFieldMapping appDetails={appDetails} hastDynamicInputfields={hastDynamicInputfields} hasActionDynamicInputfields={hasActionDynamicInputfields} /> : ""} */}
        {appDetails.dynamicFields?.hasData && (
          <DynamicFieldMapping
            appDetails={appDetails}
            hastDynamicInputfields={hastDynamicInputfields}
            hasActionDynamicInputfields={hasActionDynamicInputfields}
          />
        )}
      </div>
    </div>
  );
};

export default CredTab;
