import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreateAccountSuccessResponse, StatusType, ValidateAccountSuccessResponse } from "state/new-konnector/types";
import { AccountInitialState, TabType, CurrentState } from "../types";
import { initialState1 } from "../test/test";

export const initialState: AccountInitialState = {
  tabItems: [],
  data: {},
  activeAccount: {},
  currentActionId: 0,
  allTabValidated: false,
  currentTab: 0,
  isTabValidated: false,
  currentState: CurrentState.Konnectors,
  currentPage: 1,
  isWorkflowSaved: StatusType.idle,
  error: "",
  status: StatusType.idle,
  showModal: false,
  hasPlan: false,
  isTrial: true,
  isFreePlan: true,
  showUpdateModal: false,
  updateTabStatus: StatusType.idle,
  tab1Success: false,
  tab2Success: false,
  targetTab: StatusType.idle,
  sourceUpdate: StatusType.idle,
  targetUpdate: StatusType.idle,
  value: "",

  Reusevalidation: {},

  Reusevalue: false,

  ReuseAppdetails: {},

  validatetabboolean: false,
  validateTabobject: {},

  disablevalidation: false,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccountState: (state) => {
      state.status = StatusType.idle;
    },
    setCurrentState: (state, data) => {
      state.currentState = data.payload;
    },
    setAccountLoading: (state, data: PayloadAction<any>) => {
      state.tabItems[data.payload.currentActionId].status = StatusType.loading;
      state.status = StatusType.loading;
    },
    konnectorIdle: (state) => {
      state.isWorkflowSaved = StatusType.idle;
    },
    konnectorLoading: (state) => {
      state.isWorkflowSaved = StatusType.loading;
    },
    konnectorSuccess: (state) => {
      state.isWorkflowSaved = StatusType.success;
    },
    konnectorFailed: (state) => {
      state.isWorkflowSaved = StatusType.failed;
    },
    createAccountSuccess: (state, data: PayloadAction<any>) => {
      state.tabItems[data.payload.currentActionId].connectionConfig = data.payload.data;
      state.tabItems[data.payload.currentActionId].tabValidated = true;
      state.tabItems[data.payload.currentActionId].status = StatusType.success;
      state.status = StatusType.success;
    },
    setUpdateLoading: (state, data: PayloadAction<any>) => {
      state.tabItems[data.payload.currentActionId].status = StatusType.loading;
      state.status = StatusType.loading;
    },
    setTriggerInputSchema: (state, data) => {
      state.tabItems[data.payload.currentActionId].dynamicFields.hasData = true;
    },
    updateAccountSuccess: (state, data: PayloadAction<any>) => {
      state.tabItems[data.payload.currentActionId].connectionConfig = data.payload.data;
      state.tabItems[data.payload.currentActionId].tabValidated = true;
      state.tabItems[data.payload.currentActionId].status = StatusType.success;
      state.status = StatusType.success;
    },
    setDyanmicFieldsLoading: (state, data) => {
      state.tabItems[data.payload.currentActionId].dynamicFields.status = StatusType.loading;
    },

    setReusevalidation: (state, data) => {
      state.Reusevalidation = data.payload;
    },
    setReusevalue: (state, data) => {
      state.Reusevalue = data.payload;
    },

    setReuseAppdetails: (state, data) => {
      state.ReuseAppdetails = data.payload;
    },
    setvalidateTabobject: (state, data) => {
      state.validateTabobject = data.payload;
    },
    setDyanmicFieldsSuccess: (state, data) => {
      state.tabItems[data.payload.currentActionId].dynamicFields.status = StatusType.success;
      state.tabItems[data.payload.currentActionId].dynamicFields.hasData = true;
      state.tabItems[data.payload.currentActionId].dynamicFields.fields = data.payload.fields;
    },
    setDynamicInputDataMapping: (state, data) => {
      state.tabItems[data.payload.currentActionId].dynamicFields.dataMapping[data.payload.field] = data.payload.value;
      state.value = data.payload.value;
      const findActionIndex = data.payload.currentActionId;
      let findFieldIndex = state.tabItems[findActionIndex].dynamicFields.fields?.findIndex(
        (field) => field?.dependentTo === data.payload.field
      );
      if (findFieldIndex !== -1) {
        state.tabItems[findActionIndex].dynamicFields.fields[findFieldIndex].propValue = [];
        let dependentToField = state.tabItems[findActionIndex].dynamicFields.fields[findFieldIndex].propName;
        const updatedFields = { ...state.tabItems[findActionIndex].dynamicFields.fields };
        delete updatedFields[dependentToField];
      }
      // state.tabItems[findActionIndex].rawDataMapping = {};
      // state.tabItems[findActionIndex].inputFields = {
      //   appId: "",
      //   eventId: "",
      //   sourceId: "",
      //   data: [],
      // };
    },
    fetchDynamicFieldsLoading: (state, data) => {},
    setTab2Loading: (state, data: PayloadAction<any>) => {
      state.targetTab = StatusType.loading;
      state.status = StatusType.loading;
    },
    setUpdateAccountLoading: (state, data: PayloadAction<any>) => {
      state.sourceUpdate = StatusType.loading;
    },
    setUpdateTab2Loading: (state, data: PayloadAction<any>) => {
      state.targetUpdate = StatusType.loading;
    },
    updateCurrentPage(state, data: PayloadAction<number>) {
      state.currentPage = data.payload;
    },
    setOAuthAccountLoading: (state, data: PayloadAction<any>) => {
      state.tabItems[data.payload.currentActionId].status = StatusType.loading;
      state.status = StatusType.loading;
    },
    setUpdateOAuthAccountLoading: (state) => {
      state.status = StatusType.loading;
    },
    setAccountApp: (state, data: PayloadAction<any>) => {
      //for test
      // state.tabItems = initialState.tabItems;
      //for prod
      state.tabItems = data.payload.data;
      state.allTabValidated = data.payload.allTabValidated ? true : false;
      state.currentActionId = 0;
    },
    setUpdateAccountApp: (state, data: PayloadAction<any>) => {
      state.tabItems = data.payload;
      state.showUpdateModal = true;
    },
    setUpdateActiveAccount: (state, data: PayloadAction<any>) => {
      state.activeAccount = data.payload.account;
      state.tabItems = data.payload.app;
      state.showUpdateModal = true;
    },
    validateAccountSuccess: (state, data: PayloadAction<ValidateAccountSuccessResponse>) => {
      state.status = StatusType.success;
    },
    validateAccount2Success: (state, data: PayloadAction<ValidateAccountSuccessResponse>) => {
      state.status = StatusType.idle;
      state.targetTab = StatusType.success;
      state.isTabValidated = true;
    },
    resetTabValidation: (state) => {
      state.isTabValidated = false;
    },
    updateAccount2Success: (state, data: PayloadAction<CreateAccountSuccessResponse>) => {
      state.data = data.payload.data;
      state.targetUpdate = StatusType.success;
    },
    accountApiFailed: (state, data) => {
      state.tabItems[data?.payload?.currentActionId].tabValidated = false;
      state.tabItems[data.payload.currentActionId].status = StatusType.failed;
      state.status = StatusType.failed;
    },
    accountUpdateApiFailed: (state) => {
      state.updateTabStatus = StatusType.failed;
    },
    updateValidationSuccess: (state, data) => {
      state.tab1Success = true;
    },
    updateValidationSuccess2: (state, data) => {
      state.tab2Success = true;
    },
    setAccountModal: (state, data) => {
      if (data.payload === false) {
        state.tabItems = [];
        state.data = {};
        state.activeAccount = {};
        state.allTabValidated = false;
        state.error = "";
        state.status = StatusType.idle;
        state.showModal = false;
        state.hasPlan = false;
        state.isTrial = true;
        state.isFreePlan = true;
        state.showUpdateModal = false;
      }
      state.showModal = data.payload;
    },
    setUpdateAccountModal: (state, data) => {
      if (data.payload === false) {
        state.tabItems = [];
        state.data = {};
        state.activeAccount = {};
        state.allTabValidated = false;
        state.error = "";
        state.status = StatusType.idle;
        state.showModal = false;
        state.hasPlan = false;
        state.isTrial = true;
        state.isFreePlan = true;
        state.showUpdateModal = false;
      }
      state.showUpdateModal = data.payload;
    },
    setCurrentTab: (state, data) => {
      state.currentActionId = data.payload;
    },

    setvalidatetabboolean: (state, data) => {
      state.validatetabboolean = data.payload;
    },

    setDisablevalidation: (state, data) => {
      state.disablevalidation = data.payload;
    },
    switchTab: (state, data) => {
      state.tabItems[data.payload].status = StatusType.idle;
      const tabs = state.tabItems;
      let allTabValidated = true;
      tabs
        .filter((tab) => tab.tabType === TabType.Creds)
        .map((acc) => {
          allTabValidated = allTabValidated && acc.tabValidated;
        });
      state.allTabValidated = allTabValidated;
      state.currentActionId += 1;
    },
    getDynamicAppSchemaLoading: (state, data) => {},
    fetchAllAppSchemaLoading: (state, data) => {
      state.tabItems[data.payload.currentActionId].status = StatusType.loading;
    },
    fetchAllAppSchemaSuccess: (state, data) => {
      // state.tabItems[data.payload.currentActionId].status = StatusType.success;
    },
    setAppSchema: (state, data) => {
      state.tabItems[data.payload.currentActionId].inputSchemas = data.payload.inputSchemas;
      state.tabItems[data.payload.currentActionId].outputSchema = data.payload.outputSchema;
      state.tabItems[data.payload.currentActionId].status = StatusType.success;
      state.tabItems[data.payload.currentActionId].fieldsRetrived = true;
    },
    saveDataMapping: (state, data) => {
      state.tabItems[data.payload.currentActionId].rawDataMapping = data.payload.rawDataMapping;
    },
    resetAccount: () => initialState,
    resetStatus: (state, data) => {
      state.tabItems[data.payload.currentActionId].status = StatusType.idle;
    },
    fetchDynamicFieldsSuccess: (state, data) => {
      state.status = StatusType.success;
      state.tabItems[data.payload.currentActionId].dynamicFields.fields.find(
        (val) => val.propName === data.payload.field
      ).propValue = data.payload.value;
    },
    fetchInputFieldsLoading: (state, data) => {},
  },
});

export const {
  resetTabValidation,
  setAccountState,
  validateAccount2Success,
  setAccountLoading,
  setTab2Loading,
  setUpdateLoading,
  setUpdateTab2Loading,
  updateValidationSuccess,
  setDyanmicFieldsLoading,
  setDyanmicFieldsSuccess,
  setDynamicInputDataMapping,
  setTriggerInputSchema,
  fetchAllAppSchemaLoading,
  konnectorFailed,
  konnectorLoading,
  konnectorSuccess,
  setCurrentState,
  konnectorIdle,
  fetchAllAppSchemaSuccess,
  updateValidationSuccess2,
  accountUpdateApiFailed,
  updateCurrentPage,
  validateAccountSuccess,
  setOAuthAccountLoading,
  setAccountApp,
  resetAccount,
  createAccountSuccess,
  accountApiFailed,
  setAccountModal,
  setUpdateAccountModal,
  setUpdateAccountLoading,
  setUpdateOAuthAccountLoading,
  setUpdateAccountApp,
  setUpdateActiveAccount,
  updateAccountSuccess,
  updateAccount2Success,
  setCurrentTab,
  switchTab,
  saveDataMapping,
  getDynamicAppSchemaLoading,
  setAppSchema,
  resetStatus,
  fetchDynamicFieldsLoading,
  fetchDynamicFieldsSuccess,
  fetchInputFieldsLoading,
  setReusevalidation,
  setReusevalue,
  setReuseAppdetails,
  setvalidatetabboolean,
  setvalidateTabobject,
  setDisablevalidation,
} = accountSlice.actions;

export default accountSlice.reducer;
