import { KonnectorAppState, AppRole, InitialState } from "./types";
export const getTriggerDetails = (state): KonnectorAppState<AppRole.Source> => state.createNewKonnector.data.trigger;
export const getTargetDetails = (state): KonnectorAppState<AppRole.Target> => {
  const findActionIndex = state.createNewKonnector.data.actions.findIndex(
    (action) => action.id === state.createNewKonnector.currentActionId
  );
  return state.createNewKonnector.data.actions[findActionIndex];
};
export const getLookupDetails = (state, lookupId): KonnectorAppState<AppRole.Lookup> => {
  return state.createNewKonnector.data.lookups.find((lookup) => lookup.id == lookupId);
};

export const getCreateKonnectorDetails = (state): InitialState => state.createKonnector;
export const getEditTemplate = (state): InitialState => state.editTemplate;
