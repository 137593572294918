import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { serializeDataMapping, serializeLookupDataMapping } from "utils/appsUtils";
import { v4 as uuidv4 } from "uuid";
import { ActionMode, AppRole, ConfigurationType, InitialState, KonnectorType, StatusType, webhookUrl } from "../types";

export const initialState: InitialState = {
  template: {},
  sourceOutputSchema: {},
  targetInputSchema: {},
  fieldsRetrived: false,
  schema: {
    output: [],
    input: { data: [] },
  },
  inputIconUrl: "",
  outputIconurls: [],
  rawDataMapping: {},
  status: StatusType.idle,
  dynamicDatamappingStatus: StatusType.idle,
  accordionCard: false,
  konType: "",
  isLookup: false,
  dynamicInputFields: [],
  lookupTabState: "tab2",
  invalidLookup: [],
  isKonnectorLookup: "",
  currentstate: "",
  Dynamicvalues: [],
  dataMapping: {},
  DynamicInputDataMapping: [],
  dynamicworksheetvalues: [],
  dynamicdatastatus: "",
  dynamicinputstate: false,
  konnectorNameLoading: StatusType.idle,
  mappingsuccess: StatusType.idle,
  konnectorArray: {},
};

const createKonnectorV2 = createSlice({
  name: "dataMapping",
  initialState,
  reducers: {
    setTemplatePayload(state, data) {
      state.template = data.payload;
    },
    setSchemaLoading(state, data) {
      state.inputIconUrl = data.payload.inputIconUrl;
      state.outputIconurls = data.payload.outputIconUrl;
      // state.template = data.payload
    },
    // setSchemaSuccess(state, data) {
    //   ;
    //   if (data.payload.type == "output") {
    //     state.schema.output.unshift(data.payload.data);
    //     // state.schema.output = [...state.schema.output, data.payload.data];
    //   }
    //   //  else if (data.payload.type == "output_01") {
    //   //   ;
    //   //   state.schema.output = [...state.schema.output, data.payload.data];
    //   // }
    //   else {
    //     state.schema[data.payload.type] = data.payload.data;
    //   }
    // },
    setSchemaSuccess(state, data) {
      if (data.payload.type === "output") {
        const isDuplicate = state.schema.output.some((item) => {
          if (
            item.eventId === data.payload.data.eventId &&
            item.eventResponse.data.length === data.payload.data.eventResponse.data.length
          ) {
            return true;
          }
        });
        if (!isDuplicate) {
          if (data?.payload?.data?.eventResponse?.data !== null) {
            state.schema.output = state.schema.output.filter((e) => e.eventId !== data.payload.data.eventId);
            state.schema.output.unshift(data.payload.data);
          } else {
          }
        }
      }
      // else if (data.payload.type == "output_02") {
      //   if (data.payload.data.eventResponse.data !== null) {
      //     state.schema.output.splice(0, state.schema.output.length)
      //     state.schema.output.unshift(data.payload.data);
      //   } else {
      //     ;
      //   }
      // }
      else if (data.payload.type == "output_01") {
        state.schema.output = [...state.schema.output, data.payload.data];
      } else {
        state.schema[data.payload.type] = data.payload.data;
      }
      // if (state.schema.output.length > 0 && state.schema.input.data.length > 0) {
      //   state.mappingsuccess = StatusType.success
      // }
    },
    saveDataMapping(state, data) {
      state.rawDataMapping = data.payload;
    },
    saveKonnectorv2Loading(state, data) {
      state.accordionCard = false;
      state.status = StatusType.loading;
    },
    updateKonnectorv2Loading(state, data) {
      state.accordionCard = false;
      state.status = StatusType.loading;
    },
    saveKonnectorSucces(state, data) {
      state.accordionCard = true;
      state.status = StatusType.success;
      if (data.payload != "new") {
        state.konnectorArray = data.payload;
      }
    },
    saveKonnectorName(state, data) {
      state.konnectorNameLoading = StatusType.loading;
    },
    saveKonnectorNameSuccess(state) {
      state.konnectorNameLoading = StatusType.success;
    },
    setKonType(state, data) {
      state.konType = data.payload;
    },
    setLookupData(state, data) {
      state.isLookup = data.payload;
    },
    setDynamicInputField(state, data) {
      state.dynamicInputFields = data.payload;
    },
    setlookupTabState(state, data) {
      state.lookupTabState = data.payload;
    },
    getLookupSchemaLoading(state, data) {},

    getDynamicFieldsLoading(state, data) {},

    fetchAllAppSchemanewLoading(state, data) {},
    fetchDynamicInputnewFieldsloading(state, data) {},
    fetchDynamicFieldindatamapLoading(state, data) {
      state.dynamicDatamappingStatus = StatusType.loading;
    },
    fetchDynamicFieldindatamapstatus(state, data) {
      state.dynamicdatastatus = data.payload;
      state.dynamicDatamappingStatus = data.payload == "loading" ? StatusType.loading : StatusType.success;
    },
    setDynamicInputDataMapping(state, data) {
      state.DynamicInputDataMapping = { ...state.DynamicInputDataMapping, [data.payload.field]: data.payload.value };
      // state.DynamicInputDataMapping = data.payload;
    },
    setInvalidLookup(state, data) {
      state.isKonnectorLookup = data.payload.type;
      state.invalidLookup = [...state.invalidLookup, data.payload.payload];
    },
    setcurrentstate(state, data) {
      state.currentstate = data.payload;
    },
    setdynamicinputstate(state, data) {
      state.dynamicinputstate = data.payload;
    },
    setdynamicvalues(state, data) {
      state.Dynamicvalues.push(data.payload);
    },

    setDynamicValuesFromTemplate(state, data) {
      state.DynamicInputDataMapping = data.payload;
    },

    setdynamicworkshhetvalues(state, data) {
      state.Dynamicvalues.forEach((item) => {
        if (item.appId === data.payload.appId) {
          const fieldToUpdate = item.fields.find((val) => val.propName === data.payload.field);
          if (fieldToUpdate) {
            fieldToUpdate.propValue = data.payload.value;
          }
        }
      });
      state.dynamicDatamappingStatus = StatusType.success;

      // state.Dynamicvalues.fields.find((val) => val.propName == data.payload.field).propValue = data.payload.value;
    },

    //   state.tabItems[data.payload.currentActionId].dynamicFields.fields.find(
    //     (val) => val.propName === data.payload.field
    //   ).propValue = data.payload.value;
    // },

    setWebhookResponse(state, data) {
      state.schema.output.pop(null);
      if (state.schema.output.length == 0) {
        state.schema.output.unshift(data.payload.data.schema);
      }
    },
    resetDataMapping: () => initialState,
  },
});

export const {
  setTemplatePayload,
  setSchemaLoading,
  setSchemaSuccess,
  saveDataMapping,
  saveKonnectorv2Loading,
  resetDataMapping,
  saveKonnectorSucces,
  updateKonnectorv2Loading,
  setKonType,
  setLookupData,
  setDynamicInputField,
  setlookupTabState,
  getLookupSchemaLoading,
  setInvalidLookup,
  setcurrentstate,
  setWebhookResponse,
  getDynamicFieldsLoading,
  setdynamicvalues,
  fetchDynamicFieldindatamapLoading,
  fetchDynamicFieldindatamapstatus,
  setDynamicInputDataMapping,
  setdynamicworkshhetvalues,
  fetchAllAppSchemanewLoading,
  fetchDynamicInputnewFieldsloading,
  setdynamicinputstate,
  saveKonnectorName,
  saveKonnectorNameSuccess,
  setDynamicValuesFromTemplate,
} = createKonnectorV2.actions;

export default createKonnectorV2.reducer;
