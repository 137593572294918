import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Options } from "../types";
import { useAppSelector } from "state/store";

const TextField: React.FC<Options> = ({
  type,
  pattern,
  label,
  name,
  required,
  register,
  placeholder,
  errors,
  onChangeCapture,
  isDisabled = false,
}) => {
  const getFormConfigs = () => {
    if (required) {
      return {
        required: { value: required.value, message: required.message },
        pattern: {
          value: new RegExp(pattern.value, pattern.flags),
          message: pattern.message,
        },
      };
    }
    return {
      pattern: {
        value: new RegExp(pattern.value, pattern.flags),
        message: pattern.message,
      },
    };
  };
  const customisation = useAppSelector((state) => state.auth.customisation);
  const new_Customization = useAppSelector((state) => state.auth.portalConfigration);

  const handleOnchangeCapture = (event) => {};
  return (
    <div className="flex flex-col items-start">
      {required ? (
        <label
          htmlFor={name}
          className="block mb-2 text-sm font-medium text-gray-900 "
          style={new_Customization?.bodytext_color ? { color: new_Customization?.bodytext_color } : {}}
        >
          {label ? label : name} <span className="text-red-500 ">*</span>
        </label>
      ) : (
        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900">
          {label ? label : name}
        </label>
      )}
      <input
        type={type}
        disabled={isDisabled}
        onChangeCapture={onChangeCapture}
        className="bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-sm focus:ring-primary-color focus:border-primary-color block w-96 p-2.5"
        placeholder={placeholder ? `${placeholder}` : `Enter ${name}`}
        {...register(name, getFormConfigs())}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className="mt-2 text-sm text-red-600">{message}</p>}
      />
    </div>
  );
};

export default TextField;
