import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { serializeDataMapping, serializeLookupDataMapping } from "utils/appsUtils";
import { v4 as uuidv4 } from "uuid";
import { ActionMode, AppRole, ConfigurationType, InitialState, KonnectorType, StatusType, webhookUrl } from "../types";

export const initialState: InitialState = {
  data: {
    name: "Untitled",
    konnectorType: KonnectorType.Initial,
    webhookUrl: {
      url: "",
      id: "",
    },
    sourceTemplate: "",
    trigger: {
      triggerType: "",
      appSelectorKey: "",
      appName: "",
      formFields: [],
      formSettings: {},
      actionMode: ActionMode.Create,
      iconUrl: "",
      appSelectorType: AppRole.Source,
      appId: "",
      actionOrderId: 0,
      connectionId: "",
      connectionName: "",
      eventId: "",
      isAccountValidated: false,
      outputFields: {
        hasData: false,
        fields: {},
        schema: {},
      },
      inputFields: { appId: "", data: [], eventId: "", sourceId: "", dataMapping: {}, hasData: false },
      appDetails: {},
    },
    lookups: [],
    actions: [
      {
        id: uuidv4(),
        actionOrderId: 0,
        appSelectorKey: "",
        appName: "",
        iconUrl: "",
        appSelectorType: AppRole.Target,
        appId: "",
        connectionId: "",
        connectionName: "",
        eventId: "",
        isAccountValidated: false,
        appDetails: {},
        actionMode: ActionMode.Create,
        inputFields: {
          sourceId: "",
          eventId: "",
          appId: "",
          data: {},
        },
      },
    ],
    ruleEngine: [],
    parseEngine: [],
  },
  currentActionId: "",
  hasConfigurationSaved: false,
  configurationType: ConfigurationType.Source,
  actionMode: ActionMode.Edit,
  tabValidated: StatusType.idle,
  status: StatusType.idle,
  generateUrl: StatusType.idle,
  resetWebhook: StatusType.idle,
  errorMessage: "",
  created: false,
  saveButtonStatus: false,
  editRule: false,
  editParse: false,
  isLoading: true,
  apiKey: "",
  userId: "",
};

const createKonnectorSlice = createSlice({
  name: "createKonnector",
  initialState,
  reducers: {
    saveKonnectorLoading: (state, data) => {
      state.apiKey = data.payload.apiKey;
      state.userId = data.payload.userId;
      state.status = StatusType.loading;
    },
    saveKonnectorSuccess: (state) => {
      state.created = true;
      state.status = StatusType.success;
      state.data.webhookUrl = { url: "", id: "" };
    },
    saveDataMapping: (state, data: PayloadAction<any>) => {
      const sourceId = state?.data?.trigger?.outputFields?.schema?.sourceId;
      // const findActionIndex = state.data.actions.findIndex((action) => action.id === state.currentActionId);
      // state.data.actions[findActionIndex].actionMode = ActionMode.View;
      const serializeData = serializeDataMapping(state.data.actions[0].inputFields.data, data.payload, sourceId);

      const serializelookup = serializeLookupDataMapping(
        state.data.actions[0].inputFields.data,
        data.payload
        // sourceId
      );

      state.data.actions[0].dataMapping = serializelookup;
      state.data.actions[0].dataMapping = serializeData;
      state.data.actions[0].rawDataMapping = data.payload;
      state.data.actions[0].shouldReconfigure = false;
      state.actionMode = ActionMode.Save;
      state.currentActionId = "";
    },

    fetchOutputFieldsSuccess: (state, data: PayloadAction<any>) => {
      state.status = StatusType.success;
      // state.data.trigger.outputFields.fields = data.payload.sampleData;
      state.data.trigger.connectionId = data.payload.connectionConfigId;
      state.data.trigger.outputFields.schema = data.payload.outputFieldsSchema;
      state.data.trigger.outputFields.hasData = true;
    },
    fetchInputFieldsSuccess: (state, data: PayloadAction<any>) => {
      state.tabValidated = StatusType.success;
      // state.data.trigger.outputFields.fields = data.payload.sampleData;
      state.data.actions[0].connectionId = data.payload.connectionConfigId;
      state.data.actions[0].inputFields.data = data.payload.inputFieldsSchema.data;
      // state.data.trigger.outputFields.hasData = true;
    },
    resetCreateKonnector: () => initialState,
  },
});

export const {
  saveKonnectorLoading,
  saveKonnectorSuccess,
  saveDataMapping,
  fetchOutputFieldsSuccess,
  fetchInputFieldsSuccess,
  resetCreateKonnector,
} = createKonnectorSlice.actions;

export default createKonnectorSlice.reducer;
