import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Options } from "../types";

const TextField: React.FC<Options> = ({ type, pattern, name, required, register, errors,placeholder }) => {
  const getFormConfigs = () => {
    if (required) {
      return {
        required: { value: required.value, message: required.message },
        pattern: {
          value: new RegExp(pattern.value, pattern.flags),
          message: pattern.message,
        },
      };
    }
    return {
      pattern: {
        value: new RegExp(pattern.value, pattern.flags),
        message: pattern.message,
      },
    };
  };

  return (
    <div>
      <input
        type={type}
        placeholder={placeholder || "Enter the fixed value"}
        className={`bg-gray-50 border ${
          errors[name] ? "border-red-600 focus:border-red-800" : "border-gray-300"
        } text-gray-900 text-sm rounded-sm block w-full p-2.5`}
        {...register(name, getFormConfigs())}
      />
      {/* <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className="mt-2 text-sm text-red-600 dark:text-red-500">{message}</p>}
      /> */}
    </div>
  );
};

export default TextField;
