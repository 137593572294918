import useSWR from "swr";
import { authRequest, axiosInstance } from "./libs/fetch";

const options: any = {
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  withCredentials: true,
};
export function loginApi(type) {
  return authRequest.get(`/${type}`);
}

export function logoutApi() {
  return authRequest.get(`/logout`, options);
}

export function useCurrentUserApi() {
  return useSWR(`/users/current_user`, authRequest);
}
export function useCurrenEndtUserApi(data) {
  return useSWR(`/end_users/current_enduser/${data.apiKey}/${data.userId}`, authRequest);
}
export function currentUserApi() {
  return authRequest.get(`/users/current_user`, options);
}


export function validateEndUserApi() {
  return authRequest.get(`/end_users/validate_api`, options);
}

// export function portalCustomizationdata(){
//   return 
// }
export function getPortaldata() {
  return axiosInstance.get(`/endusers/apps/portal`, options);
}
export function getAdminApi() {
  return authRequest.get(`/end_users/`, options);
}

export function saveUserApi(userId, payload) {
  return authRequest.put(`/users/${userId}`, payload, options);
}

export function signInApi(payload) {
  return authRequest.post(`/register`, payload, options);
}

export function embedSignInApi(payload) {
  return authRequest.post(`/embedded/signup`, payload, options);
}

export function loginLocalApi(payload) {
  return authRequest.post(`/login`, payload, options);
}
export function EmbeddedloginLocalApi(payload) {
  return authRequest.post(`/login`, payload, options);
}

export function forgotPasswordApi(payload) {
  return authRequest.post(`/forgot_password`, payload, options);
}
export function resetPasswordApi(token, payload) {
  return authRequest.post(`/reset_password?token=${token}`, payload, options);
}

export function verifyUserApi(payload, tenantId) {
  const requestOptions = {
    ...options,
    headers: {
      ...options.headers,
      tenantId: tenantId,
    },
  };
  return authRequest.post(`/send_verification_email`, payload, requestOptions);
}
