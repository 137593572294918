import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "state/store";

export const BreadCrumbIcon: React.FC<any> = ({ className, fill }) => {
  const customisation = useAppSelector((state) => state.auth.customisation);
  const new_Customization = useAppSelector((state) => state.auth.portalConfigration);
  const navigate = useNavigate();
  const handleClick = () => {
    // navigate(-2);
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="3.5"
      stroke={new_Customization?.bodytext_color ? new_Customization?.bodytext_color : fill}
      className={className}
      onClick={handleClick}
    >
      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
    </svg>
  );
};
