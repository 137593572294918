import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "state/store";
import { AppsDetails, Choice, CurrentState } from "state/account/types";
import SourceFields from "./SourceFields.tsx/index.tsx";
import FieldValueType from "./FieldValueType/index.tsx";
import SourceTypes from "./SourceTypes/index.tsx";
import { konnectorIdle, resetAccount, resetStatus, saveDataMapping } from "state/account/slices";
import { ToastError, ToastSucess } from "common/components/toast/Toast";
import { StatusType } from "state/edit-workflow/types";
import { useLocation, useNavigate } from "react-router-dom";
import { flatSourceOutputFields } from "utils/appsUtils.ts";
import axios, { AxiosResponse } from "axios";
import { validateEndUserApi } from "api/authApi.ts";
import { setUserData, settoasttoastBoolean } from "state/auth/slices/index.ts";
interface InputSchemaType {
  appDetails: AppsDetails;
}

const InputSchema: React.FC<any> = ({
  appDetails,
  filterData,
  register,
  errors,
  handleSubmit,
  reset,
  getValues,
  control,
  setValue,
  watch,
}) => {
  const dispatch = useAppDispatch();
  const hasDataMapping = appDetails.input;
  const rawDataMapping = appDetails?.rawDataMapping;
  const [newObject, setnewObject] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const apiKey = useAppSelector((state) => state.auth.apiKey);
  const userId = useAppSelector((state) => state.auth.email);
  const konnectorStatus = useAppSelector((state) => state.account.isWorkflowSaved);
  const customisation_banner = useAppSelector((state) => state.auth.banner);
  let inputSchema = appDetails.output;
  const apiKeyAuth = useAppSelector((state) => state.auth.apiKey);
  const newdatass = useAppSelector((state) => state?.editTemplate?.data.actions[0]?.rawDataMapping);
  const getkonnectorstate = useAppSelector((state) => state?.editTemplate?.status);
  const triggerEventId = useAppSelector((state) => state?.editTemplate?.data.trigger.eventId);
  const toastBoolean = useAppSelector((state) => state.auth.toastBoolean);
  const currentSate = useAppSelector((state) => state.account.currentState);
  const params = new URLSearchParams(location.search);
  let tabs = useAppSelector((state) => state.account.tabItems);
  let showtab = tabs.map((data) => data.appSelectorType == "lookup");
  let ops = tabs.filter((daa) => daa?.eventId && daa.appSelectorType == "lookup");
  const url = window.location.href;
  const appName = useAppSelector((state) => state.configurationV2.data);
  const inputIconUrl = useAppSelector((state) => state.dataMapping.inputIconUrl);
  const outputIconurls = useAppSelector((state) => state.dataMapping.outputIconurls[0]);
  const outputAppName =
    appName?.sourceAppId == appDetails.output[0]?.appId
      ? appName?.sourceAppDetails.appName
      : appName?.targetAppDetails.appName;
  const inputAppName =
    appName?.sourceAppId == appDetails.input?.appId
      ? appName?.sourceAppDetails.appName
      : appName?.targetAppDetails.appName;
  const userIndex = url.indexOf("user=");
  var cc;
  if (userIndex !== -1) {
    const userEndIndex = url.indexOf("&", userIndex);
    if (userEndIndex !== -1) {
      cc = url.substring(userEndIndex + 1);
    } else {
      cc = "";
    }
  } else {
    // return null;
  }

  let commonelement = appDetails.output?.filter((item1) =>
    ops.some((item2) => item1?.eventId == item2?.eventId && item2?.appSelectorType == "lookup" && item2.data.appName)
  );
  const objectData = commonelement?.reduce((acc, obj) => {
    Object.assign(acc, obj);
    return acc;
  }, {});

  const appIds = useAppSelector((state) => {
    if (currentSate == CurrentState.Konnectors) {
      return state.editKonnector.data.workflowAppId;
    } else {
      return state.editTemplate.data.workflowAppId;
    }
  });

  let restdata = flatSourceOutputFields(appDetails?.output[0]?.eventResponse?.data)
    .filter((dat) => dat.propType !== "object" && dat.propType !== "array")
    .map((sel) => ({
      label: sel.label || sel.propName,
      value: sel.propName,
      sourceId: appDetails.output[0].sourceId,
      eventId: appDetails.output[0].eventId,
    }));
  let data_one: any = commonelement.map(
    (res) =>
      res.eventId &&
      flatSourceOutputFields(res?.eventResponse.data)
        .filter((dat) => dat.propType !== "object" && dat.propType !== "array")
        .map((sel) => ({
          label: sel.propName,
          value: sel.propName,
          sourceId: res.sourceId,
          eventId: res.eventId,
        }))
  );
  const concatenatedArray = [];

  for (let i = 0; i < data_one.length; i++) {
    for (let j = 0; j < data_one[i].length; j++) {
      concatenatedArray.push(data_one[i][j]);
    }
  }

  const newdata = useAppSelector((state) => {
    if (currentSate == CurrentState.Konnectors) {
      return state.editKonnector.data.actions[0]?.rawDataMapping;
    } else {
      return state.editTemplate.data.actions[0]?.rawDataMapping;
    }
  });
  let input_schema = !showtab ? restdata : restdata.concat(concatenatedArray);
  const mergeObjects = (...objects) => {
    const result = {};

    objects.forEach((obj) => {
      Object.entries(obj || {}).forEach(([key, value]) => {
        if (!result[key]) {
          result[key] = [value];
        } else {
          result[key].push(value);
        }
      });
    });

    return result;
  };

  const renderFieldSchema = () => {
    var arrData;
    if (filterData == undefined) {
      arrData = appDetails.input.data.filter((appData) => {
        return appData.propName != "";
      });
    } else {
      arrData = filterData;
    }
    return arrData?.map((field) => {
      return (
        <>
          <FieldValueType field={field} name={`${field.propName}__field_type`} register={register} errors={errors} />
          <SourceTypes
            field={field}
            name={`${field.propName}__field_source_type`}
            control={control}
            watch={watch}
            register={register}
            errors={errors}
          />
          <SourceFields
            setInputValue={setValue}
            field={field}
            control={control}
            getValues={getValues}
            watch={watch}
            register={register}
            errors={errors}
            inputSchema={input_schema}
            appDetails={appDetails}
            setnewObject={setnewObject}
            newObject={newObject}
          />
        </>
      );
    });
  };
  const options: any = {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
  };
  const apiFunc = async () => {
    let Endresult = await validateEndUserApi();
    dispatch(setUserData(Endresult.data));
  };
  useEffect(() => {
    if (konnectorStatus === StatusType.success) {
      dispatch(settoasttoastBoolean(true));
    } else if (konnectorStatus === StatusType.failed) {
      ToastError(customisation_banner.failureMsg);
    }
  }, [konnectorStatus]);

  useEffect(() => {
    if (toastBoolean) {
      // const timeoutId = setTimeout(() => {

      // }, 8000);

      const navigationTimeoutId = setTimeout(() => {
        navigate(`/apps/${appIds}/templates?apiKey=${apiKeyAuth}&user=${userId}&${cc}`);

        apiFunc();
        dispatch(settoasttoastBoolean(false));

        dispatch(konnectorIdle());
      }, 2000);

      return () => {
        // clearTimeout(timeoutId);
        clearTimeout(navigationTimeoutId);
      };
    }

    // else if (!toastBoolean){
    //   apiFunc();
    //   navigate(`/apps/${appIds}/templates?apiKey=${apiKeyAuth}&user=${userId}`);
    //   dispatch(konnectorIdle());
    // }
  }, [toastBoolean]);

  let fieldsWithSourceTypes = Object?.keys(newdata || {})?.filter(
    (field) => field?.endsWith("__field_source_type") && !field.includes("trigger")
  );

  let updatedData = { ...newdata };
  fieldsWithSourceTypes.forEach((fieldWithSourceType) => {
    const fieldName = fieldWithSourceType.replace("__field_source_type", "");
    const fieldValues = newdata[fieldName];
    const sourceType = newdata[fieldWithSourceType].split(":").pop().trim();
    if (Array.isArray(fieldValues)) {
      let trimmedValues = fieldValues?.map((value) => value?.trim());

      updatedData[fieldName] = trimmedValues?.map((value) => `${sourceType} : ${value}`);
    }
  });
  if (typeof newdata === "object" && newdata !== null) {
    let fieldsWithSourceTypess = Object?.keys(newdata)?.filter(
      (field) => field?.endsWith("__field_source_type") && !field?.includes("trigger")
    );

    let rawdata = { ...rawDataMapping };
    fieldsWithSourceTypess?.forEach((fieldWithSourceType) => {
      const fieldName = fieldWithSourceType?.replace("__field_source_type", "");
      const fieldValues = newdata[fieldName];
      const sourceType = newdata[fieldWithSourceType]?.split(":")?.pop()?.trim();
      if (Array.isArray(fieldValues)) {
        const trimmedValues = fieldValues.map((value) => value?.trim());

        rawdata[fieldName] = trimmedValues.map((value) => `${sourceType} : ${value}`);
      }
    });
  }
  useEffect(() => {
    reset({ ...newdatass });
  }, [newdatass, getkonnectorstate]);
  return (
    <>
      <>
        {}
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <div className="flex flex-row gap-4 ">
          <div>
            <div className="flex flex-row space-x-56">
              <div className="text-base flex flex-row space-x-3 justify-center items-center">
                <img src={inputIconUrl} className="h-5 w-5"></img>
                <div>{inputAppName}</div>
              </div>
              <div className="text-md flex flex-row space-x-3 justify-center items-center">
                <img className="h-5 w-5" src={outputIconurls?.icon}></img>
                <div>{outputAppName}</div>
              </div>
            </div>

            <>{hasDataMapping && <>{renderFieldSchema()}</>}</>
          </div>
        </div>
        {/* </form> */}
      </>
    </>
  );
};

export default InputSchema;
