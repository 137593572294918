import { AxiosResponse } from "axios";
import { takeEvery, put, call, select } from "redux-saga/effects";
import {
  logoutApi,
  currentUserApi,
  verifyUserApi,
  forgotPasswordApi,
  resetPasswordApi,
  validateEndUserApi,
} from "api/authApi";
import {
  login,
  loginSuccess,
  loginError,
  logout,
  logoutSuccess,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  forgotPasswordLoading,
  resetPasswordLoading,
  isVerified,
  isVerifiedError,
  isMailSent,
  setUserData,
  setBackgroundColor,
  setPortal,
  settenantid,
  // setPortaltemplate,
} from "./slices";
import { setApikey, setTenantId } from "api/libs/fetch";
import { getPortaldata } from "api/konnectorsApi";
import { useGetportal } from "@/api/appsApi";
const isDemo = false;

function* loginUser(data) {
  try {
    if (isDemo) {
      // setTenantId("109575191505870530182");`
      setTenantId("118350845587774157575");
      yield put(
        loginSuccess({
          name: "snekha",
          eventsLimit: 10000,
          email: "Snekha@konnectify.co",
          tenantId: "118350845587774157575",
          dtUpdated: "2022-11-27T05:57:33.030Z",
          id: "DHcPovUr8d",
          currentPlan: "paid",
          dtCreated: "2022-10-17T10:36:52.401Z",
          taskExecutedCount: 482,
          hasTrial: false,
          stripeId: "cus_Md1WHGObYpE34b",
          isEmailVerified: true,
          planExpired: false,
        })
      );
    } else {
      //  ;
      setApikey(data.payload);
      setTenantId(data.payload.api);

      yield put(settenantid(data.payload.api));
      const newdata: AxiosResponse<any> = yield call(getPortaldata, data.payload.api);
      if (newdata.data.data.length == 0) {
        yield put(
          setPortal({
            customization: {
              title: "<p>Embedded MarketPlace</p>",
              description:
                " <p>Enable one-click execution of your workflows through automation solutions, streamlining processes and minimizing manual effort.</p>",
              primary_button_colour: "#0b0909",
              primary_colour: "#ffffff",
              text_colour: "#ffffff",
              font: "Arimo",
            },
            // portalConfigration: {
            //   bodytext: "#ffffff",
            //   bodytext_color: "#0b0909",
            //   fontFamily: "Arimo",
            //   portalDescription:
            //     "<p>Enable one-click execution of your workflows through automation solutions, streamlining processes and minimizing manual effort.</p>",
            //   portalTitle: "<p>Embedded MarketPlace</p>",
            //   primary_bgColor: "#ffffff",
            //   primary_bgColor_text: "#ffffff",
            //   primary_button_Color: "#5f5d7e",
            //   primary_button_Text: "",
            //   secondary_button_Color: "#e8e8e8",
            //   secondary_button_tex: "#e8e8e8",
            // },
            logo: {
              url: "",
              colour: "#0e104e",
              size: "6",
            },
            banner: {
              bordercolour: "#f0f0f0",
              failureMsg: "Error in saving the konnector",
              heigth: "45",
              radius: "3",
              successMsg: "Konnector saved successfully",
              width: "300",
            },
            template: {
              bgColor: "#f0f0f0",
              borderRadius: "4",
              heigth: "85",
              hoverColor: "#e6e6e6",
              textColor: "#181616",
              width: "600",
              togglebuttoncolor: "#ea8e40",
              templateSnippet: "",
              templateSnippetStatus: "false",
            },
            layout: {
              heigth: "20",
              width: "20",
            },
            card: {
              backgroundcolor: "#f0f0f0",
              borderRadius: "3",
              gridValues: "1",
              heigth: "102",
              hoverColor: "#dad8d8",
              textColor: "#a65454",
              view: "Grid",
              width: "90",
              cardSnippet: "",
              cardSnippetStatus: "false",
              gridsize: "4",
              bordercolour: "#F0F0F0",
            },
          })
        );
      } else {
        yield put(setPortal(newdata.data.data[0]));
      }
      const result: AxiosResponse<any> = yield call(validateEndUserApi);

      // ;

      if (result.data.statusCode === 404) {
        yield put(loginError(result.data.message));
      } else {
        yield put(setUserData(result.data));
        // setTenantId(result.data.tenantId);
        yield put(loginSuccess({ payload: result.data, accCred: data.payload.accCred }));

        // ;
        // yield put(setBackgroundColor(result.data.admin));
        // yield put(setPortaltemplate(portal_Object));
      }
    }
  } catch (error) {
    yield put(loginError(error.message));
  }

  try {
  } catch (e) {}
}

// Remove cookie from Express
function* logoutUser() {
  try {
    if (isDemo) {
      yield put(logoutSuccess());
    } else {
      yield call(logoutApi);
    }
  } catch (error) {
    yield put(logoutSuccess());
  }
}

function* forgotPassword(response) {
  try {
    const result: any = yield call(forgotPasswordApi, response.payload);
    if (result.data.error) {
      yield put(forgotPasswordError(result.data.error.errorMessage));
    } else {
      yield put(forgotPasswordSuccess());
    }
  } catch (error) {
    yield put(forgotPasswordError(error.message));
  }
}

function* resetPassword(action: any) {
  try {
    const result: any = yield call(resetPasswordApi, action.payload.token, action.payload.body);
    if (result.data.error) {
      yield put(resetPasswordError(result.data.error.errorMessage));
    } else {
      yield put(resetPasswordSuccess());
    }
  } catch (error) {
    yield put(resetPasswordError(error.message));
  }
}

function* verifyUser() {
  try {
    const user = yield select((state) => state.auth.currentUser);
    const data = { email: user.email };
    const tenantId = user.tenantId;
    const result: AxiosResponse<any> = yield call(verifyUserApi, data, tenantId);
    if (result.data.statusCode === 401) {
      yield put(isVerifiedError(result.data.message));
    } else {
      yield put(isMailSent());
    }
  } catch (error) {
    yield put(isVerifiedError(error.message));
  }
}

export default function* rootSaga() {
  yield takeEvery(login.type, loginUser);
  yield takeEvery(logout.type, logoutUser);
  yield takeEvery(forgotPasswordLoading.type, forgotPassword);
  yield takeEvery(resetPasswordLoading.type, resetPassword);
  yield takeEvery(isVerified.type, verifyUser);
}
