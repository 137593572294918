import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitialState, StatusType } from "../types";

export const initialState: InitialState = {
  data: {
    id: "",
    targetAppId: "",
    sourceAppId: "",
    appName: "",
    sourceAppName: "",
    sourceAppConnectionId: "",
    targetAppConnectionId: "",
    sourceAppDetails: {},
    targetAppDetails: {},
    sourceAppFields: [],
    targetAppFields: [],
    sourceAppFilledFields: [],
    targetAppFilledFields: [],
    apps: [],
    sourceAppValidated: false,
    targetAppValidated: false,
    istargeAppFailed: false,
    isConfigured: false,
    apiKey: "",
    userId: "",
    currenttabindex: 0,
  },
  status: StatusType.idle,
  appStatus: StatusType.idle,
  lookupAppStatus: StatusType.idle,
  tabOrderId: 0,
  lookupAppDetails: [],
};

const configurationV2Slice = createSlice({
  name: "configurationV2",
  initialState,
  reducers: {
    setTargetAppAndAccountLoading: (state, data) => {
      state.data.targetAppId = data.payload.id;
      state.data.apiKey = data.payload.apiKey;
      state.data.userId = data.payload.user;
      state.status = StatusType.loading;
    },
    setEditAppConfigState: (state, data) => {
      state.data = data.payload;
      state.data.isConfigured = true;
      state.status = StatusType.success;
    },
    setTargetAppAndAccountSuccess: (state, data) => {
      state.data.targetAppDetails = data.payload.targetAppDetails;
      state.data.sourceAppDetails = data.payload.sourceAppDetails;
      state.data.targetAppFields = data.payload.targetAppDetails.connectionConfig.fields;
      state.data.sourceAppFields = data.payload.sourceAppDetails.connectionConfig.fields;
      state.data.targetAppId = data.payload.targetAppDetails.id;
      state.data.sourceAppId = data.payload.sourceAppDetails.id;
      state.data.apps = [data.payload.sourceAppDetails, data.payload.targetAppDetails, { id: "templates", index: 2 }];
      state.status = StatusType.success;
    },
    setOAuthAccountLoading: (state, data: PayloadAction<any>) => {
      state.status = StatusType.loading;
    },
    setUpdateOAuthAccountLoading: (state, data) => {
      state.status = StatusType.loading;
    },
    createAccountSuccess: (state, data: PayloadAction<any>) => {
      state.status = StatusType.success;
      data.payload.data.appId == state.data.sourceAppId
        ? (state.data.sourceAppConnectionId = data.payload.data.id || data.payload.data.data.id)
        : (state.data.targetAppConnectionId = data.payload.data.id || data.payload.data.data.id);
      data.payload.data.appId == state.data.sourceAppId
        ? (state.data.sourceAppFilledFields = data.payload.data.fields || data.payload.data.data.fields)
        : (state.data.targetAppFilledFields = data.payload.data.fields || data.payload.data.data.fields);
      data.payload.data.appId == state.data.sourceAppId
        ? (state.data.sourceAppValidated = true)
        : (state.data.targetAppValidated = true);
    },
    setTargetAppValidated: (state, data) => {
      state.data.targetAppValidated = data.payload;
    },
    setTargeAppFailed: (state, data) => {
      state.data.istargeAppFailed = data.payload;
    },
    createLookupAccountSuccess: (state, data) => {
      state.lookupAppDetails = [...state.lookupAppDetails, data.payload];
      state.lookupAppStatus = StatusType.success;
    },
    accountApiFailed: (state, data) => {
      state.status = StatusType.failed;
    },
    accountUpdateApiFailed: (state) => {
      state.status = StatusType.failed;
    },
    updateAccountSuccess: (state, data: PayloadAction<any>) => {
      state.status = StatusType.success;
    },
    setAccountLoading: (state, data: PayloadAction<any>) => {
      state.status = StatusType.loading;
      if (data.payload.isLookup) {
        state.lookupAppStatus = StatusType.loading;
      }
    },
    setUpdateLoading: (state, data: PayloadAction<any>) => {
      state.status = StatusType.loading;
    },
    validateAccountSuccess: (state, data: PayloadAction<any>) => {
      state.status = StatusType.success;
    },
    updateValidationSuccess: (state, data) => {
      state.status = StatusType.success;
    },
    saveConfigurationLoading: (state) => {
      state.appStatus = StatusType.loading;
    },
    updateConfigurationLoading: (state) => {
      state.appStatus = StatusType.loading;
    },
    appCreationSuccess: (state) => {
      state.appStatus = StatusType.success;
    },
    appCreationFailure: (state) => {
      state.appStatus = StatusType.failed;
    },
    resetLookupDetails: (state) => {
      state.lookupAppDetails = [];
    },
    setTabOrderId: (state, data) => {
      state.tabOrderId = data.payload;
    },
    setcurrenttabindex: (state, data) => {
      state.data.currenttabindex = data.payload;
    },
    nexttab: (state, data) => {
      // state.status = StatusType.success;
      state.data.currenttabindex += 1;
    },
    resetConfigurationV2: () => initialState,
  },
});

export const {
  setTargetAppAndAccountLoading,
  setTargetAppAndAccountSuccess,
  setOAuthAccountLoading,
  setUpdateOAuthAccountLoading,
  createLookupAccountSuccess,
  createAccountSuccess,
  accountApiFailed,
  accountUpdateApiFailed,
  updateAccountSuccess,
  setAccountLoading,
  setUpdateLoading,
  nexttab,
  setcurrenttabindex,
  validateAccountSuccess,
  updateValidationSuccess,
  saveConfigurationLoading,
  setEditAppConfigState,
  appCreationSuccess,
  appCreationFailure,
  updateConfigurationLoading,
  resetLookupDetails,
  setTabOrderId,
  setTargetAppValidated,
  setTargeAppFailed,
} = configurationV2Slice.actions;

export default configurationV2Slice.reducer;
