import useSWR from "swr";
import { fetcher, axiosInstance, options, setTenantId, setApikey } from "./libs/fetch";
const PAGE_LIMIT = 200;
export default function useKonnectors(appId, page, sortOrder = "", filterBy, userId) {
  const limit = PAGE_LIMIT;
  const queryValue = sortOrder.split("-")[0] || "";
  const sortId = sortOrder.split("-")[1] || "";
  return useSWR(
    `/endusers/konnectors/${appId}/${userId}/all?pageNumber=${page}&pageSize=${limit}${
      filterBy !== "active" ? "&sortValue=" + queryValue + "&sortOrder=" + sortId : ""
    }${
      filterBy === "active" ? "&filterBy=status" : filterBy === "freshdesk" ? "&filterBy=sourceTemplate.freshdesk" : ""
    }`,
    fetcher
  );
}
export function useKonnectorss(appId, page, sortOrder = "", filterBy, limit = PAGE_LIMIT) {
  const queryValue = sortOrder.split("-")[0] || "";
  const sortId = sortOrder.split("-")[1] || "";
  return useSWR(`/endusers/konnectors/all`, fetcher);
}
export function useKonnectorApi(data) {
  return axiosInstance.get(`/endusers/konnectors/${data.appId}`, options);
}
export function useKonnectorTrialPlan() {
  return useSWR(`/konnectors`, fetcher);
}
export function useKonnector(id) {
  return useSWR(`/konnectors/${id}`, fetcher);
}
export function useAllKonnector() {
  return useSWR(`/konnectors/all`, fetcher);
}
export function saveKonnectorApi(payload) {
  return axiosInstance.post(`/endusers/konnectors`, payload, options);
}
export function saveKonnectorDisplayName(data) {
  return axiosInstance.put(`/endusers/konnectors/${data.payload.id}/update-name`, { name: data.payload.name }, options);
}
export function getKonnector(payload) {
  return axiosInstance.post(`/endusers/konnectors`, payload, options);
}
export function updateKonnectorApi(konnectorId, payload) {
  return axiosInstance.put(`/endusers/konnectors/${konnectorId}`, payload, options);
}
export function getKonnectorApi(konnectorId) {
  return axiosInstance.get(`/endusers/konnectors/${konnectorId}`, options);
}
export function getPortaldata(tenantId) {
  return axiosInstance.get(`/endusers/apps/portal/${tenantId}`, options);
}

export function activateKonnectorApi(konnectorId) {
  return axiosInstance.post(`/endusers/konnectors/activate/${konnectorId}`, options);
}
export function deactivateKonnectorApi(konnectorId) {
  return axiosInstance.post(`/endusers/konnectors/deactivate/${konnectorId}`, options);
}

export async function deleteKonnectorApi(payload) {
  return await axiosInstance.delete(`/endusers/konnectors/${payload.konnectorId}`, options);
}
export function bulckDeleteKonnectorApi(selectedKonnectorId, store) {
  return axiosInstance.post(`/konnectors/bulk_actions?store=${store}`, { selectedKonnectorId }, options);
}
export function generateWebhookUrl(apikey: any, payload: any) {
  const requestOptions = {
    ...options,
    headers: {
      ...options.headers,
      tenantId: apikey,
    },
  };
  return axiosInstance.post(`/endusers/konnectors/generate_webhook`, payload, requestOptions);
}
export function getWebhookData(webhookId: any, konnectorType: any, apiKey: any) {
  const requestOptions = {
    ...options,
    headers: {
      ...options.headers,
      tenantId: apiKey,
    },
  };
  return axiosInstance.get(`/endusers/konnectors/webhook/${webhookId}?konnectorType=${konnectorType}`, requestOptions);
}
export function getWebhookFromTemplate(webhookId, konnectorType, apiKey, eventId) {
  const requestOptions = {
    ...options,
    headers: {
      ...options.headers,
      tenantId: apiKey,
    },
  };
  return axiosInstance.get(
    `/konnectors/webhook/${webhookId}/${eventId}?konnectorType=${konnectorType}`,
    requestOptions
  );
}
export function generateFormSampleDataApi(webhookId, tenantId, payload) {
  const requestOptions = {
    ...options,
    headers: {
      ...options.headers,
      tenantId: tenantId,
    },
  };
  return axiosInstance.post(`/konnectors/webhook/form_data/${webhookId}`, payload, requestOptions);
}
export function resetEditFormData(tenantId, payload) {
  const requestOptions = {
    ...options,
    headers: {
      ...options.headers,
      tenantId: tenantId,
    },
  };
  return axiosInstance.post(`/konnectors/reset_form`, payload, requestOptions);
}
export function resetWebhookDataApi(webhookId, apiKey) {
  const requestOptions = {
    ...options,
    headers: {
      ...options.headers,
      tenantId: apiKey,
    },
  };
  return axiosInstance.post(`/endusers/konnectors/webhook/reset/${webhookId}`, requestOptions);
}
export function deleteWebhookDataApi(webhookId, konnectorType, tenantId) {
  const requestOptions = {
    ...options,
    headers: {
      ...options.headers,
      tenantId: tenantId,
    },
  };
  return axiosInstance.delete(`/konnectors/webhook/${webhookId}?konnector_type=${konnectorType}`, requestOptions);
}
