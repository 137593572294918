import useWorkflow from "api/workflowApi";
import { getKonnectorsFilterType } from "state/konnector-list/selectors";
import { getTemplatesCurrentPage, getTemplatesFilterType, getTemplatesSortBy } from "state/template-list/selectors";
import { Link, useParams } from "react-router-dom";
import { useAppSelector } from "state/store";
import { useEffect, useState } from "react";
import EditIcon from "components/icons/Edit";
import DataMappingFunc from "./DataMapping";
import { useDispatch } from "react-redux";
import {
  getLookupSchemaLoading,
  resetDataMapping,
  saveKonnectorName,
  setKonType,
  setLookupData,
  setSchemaLoading,
  setTemplatePayload,
  setcurrentstate,
} from "state/data-mapping/slices";
import Apps from "pages/TemplateList/component/Templates/TemplateList/TemplateItem/Apps";
import { getKonnectorLoading } from "state/edit-template-settings/slices";
import useKonnectors from "api/konnectorsApi";
import Controls from "pages/TemplateList/component/Templates/TemplateList/konnectorItem/Controls";
import { mutate } from "swr";
import { resetLookupDetails, setTabOrderId } from "state/configurationv2/slices";
import { useCurrenEndtUserApi } from "api/authApi";
import { Popover } from "@mui/material";
import React from "react";
import { StatusType } from "state/new-konnector/types";
import { LoadingIcon } from "components/icons/Loading";
import { deleteKonnectorLoading } from "../../../state/konnector-list/slices";
import { Loader2 } from "lucide-react";
import { toast } from "react-toastify";

const WorkflowListing: React.FC<any> = () => {
  const dispatch = useDispatch();
  const sortBy = useAppSelector(getTemplatesSortBy);
  const filterBy = useAppSelector(getTemplatesFilterType);
  const filterByForKonnector = useAppSelector(getKonnectorsFilterType);
  const userId = useAppSelector((state) => state.auth.email);
  const apiKey = useAppSelector((state) => state.auth.apiKey);
  const status = useAppSelector((state) => state.dataMapping.status);
  const konnectorNameLoading = useAppSelector((state) => state.dataMapping.konnectorNameLoading);
  const { id } = useParams<any>();
  const currentPage = useAppSelector(getTemplatesCurrentPage);
  const [name, setName] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const ids = open ? "simple-popover" : undefined;
  const { data: templates, error: templateError } = useWorkflow(id, currentPage, sortBy, filterBy);
  const {
    data: konnectors,
    error: konnectorError,
    mutate: konnectorMutate,
  } = useKonnectors(id, currentPage, sortBy, filterByForKonnector, userId);

  const konnectorsState = useAppSelector((state) => state.konnectors);

  const { data: enduser, error: endusererror } = useCurrenEndtUserApi({ userId, apiKey });
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const accordionCard = useAppSelector((state) => state.dataMapping.accordionCard);
  const konnectorObj = useAppSelector((state) => state.dataMapping.konnectorArray);
  const [close, setClose] = useState("");
  const [listKonnector, setListKonnector] = useState([]);
  const [templateId, setTemplateid] = useState("");
  const [pushArray, setPushArray] = useState([]);
  const [konnector, setKonnetor] = useState(Array(templates?.data?.items.length).fill(false));
  const [accordions, setAccordions] = useState(Array(templates?.data?.items.length).fill(false));
  const [templatev2, setTemplatev2] = useState(Array(pushArray.length).fill(false));
  const [konnectorEdit, setKonnectorEdit] = useState(Array(listKonnector?.length).fill(false));
  const [konnectorNamePopup, setKonnectorNamePopup] = useState(Array(listKonnector?.length).fill(false));
  const [deletingKonnectorId, setDeletingKonnectorId] = useState("");
  const handleEditClick = (event) => {
    const newAccordions = Array(listKonnector.length).fill(false);
    newAccordions[event.index] = true;
    setKonnectorNamePopup(newAccordions);
    setAnchorEl(event?.e?.currentTarget);
  };
  const handlepopupclose = (data) => {
    const newAccordions = Array(listKonnector.length).fill(false);
    newAccordions[data.index] = false;
    setKonnectorNamePopup(newAccordions);
    setAnchorEl(null);
  };
  const handleClick = (data) => {
    if (data.AccordionType == "new") {
      setTemplateid(data.template.id);
      const cc = [...listKonnector];
      cc.push({ ...data.template, name: "New workflow" });
      setListKonnector(cc);
      setPushArray([{ ...data.template, name: "New workflow" }]);
      cc?.map((konnector, index) => {
        if (konnector.id == data.template.id) {
          dispatch(resetLookupDetails());
          dispatch(resetDataMapping());
          const newAccordions = Array(pushArray.length).fill(false);
          newAccordions[0] = true;
          setTemplatev2(newAccordions);
          dispatch(setcurrentstate("template"));
          dispatch(setTabOrderId(0));
          dispatch(setTemplatePayload({ ...data.template, name: "New workflow" }));
          const lengthId = data.template.konnectorApps.length - 1;
          const outputUrl = [];
          const dataPayload = {
            inputIconUrl: data.template.konnectorApps[lengthId].iconUrl,
            outputIconUrl: outputUrl,
          };
          dispatch(getKonnectorLoading({ appId: id, templateId: data.template.id, state: "template" }));
          dispatch(setKonType("Template"));
          data.template.konnectorApps.filter((app, index) => {
            if (app.appSelectorType == "lookup") {
              dispatch(getLookupSchemaLoading(app));
            }
            if (lengthId !== index) {
              const data = {
                icon: app.iconUrl,
                eventId: app.appEventId,
              };
              outputUrl.push(data);
            }
          });

          dispatch(setSchemaLoading(dataPayload));
        }
      });
    } else {
      setClose(data.index);
      if (accordions[data.index] || konnector[data.index]) {
        setTemplateid("");
        const newAccordions = Array(templates?.data?.items.length).fill(false);
        newAccordions[data.index] = false;
        setAccordions(newAccordions);
        const newAccordionss = Array(templates?.data?.items.length).fill(false);
        newAccordionss[data.index] = false;
        setKonnetor(newAccordionss);
        dispatch(resetDataMapping());
        dispatch(setLookupData(false));
        dispatch(resetLookupDetails());
      } else {
        setTemplateid(data.template.id);
        const getKonnector = konnectors?.data?.items.filter((konn) => {
          return konn.workflowId == data?.template.id;
        });
        if (getKonnector.length > 0) {
          setListKonnector(getKonnector);
          const newAccordions = Array(templates?.data?.items.length).fill(false);
          newAccordions[data.index] = true;
          setKonnetor(newAccordions);
        } else {
          setListKonnector([]);
          dispatch(resetLookupDetails());
          dispatch(resetDataMapping());
          const newAccordions = Array(templates?.data?.items.length).fill(false);
          newAccordions[data.index] = true;
          setAccordions(newAccordions);
          dispatch(setcurrentstate("template"));
          dispatch(setTabOrderId(0));
          dispatch(setTemplatePayload(data.template));
          const lengthId = data.template.konnectorApps.length - 1;
          const outputUrl = [];
          const dataPayload = {
            inputIconUrl: data.template.konnectorApps[lengthId].iconUrl,
            outputIconUrl: outputUrl,
          };
          dispatch(getKonnectorLoading({ appId: id, templateId: data.template.id, state: "template" }));
          dispatch(setKonType("Template"));
          data.template.konnectorApps.filter((app, index) => {
            if (app.appSelectorType == "lookup") {
              dispatch(getLookupSchemaLoading(app));
            }
            if (lengthId !== index) {
              const data = {
                icon: app.iconUrl,
                eventId: app.appEventId,
              };
              outputUrl.push(data);
            }
          });

          dispatch(setSchemaLoading(dataPayload));
        }
      }
    }
  };
  const handleClickv3 = (data) => {
    setClose(data.index);
    if (templatev2[0]) {
      // const newAccordionss = Array(konnectors?.data?.items.length).fill(false);
      // newAccordionss[data.index] = false;
      // setAccordionsv2(newAccordionss);
      const newAccordions = Array(pushArray.length).fill(false);
      newAccordions[0] = false;
      setTemplatev2(newAccordions);
      dispatch(resetDataMapping());
      dispatch(setLookupData(false));
      dispatch(resetLookupDetails());
    } else {
      dispatch(resetLookupDetails());
      dispatch(resetDataMapping());
      const newAccordions = Array(pushArray.length).fill(false);
      newAccordions[0] = true;
      setTemplatev2(newAccordions);
      dispatch(setcurrentstate("template"));
      dispatch(setTabOrderId(0));
      dispatch(setTemplatePayload(data.template));
      const lengthId = data.template.konnectorApps.length - 1;
      const outputUrl = [];
      const dataPayload = {
        inputIconUrl: data.template.konnectorApps[lengthId].iconUrl,
        outputIconUrl: outputUrl,
      };
      dispatch(getKonnectorLoading({ appId: id, templateId: data.template.id, state: "template" }));
      dispatch(setKonType("Template"));
      data.template.konnectorApps.filter((app, index) => {
        if (app.appSelectorType == "lookup") {
          dispatch(getLookupSchemaLoading(app));
        }
        if (lengthId !== index) {
          const data = {
            icon: app.iconUrl,
            eventId: app.appEventId,
          };
          outputUrl.push(data);
        }
      });

      dispatch(setSchemaLoading(dataPayload));
    }
  };

  const handleDeleteKonnector = (id) => {
    setDeletingKonnectorId(id);
    dispatch(deleteKonnectorLoading({ konnectorId: id }));
  };

  const handleClickv2 = (data) => {
    setClose(data.index);
    if (konnectorEdit[data.index]) {
      // const newAccordionss = Array(konnectors?.data?.items.length).fill(false);
      // newAccordionss[data.index] = false;
      // setAccordionsv2(newAccordionss);
      const newAccordions = Array(listKonnector.length).fill(false);
      newAccordions[data.index] = false;
      setKonnectorEdit(newAccordions);
      dispatch(resetDataMapping());
      dispatch(setLookupData(false));
      dispatch(resetLookupDetails());
    } else {
      dispatch(resetLookupDetails());
      dispatch(resetDataMapping());
      const newAccordions = Array(listKonnector.length).fill(false);
      newAccordions[data.index] = true;
      setKonnectorEdit(newAccordions);
      dispatch(setcurrentstate("Konnector"));
      dispatch(setTabOrderId(0));
      dispatch(setTemplatePayload(data.template));
      const lengthId = data.template.konnectorApps.length - 1;
      const outputUrl = [];
      const dataPayload = {
        inputIconUrl: data.template.konnectorApps[lengthId].iconUrl,
        outputIconUrl: outputUrl,
      };
      dispatch(getKonnectorLoading({ appId: id, templateId: data.template.id, state: "konnector" }));
      dispatch(setKonType("Konnector"));
      data.template.konnectorApps.filter((app, index) => {
        if (app.appSelectorType == "lookup") {
          dispatch(getLookupSchemaLoading(app));
        }
        if (lengthId !== index) {
          const data = {
            icon: app.iconUrl,
            eventId: app.appEventId,
          };
          outputUrl.push(data);
        }
      });

      dispatch(setSchemaLoading(dataPayload));
    }
  };

  // useEffect(() => {
  //   if (accordionCard && status == "success") {

  //     mutate(`/endusers/konnectors/${id}/${userId}/all?pageNumber=1&pageSize=200&sortValue=&sortOrder=`);
  //     setkonn(konnectors)

  //   }
  // }, [accordionCard, status]);

  useEffect(() => {
    if (accordionCard && status == "success") {
      if (listKonnector.length == 0) {
        mutate(`/endusers/konnectors/${id}/${userId}/all?pageNumber=1&pageSize=200&sortValue=&sortOrder=`);
        mutate(`/embedded/apps/${id}/all?pageNumber=1&pageSize=200&filterBy=status`);
        mutate(`/end_users/current_enduser/${apiKey}/${userId}`);

        // const getKonnector = konnectors?.data?.items.filter((konn) => {
        //   return konn.workflowId == templateId;
        // });
        setListKonnector([konnectorObj]);
        const newAccordions = Array(templates?.data?.items.length).fill(false);
        newAccordions[close] = true;
        setKonnetor(newAccordions);
      }
    }
  }, [accordionCard, status, konnectorNameLoading]);

  useEffect(() => {
    if (accordionCard) {
      if (accordions[close]) {
        mutate(`/endusers/konnectors/${id}/${userId}/all?pageNumber=1&pageSize=200&sortValue=&sortOrder=`);
        mutate(`/embedded/apps/${id}/all?pageNumber=1&pageSize=200&filterBy=status`);
        mutate(`/end_users/current_enduser/${apiKey}/${userId}`);
      }
    }
  }, [accordionCard, konnectorNameLoading]);
  useEffect(() => {
    if (accordionCard) {
      if (templatev2[0]) {
        mutate(`/endusers/konnectors/${id}/${userId}/all?pageNumber=1&pageSize=200&sortValue=&sortOrder=`);
        const newAccordions = Array(pushArray.length).fill(false);
        newAccordions[0] = false;
        setTemplatev2(newAccordions);
        const cc = [...listKonnector];
        cc.push(konnectorObj);
        setListKonnector(cc);
        const removeArray = pushArray[0].id;
        const updatedList = cc.filter((item) => item.id !== removeArray);
        setListKonnector(updatedList);
      }
    }
  }, [accordionCard, konnectorNameLoading]);

  useEffect(() => {
    if (konnectorNameLoading == "success" || konnectorsState.hasDeleted) {
      konnectorMutate();

      // mutate(`/endusers/konnectors/${id}/${userId}/all?pageNumber=1&pageSize=200&sortValue=&sortOrder=`);
      mutate(`/embedded/apps/${id}/all?pageNumber=1&pageSize=200&filterBy=status`);
      mutate(`/end_users/current_enduser/${apiKey}/${userId}`);

      const getKonnector = konnectors?.data?.items.filter((konn) => {
        return konn.workflowId == templateId;
      });

      setListKonnector(getKonnector);
    }
  }, [konnectorNameLoading, konnectors]);

  useEffect(() => {
    if (konnectorsState.hasDeleted && konnectorsState.deleteStatus == "success") {
      konnectorMutate();

      toast.success("Konnector deleted successfully", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [konnectorsState.hasDeleted]);

  if ((!templates?.data?.items || templateError) && (!konnectors || !konnectors?.data?.items || konnectorError)) {
    return (
      <>
        <div className="flex justify-center items-center bottom-10 h-64">
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-black"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        </div>
      </>
    );
  }
  return (
    <div className="p-4">
      {templates &&
        templates.data.items?.map((template, index) => {
          return (
            <>
              <div
                key={index}
                className={`flex flex-col rounded-lg border-[1px] border-gray-300 w-[100%] p-4 pb-6 ${
                  accordions[index] || konnector[index] ? `h-[30rem]` : `h-16`
                } `}
              >
                <div className="flex flex-row gap-7" id={"demo"}>
                  <div className="basis-[60%]">
                    <p className="text-sm text-komp-color font-semibold table hover:text-komp-hover-color cursor-pointer">
                      {template.name}
                    </p>
                  </div>
                  <div className="flex justify-between basis-[40%]">
                    <div>
                      {" "}
                      <Apps
                        apps={template?.konnectorApps}
                        ruleConfig={template?.ruleConfig?.length}
                        template={template}
                      />
                    </div>
                    <div className="flex flex-row gap-9">
                      <div onClick={() => handleClick({ template: template, index, AccordionType: "new" })}>
                        {/* <TemplateControls konnector={template} status={template.status} name={template.name} />{" "} */}
                        <button>New</button>
                      </div>
                      <div onClick={() => handleClick({ template: template, index })}>
                        {" "}
                        <span className="cursor-pointer underline text-blue-500 hover:text-blue-700">Edit</span>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
                <div className="overflow-scroll flex flex-col space-y-3">
                  {konnector[index]
                    ? listKonnector?.map((konnector, index) => {
                        if (konnector.id == template.id) {
                          return (
                            <div
                              key={index}
                              className={`flex flex-col rounded-lg border-[1px] border-gray-300 w-[95%] p-4 pb-6 
                        ${templatev2[0] ? `h-[30rem]` : `h-16`} `}
                            >
                              <div className="flex flex-row gap-7" id={"demo"}>
                                <div className="basis-[60%] flex flex-row space-x-4">
                                  <p
                                    className="text-sm text-komp-color font-semibold table hover:text-komp-hover-color cursor-pointer"
                                    // onClick={() => handleClickv2({ template: template, index })}
                                  >
                                    {konnector.name}
                                  </p>
                                  <div onClick={handleEditClick}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="lucide lucide-square-pen"
                                    >
                                      <path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                                      <path d="M18.375 2.625a2.121 2.121 0 1 1 3 3L12 15l-4 1 1-4Z" />
                                    </svg>
                                  </div>
                                </div>
                                <div className="flex justify-between basis-[40%]">
                                  <div>
                                    <Apps
                                      apps={konnector?.konnectorApps}
                                      ruleConfig={konnector?.ruleConfig?.length}
                                      template={konnector}
                                    />
                                  </div>
                                  <div className="flex flex-row gap-9">
                                    {/* <div>
                                <Controls konnector={konnector} status={konnector.status} name={konnector.name} />
                              </div> */}
                                    <div onClick={() => handleClickv3({ template: konnector, index })}>
                                      <span className="cursor-pointer underline text-blue-500 hover:text-blue-700">
                                        Edit
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="overflow-scroll">
                                {templatev2[0] && <DataMappingFunc></DataMappingFunc>}
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              key={index}
                              className={`flex flex-col rounded-lg border-[1px] border-gray-300 w-[95%] p-4 pb-6 
                          ${konnectorEdit[index] ? `h-[30rem]` : `h-16`} `}
                            >
                              <div className="flex flex-row gap-7" id={"demo"}>
                                <div className="basis-[60%] flex flex-row space-x-4">
                                  {/* <Tooltip text={konnector.name}> */}
                                  <span
                                    className="text-sm text-komp-color font-semibold hover:text-komp-hover-color cursor-pointer max-w-[400px] h-fit overflow-hidden whitespace-nowrap text-ellipsis"
                                    onClick={() => handleClickv2({ template: konnector, index })}
                                  >
                                    {konnector.name}
                                  </span>
                                  {/* </Tooltip> */}
                                  <div
                                    onClick={(e) => {
                                      handleEditClick({ index, e });
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="lucide lucide-square-pen"
                                    >
                                      <path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                                      <path d="M18.375 2.625a2.121 2.121 0 1 1 3 3L12 15l-4 1 1-4Z" />
                                    </svg>
                                  </div>
                                  {konnectorNamePopup[index] && (
                                    <Popover
                                      className="backdrop-blur-sm"
                                      id={ids}
                                      open={open}
                                      anchorEl={anchorEl}
                                      anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                      sx={{ shadows: 0 }}
                                    >
                                      <div className="p-5 flex flex-col space-y-3 border-[1px]">
                                        <label>Edit Konnector Name</label>
                                        <input
                                          className="w-96 border-[1px] border-slate-500 p-1"
                                          defaultValue={konnector.name}
                                          onChange={(e) => {
                                            setName(e.target.value);
                                          }}
                                        ></input>
                                        <div className="flex flex-row space-x-2">
                                          <button
                                            className="bg-slate-700 text-white p-1 w-24 rounded-md disabled:opacity-60"
                                            disabled={konnectorNameLoading === StatusType.loading}
                                            onClick={() => {
                                              dispatch(saveKonnectorName({ name: name, id: konnector.id }));
                                            }}
                                          >
                                            <>
                                              {konnectorNameLoading === StatusType.loading ? (
                                                <>
                                                  <LoadingIcon />
                                                  {"Save..."}
                                                </>
                                              ) : konnectorNameLoading === StatusType.success ? (
                                                handlepopupclose({ index })
                                              ) : (
                                                "Save"
                                              )}
                                            </>
                                          </button>
                                          <button
                                            className="bg-slate-300 text-black p-1 w-24 rounded-md"
                                            onClick={() => {
                                              handlepopupclose({ index });
                                            }}
                                          >
                                            Close
                                          </button>
                                        </div>
                                      </div>
                                    </Popover>
                                  )}
                                </div>
                                <div className="flex justify-between basis-[40%]">
                                  <div>
                                    <Apps
                                      apps={konnector?.konnectorApps}
                                      ruleConfig={konnector?.ruleConfig?.length}
                                      template={konnector}
                                    />
                                  </div>
                                  <div className="flex flex-row gap-6">
                                    <div>
                                      <Controls konnector={konnector} status={konnector.status} name={konnector.name} />
                                    </div>
                                    <div onClick={() => handleClickv2({ template: konnector, index })}>
                                      <span className="cursor-pointer underline text-blue-500 hover:text-blue-700">
                                        Edit
                                      </span>
                                    </div>
                                    <div onClick={() => handleDeleteKonnector(konnector.id)}>
                                      <div className="flex ">
                                        {konnectorsState.deleteStatus == StatusType.loading &&
                                          deletingKonnectorId === konnector.id && (
                                            <Loader2 className="animate-spin  text-red-500 h-4 w-4 mt-1 mr-1" />
                                          )}
                                        <span className="cursor-pointer text-red-500 hover:text-red-700">Delete</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {konnectorEdit[index] && <DataMappingFunc></DataMappingFunc>}
                            </div>
                          );
                        }
                      })
                    : accordions[index] && (
                        <DataMappingFunc staticApp={currentUser.admin.staticApp} id={id}></DataMappingFunc>
                      )}
                </div>
              </div>
              <br></br>
            </>
          );
        })}
    </div>
  );
};
export default WorkflowListing;
