// import { parseType } from "state/parseEngine/types";
// import { ControlType } from "state/form-builder/type";
// import { FilterType } from "state/rule-engine/types";

import { FilterType } from "state/konnector-list/types";
import { DataMappingObject } from "state/new-konnector/types";

export interface Lookup {
  id: string;
  name: string;
}

export interface Required {
  value: boolean;
  message: string;
}

export interface Pattern {
  value: string;
  flags: string;
  message: string;
}

export interface Field {
  name: string;
  required: Required;
  type: string;
  pattern: Pattern;
}

export interface ConnectionConfig {
  connectorTypeId: string;
  fields: Field[];
}

export interface Trigger {
  id: string;
  name: string;
  eventSource: string;
}

export interface Action {
  name: string;
  id: string;
}

export interface Field2 {
  value: string;
  name: string;
}

export interface ConnectionConfigValue {
  tenantId: string;
  id: string;
  dtUpdated: Date;
  dtCreated: Date;
  fields: Field2[];
  connectorTypeId: string;
  connectionName: string;
  appId: string;
}

export interface AppDetail {
  description: string;
  lookups: Lookup[];
  connectionConfig: ConnectionConfig;
  iconUrl: string;
  appName: string;
  dtUpdated: Date;
  appKey: string;
  triggers: Trigger[];
  id: string;
  dtCreated: Date;
  actions: Action[];
  connectionConfigValues: ConnectionConfigValue[];
}

export interface KonnectorApp {
  appSelectorType: string;
  actionOrderId?: Number;
  appSelectorKey: string;
  appId: string;
  appEventId: string;
  appDetail: AppDetail | {};
  connectionId: string;
  connectionName: string;
}

export interface PropValue {
  label: string;
  value: string;
}

export interface PropChildren {
  propName: string;
  propType: string;
  isRequired: boolean;
  propValue: string;
}

export interface OutputSchema {
  propName: string;
  propType: string;
  isRequired: boolean;
  propValue: PropValue[];
  propChildren: PropChildren[];
}

export interface DataSource {
  sourceId: string;
  appEventId: string;
  appId: string;
  outputSchema: OutputSchema[];
  eventResponse?: {
    data: any[];
  };
}

export interface PropValue2 {
  label: string;
  value: any;
}

export interface PropChildren2 {
  propName: string;
  propType: string;
  isRequired: boolean;
}

export interface InputSchema {
  propName: string;
  propType: string;
  isRequired: boolean;
  propValue?: PropValue2[];
  propChildren?: PropChildren2[];
}

export interface TargetActionData {
  sourceId: string;
  appEventId: string;
  appId: string;
  inputSchema: InputSchema[];
}

export interface PropValue3 {
  label: string;
  value: any;
}

export interface PropChildren3 {
  propName: string;
  propType: string;
  isRequired: boolean;
}

export interface ActionProperty {
  propName: string;
  propType: string;
  isRequired: boolean;
  source: string;
  sourceProp: string[];
  propValue?: PropValue3[];
  propChildren?: PropChildren3[];
}

export interface TargetActionProperties {
  appId: string;
  appEventId: string;
  actionProperties: ActionProperty[];
}

export interface Condition {
  id: string;
  sourceProperty: string;
  clause: string;
  matchDate: string;
  matchContains: string;
  rank: number;
  sourceId: string;
}

export interface ConditionSet {
  id: string;
  conditions: Condition[];
  meets: string;
  sourceId: string;
}

export interface WorkflowFilter {
  conditionSets: ConditionSet[];
  meets: string;
}

export interface TriggerProps {
  subscriptionType?: string;
  recurringType?: string;
  minute?: number;
}

export interface TriggerDefinition {
  appId: string;
  eventId: string;
  triggerType: string;
  triggerProps: TriggerProps;
  inputFields: any;
  connectionId: string;
}

export enum KonnectorType {
  WebHook = "webhook",
  Regular = "regular",
  Form = "form",
  Initial = "",
}

export type ActionItemDataType = RuleDataType | LookupDataType | ActionDataType | ParseDataType;

export interface RootObject {
  name: string;
  description: string;
  iconUrl: string;
  status: string;
  konnectorType: KonnectorType;
  webhookUrl: { url: string; id: string };
  konnectorApps: KonnectorApp[];
  targetActionData: TargetActionData | {};
  triggerDefinition: TriggerDefinition;
  actionItems?: ActionItem<ActionItemDataType>[];
  ruleConfig: any;
  parseConfig: any;
  formFields?: FormFields[];
  formSettings: any;
  konnectorSource: any;
  workflowAppId?: string;
  workflowDescription?: string;
  workflowInstruction?: string;
  konnectorAppId?: string;
  adminRequiredData?: DataMappingObject[];
  workflowId?: string
}

export interface ActionItem<T> {
  actionOrderId: number;
  actionType: ActionType;
  data: T;
}

export interface RuleDataType {
  name: string;
  operator: string;
  filters: FilterType[];
}

export interface ParseDataType {
  id: string;
  source_value: [];
  pattern_type: string;
  patterns: parseType[];
  conditions?: Condition[];
  outputSchema?: any;
  actionProperties?: any;
  source_id: any;
}

export interface parseType {
  parsingItems: [];
}

export interface LookupDataType {
  sourceId: string;
  appEventId: string;
  appId: string;
  actionOrderId: number;
  outputSchema: any;
  eventProps: any;
}

export interface ActionDataType {
  appId: string;
  actionOrderId: number;
  appEventId: string;
  actionProperties: any;
  connectionId: string;
  dynamicInputFields: any;
}

export enum ActionType {
  Action = "action",
  Lookup = "lookup",
  RuleConfig = "ruleConfig",
  ParseConfig = "parseConfig",
}

export interface FormFields {
  orderId?: number;
  label: string;
  // inputType: ControlType;
  fieldName: string;
  sampleData: any;
  options?: SelectOptionVm[];
  config?: RegisterOptions;
}

export interface SelectOptionVm {
  label: string;
  isRepeated?: boolean;
  subCategory?: SubcategoryField[];
}

export interface SubcategoryField {
  label: string;
  items: ItemField[];
}

export interface ItemField {
  label: string;
}

interface RegisterOptions {
  requires: boolean;
  pattern?: string;
}

export interface FormFields {
  orderId?: number;
  label: string;
  // inputType: ControlType;
  description: string;
  fieldName: string;
  sampleData: any;
  options?: SelectOptionVm[];
  config?: RegisterOptions;
}

export interface SelectOptionVm {
  label: string;
  isRepeated?: boolean;
  subCategory?: SubcategoryField[];
}

export interface SubcategoryField {
  label: string;
  items: ItemField[];
}

export interface ItemField {
  label: string;
}

interface RegisterOptions {
  requires: boolean;
  pattern?: string;
}
