import React, { useEffect } from "react";
import TemplateItem from "./TemplateItem";
import { useAppSelector } from "state/store";

const TemplateList: React.FC<any> = ({ data }) => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const filteredTemplates = data?.filter((template) => !currentUser?.result?.workflows?.includes(template.id));
  // useEffect(() => {
  //   if (konnStatus === "success" && getKonnectors.currentActionId === konnector.id && !hasDeleted) {
  //     setKonnectorStatus(getKonnectors.hasActivated ? "active" : "inactive");
  //     toast.success(notificationMessage[getKonnectors.hasActivated], {
  //       position: "bottom-center",
  //       autoClose: 3000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     dispatch(konectorStatus())
  //   }
  // }, [getKonnectors.status]);

  return (
    <div>
      {filteredTemplates?.map((template) => (
        <TemplateItem key={template.id} template={template} />
      ))}
    </div>
  );
};

export default TemplateList;
