import React, { useState } from "react";
import { AppsProps } from "../types";
import AppItem from "./AppItem";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from "components/Spinner";
import { useAppSelector } from "state/store";

interface AppListProps extends AppsProps {
  eof: boolean;
  isTrue: boolean;
}

const AppList: React.FC<AppListProps> = ({ apps, eof, isTrue }) => {
  const themeCustomization = useAppSelector((state) => state.auth.Cardcustomisation);

  const Gridsize = themeCustomization.gridsize;
  const gridStyles = {
    display: "grid",
    gridTemplateColumns: `repeat(${Gridsize}, 1fr)`, // Dynamically set the number of columns
    gap: "4px", // Adjust the gap as needed
  };
  const cssString = themeCustomization.cardSnippet;

  function cssStringToObject(cssString) {
    const cssObject = {};

    const lines = cssString.split(";");

    lines.forEach((line) => {
      const [property, value] = line.split(":");
      if (property && value) {
        cssObject[property.trim()] = value.trim();
      }
    });

    return cssObject;
  }

  const cardsnipet = "background-color: aqua; border: 12px;";
  const cssObject = cssStringToObject(cardsnipet);
  const numericGridSize = parseInt(Gridsize, 10);
  const renderApps = () => {
    return apps.map((app, index) => <AppItem key={index} app={app} isTrue={isTrue} />);
    const gridStyles = {
      display: "grid",
      gridTemplateColumns: `repeat(${Gridsize}, 1fr)`, // Dynamically set the number of columns
      gap: "10px", // Adjust the gap as needed
    };
  };
  return (
    <>
      {/* <div  className={`grid grid-cols-${numericGridSize}   p-2  gap-4 `}> */}

      {themeCustomization.view == "Grid" ? (
        <div style={gridStyles} className={`grid grid-cols-${numericGridSize}   p-2  gap-4 `}>
          {renderApps()}
        </div>
      ) : (
        <div className={`grid  lg:grid-cols-1 p-2 gap-4 `}>{renderApps()}</div>
      )}
    </>
  );
  {
    /* <div className={`grid grid-cols-4 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-${themeCustomization.gridsize} p-2 gap-4 `}>{renderApps()}</div>; */
  }
};
export default AppList;
