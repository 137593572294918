import { AxiosResponse } from "axios";
import { takeEvery, put, call, all, select } from "redux-saga/effects";
import { DynamicFieldsSchemaType, KonnectifyApiResponse, KonnectorType } from "state/new-konnector/types";
import { deserializeTempateToEditTemplateState } from "utils/templateUtils";
import {
  createAccountFailed,
  getAppSchemaLoading,
  getDynamicAppSchemaLoading,
  getDynamicAppSchemaSuccess,
  getKonnectorFailed,
  getKonnectorLoading,
  getKonnectorSuccess,
} from "./slices";
import {
  getAppSchemaApi,
  getAppsApi,
  getAppsFieldsApi,
  getAppsFieldsApiCreate,
  getAppsFieldsApiTest,
} from "api/appsApi";
import { getWorkflowApi, useWorkflowApi } from "api/workflowApi";
import { serializeTabItemsForAppDetails, serializeAppFields, serializeTabItems } from "utils/appsUtils";
import { setAccountApp, setDyanmicFieldsLoading, setDyanmicFieldsSuccess } from "state/account/slices";
import { useAppSelector } from "state/store";
import { getKonnectorApi } from "api/konnectorsApi";
import { setKonType } from "../data-mapping/slices";

export function* getKonnector(data) {
  try {
    var result;
    if (data.payload.state == "konnector") {
      result = yield call(getKonnectorApi, data.payload.templateId);
      yield put(setKonType("Konnector"));
    } else {
      result = yield call(useWorkflowApi, data.payload);
      yield put(setKonType("Template"));
    }
    if (result.data.status !== 500) {
      // const dataoutput = yield select((state) => state.konnectors.savewebhookresponse);
      const appsDetails = yield call(getAppsApi, result.data.data.konnectorApps, false, result.data.data);
      // const appsDetails = yield call(getAppsApi, result.data.data.konnectorApps, );

      const payload = {
        webhookId: result.data.data.webhookUrl?.id || "",
        konnectorType: result.data.data.konnectorType,
        triggerInputFields: result.data.data.triggerDefinition.inputFields || null,
        actionInputFields: result.data.data.actionItems.slice(-1)[0].dynamicInputFields || null,
      };
      let appDetailsItem = appsDetails?.map((app) => app?.data);
      let appsDynamicFields = yield call(getAppsFieldsApiTest, result.data.data.konnectorApps, payload);
      const serializedappsFields = serializeAppFields(appsDynamicFields);
      const state = deserializeTempateToEditTemplateState(
        result.data,
        appDetailsItem,
        serializedappsFields,
        "template"
      );
      let serializeTabItemsState: any = serializeTabItemsForAppDetails(
        appsDetails,
        payload.triggerInputFields,
        payload.actionInputFields
      );
      serializeTabItemsState.push(...serializeTabItems(serializeTabItemsState, result.data?.data));
      yield put(setAccountApp({ data: serializeTabItemsState }));
      yield put(getKonnectorSuccess(state));
    } else {
    }
  } catch (e) {
    yield put(getKonnectorFailed());

    yield put(createAccountFailed());
  }
}

export function* getTriggerDynamicFields(data) {
  try {
    const payload = {
      appId: data.payload.appId,
      data: {
        schemaType: DynamicFieldsSchemaType.Input,
        eventId: data.payload.event,
        connectionConfigId: data.payload.connectionId,
      },
    };
    yield put(setDyanmicFieldsLoading({ currentActionId: data.payload.currentActionId }));
    const result: AxiosResponse<KonnectifyApiResponse<any>> = yield call(getAppSchemaApi, payload);
    const inputFields = {
      fields: result.data.data.data,
      appId: result.data.data.appId,
      eventId: result.data.data.eventId,
      currentActionId: data.payload.currentActionId,
    };

    if (result) {
      yield put(setDyanmicFieldsSuccess({ currentActionId: data.payload.currentActionId, ...inputFields }));
    }
  } catch (e) {
    yield put(createAccountFailed());
  }
}

export function* getAppSchema() {
  const appsDetails = yield select((state) => state.account.tabItems);
}

export function* getDataMapping(data) {}

export default function* rootSaga() {
  yield takeEvery(getKonnectorLoading.type, getKonnector);
  yield takeEvery(getAppSchemaLoading.type, getAppSchema);
  // yield takeEvery(getDynamicAppSchemaLoading.type, getTriggerDynamicFields);
}
