import { AxiosResponse } from "axios";
import { takeEvery, put, call, select } from "redux-saga/effects";
import { getAuthState, getConfigurationDetails } from "./selectors";

import { setTargetAppAndAccountLoading, setTargetAppAndAccountSuccess } from "./actions";
import { fetchApp, getAppSchemaApi } from "../../api/appsApi";
import {
  createAccountApi,
  createAccountApiv2,
  updateAccountApi,
  updateAccountApiv2,
  validateAccountApi,
  saveAppConfigv2,
  getAppConfigv2,
  updateAppConfigv2,
} from "../../api/configApi";
import {
  accountApiFailed,
  appCreationFailure,
  appCreationSuccess,
  createAccountSuccess,
  createLookupAccountSuccess,
  saveConfigurationLoading,
  setAccountLoading,
  setEditAppConfigState,
  setOAuthAccountLoading,
  setUpdateLoading,
  setUpdateOAuthAccountLoading,
  updateAccountSuccess,
  updateConfigurationLoading,
  updateValidationSuccess,
  validateAccountSuccess,
} from "./slices";
import { setSchemaSuccess } from "../data-mapping/slices";

export function* setTargetAppAndAccount() {
  try {
    const configuationState = yield select(getConfigurationDetails);
    const getEditAppConfig = yield call(getAppConfigv2, configuationState.data);
    if (Object.keys(getEditAppConfig.data.data).length) {
      yield put(setEditAppConfigState(getEditAppConfig.data.data));
    } else {
      const authState = yield select(getAuthState);
      const sourceAppId = authState.currentUser.admin.staticApp;
      const targetAppId = configuationState.data.targetAppId;
      const sourceAppDetail: AxiosResponse<any> = yield call(fetchApp, sourceAppId);
      const targetAppDetail: AxiosResponse<any> = yield call(fetchApp, targetAppId);

      let sourceAppDetails = sourceAppDetail.data.data;

      if (sourceAppDetails) {
        sourceAppDetails.index = 0;
      }

      const dynamic_inputfields = yield select((state) => state.dataMapping.dynamicInputFields);
      let find_Booleanfordynamicfields = dynamic_inputfields.find(
        (obj) => obj.trigger === true || obj.action_0 == true
      );

      // let f
      let targetAppDetails = targetAppDetail.data.data;

      if (targetAppDetails) {
        targetAppDetails.index = 1;
      }
      // let targetAppDetails = (targetAppDetail.data.data.index = 1);

      yield put(setTargetAppAndAccountSuccess({ sourceAppDetails, targetAppDetails }));
    }
  } catch (e) {}
}

export function* createOAuthAccount(data) {
  try {
    const result: AxiosResponse<any> = yield call(createAccountApiv2, data);
    yield put(createAccountSuccess({ ...result.data }));
  } catch (e) {
    yield put(accountApiFailed({ ...data.payload }));
  }
}

export function* updateOAuthAccount(data) {
  try {
    const accountId = yield select((state) =>
      data.payload.type == "source"
        ? state.configurationV2.data.sourceAppConnectionId
        : state.configurationV2.data.targetAppConnectionId
    );
    const result: AxiosResponse<any> = yield call(updateAccountApiv2, accountId, data.payload);
    yield put(updateAccountSuccess({ ...result.data }));
  } catch (e) {
    yield put(accountApiFailed({ ...data.payload }));
  }
}

export function* updateAccount(data) {
  try {
    const ValidateResult: AxiosResponse<any> = yield call(validateAccountApi, data.payload.accountDetails);
    if (ValidateResult.data.data) {
      yield put(validateAccountSuccess(ValidateResult.data));
      const results: AxiosResponse<any> = yield call(validateAccountApi, data.payload);
      yield put(updateValidationSuccess(results.data));
      if (results.data) {
        const accId = yield select((state) =>
          data.payload.type == "source"
            ? state.configurationV2.data.sourceAppConnectionId
            : state.configurationV2.data.targetAppConnectionId
        );
        const result: AxiosResponse<any> = yield call(updateAccountApi, accId, data.payload);
        yield put(updateAccountSuccess({ ...data.payload, ...result.data }));
      }
    } else {
      yield put(accountApiFailed({ ...data.payload }));
    }

    // }
  } catch (e) {
    yield put(accountApiFailed({ ...data.payload }));
  }
}

export function* createAccount(data) {
  try {
    // const eventId = yield select((state) => state.editTemplate.data.lookups[0].eventId);
    const ValidateResult: AxiosResponse<any> = yield call(validateAccountApi, data.payload.accountDetails);
    if (ValidateResult.data.data) {
      yield put(validateAccountSuccess(ValidateResult.data));
      const result: AxiosResponse<any> = yield call(createAccountApi, data.payload.accountDetails);
      if (data.payload.isLookup) {
        const payload = {
          appId: data.payload.accountDetails.appId,
          data: {
            connectionConfigId: result.data.data.id,
            eventData: {},
            eventId: data.payload.eventId,
            schemaType: "output",
          },
        };
        const result1: AxiosResponse<any> = yield call(getAppSchemaApi, payload);
        yield put(setSchemaSuccess({ type: "output_01", data: result1.data.data }));
        yield put(createLookupAccountSuccess({ appId: data.payload.accountDetails.appId, body: result.data }));
      } else {
        yield put(createAccountSuccess({ ...result.data }));
      }
    } else {
      yield put(accountApiFailed({ ...data.payload }));
    }
  } catch (e) {
    yield put(accountApiFailed({ ...data.payload }));
  }
}

export function* saveAppConfig(data) {
  try {
    const configuationState = yield select(getConfigurationDetails);
    const result: AxiosResponse<any> = yield call(saveAppConfigv2, configuationState.data);
    yield put(appCreationSuccess());
  } catch (e) {
    yield put(appCreationFailure());
  }
}
export function* updateAppConfig(data) {
  try {
    const configuationState = yield select(getConfigurationDetails);
    const result: AxiosResponse<any> = yield call(updateAppConfigv2, configuationState.data);
    yield put(appCreationSuccess());
  } catch (e) {
    yield put(appCreationFailure());
  }
}

export default function* rootSaga() {
  yield takeEvery(setTargetAppAndAccountLoading.type, setTargetAppAndAccount);
  yield takeEvery(setOAuthAccountLoading.type, createOAuthAccount);
  yield takeEvery(setUpdateOAuthAccountLoading.type, updateOAuthAccount);
  yield takeEvery(setAccountLoading.type, createAccount);
  yield takeEvery(setUpdateLoading.type, updateAccount);
  yield takeEvery(saveConfigurationLoading.type, saveAppConfig);
  yield takeEvery(updateConfigurationLoading.type, updateAppConfig);
}
