import { useAppDispatch, useAppSelector } from "state/store";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUserData, settoasttoastBoolean } from "state/auth/slices";
import { useNavigate } from "react-router-dom";
import { validateEndUserApi } from "api/authApi";
import { konnectorIdle } from "state/account/slices";
import { CurrentState } from "state/account/types";

const Success: React.FC<any> = ({ message, closeNotification, redirectPath }): JSX.Element => {
  const dispatch = useAppDispatch();
  const new_Customization_banner = useAppSelector((state) => state.auth.banner);
  const navigate = useNavigate();
  const currentSate = useAppSelector((state) => state.account.currentState);
  const apiKeyAuth = useAppSelector((state) => state.auth.apiKey);
  const userId = useAppSelector((state) => state.auth.email);
  const appIds = useAppSelector((state) => {
    if (currentSate == CurrentState.Konnectors) {
      return state.editKonnector.data.workflowAppId;
    } else {
      return state.editTemplate.data.workflowAppId;
    }
  });
  const handleclose = () => {
    dispatch(settoasttoastBoolean(false));

    const apiFunc = async () => {
      let Endresult = await validateEndUserApi();
      dispatch(setUserData(Endresult.data));
    };
    apiFunc();
    navigate(`/apps/${appIds}/templates?apiKey=${apiKeyAuth}&user=${userId}`);
    dispatch(konnectorIdle());
  };

  const [shouldRedirect, setRedirect] = useState(false);
  // useEffect(() => {
  // 	setTimeout(() => {
  // 		closeNotification();
  // 	}, 3000);
  // });
  return (
    <>
      <div className="flex overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-50 justify-center items-start mt-10 h-modal md:h-full md:inset-0">
        <div className="flex-initial flex h-[70vh] items-start   w-[85vh]  -mr-12 relative">
          <div className="" style={{ width: `827px`, height: `256px` }}>
            {/* <img className=" p-5 transition-all duration-300 rounded-lg cursor-pointer filter object-cover" src={mainLogo} alt="image description"> */}
            {/* </img> */}
            <div
              className=" flex items-center absolute  top-8 right-72  bg-white border border-gray-200 rounded-xl shadow-lg"
              style={{
                width: `${new_Customization_banner.width}px`,
                height: `${new_Customization_banner.heigth}px`,
                borderRadius: `${new_Customization_banner.radius}px`,
                borderColor: new_Customization_banner.bordercolour,
                //   background: bgColor,
                //   color: textColor,
              }}
              role="alert"
            >
              <div className="flex p-4">
                <div className="flex-shrink-0">
                  <svg
                    className="flex-shrink-0 size-4 text-teal-500 mt-0.5"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg>
                </div>
                <div className="ms-3">
                  <p className="text-sm text-gray-700 dark:text-gray-400">{new_Customization_banner.successMsg}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div id="toast-success" className="flex grow items-center p-5 mb-4 mr-5 text-gray-500 bg-white rounded-lg shadow border-2" style={{
                            flex: `0 0 ${new_Customization_banner?.width}%`,
                            height: `${new_Customization_banner?.heigth}px`,
                            borderRadius: `${new_Customization_banner?.radius}px`,
                            borderColor: new_Customization_banner?.bordercolour
                            //   background: bgColor,
                            //   color: textColor,
                        }} role="alert">
                            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg">
                                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <span className="sr-only">Check icon</span>
                            </div>
                            <div className="ms-3 text-sm font-normal">{new_Customization_banner?.successMsg}</div>
                            <button onClick={handleclose}type="button" className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8" data-dismiss-target="#toast-success" aria-label="Close">
                                <span className="sr-only">Close</span>
                                <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                            </button>
                        </div> */}
      </div>
    </>
  );
};

export default Success;
