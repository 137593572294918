import { borderColor } from "@mui/system";
import { AppObject } from "pages/Apps/types";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "state/store";

interface AppItemProps {
  app: AppObject;
  isTrue: boolean;
}

const AppItem: React.FC<AppItemProps> = ({ app }) => {
  const apiKey = useAppSelector((state) => state.auth.apiKey);
  const userId = useAppSelector((state) => state.auth.email);
  const customisation = useAppSelector((state) => state.auth.customisation);
  const themeCustomization = useAppSelector((state) => state.auth.Cardcustomisation);
  const newcustomization = useAppSelector((state) => state.auth.customization);
  const navigate: any = useNavigate();
  const url = window.location.href;
  const userIndex = url.indexOf("user=");
  var cc;
  if (userIndex !== -1) {
    const userEndIndex = url.indexOf("&", userIndex);
    if (userEndIndex !== -1) {
      cc = url.substring(userEndIndex + 1);
    } else {
      cc = "";
    }
  } else {
    // return null;
  }
  const [styleObject, setStyleObject] = useState({});

  useEffect(() => {
    // Check if the dynamicCssClass is available
    if (themeCustomization.cardSnippet) {
      // Extract content inside curly braces
      const match = themeCustomization.cardSnippet.match(/\.([^{]+)\s*\{([^}]+)\}/);

      if (match && match[1] && match[2]) {
        const cssProperties = match[2].trim();

        const propertiesArray = cssProperties.split(";");
        const cssObject = propertiesArray.reduce((acc, property) => {
          const [key, value] = property.split(":");
          if (key && value) {
            const camelCaseKey = key.trim().replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
            acc[camelCaseKey] = value.trim();
          }
          return acc;
        }, {});

        setStyleObject(cssObject);
      } else {
      }
    }
  }, [themeCustomization.cardSnippet]);

  const [isHovered, setHovered] = useState(false);

  return (
    <>
      {themeCustomization.view == "Grid" ? (
        <a
          onClick={() => {
            navigate(`/apps/${app.id}?apiKey=${apiKey}&user=${userId}&${cc}`);
          }}
          // href={`/apps/${app.id}?apiKey=${apiKey}&user=${userId}&${cc}`}
          className=" block overflow-hidden rounded-lg border border-zinc-400 p-4 relative w-full min-h-fit bg-white"
          style={
            themeCustomization.cardSnippetStatus == "true"
              ? styleObject
              : {
                  backgroundColor: isHovered ? themeCustomization.hoverColor : themeCustomization.backgroundcolor,
                  color: themeCustomization.textColor,
                  height: themeCustomization.heigth,
                  width: themeCustomization.width,
                  borderRadius: themeCustomization.borderRadius,
                  borderColor: themeCustomization.bordercolour,
                  // background:themeCustomization.backgroundcolor,
                }
          }
          onMouseEnter={() => {
            setHovered(true);
          }}
          onMouseLeave={() => {
            setHovered(false);
          }}
        >
          <div className="sm:flex sm:justify-between sm:gap-6">
            <div className="hidden sm:block sm:shrink-0">
              <img alt="Paul Clapton" src={app.iconUrl} className=" w-6 object-cover shadow-sm" />
            </div>
          </div>
          <div className="mt-1">
            <h3
              className="text-md  text-gray-600 sm:text-lg truncate hover:text-clip overflow-hidden line-clamp-1"
              style={{
                fontFamily: newcustomization.font,
                color: newcustomization.text_colour,
              }}
            >
              {app.appName}
              {/* {
            "text-base font-bold text-gray-600 sm:text-lg text-base font-bold text-gray-600 sm:text-lgtext-base font-bold text-gray-600 sm:text-lgtext-base font-bold text-gray-600 sm:text-lgtext-base font-bold text-gray-600 sm:text-lg"
          } */}
            </h3>
            <p
              className="max-w-[40ch] text-sm text-gray-500 truncate line-clamp-1"
              style={{
                fontFamily: newcustomization.font,
                color: newcustomization.text_colour,
              }}
            >
              <h2
                dangerouslySetInnerHTML={{
                  __html: app.description ? app.description : "Get more out of your Konnectify product",
                }}
                className="truncate line-clamp-1"
              ></h2>
            </p>
          </div>
        </a>
      ) : (
        <a
          href={`/apps/${app.id}?apiKey=${apiKey}&user=${userId}&${cc}`}
          className=" block overflow-hidden rounded-lg border border-zinc-400 p-2 relative w-full min-h-fit bg-white"
          style={{
            width: "598px",
            height: "122px",
            backgroundColor: themeCustomization.backgroundcolor,
          }}
        >
          <div className="sm:flex sm:justify-between sm:gap-6">
            <div className="hidden sm:block sm:shrink-0">
              <img alt="Paul Clapton" src={app.iconUrl} className=" w-6 object-cover shadow-sm" />
            </div>
          </div>
          <div className="mt-1">
            <h3
              className="text-md  text-gray-600 sm:text-lg truncate hover:text-clip overflow-hidden line-clamp-1"
              style={{
                fontFamily: newcustomization.font,
                color: newcustomization.text_colour,
              }}
            >
              {app.appName}
              {/* {
          "text-base font-bold text-gray-600 sm:text-lg text-base font-bold text-gray-600 sm:text-lgtext-base font-bold text-gray-600 sm:text-lgtext-base font-bold text-gray-600 sm:text-lgtext-base font-bold text-gray-600 sm:text-lg"
        } */}
            </h3>
            <p
              className="max-w-[40ch] text-sm text-gray-500 truncate line-clamp-1"
              style={{
                fontFamily: newcustomization.font,
                color: newcustomization.text_colour,
              }}
            >
              <h2
                dangerouslySetInnerHTML={{
                  __html: app.description ? app.description : "Get more out of your Konnectify product",
                }}
                className="truncate line-clamp-1"
              ></h2>
            </p>
          </div>
        </a>
      )}
    </>
  );
};

export default AppItem;
