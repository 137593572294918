import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "state/store";
import { fetchAllAppSchemaLoading } from "state/account/slices";
import { AppsDetails } from "state/account/types";
// import Spinner from "common/components/Spinner";
import Spinner from "components/Spinner";
import InputSchema from "./FieldSchema";

interface CredTab {
  appDetails: AppsDetails;
}

const DataMappingTab: React.FC<CredTab> = ({ appDetails }) => {
  const currentState = useAppSelector((state) => state.account.currentState);
  let allTabValidated = useAppSelector((state) => state.account.allTabValidated);
  const hasDataMapping = appDetails.outputSchema?.data?.length > 0 && appDetails.inputSchemas?.length > 0;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (allTabValidated) {
      dispatch(fetchAllAppSchemaLoading({ currentActionId: appDetails.id }));
    }
  }, []);

  return (
    <>
      <div>
        {currentState === "konnectors" && (
          <div className=" w-full  fixed top-0 left-0 ">{!hasDataMapping && <Spinner />}</div>
        )}
      </div>
      <>
        <div className="p-4 mb-20px overflow-y-scroll h-[calc(70vh-3rem)] w-full">
          {allTabValidated ? <InputSchema appDetails={appDetails} /> : <>Please Validate the Configuration tabs !!</>}
        </div>
      </>
    </>
  );
};

export default DataMappingTab;
