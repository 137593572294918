import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Options } from "../types";

const Password: React.FC<Options> = ({
  pattern,
  name,
  required,
  register,
  errors,
  onChangeCapture,
  isDisabled = false,
}) => {
  return (
    <div className="flex flex-col justify-center items-start">
      {required ? (
        <label htmlFor={name} className=" mb-2 text-sm font-medium text-gray-900 :text-gray-300">
          {name} <span className="text-red-500 ">*</span>
        </label>
      ) : (
        <label htmlFor={name} className=" mb-2 text-sm font-medium text-gray-900 :text-gray-300">
          {name}
        </label>
      )}
      <input
        type="password"
        disabled={isDisabled}
        onChangeCapture={onChangeCapture}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-color focus:border-primary-color block w-96 p-2.5"
        placeholder={"Enter " + name}
        {...register(name, {
          required: { value: required.value, message: required.message },
          pattern: {
            value: new RegExp(pattern.value, pattern.flags),
            message: pattern.message,
          },
        })}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className="mt-2 text-sm text-red-600 :text-red-500">{message}</p>}
      />
    </div>
  );
};

export default Password;
