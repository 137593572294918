import { AxiosResponse } from "axios";
import { takeEvery, put, call, all, select } from "redux-saga/effects";
import { getCreateKonnectorDetails, getEditTemplate, getLookupDetails } from "./selectors";
// import { getParseEngineSlice, getRuleConfigSlice } from "state/selectors";

import { saveKonnectorLoading } from "./actions";
import { serializeCreateKonnectorData } from "utils/konnectorUtils";
import { saveKonnectorSuccess } from "./slices";
import { saveKonnectorApi } from "api/konnectorsApi";
import { konnectorLoading, konnectorSuccess } from "state/account/slices";
import { Webhook } from "@mui/icons-material";
import { settoasterrorBoolean } from "../auth/slices";

export function* saveKonnector() {
  try {
    const createKonnectorState = yield select(getCreateKonnectorDetails);

    const tabItems = yield select((state) => state.account.tabItems);
    const showWebhook = yield select((state) => state.konnectors.storewebhook);

    const webhookurl = showWebhook?.data?.webhookUrl;
    const webhookid = showWebhook?.data?.webhookId;
    // ;
    // const url = showWebhook?.data?.webhookUrl;
    // const id = showWebhook?.data?.webhookId;
    const getTemplate = yield select(getEditTemplate);

    const serializeData = serializeCreateKonnectorData(
      createKonnectorState,
      getTemplate,
      tabItems,
      webhookurl,
      webhookid
    );
    yield put(konnectorLoading());
    const result: AxiosResponse<any> = yield call(saveKonnectorApi, serializeData);
    yield put(konnectorSuccess());
    yield put(saveKonnectorSuccess());
    // const value   =
  } catch (e) {}
}
export default function* rootSaga() {
  yield takeEvery(saveKonnectorLoading.type, saveKonnector);
}
