import React from "react";
import KonnectorList from "../konnectorList";
import TemplateList from ".";
import { useAppSelector } from "state/store";

interface KonnectorItemProps {
  data: any;
  konnectors: any;
}

const ListAllDetails: React.FC<KonnectorItemProps> = ({ data, konnectors }) => {
  const portal = useAppSelector((state) => state.auth.Cardcustomisation);
  return (
    <div className="overflow-scroll h-[80vh] ">
      <KonnectorList konnectors={konnectors} />
      <TemplateList data={data} />
    </div>
  );
};

export default ListAllDetails;
