import { AxiosResponse } from "axios";
import {
  activateKonnectorApi,
  deactivateKonnectorApi,
  deleteKonnectorApi,
  generateWebhookUrl,
  getWebhookData,
  resetWebhookDataApi,
} from "api/konnectorsApi";
import { takeEvery, put, call, select } from "redux-saga/effects";
import { ErrorStatus } from "./types";
import {
  activateKonnectorLoading,
  deactivateKonnectorLoading,
  deactivateKonnectorSuccess,
  activateKonnectorSuccess,
  apiError,
  deleteKonnectorLoading,
  deleteKonnectorSuccess,
  displayErrorMessage,
  generatehook,
  setwebhookurl,
  getWebhookresponse,
  setwebhookresponseStore,
  setwebhookreset,
  setwebhookstatus,
  setResetwebhookstatus,
  setSaveWebhookstatus,
  setErrorText,
} from "./slices";
import { PublicOutlined } from "@mui/icons-material";
import { setWebhookResponse } from "../data-mapping/slices";
// import {setAppSchema} form "account/slices"
function* activateKonnector(data) {
  try {
    const result: AxiosResponse<any> = yield call(activateKonnectorApi, data.payload);
    const statusCode = ErrorStatus.find((status) => status === result.data.status) ? true : false;
    if (statusCode) {
      yield put(displayErrorMessage(result.data.message));
      throw Error(result.data.message);
    }
    yield put(activateKonnectorSuccess(result.data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}
function* deactivateKonnector(data) {
  try {
    const result: AxiosResponse<any> = yield call(deactivateKonnectorApi, data.payload);

    yield put(deactivateKonnectorSuccess(result.data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}
function* deleteKonnector(data) {
  try {
    const result: AxiosResponse<any> = yield call(deleteKonnectorApi, data.payload);
    yield put(deleteKonnectorSuccess(result.data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* generateWebhook(data) {
  try {
    yield put(setwebhookstatus(true));
    const userdata = yield select((state) => state.auth.apiKey);
    const result = yield call(generateWebhookUrl, userdata.apikey, data.payload);
    yield put(setwebhookstatus(false));
    yield put(setwebhookurl(result.data));
  } catch (error) {
    yield put(setwebhookstatus(false));
    yield put(apiError(error.message));
  }
}
function* getWebhook() {
  try {
    yield put(setSaveWebhookstatus(true));
    const userdata = yield select((state) => state.auth.apiKey);
    const showWebhook = yield select((state) => state.konnectors.storewebhook);
    const dattype = "webhook";
    const result = yield call(getWebhookData, showWebhook.data.webhookId, dattype, userdata.apikey);
    yield put(setSaveWebhookstatus(false));
    if (result.data.data.error) {
      yield put(setErrorText(result.data));
    } else {
      yield put(setwebhookresponseStore(result.data));
      yield put(setWebhookResponse(result.data));
    }
  } catch (error) {
    yield put(setSaveWebhookstatus(false));
    yield put(apiError(error.message));
  }
}

function* resetWebhookData() {
  try {
    yield put(setResetwebhookstatus(true));
    const showWebhook = yield select((state) => state.konnectors.storewebhook);
    const userdata = yield select((state) => state.auth.apiKey);
    const result = yield call(resetWebhookDataApi, showWebhook.data.webhookId, userdata.apikey);
    yield put(setResetwebhookstatus(false));
    yield put(setwebhookresponseStore(result.data.data.sampleData));
    yield put(setWebhookResponse(result.data.data.sampleData));
  } catch (error) {
    yield put(setResetwebhookstatus(false));
    yield put(apiError(error.message));
  }
}
export default function* rootSaga() {
  yield takeEvery(activateKonnectorLoading, activateKonnector);
  yield takeEvery(deactivateKonnectorLoading, deactivateKonnector);
  yield takeEvery(deleteKonnectorLoading, deleteKonnector);
  yield takeEvery(generatehook, generateWebhook);
  yield takeEvery(getWebhookresponse, getWebhook);
  yield takeEvery(setwebhookreset, resetWebhookData);
}
