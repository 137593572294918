import { Item } from "pages/TemplateList/types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "state/store";
import { setKonnectorState } from "state/template-list/slices";
import { TemplateState } from "state/template-list/types";
import OutsideClickHandler from "react-outside-click-handler";
import KonnectorMenu from "./konnectorMenu";
import KonnectorApps from "./KonnectorApps";
import { setKonnectorLoading } from "state/edit-workflow/slices";
import { KonnectorState } from "state/konnector-list/types";
import { setKonnectorState1, setwebhookurl } from "state/konnector-list/slices";
import Controls from "./Controls";
import EditIcon from "components/icons/Edit";
import { setCurrentState } from "state/account/slices";
import Tooltip from "common/components/Tooltip/Tooltip";
import { CurrentState } from "state/account/types";
interface KonnectorItemProps {
  konnector: Item;
}
const KonnectorItem: React.FC<KonnectorItemProps> = ({ konnector }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showKonnectorMenu, setShowKonnectorMenu] = useState(false);
  const dispatch = useAppDispatch();
  const onClickHandler = () => {
    // dispatch(setKonnectorState1(KonnectorState.EditKonnectorState));
    // dispatch(setKonnectorState(TemplateState.EditTemplateState));
    dispatch(setCurrentState(CurrentState.Konnectors));
    dispatch(setKonnectorLoading(konnector.id));
    dispatch(setwebhookurl(""));
  };
  const toggleMenu = (e) => {
    setShowMenu((value) => !value);
  };
  const toggleKonnectorMenu = (e) => {
    setShowKonnectorMenu((value) => !value);
  };
  const closeModalHandler = () => {
    setShowModal(false);
  };
  const apiKey = useAppSelector((state) => state.auth.apiKey);
  const userId = useAppSelector((state) => state.auth.email);
  const customisation = useAppSelector((state) => state.auth.customisation);
  const new_Customization = useAppSelector((state) => state.auth.portalConfigration);

  const new_Customization_template = useAppSelector((state) => state.auth.template);
  const {
    bgColor,
    borderRadius,
    heigth,
    hoverColor,
    textColor,
    width,
    togglebuttoncolor,
    templateSnippet,
    templateSnippetStatus,
  } = new_Customization_template;
  const url = window.location.href;
  const userIndex = url.indexOf("user=");
  var cc;
  if (userIndex !== -1) {
    const userEndIndex = url.indexOf("&", userIndex);
    if (userEndIndex !== -1) {
      cc = url.substring(userEndIndex + 1);
    } else {
      cc = "";
    }
  } else {
  }

  const new_Customization_templatesnippet = useAppSelector((state) => state.auth.templatesnippet);

  const [object, setobject] = useState({});
  useEffect(() => {
    // Check if the dynamicCssClass is available
    if (new_Customization_template.templateSnippet) {
      // Extract content inside curly braces
      const match = new_Customization_template.templateSnippet.match(/\.([^{]+)\s*\{([^}]+)\}/);

      if (match && match[1] && match[2]) {
        const cssProperties = match[2].trim();

        const propertiesArray = cssProperties.split(";");
        const cssObject = propertiesArray.reduce((acc, property) => {
          const [key, value] = property.split(":");
          if (key && value) {
            const camelCaseKey = key.trim().replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
            acc[camelCaseKey] = value.trim();
          }
          return acc;
        }, {});

        setobject(cssObject);
      } else {
      }
    }
  }, [new_Customization_template.templateSnippet]);

  const [isHovered, setHovered] = useState(false);
  return (
    <div className="">
      <div
        className="p-5 mb-4  border-[1px] border-gray-300  flex items-center"
        style={
          new_Customization_template?.templateSnippetStatus == "true"
            ? object
            : {
                borderRadius: `${new_Customization_template?.borderRadius}px`,
                width: `${(1420 * parseFloat(new_Customization_template?.width)) / 630}px`,
                height: `${new_Customization_template?.heigth}px`,

                transition: `${new_Customization_template?.hoverColor}`,
                background: isHovered ? new_Customization_template?.hoverColor : new_Customization_template.bgColor,
              }
        }
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
      >
        <>
          <div className="basis-[60%]">
            <Link
              to={`/apps/createtemplates?apiKey=${apiKey}&user=${userId}&${cc}`}
              onClick={onClickHandler}
              className="text-base text-komp-color font-semibold table hover:text-komp-hover-color"
              style={new_Customization_template?.textColor ? { color: new_Customization_template?.textColor } : {}}
            >
              <span className="mr-2 "> {konnector.name}</span>
            </Link>
            <p
              className="flex text-xs flex-row  space-x-2 text-gray-500 cursor-pointer"
              style={new_Customization_template?.textColor ? { color: new_Customization_template?.textColor } : {}}
            >
              <span className="flex items-center">
                <Tooltip
                  text={konnector.workflowDescription ? `Description:  ${konnector.workflowDescription}` : "" || ""}
                >
                  <span className="w-40 truncate cursor-pointer">
                    {konnector.workflowDescription ? `Description:${konnector.workflowDescription}` : "" || ""}
                  </span>
                </Tooltip>
              </span>

              <Tooltip
                text={konnector.workflowInstruction ? `Instruction:  ${konnector.workflowInstruction}` : "" || ""}
              >
                <span className="flex items-center">
                  <span className="w-40 truncate cursor-pointer">
                    {" "}
                    {konnector.workflowInstruction ? `Instruction: ${konnector.workflowInstruction}` : "" || ""}
                  </span>
                </span>
              </Tooltip>
            </p>
          </div>
          <div className="flex justify-between basis-[40%]">
            <KonnectorApps
              apps={konnector?.konnectorApps}
              ruleConfig={konnector?.ruleConfig?.length}
              konnectors={konnector}
            />
            <div className="ml-auto flex items-center justify-center ">
              <div className="flex">
                <Controls konnector={konnector} status={konnector.status} name={konnector.name} />
              </div>
              <div className="ml-3 relative">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setShowKonnectorMenu(false);
                  }}
                >
                  <Link
                    to={`/apps/createtemplates/?appId=${konnector.triggerDefinition.appId}&KonnectorId=${konnector.id}&apiKey=${apiKey}&user=${userId}&${cc}`}
                    type="button"
                    onClick={onClickHandler}
                    className="cursor-pointer p-4"
                  >
                    <EditIcon />
                  </Link>
                  {/* {showKonnectorMenu && <KonnectorMenu konnectors={konnector} setShowModal={setShowModal} />} */}
                </OutsideClickHandler>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};
export default KonnectorItem;
