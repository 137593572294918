import React, { useEffect, useState } from "react";
import MultipleSelectField from "common/components/FormFields/MultipleSelect";
import { Choice } from "common/components/FormFields/types";
import { useAppSelector } from "state/store";
import { Field } from "utils/appsUtils";
import { flatSourceOutputFields } from "utils/konnectorUtils";
import TextField from "common/components/FormFields/TextFieldWithoutLabel";
import { getEffectiveConstraintOfTypeParameter } from "typescript";

export default function SourceFields({
  field,
  register,
  control,
  watch,
  errors,
  inputSchema,
  getValues,
  setInputValue,
  appDetails,
  setnewObject,
  newObject,
}) {
  const sourceFields: any = "";
  let sourcefield = inputSchema.map((sol) => sol.sourceId);
  let label = field.label ? field.label : field.propName;
  const [fieldType, fieldSourceId] = watch([field.propName + "__field_type", field.propName + "__field_source_type"]);
  // ;
  //
  const getFixedChoices = (): Choice[] => {
    return field.propValue.map((ele) => ({ label: ele.label, value: ele.value }));
  };
  const getFieldOptions = () => {
    const fieldData = new Field(field).format();
    return fieldData;
  };
  const iconUrl: any = useAppSelector((state) => state.account.tabItems);
  // const onChangeSourceFieldsHandler = (value) => {
  //   value.map((sol) => {
  //     setInputValue(field.propName + "__field_type", "source");
  //     setInputValue(field.propName + "__field_source_type", sol.sourceId);
  //   });
  // };
  const [previousValues, setPreviousValues] = useState([]);
  // ;

  const onChangeSourceFieldsHandler = (currentValues, eventId) => {
    //
    //  setnewObject({ ...newObject, [eventId]: currentValues });
    // previousValues.map((prevValue) => {
    //   setInputValue(prevValue.field.propName + "__field_type", "source");
    //   setInputValue(prevValue.field.propName + "__field_source_type", prevValue.sol.sourceId);
    // });
    // setPreviousValues(currentValues.map((sol) => ({ field, sol })));
    // currentValues.map((sol) => {
    //   setInputValue(field.propName + "__field_type", "source");
    //   setInputValue(field.propName + "__field_source_type", sol.sourceId);
    // });
  };
  useEffect(() => {
    if (fieldType === "fixed" && fieldSourceId) {
      setInputValue(field.propName, "");
      setInputValue(field.propName + "__field_source_type", "");
    }
  }, [fieldType]);

  return (
    <div className="flex justify-center items-center min-w-[244px] w-[444px] pb-3">
      <div className=" w-full">
        <div className="flex">
          <img src={iconUrl[appDetails.id - 1].data.iconUrl} className="h-6 w-6"></img>
          <label
            className="block mb-2 text-base font-bold text-gray-900 pl-3"
            style={{ textTransform: "capitalize" }}
          >{`${label}`}</label>
          {field.isRequired && <span className="ml-2 text-red-600">*</span>}
        </div>
        {fieldType == "fixed" ? (
          <TextField {...getFieldOptions()} register={register} errors={errors} />
        ) : (
          <MultipleSelectField
            choices={inputSchema}
            onChangeHandler={onChangeSourceFieldsHandler}
            control={control}
            getValues={getValues}
            {...getFieldOptions()}
            register={register}
            setInputValue={setInputValue}
            errors={errors}
            appDetails={appDetails}
          />
        )}
      </div>
    </div>
  );
}
