import Apps from "pages/Apps";
import AppsDetails from "pages/AppDetails";
import Configitem from "pages/Configuration/index";
import TemplateList from "pages/TemplateList/index";
// import FreshserviceGenesys from "customapps/FreshserviceGenesys/index";

import { RouteProperties } from "./interfaces";
import ConfigurationItem from "pages/ConfigutationVersion/index";
import ConfigurationItemMoenggae from "pages/ConfigutationVersion-Moengage";

// const { loading, isAuthenticated, currentUser, apiKey, email } = useAppSelector((state) => state.auth);
const routes: RouteProperties[] = [
  {
    component: Apps,
    exact: true,
    params: {
      name: "Apps",
    },
    path: "/apps",
  },
  {
    component: AppsDetails,
    exact: true,
    params: {
      name: "App Details",
    },
    path: `/apps/:id`,
  },
  {
    component: Configitem,
    exact: true,
    params: {
      name: "App Settings",
    },
    path: `/apps/createtemplates`,
  },
  {
    component: TemplateList,
    exact: true,
    params: {
      name: "Workflow Template",
    },
    path: `/apps/:id/templates`,
  },
  {
    component: TemplateList,
    exact: true,
    params: {
      name: "Workflow Template Only",
    },
    path: `/apps/:id/templates/only_templates`,
  },
  //Configuration v2

  {
    component: ConfigurationItem,
    exact: true,
    params: {
      name: "Configuation v2",
    },
    path: `/configuration/apps/:id`,
  },
  {
    component: ConfigurationItemMoenggae,
    exact: true,
    params: {
      name: "Configuation v2",
    },
    path: `/configuration-moengage/apps/:id`,
  },

];

export default routes;
