import React, { useEffect } from "react";
import { RouterProviderProps, Navigate, useNavigate, Route, useLocation } from "react-router-dom";
import { RouteProperties } from "routes/interfaces";
import { useAppSelector, useAppDispatch } from "state/store";
import { login } from "state/auth/slices";
import Spinner from "common/components/Spinner";
// import Spinner from "./components/Spinner";
import { setApikey } from "api/libs/fetch";

interface AuthProperties<T extends { [K in keyof T]?: unknown } = React.ReactNode> {
  appRoute: RouteProperties;
}
const Auth = ({ appRoute }: AuthProperties) => {
  const location = useLocation();
  let route: any = <></>;
  let api;
  let user;
  const params = new URLSearchParams(location.search);
  api = params.get("apiKey");
  user = params.get("user");
  const url = window.location.href;
  const urlParams = new URLSearchParams(new URL(url).search);
  const accCred = [];
  let isuser = false;
  for (let i = 0; i < Array.from(urlParams.entries()).length; i++) {
    const [name, value] = Array.from(urlParams.entries())[i];
    if (name === "user") {
      isuser = true;
      continue;
    }
    if (isuser) {
      accCred.push({ name, value });
    }
  }

  // ===========================================================================
  // Selectors
  // =====================================

  const { loading, isAuthenticated, currentUser, apiKey, email } = useAppSelector((state) => state.auth);

  const history: any = useNavigate();
  // ===========================================================================
  // Dispatch
  // ===========================================================================

  const dispatch = useAppDispatch();

  const _login = () => dispatch(login({ api, user, accCred }));
  // const _logout = () => dispatch(logout());

  // ===========================================================================
  // Hooks
  // ===========================================================================

  useEffect(() => {
    if (loading && !isAuthenticated) _login();
  }, [isAuthenticated, loading]);

  if (loading) {
    return <Spinner type="public" />;
  }
  if (apiKey && apiKey !== api) {
    return <>Error 403</>;
  }

  // if (isPrivate(type) && !isAuthenticated) {
  //   return <Redirect to="/login" />;
  // }

  if (isAuthenticated) {
    return <appRoute.component route={route} />;
  } else {
    return (
      <>
        <div className="flex h-screen">
          <div className="m-auto">
            <span className="text-xl">Admin Not Found!!</span>
          </div>
        </div>
      </>
    );
  }

  return <>kihosre</>;
};

export default Auth;
