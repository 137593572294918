import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StatusType } from "state/new-konnector/types";

import { SortByType, FilterType, InitialState, TemplateState } from "../types";

export const initialState: InitialState = {
  sortBy: SortByType.DEFAULT,
  filterBy: FilterType.ACTIVE,
  status: StatusType.idle,
  templateState: TemplateState.listAllTemplateState,
  currentPage: 1,
  currentActionId: "",
  hasActivated: false,
  hasDeleted: false,
  selectedKonnectorId: [],
  appType: "all",
  errorMessage: "",
};

const templateSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    updateSortBy(state, data: PayloadAction<SortByType>) {
      state.currentPage = 1;
      state.sortBy = data.payload;
    },
    updateCurrentPage(state, data: PayloadAction<number>) {
      state.currentPage = data.payload;
    },
    updateFilter(state, data: PayloadAction<FilterType>) {
      state.currentPage = 1;
      state.filterBy = data.payload;
    },
    resetStatus(state) {
      state.status = StatusType.idle;
      state.currentActionId = "";
    },

    apiError(state, data: PayloadAction<any>) {
      state.status = StatusType.failed;
    },
    displayErrorMessage: (state, data: PayloadAction<string>) => {
      state.status = StatusType.failed;
      state.errorMessage = data.payload;
    },
    setKonnectorState: (state, data) => {
      state.templateState = data.payload;
    },
    setAppType: (state, data) => {
      state.appType = data.payload;
    },
    reset: () => initialState,
  },
});

export const {
  updateSortBy,
  updateCurrentPage,
  apiError,
  updateFilter,

  displayErrorMessage,
  setKonnectorState,
  setAppType,
  reset,
  resetStatus,
} = templateSlice.actions;

export default templateSlice.reducer;
