import { DataMappingObject } from "./../state/new-konnector/types";
import { Options } from "./../common/components/FormFields/types";
// import { v4 as uuidv4 } from "uuid";
import { StatusType } from "state/create-konnector/types";
import { AppsDetails, TabType } from "state/account/types";
import { InitialState } from "state/edit-template-settings/types";
import { ActionType } from "interfaces/pages/create-new-konnector";
import _ from "lodash";

export interface PropValueObject {
  label: string;
  value: string;
}

export interface InputField {
  propName: string;
  propType: string;
  isRequired: boolean;
  propValue?: PropValueObject[];
}

export class Field {
  constructor(public field: InputField) {}
  format(): Options {
    return {
      type: "select",
      name: this.field.propName,
      pattern: { message: "", value: "", flags: "" },
      required: { message: "This field is mandatory!", value: this.field.isRequired },
    };
  }
}

export const serializeDataMapping = (
  inputFields: InputField[] | any,
  mappingData,
  sourceId: string
): DataMappingObject[] => {
  return inputFields?.map((field: InputField): DataMappingObject => {
    const type = mappingData[field.propName + "__field_type"];
    const sourceId = mappingData[field.propName + "__field_source_type"];
    const value =
      (type !== "source" && field.propType) === "number"
        ? parseInt(mappingData[field.propName])
        : mappingData[field.propName];

    let filterdetails;
    if (value === undefined && Number.isNaN(value)) {
      filterdetails = undefined;
    } else {
      if (value != undefined && !Number.isNaN(value)) {
        let array = [];
        if (type == "source") {
          value?.filter((item) => {
            let val1 = item.split(":").pop().trim();
            array.push(val1);
          });
          filterdetails = array;
        } else {
          filterdetails = value;
        }
      }
    }
    return {
      ...field,
      source: sourceId,
      sourceProp: filterdetails,
      staticValue: type !== "source" ? value : "",
    };
  });
};

export const serializeLookupDataMapping = (inputFields: InputField[], mappingData): DataMappingObject[] => {
  return inputFields?.map((field: InputField): DataMappingObject => {
    const sourceId = mappingData[field.propName + "__field_source_type"];
    const type = mappingData[field.propName + "__field_type"];
    const value = mappingData[field.propName];
    return {
      ...field,
      source: sourceId,
      sourceProp: value,
      staticValue: type !== "source" ? value : "",
    };
  });
};

export const deserializeDataMapping = (targetActionProperties) => {
  const rawDataMapping = {};
  targetActionProperties.forEach((field: DataMappingObject) => {
    rawDataMapping[field.propName + "__field_type"] = field.staticValue ? "fixed" : "source";
    rawDataMapping[field.propName + "__field_source_type"] = field.source || "";
    rawDataMapping[field.propName] = field.sourceProp;
  });
  return rawDataMapping;
};

export const serializeAppFields = (appFields) => {
  try {
    const source = appFields[0];
    const lookups = appFields[1];
    const actions = appFields[2];
    let fields: any = {};
    if (source?.length === 1) {
      fields["sourceSample"] = source[0]?.data?.data;
    } else if (source?.length === 3) {
      fields["sourceInput"] = source[0]?.data?.data;
      fields["sourceSample"] = source[1]?.data?.data;
      fields["sourceOutput"] = source[2]?.data?.data;
    } else {
      fields["sourceSample"] = source[0]?.data?.data;
      fields["sourceOutput"] = source[1]?.data?.data;
    }
    if (lookups[0]?.length > 0) {
      for (let i in lookups) {
        fields[`lookupInput_${i}`] = lookups[i][0]?.data?.data;
        fields[`lookupOutput_${i}`] = lookups[i][1]?.data?.data;
      }
    }

    for (let i in actions) {
      if (actions[i].length === 3) {
        fields[`targetDynamicInput_${i}`] = actions[i][0]?.data?.data;
        fields[`targetInput_${i}`] = actions[i][1]?.data?.data;
        fields[`targetOutput_${i}`] = actions[i][2]?.data?.data;
      } else {
        fields[`targetInput_${i}`] = actions[i][0]?.data?.data;
        fields[`targetOutput_${i}`] = actions[i][1]?.data?.data;
      }
    }

    return fields;
  } catch (e) {}
};

export const serializeTabItemsForAppDetails = (
  appsDetails,
  triggerInputFields = null,
  actionInputField,
  forEditworkflowState = false
): AppsDetails[] => {
  let appDetailsVm: AppsDetails[] = appsDetails.map((app, index) => {
    if (index == 0) {
      triggerInputFields = triggerInputFields;
    } else {
      triggerInputFields = actionInputField;
    }
    let hasTriggerInPutFields = triggerInputFields ? Object.keys(triggerInputFields)?.length > 0 : false;
    return {
      id: index,
      data: app?.data?.data?.data,
      actionOrderId: app?.actionOrderId,
      tabTitle: app?.appEventId != "webhook" ? `Connect ${app?.data?.data?.data?.appName}` : "Connect Webhook",
      appId: app?.appId,
      tabValidated: forEditworkflowState,
      status: StatusType.idle,
      eventId: app?.appEventId,
      appSelectorType: app?.appEventId != "webhook" ? app?.appSelectorType : "source",
      dynamicFields: {
        hasData: hasTriggerInPutFields ? true : false,
        fields: [],
        status: StatusType.idle,
        dataMapping: triggerInputFields || {},
      },
      tabType: app?.appEventId == "webhook" ? app?.appEventId : TabType.Creds,
      connectionConfig: app?.connectionConfig || "",
    };
  });

  return appDetailsVm;
};

export const serializeTabItems = (serializeTabItemsState, template, forEditworkflowState = false) => {
  let tabLength = serializeTabItemsState.length - 1;
  let filteritem = template?.actionItems?.filter(
    (item) => item.actionType != "lookup" && item.actionType != "ruleConfig" && item.actionType != "parseConfig"
  );
  let tabItem = filteritem?.map((item) => {
    let rawDataMapping = {};
    if (forEditworkflowState) {
      let actionProperties;
      actionProperties = template.targetActionProperties.find(
        (action) => action.appEventId === item.appEventId
      )?.actionProperties;
      rawDataMapping = deserializeDataMapping(actionProperties);
    }

    tabLength += 1;

    return {
      id: tabLength,
      appId: item.appId,
      eventId: item.appEventId,
      connectionId: item.connectionId,
      tabType: item.actionType,
      tabTitle: parseTabTitle(item.actionType),
      inputSchemas: [],
      outputSchema: {},
      rawDataMapping: rawDataMapping,
      fieldsRetrived: false,
      status: StatusType.idle,
      data: item,
    };
  });

  return tabItem;
};
const parseTabTitle = (actionType: ActionType) => {
  let tabTitle = "";
  switch (actionType) {
    // case actionType.Webhook:
    //     return <Webhook appDetails={tabItem} />;
    case ActionType.RuleConfig:
      tabTitle = "Configure Rule";
      break;
    case ActionType.Action:
      tabTitle = "Field Mappings";
      break;
    case ActionType.ParseConfig:
      tabTitle = "Configure Parse";
      break;
  }
  return tabTitle;
};

export const flatSourceOutputFields = (outputFields): any[] => {
  const getMembers = (member) => {
    if (!member.propChildren || !member.propChildren.length) {
      return member;
    }
    return [member, _.flatMapDeep(member.propChildren, getMembers)];
  };
  return _.flatMapDeep(outputFields, getMembers);
};
