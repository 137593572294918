import { IconButton, InputAdornment, InputLabel, OutlinedInput } from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
// import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { generateWebhookUrl, getKonnectorApi } from "api/konnectorsApi";
import { useDispatch } from "react-redux";
import { generatehook, getWebhookresponse, setwebhookreset } from "state/konnector-list/slices";
import { useAppSelector } from "state/store";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import Modal from "./Modal";
import { switchTab, setAppSchema, fetchAllAppSchemaLoading } from "state/account/slices";
import { AppsDetails } from "state/account/types";
import { Loader } from "components/icons/Loader.tsx";
import { AxiosResponse } from "axios";
interface CredTab {
  appDetails: AppsDetails;
}
const Webhook: React.FC<CredTab> = ({ appDetails }) => {
  const showWebhook = useAppSelector((state) => state.konnectors.storewebhook);
  const updatewebhookurl = useAppSelector((state) => state.editKonnector.updatewebhookdata);

  const webhookUrlData = updatewebhookurl?.data?.webhookUrl;

  const url =
    webhookUrlData &&
    (typeof webhookUrlData.url === "string"
      ? webhookUrlData.url
      : webhookUrlData.url && typeof webhookUrlData.url === "object" && webhookUrlData.url.url
      ? webhookUrlData.url.url
      : null);

  const [state, setstate] = useState(false);

  // if (updatewebhookurl?.data?.webhookUrl?.url == undefined || updatewebhookurl?.data?.webhookUrl?.url == "") {
  //   setstate(false);
  // }
  // if (updatewebhookurl?.data?.webhookUrl?.url != "" || updatewebhookurl?.data?.webhookUrl?.url != undefined) {
  //   setstate(true);
  // }
  const showWebhookStatus = useAppSelector((state) => state.konnectors.showWebhookStatus);
  const saveWebhookStatus = useAppSelector((state) => state.konnectors.saveWebhookStatus);
  const resetWebhookstatus = useAppSelector((state) => state.konnectors.resetWebhookstatus);
  const webhookrespone = useAppSelector((state) => state.konnectors.savewebhookresponse);
  const errorResponse = useAppSelector((state) => state.konnectors.errorText);
  const tablength: any = useAppSelector((state) => state.account.tabItems);
  let appsdetail = useAppSelector((state) => state.account.tabItems);
  const newdata = errorResponse?.data?.fields?.eventResponse?.data;
  const successData = webhookrespone?.data?.fields?.eventResponse?.data;
  let dataoutput = webhookrespone?.data?.fields?.eventResponse?.data?.error;
  const [copySuccess, setCopySuccess] = useState(false);
  const [errorToast, setErrorToast] = useState(false);

  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const textAreaRef = useRef(null);
  const [loader, setloader] = useState(false);
  const webhookurl = () => {
    setloader(true);
    dispatch(
      generatehook({
        konnectorType: "webhook",
      })
    );
    if (showWebhook?.data?.webhookUrl != "" || showWebhook?.data?.webhookUrl != undefined) {
      setloader(false);
    }
  };
  const [showmodal, setshowmodal] = useState(false);
  const [showmodaldialog, setshowmodaldialog] = useState(false);
  const handleOpen = () => setshowmodal(true);
  const webhook = () => {
    setloader(true);
    dispatch(fetchAllAppSchemaLoading({ currentActionId: tablength.length - 1 }));
    dispatch(getWebhookresponse({ konnectorType: "webhook" }));
    if (newdata != "") {
      setloader(false);
    }
    setshowmodal(true);

    if (webhookrespone?.status == 200) {
      setshowmodaldialog(true);
    } else {
      setErrorToast(true);
    }
  };
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleButtonClick = () => {
    setTooltipOpen(true);

    setTimeout(() => {
      setTooltipOpen(false);
      setshowmodal(false);
    }, 3000);
  };

  const [tooltipOpenupdte, setTooltipOpenupdate] = useState(false);
  const handleButtonClickupdate = () => {
    setTooltipOpenupdate(true);

    setTimeout(() => {
      setTooltipOpenupdate(false);
      setshowmodal(false);
    }, 3000);
  };
  interface Props {
    showmodal: boolean;
  }

  const copyclick = () => {
    navigator.clipboard.writeText(showWebhook?.data?.webhookUrl);
  };

  const copyclicker = () => {
    navigator.clipboard.writeText(updatewebhookurl?.data?.webhookUrl?.url);
  };
  const resetWebhook = () => {
    dispatch(setwebhookreset({ konnectorType: "webhook" }));
  };

  useEffect(() => {
    if (errorToast && errorResponse?.data?.fields?.eventResponse?.data?.error) {
      toast.info(errorResponse?.data?.fields?.eventResponse?.data?.error, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {
          setErrorToast(false);
          // navigate(`/apps/${appId}/templates`);
        },
      });
    }
  }, [errorResponse]);

  const instructionClassName = {
    mainDiv: "relative px-4 w-full max-w-xl h-full md:h-auto",
    insetOpacity: "opacity-50 fixed inset-0 z-40 bg-white border-4 border-gray-400",
  };

  const closeModalHandler = () => {
    setshowmodaldialog(false);
    dispatch(switchTab(appDetails.id));
  };

  return (
    <div>
      <Modal
        visible={showmodaldialog}
        closeModalHandler={closeModalHandler}
        text={JSON.stringify(successData)}
        className={instructionClassName}
      ></Modal>

      <div className="flex flex-col gap-8 mt-10 ">
        {updatewebhookurl?.data?.webhookUrl?.url == undefined || updatewebhookurl?.data?.webhookUrl?.url == "" ? (
          <>
            <div>
              {showWebhook?.data?.webhookUrl == "" || showWebhook?.data?.webhookUrl == undefined ? (
                <button
                  className="bg-komp-secondary-color text-white  font-medium rounded-sm text-sm p-3 text-center w-72 "
                  onClick={webhookurl}
                >
                  {/* <>
                    {" "}
                    <Loader />
                    Loading
                  </> */}
                  {showWebhookStatus ? (
                    <>
                      {" "}
                      <Loader />
                      Loading
                    </>
                  ) : (
                    "Generate Webhook Url"
                  )}
                </button>
              ) : (
                <button
                  disabled={true}
                  className="text-white bg-gray-300  font-medium rounded-sm text-sm p-3 text-center w-72 "
                  // onClick={webhookurl}
                >
                  {showWebhookStatus ? (
                    <>
                      {" "}
                      <Loader />
                      Loading
                    </>
                  ) : (
                    "Generate Webhook Url"
                  )}
                </button>
              )}
            </div>
            <div className="flex flex-col">
              <label>Webhook url</label>

              <OutlinedInput
                className="border-stone-200 border-2 resize-none w-96"
                id="outlined-adornment-weight"
                value={
                  showWebhook?.data?.webhookUrl == "" || showWebhook?.data?.webhookUrl == undefined
                    ? ""
                    : showWebhook?.data?.webhookUrl
                }
                ref={textAreaRef}
                endAdornment={
                  <Tooltip open={tooltipOpen} onClose={() => setTooltipOpen(false)} title="copied" arrow>
                    <InputAdornment position="end">
                      {
                        <FileCopyIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            copyclick();
                            handleButtonClick();
                          }}
                        />
                      }
                    </InputAdornment>
                  </Tooltip>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
              />

              {copySuccess && showWebhook?.data?.webhookUrl != "" ? (
                <>
                  <Tooltip title="copied" children={undefined}></Tooltip>
                </>
              ) : (
                <Tooltip title=" No data" children={undefined}></Tooltip>
              )}
            </div>
            <div className="mt-10 flex flex-row gap-16">
              <div>
                <button
                  className="bg-komp-secondary-color text-white  font-medium rounded-sm text-sm p-3 text-center w-32"
                  onClick={resetWebhook}
                >
                  {resetWebhookstatus ? (
                    <>
                      {" "}
                      <Loader />
                      Loading
                    </>
                  ) : (
                    "Reset"
                  )}
                </button>
              </div>
              <div>
                <button
                  className="bg-komp-secondary-color text-white  font-medium rounded-sm text-sm p-3 text-center w-32"
                  onClick={webhook}
                >
                  {saveWebhookStatus ? (
                    <>
                      {" "}
                      <Loader />
                      Loading
                    </>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              {updatewebhookurl?.data?.webhookUrl?.url == "" || updatewebhookurl?.data?.webhookUrl?.url == undefined ? (
                <button
                  className="bg-komp-secondary-color text-white  font-medium rounded-sm text-sm p-3 text-center w-72 "
                  onClick={webhookurl}
                >
                  {" "}
                  Generate Webhook Url{" "}
                </button>
              ) : (
                <button
                  disabled={true}
                  className="text-white bg-gray-300  font-medium rounded-sm text-sm p-3 text-center w-72 "
                  // onClick={webhookurl}
                >
                  {" "}
                  Generate Webhook Url{" "}
                </button>
              )}
            </div>
            <div className="flex flex-col">
              <label>Webhook url</label>

              <OutlinedInput
                className="border-stone-200 border-2 resize-none w-96"
                id="outlined-adornment-weight"
                value={updatewebhookurl?.data?.webhookUrl?.url}
                ref={textAreaRef}
                endAdornment={
                  <Tooltip open={tooltipOpenupdte} onClose={() => setTooltipOpen(false)} title="copied" arrow>
                    <InputAdornment position="end">
                      {
                        <FileCopyIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            copyclicker();
                            handleButtonClickupdate();
                          }}
                        />
                      }
                    </InputAdornment>
                  </Tooltip>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
              />

              {copySuccess && updatewebhookurl.data.webhookUrl.url != "" ? (
                <>
                  <Tooltip title="copied" children={undefined}></Tooltip>
                </>
              ) : (
                <Tooltip title=" No data" children={undefined}></Tooltip>
              )}
            </div>
            <div className="mt-10 flex flex-row gap-16">
              <div>
                <button
                  className="bg-komp-secondary-color text-white  font-medium rounded-sm text-sm p-3 text-center w-32"
                  onClick={resetWebhook}
                >
                  {resetWebhookstatus ? (
                    <>
                      {" "}
                      <Loader />
                      Loading
                    </>
                  ) : (
                    "Reset"
                  )}
                </button>
              </div>
              <div>
                <button
                  className="bg-komp-secondary-color text-white  font-medium rounded-sm text-sm p-3 text-center w-32"
                  onClick={webhook}
                >
                  {saveWebhookStatus ? (
                    <>
                      {" "}
                      <Loader />
                      Loading
                    </>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default Webhook;
