import { Options } from "common/components/FormFields/types";

export const ruleConfigRawDataMapping = (data, index) => {
  const dataMapping = { ...data };
  for (const data in dataMapping) {
    if (data.includes(`filter_${index}`)) {
      delete dataMapping[data];
    }
    for (let i = index; i <= 3; i++) {
      if (data.includes(`filter_${i + 1}`)) {
        let dataProp = data.replace(`filter_${i + 1}`, "");
        let dataVal = `filter_${i}${dataProp}`;
        renameKey(dataMapping, data, dataVal);
      }
    }
  }
  return dataMapping;
};

export const ruleConfigRawDataMappingConditions = (data, filterIndex, conditionIndex) => {
  const dataMapping = { ...data };
  for (const data in dataMapping) {
    if (data.includes(`filter_${filterIndex}_condition_${conditionIndex}`)) {
      delete dataMapping[data];
    }
    for (let i = conditionIndex; i <= 5; i++) {
      if (data.includes(`filter_${filterIndex}_condition_${i + 1}`)) {
        let dataProp = data.replace(`filter_${filterIndex}_condition_${i + 1}`, "");
        let dataVal = `filter_${filterIndex}_condition_${i}${dataProp}`;
        renameKey(dataMapping, data, dataVal);
      }
    }
  }

  return dataMapping;
};

const renameKey = (obj, old_key, new_key) => {
  if (old_key !== new_key) {
    Object.defineProperty(obj, new_key, Object.getOwnPropertyDescriptor(obj, old_key));
    delete obj[old_key];
  }
};

export const setRuleConfig = (state, filterConditionLength) => {
  const data = JSON.parse(JSON.stringify(state));
  const filterLen = data.filters.length;
  const regexObj = new RegExp("filter_set_[0-9]", "gm");
  let result = {};
  let operators = [];
  for (let i = 0; i < filterLen; i++) {
    const conditionLen = state.filters[i].conditions.length;
    for (let j = 0; j < conditionLen; j++) {
      const dataType = data.rawDataMapping[`filter_${i}_condition_${j}___data_type`];
      data.filters[i].conditions[j].field_type = data.rawDataMapping[`filter_${i}_condition_${j}___field_source_type`];
      data.filters[i].conditions[j].field_name = data.rawDataMapping[`filter_${i}_condition_${j}___field_source_field`];
      data.filters[i].conditions[j].operator = data.rawDataMapping[`filter_${i}_condition_${j}___field_operator_type`];
      data.filters[i].conditions[j].value =
        dataType === "number"
          ? parseInt(data.rawDataMapping[`filter_${i}_condition_${j}___field_value`])
          : data.rawDataMapping[`filter_${i}_condition_${j}___field_value`];
    }
    data.filters[i].match_type = data.rawDataMapping[`filter_${i}___match_type`];
  }
  //setting operator array
  const operator = data.operator.split(" ");
  for (let i = 0; i < operator.length; i++) {
    const matchResults = JSON.parse(JSON.stringify(regexObj.exec(operator[i])));
    if (matchResults && i < operator.length - 1) {
      result[matchResults[0]] = "";
    } else if (operator[i] === "OR" || operator[i] === "AND") {
      operators.push(operator[i]);
    }
  }
  let flag = 0;
  for (let i in result) {
    result[i] = operators[flag];
    flag++;
  }
  data.operatorArray = result;
  return data;
};

export const setRawDataMapping = (state) => {
  const data = JSON.parse(JSON.stringify(state));
  data.rawDataMapping = {};
  const filterLen = data.filters.length;
  for (let i = 0; i < filterLen; i++) {
    const conditionLen = state.filters[i].conditions.length;
    for (let j = 0; j < conditionLen; j++) {
      data.rawDataMapping[`filter_${i}_condition_${j}___field_source_type`] = data.filters[i].conditions[j].field_type;
      data.rawDataMapping[`filter_${i}_condition_${j}___field_source_field`] = data.filters[i].conditions[j].field_name;
      data.rawDataMapping[`filter_${i}_condition_${j}___field_operator_type`] = data.filters[i].conditions[j].operator;
      data.rawDataMapping[`filter_${i}_condition_${j}___field_value`] = data.filters[i].conditions[j].value;
    }
    data.rawDataMapping[`filter_${i}___match_type`] = data.filters[i].match_type;
  }
  return data.rawDataMapping;
};

export const setRawDataMappingKeys = (filterIndex, conditionIndex, fieldType) => {
  const format = `filter_${filterIndex}_condition_${conditionIndex}___${fieldType}`;
  return format;
};

export const setOperator = (operator, operatorArray) => {
  let result = "";
  const res1 = operatorArray;
  let flag = 1;
  for (let i in res1) {
    if (Object.keys(res1).length === 1) {
      result = `filter_set_1 ${res1[i]} filter_set_2`;
    } else if (flag === 1) {
      result = `filter_set_1 ${res1[i]} `;
    } else if (flag === Object.keys(res1).length) {
      result += `${i} ${res1[i]} filter_set_${flag + 1}`;
    } else {
      result += `${i} ${res1[i]} `;
    }
    flag += 1;
  }
  return result;
};

export const setOperatorArray = (filter, filterOperatorArray) => {
  var filterNames = ["filter_set_1", "filter_set_2", "filter_set_3", "filter_set_4", "filter_set_5"];
  let findFilterName;
  filterNames.map((filterName, i, filterNames) => {
    if (filter.name === filterName) {
      findFilterName = filterNames[i - 1];
    }
  });
  filterOperatorArray[findFilterName] = "OR";
  return filterOperatorArray;
};

export const setOperatorArrayAfterRemovingFilter = (stateOperatorArray, index) => {
  let operatorArray = { ...stateOperatorArray };
  delete operatorArray[`filter_set_${index}`];
  for (const key in operatorArray) {
    for (let i = index; i <= Object.keys(operatorArray).length; i++) {
      if (key.includes(`${i + 1}`)) {
        renameKey(operatorArray, key, `filter_set_${i}`);
      }
    }
  }
  return operatorArray;
};

export const setFieldType = (field: Options, dataType) => {
  if (dataType) {
    if (dataType === "date") {
      return "datetime-local";
    } else if (dataType == "description") {
      return "multiline";
    } else {
      return dataType;
    }
  } else {
    return field.type;
  }
};
