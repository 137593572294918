import { Tab } from "@headlessui/react";
import Spinner from "components/Spinner";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getKonnectorLoading } from "state/edit-template-settings/slices";
import { StatusType } from "state/edit-template-settings/types";
import { useAppDispatch, useAppSelector } from "state/store";
import CredTab from "./CredTabs";
import {
  setAccountLoading,
  setCurrentTab,
  setTriggerInputSchema,
  setvalidateTabobject,
  setvalidatetabboolean,
} from "state/account/slices";
import { AppsDetails, CurrentState, TabType } from "state/account/types";
import RuleConfig from "./RuleConfig";
import DataMappingTab from "./DataMapping";
import { generateJSONForAccountValidation, generateJSONForAccountValidations } from "utils/config";
import { ToastError, ToastSucess } from "common/components/toast/Toast";
import TemplateList from "../TemplateList";
import {
  saveConfigurationLoading,
  setTargetAppAndAccountLoading,
  setcurrenttabindex,
  updateConfigurationLoading,
  nexttab,
} from "state/configurationv2/slices";
import Webhook from "./Webhook";
import WorkflowListing from "./WorkflowIntegration";
import { ToastContainer, toast } from "react-toastify";

const ConfigurationItemMoenggae: React.FC<any> = ({ appDetails }) => {
  const dispatch = useAppDispatch();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  let [urlParams, setUrlParams] = useSearchParams();
  let apiKey = urlParams.get("apiKey");
  let user = urlParams.get("user");

  const { id } = useParams<any>();

  useEffect(() => {
    dispatch(setTargetAppAndAccountLoading({ id, apiKey, user }));
  }, [id]);

  const sourceAppValidated = useAppSelector((state) => state.configurationV2.data.sourceAppValidated);
  const targetAppValidated = useAppSelector((state) => state.configurationV2.data.targetAppValidated);
  const sourceApp = useAppSelector((state) => state.configurationV2.data.sourceAppDetails);
  const targetApp = useAppSelector((state) => state.configurationV2.data.targetAppDetails);
  const sourceAppFields = useAppSelector((state) => state.configurationV2.data.sourceAppFields);
  const targetAppFields = useAppSelector((state) => state.configurationV2.data.targetAppFields);
  const apps = useAppSelector((state) => state.configurationV2.data.apps);

  const isUpdate = useAppSelector((state) => state.configurationV2.data.isConfigured);

  const indexTab = useAppSelector((state) => state.configurationV2.data.currenttabindex);

  const currenttab = useAppSelector((state) => state.configurationV2.data.currenttabindex);

  const renderHeaderTabsList: any = () => {
    return apps.map((app, index) => {
      return app.id == "templates" ? (
        <Tab
          onClick={() => {
            dispatch(setcurrenttabindex(index));
          }}
          key={app.index}
          // key={app.index}
          className={({ selected }) =>
            classNames(
              `${
                selected ? "text-indigo-600 border-indigo-700" : "text-gray-500 border-transparent"
              } py-4  px-2 mr-8 border-b-2`
            )
          }
        >
          Workflows
        </Tab>
      ) : (
        <Tab
          onClick={() => {
            dispatch(setcurrenttabindex(index));
          }}
          key={app.index}
          className={({ selected }) =>
            classNames(
              `${
                selected ? "text-indigo-600 border-indigo-700" : "text-gray-500 border-transparent"
              } py-4  px-2 mr-8 border-b-2`
            )
          }
        >
          {app.appName}
        </Tab>
      );
    });
  };
  const renderTabPanelItems = (tabType: TabType, tabItem: any) => {
    if (tabItem.id == "templates") {
      tabType = TabType.Templates;
    }
    if (tabItem.id !== "templates") {
      tabType = TabType.Creds;
    }
    if (tabItem.id == sourceApp.id) {
      var isSource = true;
    }
    switch (tabType) {
      case TabType.Webhook:
        return <Webhook appDetails={tabItem} />;
      case TabType.Creds:
        return (
          <>
            <CredTab appDetails={tabItem} type={isSource ? "source" : "target"} />
          </>
        );
      case TabType.RuleConfig:
        return <RuleConfig ruleConfig={tabItem} />;
      case TabType.Templates:
        return <WorkflowListing />;
    }
  };

  const saveAppConfig = () => {
    if (sourceAppValidated && targetAppValidated) {
      // dispatch(nexttab(indexTab));
      if (isUpdate) {
        // dispatch(nexttab(indexTab));
        dispatch(updateConfigurationLoading());
        toast.success("Configuration has been saved successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        dispatch(saveConfigurationLoading());

        toast.success("Configuration has been saved successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  const renderHeaderTabsPanels = () => {
    return apps.map((app, index) => {
      return (
        <Tab.Panel tabIndex={index} key={app.index} className={({ selected }) => classNames(`h-full`)}>
          {renderTabPanelItems(app.tabType, app)}
        </Tab.Panel>
      );
    });
  };

  if (apps?.length === 0) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  return (
    <>
      {apps.length && (
        <>
          <div className="relative h-full">
            <ToastContainer />
            <Tab.Group selectedIndex={indexTab}>
              <div className="sticky top-0 pb-3 z-50 bg-white ">{renderHeaderTabsList()}</div>
              <div className=" h-full">{renderHeaderTabsPanels()}</div>
            </Tab.Group>
            <div className="fixed right-2 bottom-0">
              <button
                onClick={saveAppConfig}
                className="w-auto text-white hover:bg-[#0e1a2e] bg-komp-secondary-color hover:transition-all focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 text-center disabled:opacity-45 bottom-0 right-0"
              >
                Save Configuration
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ConfigurationItemMoenggae;
