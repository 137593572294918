import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "state/store";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useApp } from "api/appsApi";
import { KonnectorType } from "state/new-konnector/types";
import { BreadCrumbIcon } from "components/icons/BreadCrumbIcon";
import { useDispatch } from "react-redux";
import { setparamsid } from "state/edit-template-settings/slices";

interface HeaderProps {
  name: string;
}
const EmbeddedHeader: React.FC<HeaderProps> = ({ name }) => {
  const dispatch = useDispatch<any>();

  const konnectorType = useAppSelector((state) => state.editTemplate.data.konnectorType);
  const hasConfigurationSaved = useAppSelector((state) => state.editTemplate.hasConfigurationSaved);
  const { id } = useParams<any>();
  const newcustomization = useAppSelector((state) => state.auth.customization);

  dispatch(setparamsid(id));

  const { data: apps, error: error } = useApp(id);
  const apiKeyAuth = useAppSelector((state) => state.auth.apiKey);
  const userId = useAppSelector((state) => state.auth.email);
  const customisation = useAppSelector((state) => state.auth.customisation);
  const new_Customization = useAppSelector((state) => state.auth.portalConfigration);
  const navigate = useNavigate();
  const url = window.location.href;
  const userIndex = url.indexOf("user=");
  var cc;
  if (userIndex !== -1) {
    const userEndIndex = url.indexOf("&", userIndex);
    if (userEndIndex !== -1) {
      cc = url.substring(userEndIndex + 1);
    } else {
      cc = "";
    }
  } else {
    // return null;
  }
  const appId = id;
  const handleClick = () => {
    navigate(-2);
  };

  return (
    <header className="flex justify-between w-full items-center   border-gray-200  h-20 rounded-sm pl-2 ml-5">
      {!id ? (
        <h4 className=" flex items-center justify-center  cursor-pointer text-sm mx-2 text-primary-color font-medium">
          <Link to={`/apps/${appId}/templates?apiKey=${apiKeyAuth}&user=${userId}`}>
            <BreadCrumbIcon className={"w-4 h-4 mr-4"} fill={"black"} />
          </Link>
          <span
            className=" text-xl text-primary-color "
            style={{
              fontFamily: newcustomization?.font,
              color: newcustomization?.text_colour,
            }}
          >
            Configuration
          </span>
          <span className="mt-2"></span>
          {/* {name} */}
        </h4>
      ) : name == "Configuration" ? (
        <h4 className=" flex items-center justify-center  cursor-pointer text-sm mx-2 text-primary-color font-medium">
          <BreadCrumbIcon className={"w-4 h-4 mr-4"} fill={"black"} />
          <Link to={`/apps/${appId}/templates?apiKey=${apiKeyAuth}&user=${userId}`}>
            {" "}
            <span
              className=" text-xl text-primary-color "
              style={{
                fontFamily: newcustomization?.font,
                color: newcustomization?.text_colour,
              }}
            >
              Configuration
            </span>
          </Link>
          <span className="mt-2"></span>
          {/* {name} */}
        </h4>
      ) : (
        <>
          <h4 className=" flex items-center justify-center  cursor-pointer text-sm mx-2 text-primary-color font-medium">
            <>
              {" "}

              <nav className="flex" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">

                  <li>
                    <Link to={`/apps/${id}?apiKey=${apiKeyAuth}&user=${userId}&${cc}`}>
                      <div className="flex items-center">
                        <a href="#" className=" text-md text-primary-color "
                          style={{
                            background: newcustomization?.primary_colour,
                            fontFamily: newcustomization?.font,
                            color: newcustomization?.text_colour,
                          }}>Back to Settings</a>
                      </div>
                    </Link>
                  </li>
                  <li aria-current="page">
                    <div className="flex items-center">
                      <svg className="rtl:rotate-180 w-3 h-3 text-gray-900 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                      </svg>
                      <span className=" text-md text-primary-color "
                        style={{
                          background: newcustomization?.primary_colour,
                          fontFamily: newcustomization?.font,
                          color: newcustomization?.text_colour,
                        }}> {apps?.data?.appName ? apps?.data?.appName : id} - Manage templates</span>
                    </div>
                  </li>
                </ol>
              </nav>

            </>

            {/* {name} */}
          </h4>
        </>
      )}
    </header>
  );
};

export default EmbeddedHeader;
