import { Tab } from "@headlessui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { HeaderTabs } from "./type";
import { useAppDispatch, useAppSelector } from "state/store";
import { getKonnectorLoading } from "state/edit-template-settings/slices";
import { StatusType } from "state/new-konnector/types";
import Spinner from "components/Spinner";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetAccount, setCurrentState } from "state/account/slices";
import { resetCreateKonnector } from "state/create-konnector/slices";
import { resetEditKonnector, setKonnectorLoading } from "state/edit-workflow/slices";
import { CurrentState } from "state/account/types";
import { setwebhookurl } from "state/konnector-list/slices";

import Success from "./Settings/DataMapping/FieldSchema/Toast";

import Error from "./Settings/DataMapping/FieldSchema/ToastError";
import { settoasterrorBoolean } from "state/auth/slices";

const Configitem: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const apiStatus = useAppSelector((state) => state.editTemplate.status);
  const konnectorStatus = useAppSelector((state) => state.editKonnector.status);

  const toasterrorBoolean = useAppSelector((state) => state.auth.toasterrorBoolean);
  const new_Customization = useAppSelector((state) => state.auth.portalConfigration);
  const apiKeyAuth = useAppSelector((state) => state.auth.apiKey);
  const userId = useAppSelector((state) => state.auth.email);
  const customisation = useAppSelector((state) => state.auth.customisation);
  const currentSate = useAppSelector((state) => state.account.currentState);
  const new_Customization_portalCustomization = useAppSelector((state) => state.auth.portalConfigration);
  const newcustomization = useAppSelector((state) => state.auth.customization);
  const toast = useAppSelector((state) => state.auth.toastBoolean);
  let appId = params.get("appId");
  const appIds = useAppSelector((state) => {
    if (currentSate == CurrentState.Konnectors) {
      return state?.editKonnector?.data?.workflowAppId;
    } else {
      return state?.editTemplate?.data?.workflowAppId;
    }
  });
  const url = window.location.href;
  const userIndex = url.indexOf("user=");
  var cc;
  if (userIndex !== -1) {
    const userEndIndex = url.indexOf("&", userIndex);
    if (userEndIndex !== -1) {
      cc = url.substring(userEndIndex + 1);
    } else {
      cc = "";
    }
  } else {
    // return null;
  }
  let templateId = params.get("templateId");
  let konnectorId = params.get("KonnectorId");

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  //
  //

  // const [workflowAppId, setWorkflowAppId] = useState(null);

  const paramsdata = useAppSelector((state) => state.editTemplate.paramsid);

  useEffect(() => {
    if (toasterrorBoolean) {
      const navigationTimeoutId = setTimeout(() => {
        dispatch(settoasterrorBoolean(false));
      }, 3000);

      return () => {
        // clearTimeout(timeoutId);
        clearTimeout(navigationTimeoutId);
      };
    }
  }, [toasterrorBoolean]);

  // const handletoastClose=()=>{

  //   dispatch(settoasttoastBoolean(false))
  // }
  const renderHeaderTabsList: any = () => {
    return HeaderTabs.map((tab) => {
      return (
        <Tab
          key={tab.value}
          className={({ selected }) =>
            classNames(
              `${
                selected ? "text-indigo-600 b-2 border-indigo-700" : "text-gray-500 border-transparent"
              } p-4 border-b-2`
            )
          }
          style={{
            fontFamily: newcustomization?.font,
            color: newcustomization?.text_colour,
          }}
        >
          <>{tab.label}</>
        </Tab>
      );
    });
  };

  const renderHeaderTabsPanels = () => {
    return HeaderTabs.map((tab) => {
      return (
        <Tab.Panel key={tab.value} className={({ selected }) => classNames(``)}>
          {tab.props}
        </Tab.Panel>
      );
    });
  };

  const [showComponent, setShowComponent] = useState(false);
  useEffect(() => {
    if (konnectorStatus === StatusType.success) {
      // Set a timeout to navigate and dispatch after 1000 milliseconds
      const timeoutId = setTimeout(() => {
        setShowComponent(true);
      }, 1000);

      // Clear the timeout to avoid memory leaks
      return () => clearTimeout(timeoutId);
    }
  }, [konnectorStatus]);

  useEffect(() => {
    dispatch(resetAccount());
    dispatch(resetCreateKonnector());
    dispatch(resetEditKonnector());
    if (templateId) {
      dispatch(setCurrentState(CurrentState.Templates));
      dispatch(getKonnectorLoading({ appId: appId, templateId: templateId }));
    } else if (konnectorId) {
      // dispatch(setCurrentState(CurrentState.Konnectors));
      // dispatch(setKonnectorLoading(konnectorId));
      // dispatch(setwebhookurl(""));
    }
  }, []);

  if (currentSate === CurrentState.Konnectors) {
    if (konnectorStatus === StatusType.idle) {
      return <Spinner />;
    }
  } else {
    if (apiStatus === StatusType.loading) {
      return <Spinner />;
    }
  }

  return (
    <div
      className=" w-full h-screen flex flex-col justify-start h-auto "
      style={{
        background: `${newcustomization?.primary_colour}`,
        color: `${newcustomization?.text_colour}`,
      }}
    >
      <div
        className="flex items-center whitespace-nowrap min-w-0 my-4"
        aria-label="Breadcrumb grid grid-cols-2 gap-4 overflow-auto"
      >
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
            <li>
              <Link
                to={`/apps/${
                  appIds != undefined ? appIds : paramsdata
                }/templates?apiKey=${apiKeyAuth}&user=${userId}&${cc}`}
              >
                <div className="flex items-center">
                  <a
                    href="#"
                    className=" text-md text-primary-color pl-6"
                    style={{
                      background: newcustomization?.primary_colour,
                      fontFamily: newcustomization?.font,
                      color: newcustomization?.text_colour,
                    }}
                  >
                    Manage Templates
                  </a>
                </div>
              </Link>
            </li>
            <li aria-current="page">
              <div className="flex items-center">
                <svg
                  className="rtl:rotate-180 w-3 h-3 text-gray-900 mx-1"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
                <span
                  className=" text-md text-primary-color "
                  style={{
                    background: newcustomization?.primary_colour,
                    fontFamily: newcustomization?.font,
                    color: newcustomization?.text_colour,
                  }}
                >
                  {" "}
                  Configuration
                </span>
              </div>
            </li>
          </ol>
        </nav>
        <h4 className=" flex items-center justify-center  cursor-pointer text-sm mx-2 text-primary-color font-medium "></h4>
        <>
          {toast && <Success />}

          {toasterrorBoolean && <Error />}
        </>
      </div>
      <div>
        <div
          className=" p-5 flex flex-col"
          style={{
            fontFamily: newcustomization?.font,
            color: newcustomization.text_colour,
          }}
        >
          <Tab.Group>
            <Tab.List
              className={"flex bg-gray-100 text-sm font-medium"}
              style={{
                fontFamily: newcustomization?.font,
                color: newcustomization.text_colour,
              }}
            >
              {renderHeaderTabsList}
            </Tab.List>
            {renderHeaderTabsPanels()}
          </Tab.Group>
        </div>
      </div>
      <ToastContainer transition={Slide} />
    </div>
  );
};

export default Configitem;
