import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "state/store";
import { AppsDetails, Choice, CurrentState } from "state/account/types";
import SourceFields from "./SourceFields.tsx";
import FieldValueType from "./FieldValueType";
import SourceTypes from "./SourceTypes";
import { konnectorIdle, resetAccount, resetStatus, saveDataMapping } from "state/account/slices";
import { saveKonnectorLoading } from "state/create-konnector/slices";
import { editWorkflowSaveKonnector } from "state/edit-workflow/slices";
import { ToastError, ToastSucess } from "common/components/toast/Toast";
import { StatusType } from "state/edit-workflow/types";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "components/icons/Loader.tsx";
import { flatSourceOutputFields } from "utils/appsUtils.ts";
import axios, { AxiosResponse } from "axios";
import { validateEndUserApi } from "api/authApi.ts";
import { setUserData, settoasttoastBoolean } from "state/auth/slices/index.ts";
import { toast } from "react-toastify";
import KonnectorMenu from "pages/TemplateList/component/Templates/TemplateList/konnectorItem/konnectorMenu/index.tsx";
interface InputSchemaType {
  appDetails: AppsDetails;
}

const InputSchema: React.FC<any> = ({ appDetails }) => {
  let new_data = appDetails.inputSchemas.map((res) => res?.eventResponse.data);
  const dispatch = useAppDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    control,
    setValue,
    watch,
  } = useForm();

  const hasDataMapping = appDetails.outputSchema?.data?.length > 0 && appDetails.inputSchemas?.length > 0;
  // konnectorStatus === StatusType.loading || konnectorStatus === StatusType.success || !hasDataMapping
  const rawDataMapping = appDetails?.rawDataMapping;

  const raw = useAppSelector((state) => state.account.tabItems);
  const rawValue = useAppSelector((state) => state.account.value);
  const [newObject, setnewObject] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const apiKey = useAppSelector((state) => state.auth.apiKey);
  const userId = useAppSelector((state) => state.auth.email);
  const konnectorStatus = useAppSelector((state) => state.account.isWorkflowSaved);
  const customisation_banner = useAppSelector((state) => state.auth.banner);
  let inputSchema = appDetails.inputSchemas ? appDetails.inputSchemas[0] : {};
  const apiKeyAuth = useAppSelector((state) => state.auth.apiKey);

  const toastBoolean = useAppSelector((state) => state.auth.toastBoolean);
  const currentSate = useAppSelector((state) => state.account.currentState);

  let tabs = useAppSelector((state) => state.account.tabItems);
  let showtab = tabs.map((data) => data.appSelectorType == "lookup");
  let ops = tabs.filter((daa) => daa?.eventId && daa.appSelectorType == "lookup");
  const url = window.location.href;
  const userIndex = url.indexOf("user=");
  var cc;
  if (userIndex !== -1) {
    const userEndIndex = url.indexOf("&", userIndex);
    if (userEndIndex !== -1) {
      cc = url.substring(userEndIndex + 1);
    } else {
      cc = "";
    }
  } else {
    // return null;
  }

  let commonelement = appDetails.inputSchemas?.filter((item1) =>
    ops.some((item2) => item1?.eventId == item2?.eventId && item2?.appSelectorType == "lookup" && item2.data.appName)
  );
  const objectData = commonelement?.reduce((acc, obj) => {
    Object.assign(acc, obj);
    return acc;
  }, {});

  let commonelementsource = commonelement?.filter((item1) =>
    commonelement.some(
      (item2) =>
        item1.sourceId == item2.sourceId &&
        item2.eventResponse.data?.map((sel) => ({
          label: sel.label || sel.propName,
          value: sel.propName,
        }))
    )
  );

  const appIds = useAppSelector((state) => {
    if (currentSate == CurrentState.Konnectors) {
      return state.editKonnector.data.workflowAppId;
    } else {
      return state.editTemplate.data.workflowAppId;
    }
  });

  let restdata = flatSourceOutputFields(appDetails?.inputSchemas[0]?.eventResponse?.data).map((sel) => ({
    label: sel.label || sel.propName,
    value: sel.propName,
    sourceId: appDetails.inputSchemas[0].sourceId,
    eventId: appDetails.inputSchemas[0].eventId,
  }));
  let data_one: any = commonelement.map(
    (res) =>
      res.eventId &&
      flatSourceOutputFields(res.eventResponse.data).map((sel) => ({
        label: sel.propName,
        value: sel.propName,
        sourceId: res.sourceId,
        eventId: res.eventId,
      }))
  );
  const concatenatedArray = [];

  for (let i = 0; i < data_one.length; i++) {
    for (let j = 0; j < data_one[i].length; j++) {
      concatenatedArray.push(data_one[i][j]);
    }
  }
  let dataop = commonelement.find((res) => {
    let pref =
      `${res.eventId} : ` &&
      flatSourceOutputFields(res.data).filter((dat) => dat.propType !== "object" && dat.propType !== "array");
  });
  const newdata = useAppSelector((state) => {
    if (currentSate == CurrentState.Konnectors) {
      return state.editKonnector.data.actions[0]?.rawDataMapping;
    } else {
      return state.editTemplate.data.actions[0]?.rawDataMapping;
    }
  });

  let input_schema = !showtab ? restdata : restdata.concat(concatenatedArray);
  const mergeObjects = (...objects) => {
    const result = {};

    objects.forEach((obj) => {
      Object.entries(obj || {}).forEach(([key, value]) => {
        if (!result[key]) {
          result[key] = [value];
        } else {
          result[key].push(value);
        }
      });
    });

    return result;
  };

  const mergedObject: any = mergeObjects(inputSchema, objectData);
  const renderFieldSchema = () => {
    // let data = [...appDetails.outputSchema?.eventResponse?.data]
    // let v = { isRequired: false, label: "", propName: rawValue, propType: "" }
    // data.push(v)
    return appDetails.outputSchema?.data?.map((field) => {
      return (
        <>
          <FieldValueType field={field} name={`${field.propName}__field_type`} register={register} errors={errors} />
          <SourceTypes
            field={field}
            name={`${field.propName}__field_source_type`}
            control={control}
            watch={watch}
            register={register}
            errors={errors}
          />
          <SourceFields
            setInputValue={setValue}
            field={field}
            control={control}
            getValues={getValues}
            watch={watch}
            register={register}
            errors={errors}
            inputSchema={input_schema}
            appDetails={appDetails}
            setnewObject={setnewObject}
            newObject={newObject}
          />
        </>
      );
    });
  };
  const options: any = {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
  };
  const onSubmit = async (data) => {
    dispatch(saveDataMapping({ currentActionId: appDetails.id, rawDataMapping: data }));
    if (currentSate === CurrentState.Templates) {
      dispatch(saveKonnectorLoading({ apiKey, userId }));
    } else {
      dispatch(editWorkflowSaveKonnector());
    }
  };
  const apiFunc = async () => {
    let Endresult = await validateEndUserApi();
    dispatch(setUserData(Endresult.data));
  };

  useEffect(() => {
    if (konnectorStatus === StatusType.success) {
      dispatch(settoasttoastBoolean(true));

      // return () => clearInterval(intervalId);

      // toast.success(customisation_banner.successMsg, {
      //   position: "top-center",
      //   autoClose: 1000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   onClose: () => {
      //     navigate(`/apps/${appIds}/templates?apiKey=${apiKeyAuth}&user=${userId}`);
      //     dispatch(konnectorIdle());
      //   },
      // });

      // ToastSucess("Workflow Saved Successfully..");

      // navigate(`/apps/${appIds}/templates?apiKey=${apiKeyAuth}&user=${userId}`);
    } else if (konnectorStatus === StatusType.failed) {
      ToastError(customisation_banner.failureMsg);
    }
  }, [konnectorStatus]);

  useEffect(() => {
    if (toastBoolean) {
      // const timeoutId = setTimeout(() => {

      // }, 8000);

      const navigationTimeoutId = setTimeout(() => {
        navigate(`/apps/${appIds}/templates?apiKey=${apiKeyAuth}&user=${userId}&${cc}`);

        apiFunc();
        dispatch(settoasttoastBoolean(false));

        dispatch(konnectorIdle());
      }, 2000);

      return () => {
        // clearTimeout(timeoutId);
        clearTimeout(navigationTimeoutId);
      };
    }

    // else if (!toastBoolean){
    //   apiFunc();
    //   navigate(`/apps/${appIds}/templates?apiKey=${apiKeyAuth}&user=${userId}`);
    //   dispatch(konnectorIdle());
    // }
  }, [toastBoolean]);

  let fieldsWithSourceTypes = Object?.keys(newdata || {})?.filter(
    (field) => field?.endsWith("__field_source_type") && !field.includes("trigger")
  );

  let updatedData = { ...newdata };
  fieldsWithSourceTypes.forEach((fieldWithSourceType) => {
    const fieldName = fieldWithSourceType.replace("__field_source_type", "");
    const fieldValues = newdata[fieldName];
    const sourceType = newdata[fieldWithSourceType].split(":").pop().trim();
    if (Array.isArray(fieldValues)) {
      let trimmedValues = fieldValues?.map((value) => value?.trim());

      updatedData[fieldName] = trimmedValues?.map((value) => `${sourceType} : ${value}`);
    }
  });
  if (typeof newdata === "object" && newdata !== null) {
    let fieldsWithSourceTypess = Object?.keys(newdata)?.filter(
      (field) => field?.endsWith("__field_source_type") && !field?.includes("trigger")
    );

    let rawdata = { ...rawDataMapping };
    fieldsWithSourceTypess?.forEach((fieldWithSourceType) => {
      const fieldName = fieldWithSourceType?.replace("__field_source_type", "");
      const fieldValues = newdata[fieldName];
      const sourceType = newdata[fieldWithSourceType]?.split(":")?.pop()?.trim();
      if (Array.isArray(fieldValues)) {
        const trimmedValues = fieldValues.map((value) => value?.trim());

        rawdata[fieldName] = trimmedValues.map((value) => `${sourceType} : ${value}`);
      }
    });
  }

  useEffect(() => {
    setnewObject({});
    reset({ ...newdata, ...rawDataMapping });
  }, []);
  return (
    <>
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-row gap-4 ">
            <div>
              <>{hasDataMapping && <>{renderFieldSchema()}</>}</>
            </div>
          </div>

          <button
            disabled={
              konnectorStatus === StatusType.loading || konnectorStatus === StatusType.success || !hasDataMapping
            }
            type="submit"
            className="max-w-xs mr-10 mb-2 fixed right-8  bottom-2 text-white hover:bg-[#0e1a2e] bg-komp-secondary-color font-medium rounded-sm text-sm p-3 text-center disabled:opacity-50"
          >
            {konnectorStatus === StatusType.loading ? (
              <>
                <Loader />
                Loading
              </>
            ) : (
              "Save And Publish"
            )}
          </button>
        </form>
      </>
    </>
  );
};

export default InputSchema;
