import { AxiosResponse } from "axios";
import { takeEvery, put, call, select } from "redux-saga/effects";
import {
  validateAccountSuccess,
  validateAccount2Success,
  accountApiFailed,
  createAccountSuccess,
  setTab2Loading,
  setOAuthAccountLoading,
  setAccountLoading,
  setUpdateAccountLoading,
  updateAccountSuccess,
  setUpdateOAuthAccountLoading,
  setUpdateLoading,
  setUpdateTab2Loading,
  accountUpdateApiFailed,
  updateValidationSuccess,
  updateValidationSuccess2,
  updateAccount2Success,
  fetchAllAppSchemaLoading,
  setAppSchema,
  setDyanmicFieldsLoading,
  setDyanmicFieldsSuccess,
  getDynamicAppSchemaLoading,
  fetchInputFieldsLoading,
  fetchDynamicFieldsLoading,
  fetchDynamicFieldsSuccess,
} from "./slices";
import {
  AppRole,
  CreateAccountSuccessResponse,
  DynamicFieldsSchemaType,
  InputFields,
  KonnectifyApiResponse,
  KonnectorAppState,
  ValidateAccountSuccessResponse,
} from "state/new-konnector/types";
import {
  createAccountApi,
  fetchAppSchemaApi,
  getFieldMapping,
  updateAccountApi,
  validateAccountApi,
} from "api/configApi";
import {
  createAccountFailed,
  fetchInputFieldsSuccess,
  fetchOutputFieldsSuccess,
} from "state/edit-template-settings/slices";
import {
  serializeAppSchemaFields,
  serializeAppSchemaFieldsForState,
  serializeDataForAppSchema,
} from "utils/stateUtils";
import { getAllAppsSchemaApi } from "api/allAppsApi";
import { fetchDynamicInputFieldsApi, getAppSchemaApi } from "api/appsApi";
import { getTargetDetails } from "state/edit-workflow/selectors";

export function* createAccount(data) {
  try {
    const ValidateResult: AxiosResponse<ValidateAccountSuccessResponse> = yield call(
      validateAccountApi,
      data.payload.accountDetails
    );
    if (ValidateResult.data.data) {
      yield put(validateAccountSuccess(ValidateResult.data));
      const result: AxiosResponse<CreateAccountSuccessResponse> = yield call(
        createAccountApi,
        data.payload.accountDetails
      );
      yield put(createAccountSuccess({ ...data.payload, ...result.data }));
    } else {
      yield put(accountApiFailed({ ...data.payload }));
    }
  } catch (e) {
    yield put(accountApiFailed({ ...data.payload }));
  }
}

export function* updateAccount(data) {
  try {
    const ValidateResult: AxiosResponse<ValidateAccountSuccessResponse> = yield call(
      validateAccountApi,
      data.payload.accountDetails
    );
    if (ValidateResult.data.data) {
      yield put(validateAccountSuccess(ValidateResult.data));
      const results: AxiosResponse<ValidateAccountSuccessResponse> = yield call(validateAccountApi, data.payload);
      yield put(updateValidationSuccess(results.data));
      if (results.data) {
        const result: AxiosResponse<CreateAccountSuccessResponse> = yield call(
          updateAccountApi,
          data.payload.accountId,
          data.payload
        );
        yield put(updateAccountSuccess({ ...data.payload, ...result.data }));
      }
    } else {
      yield put(accountApiFailed({ ...data.payload }));
    }

    // }
  } catch (e) {
    yield put(accountApiFailed({ ...data.payload }));
  }
}

export function* getAllAppsSchema(data) {
  try {
    const tabs = yield select((state) => state.account.tabItems);
    let appSchemaPayload = serializeDataForAppSchema(tabs);
    const dataoutput = yield select((state) => state.konnectors.savewebhookresponse);
    const ValidateResult: AxiosResponse<ValidateAccountSuccessResponse> = yield call(
      getAllAppsSchemaApi,
      appSchemaPayload
    );
    let appSchemas = serializeAppSchemaFields(ValidateResult);
    let statePayload = serializeAppSchemaFieldsForState(appSchemas, dataoutput);
    let outputSchema = [];

    outputSchema.push(dataoutput?.data?.schema);
    let final_response = { ...outputSchema, ...statePayload.inputSchemas };
    // dataoutput
    // if (dataoutput?.fields?.appId == "generic") {
    //   yield put(setAppSchema({ ...final_response, ...data.payload }));
    // } else {
    yield put(setAppSchema({ ...statePayload, ...data.payload }));
    // }
  } catch (e) {}
}

export function* getTriggerDynamicFields(data) {
  try {
    let payload;
    if (data.payload.hastDynamicInputfields) {
      payload = {
        appId: data.payload.appId,
        data: {
          schemaType: DynamicFieldsSchemaType.Input,
          eventId: data.payload.event,
          connectionConfigId: data.payload.connectionId,
        },
      };
    } else {
      payload = {
        appId: data.payload.appId,
        data: {
          schemaType: DynamicFieldsSchemaType.DynamicInput,
          eventId: data.payload.event,
          connectionConfigId: data.payload.connectionId,
        },
      };
    }

    yield put(setDyanmicFieldsLoading({ currentActionId: data.payload.currentActionId }));
    const result: AxiosResponse<KonnectifyApiResponse<any>> = yield call(getAppSchemaApi, payload);
    const inputFields = {
      fields: result.data.data.data,
      appId: result.data.data.appId,
      eventId: result.data.data.eventId,
      currentActionId: data.payload.currentActionId,
    };

    if (result) {
      yield put(setDyanmicFieldsSuccess({ currentActionId: data.payload.currentActionId, ...inputFields }));
    }
  } catch (e) {
    yield put(createAccountFailed());
  }
}

export function* createOAuthAccount(data) {
  try {
    const result: AxiosResponse<CreateAccountSuccessResponse> = yield call(
      createAccountApi,
      data.payload.accountDetails
    );
    yield put(createAccountSuccess({ ...data.payload, ...result.data }));
  } catch (e) {
    yield put(accountApiFailed({ ...data.payload }));
  }
}

export function* updateOAuthAccount(data) {
  try {
    const accountId = yield select((state) => state.account.activeAccount.id);
    const result: AxiosResponse<CreateAccountSuccessResponse> = yield call(updateAccountApi, accountId, data.payload);
    yield put(updateAccountSuccess({ ...data.payload, ...result.data }));
  } catch (e) {
    yield put(accountApiFailed({ ...data.payload }));
  }
}

export function* fetchDynamicInputFields(data) {
  try {
    // const targetDetails: KonnectorAppState<AppRole.Target> = yield select(getTargetDetails, data.payload.parentId)

    let dataMapping = data.payload.mapping;

    const result: AxiosResponse<KonnectifyApiResponse<InputFields>> = yield call(fetchDynamicInputFieldsApi, {
      appId: data.payload.appDetails.appId,
      data: {
        schemaType: DynamicFieldsSchemaType.DynamicFields,
        eventId: data.payload.eventId,
        connectionConfigId: data.payload.appDetails.connectionConfig.id,
        eventData: { ...dataMapping },
      },
    });
    // ;
    if (result) {
      yield put(
        fetchDynamicFieldsSuccess({
          currentActionId: data.payload.appDetails.actionOrderId,
          value: result.data.data.data,
          field: data.payload.field,
        })
      );
    }
  } catch (e) {
    yield put(createAccountFailed());
  }
}

export default function* rootSaga() {
  yield takeEvery(setAccountLoading.type, createAccount);
  yield takeEvery(setUpdateLoading.type, updateAccount);
  yield takeEvery(setOAuthAccountLoading.type, createOAuthAccount);
  yield takeEvery(fetchAllAppSchemaLoading.type, getAllAppsSchema);
  yield takeEvery(setUpdateOAuthAccountLoading.type, updateOAuthAccount);
  yield takeEvery(getDynamicAppSchemaLoading.type, getTriggerDynamicFields);
  yield takeEvery(fetchDynamicFieldsLoading.type, fetchDynamicInputFields);
}
