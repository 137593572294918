import { AppRootObject } from "interfaces/modules/apps";
import { DataSource } from "interfaces/pages/create-new-konnector";
// import { ControlType, SubcategoryField } from "state/form-builder/type";

//Common Types
export enum StatusType {
  idle = "idle",
  loading = "loading",
  success = "success",
  failed = "failed",
}

export enum DynamicFieldsSchemaType {
  Input = "input",
  Output = "output",
  DynamicInput = "dynamic_input",
}

export enum ActionMode {
  Edit = "edit",
  Save = "save",
  View = "view",
  Create = "create",
}

export enum ConfigurationType {
  Source = "source",
  Lookup = "lookup",
  Target = "target",
  RuleEngine = "ruleEngine",
  ParseEngine = "parseEngine",
}

export enum AppRole {
  Source = "source",
  Target = "target",
  Lookup = "lookup",
  RuleEngine = "ruleEngine",
  ParseEngine = "parseEngine",
}

export enum StatusCode {
  "SuccessOK" = 200,
  "SuccessCreated" = 201,
  "ClientErrorBadRequest" = 400,
  "ClientErrorUnauthorized" = 401,
  "ClientErrorNotFound" = 404,
  "ServerErrorInternal" = 500,
}

export interface KonnectifyApiResponse<T> {
  data: T;
  status: StatusCode;
}

export interface OutputFieldsObject {
  appId?: string;
  eventId?: string;
  eventResponse?: {
    status?: StatusCode.SuccessOK;
    data?: object;
  };
}

export interface OutputFields {
  hasData: boolean;
  fields?: OutputFieldsObject;
  schema:
    | DataSource
    | {
        sourceId?: string;
        eventResponse?: {
          data: any[];
        };
      };
}

export interface PropValueObject {
  label: string;
  value: string;
}

export interface InputField {
  propName: string;
  propType: string;
  isRequired: boolean;
  propValue?: PropValueObject[];
}
export interface InputFields {
  sourceId: string;
  eventId: string;
  appId: string;
  data: InputField[] | any;
  rawDataMapping?: any;
  dataMapping?: any;
  hasData?: boolean;
}

export interface DynamicInputFields {
  data: InputField[] | any;
  hasDynamicInputFields: boolean;
  dataMapping: any;
  hasData: boolean;
}

export interface webhookUrl {
  url: any;
  id: any;
}
export interface DataMappingObject {
  propName: string;
  propValue?: any[];
  propType: string;
  isRequired: boolean;
  source: string;
  sourceProp: string[];
  staticValue: string;
}
export interface KonnectorApp {
  id?: string;
  baseUrl?: string;
  apiKey?: string;
}

export interface KonnectorAppState<T> {
  id?: string;
  actionOrderId: number;
  triggerType?: string;
  formFields?: FormFieldData[];
  formSettings?: any;
  appSelectorType: T;
  appName: string;
  connectionName: string;
  actionMode?: ActionMode;
  appSelectorKey: string;
  iconUrl: string;
  appId: string;
  eventId: string;
  connectionId: string;
  isAccountValidated: boolean;
  outputFields?: OutputFields;
  inputFields?: InputFields;
  dynamicInputFields?: DynamicInputFields;
  dataMapping?: DataMappingObject[];
  rawDataMapping?: object;
  shouldReconfigure?: boolean;
  appDetails: AppRootObject | {};
}

export interface NewKonnectorState {
  id?: string;
  name: string;
  konnectorType: KonnectorType;
  webhookUrl: {
    url: string;
    id: string;
  };
  trigger: KonnectorAppState<AppRole.Source>;
  lookups: KonnectorAppState<AppRole.Lookup>[];
  actions: KonnectorAppState<AppRole.Target>[];
  actionItems?: any;
  ruleEngine?: any;
  parseEngine?: any;
  sourceTemplate?: any;
  konnectorSource?: any;
  workflowAppId?: string;
  workflowDescription?: string;
  workflowInstruction?: string;
  adminRequiredData?: DataMappingObject[];
}
export interface UpdateSourceAppPayload {
  appId: string;
  appName?: string;
  iconUrl?: string;
  connectionId?: string;
  connectionName?: string;
  appDetails?: AppRootObject;
}
export interface UpdateSourceAccountPayload {
  connectionId: string;
  connectionName: string;
}

export interface UpdateTargetAppPayload {
  id: string;
  appId: string;
  appName?: string;
  iconUrl?: string;
  connectionId?: string;
  connectionName?: string;
  appDetails?: AppRootObject;
}
export interface UpdateTargetAccountPayload {
  id: string;
  connectionId: string;
  connectionName: string;
}

export interface UpdateTargetEventPayload {
  id: string;
  eventId: string;
  hasDynamicInputFields?: boolean;
}

export interface UpdateLookupEventPayload {
  id: string;
  eventId: string;
  attribute?: string;
}

export interface AccountField {
  value: string;
  name: string;
}

export interface AccountData {
  fields: AccountField[];
  connectionName: string;
  id: string;
  connectorTypeId: string;
  appId: string;
  dtUpdated: Date;
  dtCreated: Date;
  tenantId: string;
}

export interface FetchDynamicInputFieldsRequestPayload {
  schemaType: DynamicFieldsSchemaType;
  connectionConfigId: string;
  eventId: string;
}

export enum KonnectorType {
  WebHook = "webhook",
  Regular = "regular",
  Form = "form",
  Initial = "",
}

export type ValidateAccountSuccessResponse = KonnectifyApiResponse<boolean>;
export type CreateAccountSuccessResponse = KonnectifyApiResponse<AccountData>;

export interface InitialState {
  template: any;
  sourceOutputSchema: any;
  dynamicDatamappingStatus: StatusType;
  targetInputSchema: any;
  schema: {
    output: any;
    input: {
      data: [];
    };
  };
  fieldsRetrived: boolean;
  rawDataMapping: any;
  inputIconUrl: any;
  outputIconurls: any;
  status: StatusType;
  accordionCard: boolean;
  konType: any;
  isLookup: boolean;
  dynamicInputFields: any;
  lookupTabState: any;
  invalidLookup: any;
  isKonnectorLookup: any;
  currentstate: any;
  Dynamicvalues?: any;
  dataMapping?: any;
  DynamicInputDataMapping?: any;
  dynamicworksheetvalues?: any;
  dynamicdatastatus?: any;
  dynamicinputstate?: boolean;
  konnectorNameLoading?: StatusType;
  mappingsuccess?: StatusType;
  konnectorArray?: any;
}

export interface SelectOption {
  id: number;
  label: string;
  isRepeated?: boolean;
  //   subCategory?: SubcategoryField[];
}

interface RegisterOptions {
  requires: boolean;
  pattern?: string;
  subcategoryField?: string;
  textFieldType?: string;
  itemsField?: string;
  subcategoryDescription?: string;
  itemDescription?: string;
  confirmationField: boolean;
}

export interface FormFieldData {
  orderId?: number;
  fieldId?: string;
  actionMode?: ActionMode;
  description: string;
  label: string;
  //   inputType: ControlType;
  fieldName: string;
  sampleData: any;
  options?: SelectOption[];
  config?: RegisterOptions;
  isSaved?: boolean;
}
