import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ActionMode,
  AppRole,
  ConfigurationType,
  CreateAccountSuccessResponse,
  InitialState,
  KonnectorType,
  StatusType,
} from "../types";

export const initialState: InitialState = {
  data: {
    id: "",
    name: "Edit Template",
    konnectorType: KonnectorType.Initial,
    webhookUrl: {
      url: "",
      id: "",
    },
    trigger: {
      triggerType: "",
      appSelectorKey: "",
      appName: "",
      iconUrl: "",
      appSelectorType: AppRole.Source,
      formFields: [],
      formSettings: {},
      appId: "",
      actionOrderId: 0,
      connectionId: "",
      connectionName: "",
      eventId: "",
      isAccountValidated: false,
      outputFields: {
        hasData: false,
        fields: {},
        schema: {},
      },
      inputFields: { appId: "", data: [], eventId: "", sourceId: "", dataMapping: {}, hasData: false },
      appDetails: {
        connectionConfig: undefined,
        actions: [],
        description: "",
        triggers: [],
        iconUrl: "",
        id: "",
        appName: "",
        appKey: "",
        dtCreated: undefined,
        lookups: [],
        dtUpdated: undefined,
      },
    },
    lookups: [],
    actions: [],
    ruleEngine: [],
    parseEngine: [],
    sourceTemplate: "",
  },
  currentActionId: "",
  hasConfigurationSaved: true,
  configurationType: ConfigurationType.Source,
  actionMode: ActionMode.View,
  status: StatusType.idle,
  resetWebhook: StatusType.idle,
  generateUrl: StatusType.idle,
  resetFormStatus: StatusType.idle,
  isFormGenerated: false,
  errorMessage: "",
  paramsid: "",

  created: false,
  saveButtonStatus: false,
  editRule: false,
  edit: false,
  editParse: false,
};
const editTemplateSlice = createSlice({
  name: "editTemplate",
  initialState,
  reducers: {
    getKonnectorLoading: (state, data: PayloadAction<any>) => {
      state.status = StatusType.loading;
    },
    getKonnectorSuccess: (state, data: PayloadAction<any>) => {
      state.status = StatusType.success;
      state.data = data.payload.data;
      state.generateUrl = StatusType.success;
    },
    setparamsid: (state, data) => {
      state.paramsid = data.payload;
    },
    getKonnectorFailed: (state) => {
      state.status = StatusType.failed;
    },
    fetchOutputFieldsSuccess: (state, data: PayloadAction<any>) => {
      state.status = StatusType.success;
      // state.data.trigger.outputFields.fields = data.payload.sampleData;
      state.data.trigger.connectionId = data.payload.connectionConfigId;
      state.data.trigger.outputFields.schema = data.payload.outputFieldsSchema;
      state.data.trigger.outputFields.hasData = true;
    },
    fetchInputFieldsSuccess: (state, data: PayloadAction<any>) => {
      // state.data.trigger.outputFields.fields = data.payload.sampleData;
      state.data.actions[0].connectionId = data.payload.connectionConfigId;
      state.data.actions[0].inputFields.data = data.payload.inputFieldsSchema.data;
      // state.data.trigger.outputFields.hasData = true;
    },
    createAccountLoading: (state, data: PayloadAction<any>) => {
      state.status = StatusType.loading;
    },
    getDynamicAppSchemaLoading: (state, data) => {
      // state.status = StatusType.loading;
    },
    getDynamicAppSchemaSuccess: (state, data) => {},

    createAccountSuccess: (state, data: PayloadAction<CreateAccountSuccessResponse>) => {
      state.data.trigger.connectionId = data.payload.data.id;
      state.status = StatusType.success;
    },
    createAccountFailed: (state) => {
      state.status = StatusType.failed;
    },
    getAppSchemaLoading: (state) => {
      state.status = StatusType.loading;
    },
    getAppSchemaSucess: (state) => {
      state.status = StatusType.success;
    },
    resetEditTemplate: () => initialState,

    // saveworkFlow()
  },
});

export const {
  getKonnectorLoading,
  getKonnectorSuccess,
  getKonnectorFailed,
  getDynamicAppSchemaLoading,
  getDynamicAppSchemaSuccess,
  createAccountLoading,
  createAccountSuccess,
  fetchOutputFieldsSuccess,
  createAccountFailed,
  fetchInputFieldsSuccess,
  getAppSchemaLoading,
  getAppSchemaSucess,
  resetEditTemplate,
  setparamsid,
} = editTemplateSlice.actions;

export default editTemplateSlice.reducer;
