import React, { useEffect, useState, useRef, useMemo } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "components/ui/accordion";
import { MultiOptions } from "../types";
import { useAppSelector } from "state/store";
const MultipleSelectField: React.FC<any> = ({
  choices,
  control,
  name,
  onChangeHandler,
  register,
  getValues,
  errors,
  setInputValue,
  placeholder,
  appDetails,
}) => {
  const getFormConfigs = () => {};

  const newdata: any = useAppSelector((state) =>
    state.editTemplate.data.actions[0]?.dataMapping?.map((res) => res.propName)
  );
  const formState = getValues()?.[name]
    ? [
        {
          values: getValues()?.[name],
          eventId: getValues()?.[`${name}__field_source_type`]
            ? getValues()?.[`${name}__field_source_type`].split(":")[1]
            : "",
        },
      ]
    : [];

  const [selectedValues, setSelectedValues] = useState(formState);
  const iconUrl: any = useAppSelector((state) => state.account.tabItems);

  const [finalSelectedValue, setFinalSelectedValue] = useState(null);
  const [openAccordion, setOpenAccordion] = useState(false);

  const closeAccordion = () => {
    setOpenAccordion(false);
  };
  const [newState, setnewSatate] = useState([]);
  const [booleanstate, setbooleansttate] = useState(false);
  useEffect(() => {
    const formState = getValues()?.[name]
      ? [
          {
            values: getValues()?.[name],
            eventId: getValues()?.[`${name}__field_source_type`]
              ? getValues()?.[`${name}__field_source_type`].split(":")[1]
              : "",
          },
        ]
      : [];
    setnewSatate(formState);
    // setSelectedValues(formState);
  }, [name, getValues, selectedValues]);

  useEffect(() => {
    const formState = getValues()?.[name]
      ? [
          {
            values: getValues()?.[name],
            eventId: getValues()?.[`${name}__field_source_type`]
              ? getValues()?.[`${name}__field_source_type`].split(":")[1]
              : "",
          },
        ]
      : [];
    setnewSatate(formState);
    // setSelectedValues(formState);
  }, [booleanstate, selectedValues, booleanstate]);

  const handleAccordionChange = (headerId, selectedValue) => {
    setbooleansttate(true);
    setSelectedValues((prevSelectedValues) => {
      const existingIndex = prevSelectedValues.findIndex((item) => item.eventId === headerId);

      if (existingIndex === -1) {
        // If the headerId is not present, add a new entry
        const updatedValues = [...prevSelectedValues, { eventId: headerId, values: [selectedValue] }];
        // Clear values for other eventIds
        const clearedValues = updatedValues.filter((item) => item.eventId === headerId);

        onChangeHandler(vall(clearedValues).map((v) => v.value));
        setInputValue(
          name,
          vall(clearedValues).map((v) => v.value)
        );

        return clearedValues;
      } else {
        // If the headerId is present, update the existing entry
        const updatedValues = prevSelectedValues.map((item) => {
          if (item.eventId === headerId) {
            // If it's the correct eventId, update the values
            const newValues = [...item.values, selectedValue];

            // Clear values for other eventIds
            const clearedValues = prevSelectedValues.filter((item) => item.eventId === headerId);

            onChangeHandler(vall([...clearedValues, { eventId: headerId, values: newValues }]).map((v) => v.value));
            setInputValue(
              name,
              vall([...clearedValues, { eventId: headerId, values: newValues }]).map((v) => v.value)
            );

            return { eventId: item.eventId, values: newValues };
          }

          return item;
        });

        // Use the callback version of setSelectedValues to ensure the updatedValues are used for the next state
        setSelectedValues(() => updatedValues);
        setInputValue(
          name,
          vall(updatedValues).map((v) => v.value)
        );
        return updatedValues;
      }
    });
  };

  // useEffect(() => {
  //   // Use useEffect to update the final selected value
  //   // const updatedFormValues = vall(selectedValues).map((v) => v.value);
  //   const updatedFormValues = vall(selectedValues).map((v) => v.value);
  //   setInputValue(name, vall(selectedValues).map((v)=>v.value));
  //   // setInputValue(name, updatedFormValues); // Use setValue to update the form field
  // }, [selectedValues,]);

  // ...
  const accordionRef = useRef(null);

  const handleClickOutside = (event) => {
    if (accordionRef.current && !accordionRef.current.contains(event.target)) {
      // Clicked outside the accordion, so close it
      setOpenAccordion(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const vall = (selectedValues) => {
    const hasParsedEvent = selectedValues?.some((n) => n.eventId?.includes("Parsed"));

    if (hasParsedEvent) {
      return selectedValues.flatMap((item) =>
        item.values.map((value) => ({ label: value, value, eventId: item.eventId }))
      );
    }

    return choices
      .filter((c) => selectedValues?.some((s) => s.values?.includes(c.value) && s.eventId === c.eventId))
      .map((c) => ({ label: c.label, value: c.value, eventId: c.eventId }));
  };
  if (name === "name") {
  }

  const manualHandler = (onChange, e) => {};
  let data_res = vall(selectedValues).map((v) => v.value);

  useEffect(() => {
    const formState = getValues()?.[name];
    const newSelectedValues = formState
      ? [
          {
            values: formState,
            eventId: getValues()?.[`${name}__field_source_type`]
              ? getValues()?.[`${name}__field_source_type`].split(":")[1]
              : "",
          },
        ]
      : [];

    setSelectedValues(newSelectedValues);
    vall(selectedValues);
  }, [name, getValues]);
  return (
    <div>
      <Controller
        control={control}
        {...register(name, getFormConfigs())}
        render={({ field: { onChange: hookOnChange, value, ref } }) => (
          <div className="pb-6 pb-3">
            <div
              className={`flex w-full border-[1px] p-2 pb-3 m-1 ml-0 rounded-lg h-12 gap-2 items-center overflow-hidden ${
                newdata?.includes(name) ? "bg-gray-300" : ""
              }`}
              ref={ref}
              onClick={() => {
                setOpenAccordion(true);
              }}
              onChange={(e) => manualHandler(hookOnChange, e)}
            >
              {vall(newState).map((f) => {
                return (
                  <span
                    className={`flex p-1 items-center bg-gray-100 rounded ${
                      newdata?.includes(name) ? "bg-gray-300" : ""
                    }`}
                  >
                    {f.value}
                    {!newdata?.includes(name) && (
                      <button
                        type="button"
                        className="ml-2 font-medium"
                        onClick={() => {
                          const updatedValues = selectedValues
                            .map((item) => ({
                              ...item,
                              values: item.values.filter((value) => value !== f.value),
                            }))
                            .filter((item) => item.values.length > 0);

                          setSelectedValues(updatedValues);
                          hookOnChange(vall(updatedValues).map((v) => v.value));
                        }}
                      >
                        x
                      </button>
                    )}
                  </span>
                );
              })}
            </div>

            {openAccordion && !newdata?.includes(name) ? (
              <div className="border-[1px] border-slate-200 rounded-lg  pr-3 p-3 pl-3 " ref={accordionRef}>
                <div className="flex justify-end p-3">
                  <div className="font-medium">Select Field</div>
                  <button
                    type="button"
                    className="font-black  hover:bg-gray-200 font-medium hover:text-gray-900 rounded-sm text-sm ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                    data-modal-toggle="account-modal"
                    onClick={closeAccordion}
                  >
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <Accordion type="single" collapsible className="pb-7, ">
                  {appDetails?.inputSchemas?.map((header, index) => (
                    <AccordionItem
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                        borderBottom: "none",
                      }}
                      key={`${header?.eventId}-${index}`}
                      value={header?.eventId}
                    >
                      <AccordionTrigger
                        style={{
                          textDecoration: "none",
                          cursor: "pointer",
                          transition: "background-color 0.3s",
                          borderBottom: "none",
                          textTransform: "capitalize",

                          display: "flex", // Make the container a flex container
                          justifyContent: "space-between", // Adjust this value as needed
                          alignItems: "center",
                        }}
                        className="font-medium pb-9 cursor-pointer hover:bg-gray-200 rounded-lg p-3  "
                      >
                        {iconUrl.map((data) => {
                          if (data?.eventId === header?.eventId) {
                            return (
                              <img
                                key={data.id} // Add a unique key for each image
                                src={data.data.iconUrl}
                                // alt={`Icon for eventId ${header.eventId}`}
                                className="h-6 w-6"
                              />
                            );
                          }
                          return null; // Return null for elements without a matching eventId
                        })}

                        {header?.eventId}
                      </AccordionTrigger>

                      <AccordionContent className="pb-9 font-medium pb-27 cursor-pointer">
                        <div className="h-[270px] p-2 overflow-auto">
                          {choices
                            .filter((choice) => choice?.eventId === header?.eventId)
                            .map((res) => (
                              <div
                                key={res.eventId}
                                onClick={() => {
                                  handleAccordionChange(header?.eventId, res.value);
                                  hookOnChange(vall(selectedValues).map((v) => v.value));
                                  // setInputValue(name, vall(selectedValues).map((v)=>v.value));
                                  setInputValue(name + "__field_type", "source");
                                  setInputValue(`${name}__field_source_type`, header?.sourceId);
                                }}
                                className={`${
                                  selectedValues.some(
                                    (s) => s?.eventId === header?.eventId && s.values?.includes(res.value)
                                  )
                                    ? "bg-gray-300 "
                                    : " hover:bg-gray-200 bg-white rounded-lg"
                                } p-2 m-1 mt-0 mb-0 cursor-pointer transition duration-300`}
                              >
                                {res.label}
                              </div>
                            ))}
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            ) : null}
          </div>
        )}
      />
    </div>
  );
};

export default MultipleSelectField;
