export interface InitialState {
  data: ConfigurationState;
  lookupAppStatus: any;
  status: StatusType;
  appStatus: StatusType;
  tabOrderId: any;
  lookupAppDetails: any;
}

export interface ConfigurationState {
  id?: string;
  targetAppId: string;
  sourceAppId: string;
  appName: string;
  sourceAppName: string;
  sourceAppConnectionId: string;
  targetAppConnectionId: string;
  sourceAppFields: any;
  targetAppFields: any;
  sourceAppDetails: any;
  targetAppDetails: any;
  isConfigured: boolean;
  apiKey?: string;
  userId?: string;
  sourceAppValidated: boolean;
  targetAppValidated: boolean;
  istargeAppFailed: boolean;
  apps: any;
  sourceAppFilledFields: any;
  targetAppFilledFields: any;
  currenttabindex: any;
}

export enum StatusType {
  idle = "idle",
  loading = "loading",
  success = "success",
  failed = "failed",
}
