// import { getDynamicAppSchemaLoading } from "state/account/slices";
import {
  fetchAllAppSchemanewLoading,
  fetchDynamicFieldindatamapLoading,
  fetchDynamicInputnewFieldsloading,
  getDynamicFieldsLoading,
  setDynamicInputDataMapping,
  setdynamicinputstate,
} from "state/data-mapping/slices";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "state/store";
import Select from "react-select";
import { nexttab, setTabOrderId } from "state/configurationv2/slices";
import TextField1 from "common/components/FormFields/TextField1";
import { setFieldType } from "utils/ruleEngineUtils";
import { Field } from "utils/appsUtils";

// import { fetchDynamicFieldsLoading, setDynamicInputDataMapping } from "state/account/slices";
const Dynamicinputfieldtab: React.FC<any> = ({ appDetails, index, field, data }) => {
  const dynamic_inputfields = useAppSelector((state) => state.dataMapping.dynamicInputFields);
  const dependentField = field.dependentTo;
  const Dynamicvalues = useAppSelector((state) => state.dataMapping.Dynamicvalues);
  const targetAppIdconfigid = useAppSelector((state) => state.configurationV2.data.targetAppId);
  const sourceAppId = useAppSelector((state) => state.configurationV2.data.sourceAppId);
  const targetAppConId = useAppSelector((state) => state.configurationV2.data.targetAppConnectionId);
  const sourceAppConId = useAppSelector((state) => state.configurationV2.data.sourceAppConnectionId);

  const apiStatus = useAppSelector((state) => state.dataMapping.dynamicdatastatus);
  const d_apiStatus = useAppSelector((state) => state.dataMapping.dynamicDatamappingStatus);

  // let Connectionconfigid = Dynamicvalues.appId == targetAppIdconfigid ? targetAppConId : sourceAppConId;
  let cc;
  let Connectionconfigid = Dynamicvalues.filter((data1) => {
    if (targetAppIdconfigid === data1.appId) {
      cc = targetAppConId;
    } else {
      cc = sourceAppConId;
    }
  });
  // Dynamicvalues
  // .filter(data1 => targetAppIdconfigid === data1.appId && data1.fields) // Filter data by matching appId and ensuring fields exist
  // .flatMap(data =>
  //   data.fields.map((field, index) => (
  //     <Dynamicinputfieldtab key={index} appDetails={app} index={index} field={field} data={data} />
  //   ))
  // )
  const Kontype = useAppSelector((state) => state.dataMapping.konType);

  // ;

  const propValues = field.propValues
    ? Array.isArray(field.propValues)
      ? field.propValues
      : []
    : field.propValue
    ? Array.isArray(field.propValue)
      ? field.propValue
      : []
    : [];

  // const propValues = [];

  // ;

  const DynamicInputDataMapping: any = useAppSelector((state) => state.dataMapping.DynamicInputDataMapping);
  // ;
  const Dynamicvals = useAppSelector((state) => state.editTemplate.data.actions[0]?.dynamicInputFields.dataMapping);
  const [states, setstate] = useState(false);

  const dynamicinputstate = useAppSelector((state) => state.dataMapping.dynamicinputstate);
  const newfield = dynamicinputstate ? DynamicInputDataMapping[field?.propName] : Dynamicvals[field?.propName];
  const fieldValue = DynamicInputDataMapping[field?.propName];
  const renderFields = propValues
    ? propValues?.find((val) => val.value === newfield)
    : { label: newfield, value: newfield };
  const tabOrderId = useAppSelector((state) => state.configurationV2.tabOrderId);
  // ;
  const getFieldOptions = () => {
    const fieldData = new Field(field).format();
    if (!["array", "object"].includes(field.propType)) {
      fieldData.type = setFieldType(fieldData, field.propType);
    }
    return fieldData;
  };

  const options =
    !propValues?.length && !fieldValue
      ? null
      : fieldValue
      ? propValues?.find((val) => val.value === fieldValue) || {
          label: fieldValue || "",
          value: fieldValue || "",
        }
      : null;

  const renderEvents = () => {
    return propValues?.map((prop: any) => {
      return { label: `${prop.label} (${prop.value})`, value: prop.value };
    });
  };
  const targetid = useAppSelector((state) => state.configurationV2.data);

  let find_Booleanfordynamicfields = dynamic_inputfields.find((obj) => obj.trigger === true || obj.action_0 == true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (Kontype == "Konnector") {
      dispatch(fetchAllAppSchemanewLoading(appDetails));
    }
  }, [Kontype]);

  const buttonclick = () => {
    if (index === data?.fields.length - 1) {
      dispatch(fetchAllAppSchemanewLoading(appDetails));

      if (DynamicInputDataMapping?.CustomField != "") {
        dispatch(setTabOrderId(tabOrderId + 1));
      }
    }
  };

  const handleNoOption = () => {
    if (dependentField && DynamicInputDataMapping?.[dependentField?.[0]]) {
      const propLabel = Dynamicvalues?.[0]?.fields?.find((f) => f?.propName === dependentField?.[0]);

      return (
        <div className="p-2 text-center text-gray-500">
          Please choose {propLabel?.label} {propLabel?.label ? `(${dependentField})` : dependentField} to proceed
        </div>
      );
    }

    const no_option_text =
      field.propName === "entityId"
        ? "It looks like you don't have access to any Ad Accounts/Organizations. Reach out to an Admin on your team to grant access."
        : field.propName === "formId"
        ? "It looks like you don't have access to any forms in your Ad Account/Organization. Reach out to an Admin on your team to grant access."
        : " No options";
    return <div className="p-2 text-center text-gray-500">{no_option_text}</div>;
  };

  const field_value = options
    ? { ...options, label: options.label ? `${options.label} (${options.value})` : "" }
    : null;

  return (
    <>
      <div className="mb-3 w-96">
        <>
          <label className="block mb-2 text-sm font-medium text-gray-900">{`Select ${field?.label}`}</label>
          {Array.isArray(propValues) || dependentField ? (
            <Select
              placeholder={`Choose ${field?.label}`}
              options={renderEvents()}
              value={field_value}
              onMenuOpen={() => {
                if (dependentField && (!propValues || propValues?.length === 0)) {
                  dispatch(
                    fetchDynamicFieldindatamapLoading({
                      actionType: "actions",
                      eventId: field.lookupId,
                      eventData: field.dependentTo,
                      field: field.propName,
                      mapping: DynamicInputDataMapping,
                      appDetails: appDetails,
                      cc,
                    })
                  );
                }
              }}
              isLoading={apiStatus === "loading" || d_apiStatus === "loading"}
              components={{ NoOptionsMessage: handleNoOption }}
              onChange={(e: any) => {
                dispatch(setdynamicinputstate(true));
                dispatch(
                  setDynamicInputDataMapping({
                    field: field.propName,
                    currentActionId: 1,
                    value: e.value,
                  })
                );
                if (index === data?.fields.length - 1) {
                  dispatch(fetchAllAppSchemanewLoading(appDetails));
                  dispatch(setTabOrderId(tabOrderId + 1));
                }

                // if (index === appDetails.dynamicFields?.fields?.length - 1) {
                //   dispatch(nexttab(appDetails.id));
                // }
                // dispatch(switchTab(appDetails.id));
              }}
            />
          ) : (
            <>
              <TextField1
                {...getFieldOptions()}
                // value={DynamicInputDataMapping[field.propName] || Dynamicvals[field.propName]}
                value={renderFields?.value}
                onChange={(e: any) => {
                  dispatch(setdynamicinputstate(true));

                  const newValue = e.value;
                  dispatch(
                    setDynamicInputDataMapping({
                      field: field.propName,
                      value: newValue,
                      currentActionId: 1,
                    })
                  );
                  if (index === data?.fields.length - 1) {
                    dispatch(fetchAllAppSchemanewLoading(appDetails));
                  }
                }}
              />
              <button
                type="submit"
                className="w-auto text-white hover:bg-[#0e1a2e] bg-komp-secondary-color hover:transition-all focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 text-center mt-8"
                onClick={buttonclick}
              >
                Next
              </button>
            </>
          )}
        </>
      </div>
    </>
  );
};
export default Dynamicinputfieldtab;
