import { StatusType } from "state/new-konnector/types";

export enum FilterType {
  ALL = "all",
  ACTIVE = "active",
  freshdesk = "freshdesk",
}

export enum SortByType {
  DEFAULT = "dtCreated",
  STATUS = "status",
  ACTIVE = "active",
  INACTIVE = "inactive",
  CREATED = "dtCreated",
  LATEST = "latest",
  OLDEST = "oldest",
}

export interface InitialState {
  sortBy: SortByType;
  filterBy: FilterType;
  status: StatusType;
  currentPage: number;
  appType: string;
  konnectorState: KonnectorState;
  hasActivated: boolean;
  currentActionId: string;
  hasDeleted: boolean;
  errorMessage: string;
  selectedKonnectorId: any;
  storewebhook: any;
  savewebhookresponse: any;
  resetwebhookapi: any;
  showWebhookStatus: boolean;
  saveWebhookStatus: boolean;
  resetWebhookstatus: boolean;
  errorText: any;
  deleteStatus: StatusType;
}

export enum KonnectorState {
  NewKonnectorState = "createNewKonnector",
  EditKonnectorState = "editKonnector",
  NewKon = "createNewKon",
}
export const ErrorStatus = [400, 401, 500];
