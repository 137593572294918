import Settings from "./Settings";
export const HeaderTabs = [
  {
    label: "Settings",
    id: 1,
    value: "settings",
    props: <Settings />,
  },
];

export default HeaderTabs;
