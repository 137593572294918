import React from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
const images = [
  {
    original: "/icons/gss1.png",
    thumbnail: "/icons/gss1.png",
    originalHeight: 400,
    originalWidth: 600,
    thumbnailHeight: 40,
    thumbnailWidth: 60,
  },
  {
    original: "/icons/gss2.png",
    thumbnail: "/icons/gss2.png",
    originalHeight: 400,
    originalWidth: 600,
    thumbnailHeight: 40,
    thumbnailWidth: 60,
  },
  {
    original: "/icons/gss3.png",
    thumbnail: "/icons/gss4.png",
    originalHeight: 400,
    originalWidth: 600,
    thumbnailHeight: 40,
    thumbnailWidth: 60,
  },
  {
    original: "/icons/gss4.png",
    thumbnail: "/icons/gss4.png",
    originalHeight: 400,
    originalWidth: 600,
    thumbnailHeight: 40,
    thumbnailWidth: 60,
  },
  {
    original: "/icons/gss5.png",
    thumbnail: "/icons/gss5.png",
    originalHeight: 400,
    originalWidth: 600,
    thumbnailHeight: 40,
    thumbnailWidth: 60,
  },
  {
    original: "/icons/gss6.png",
    thumbnail: "/icons/gss6.png",
    originalHeight: 400,
    originalWidth: 600,
    thumbnailHeight: 40,
    thumbnailWidth: 60,
  },
];

const ScreenshotCarousel = () => {
  return (
    <>
      <div className="mt-8 w-2/3 h-7 items-center ml-64">
        <ImageGallery items={images} className="flex" />
      </div>

    </>
  );
};

export default ScreenshotCarousel;
