import { AppsDetails, TabType } from "state/account/types";
import { useAppSelector } from "state/store";

export const serializeDataForAppSchema = (data: AppsDetails[]) => {
  let credTabs = data.filter((tab) => tab.tabType === TabType.Creds);
  let appSchema = [];
  appSchema.push(
    ...credTabs.map((item) => {
      return {
        appId: item.appId,
        eventId: item.eventId,
        appSelectorType: item.appSelectorType,
        hasDynamicSchema: item?.dynamicFields?.hasData || false,
        dynamicDataMapping: item?.dynamicFields?.dataMapping,
        connectionId: item.connectionConfig?.id,
      };
    })
  );
  return appSchema;
};

export const serializeAppSchemaFields = (appFields) => {
  try {
    const source = appFields[0];
    const lookups = appFields[1];
    const actions = appFields[2];
    let fields: any = {};
    if (source.length > 0) {
      if (source.length === 1) {
        fields["sourceSample"] = source[0].data.data;
      } else if (source.length === 3) {
        fields["sourceInput"] = source[0].data.data;
        fields["sourceSample"] = source[1].data.data;
        fields["sourceOutput"] = source[2].data.data;
      } else {
        fields["sourceSample"] = source[0].data.data;
        fields["sourceOutput"] = source[1].data.data;
      }
    }
    if (lookups[0]?.length > 0) {
      for (let i in lookups) {
        fields[`lookupInput_${i}`] = lookups[i][0]?.data?.data;
        fields[`lookupOutput_${i}`] = lookups[i][1]?.data?.data;
      }
    }
    if (actions[0]?.length > 0) {
      for (let i in actions) {
        if (actions[i].length === 3) {
          fields[`targetDynamicInput_${i}`] = actions[i][0]?.data?.data;
          fields[`targetInput_${i}`] = actions[i][1]?.data?.data;
          fields[`targetOutput_${i}`] = actions[i][2]?.data?.data;
        } else {
          fields[`targetInput_${i}`] = actions[i][0]?.data?.data;
          fields[`targetOutput_${i}`] = actions[i][1]?.data?.data;
        }
      }
    }
    return fields;
  } catch (e) {}
};

export const serializeAppSchemaFieldsForState = (schema, dataoutput) => {
  let inputSchemas = [];
  let outputSchema = {};
  for (let i in schema) {
    if (i.includes("Output") && !i.startsWith("target")) {
      inputSchemas.push(schema[i]);
    }
    if (i.includes("Input") && i.startsWith("target")) {
      outputSchema = schema[i];
    }
    if (!i.includes("source") && dataoutput?.data?.schema?.appId == "generic") {
      let result_data = [dataoutput?.data?.schema];

      result_data.push(dataoutput?.data?.schema);

      const uniqueData = result_data.filter((item, index, array) => {
        return array.findIndex((other) => other.appId === item.appId) === index;
      });
      inputSchemas = [...uniqueData];
      // inputSchemas.push(dataoutput?.data?.schema);
    }
  }
  return { inputSchemas, outputSchema };
};

// export const serializeWebhookFieldsState = (schema) => {
//   let outputSchema = {};

//   if (schema.appId == "generic") {
//     outputSchema = schema;
//   }

//   return { outputSchema };
// };
