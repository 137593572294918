import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store, { useAppSelector } from "state/store";
import "styles/global.css";
import "./i18n";
import Configitem from "pages/Configuration";
import TemplateList from "pages/TemplateList";
import routes from "routes";
import Auth from "Auth";
import FreshserviceGenesys from "./customapps/FreshserviceGenesys";

function App(): JSX.Element {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            {routes.map((route) => {
              if (route.isPublic) {
                return <Route path={route.path} key={route.path} element={<FreshserviceGenesys />} />;
              }
              return <Route path={route.path} key={route.path} element={<Auth appRoute={route} />} />;
            })}
            <Route path="/apps/:id/templates" element={<TemplateList />} />
            <Route path="/apps/:appId/templates/:templateId" element={<Configitem />} />
            <Route path="*" element={<Navigate to="/apps" replace={true} />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
