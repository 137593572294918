import { Tab } from "@headlessui/react";
import Spinner from "components/Spinner";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getKonnectorLoading } from "state/edit-template-settings/slices";
import { StatusType } from "state/edit-template-settings/types";
import { useAppDispatch, useAppSelector } from "state/store";
import CredTab from "./CredTabs";
import {
  setAccountLoading,
  setCurrentTab,
  setDisablevalidation,
  setTriggerInputSchema,
  setvalidateTabobject,
  setvalidatetabboolean,
  switchTab,
} from "state/account/slices";
import { AppsDetails, CurrentState, TabType } from "state/account/types";
import RuleConfig from "./RuleConfig";
import DataMappingTab from "./DataMapping";
import Webhook from "./Webhook";
import { generateJSONForAccountValidation, generateJSONForAccountValidations } from "utils/config";
import { ToastError, ToastSucess } from "common/components/toast/Toast";

const RenderTabs: React.FC<any> = ({ appDetails }) => {
  const dispatch = useAppDispatch();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const accCred = useAppSelector((state) => state.auth.accCred);

  // const hasDataMapping = appDetails.outputSchema?.data?.length > 0 && appDetails.inputSchemas?.length > 0;
  const currentSate = useAppSelector((state) => state.account.currentState);
  const tabIndex = useAppSelector((state) => state.account.currentActionId);
  const Details = useAppSelector((state) => state.account.ReuseAppdetails);
  const hasDataMapping = Details?.outputSchema?.data?.length > 0 && Details.inputSchemas?.length > 0;
  let allTabValidated = useAppSelector((state) => state.account.allTabValidated);

  let apps = useAppSelector((state) => state.account.tabItems);
  const lastIndex = apps.length - 1;

  let prev_Response = apps[Details.actionOrderId - 1];
  let compare_Prevsresponse = Details?.appId == prev_Response?.appId;
  let comparebefore_PreviousResponse = Details?.appId == prev_Response?.appId;

  let next_tab = apps[appDetails?.actionOrderId + 1];
  let filteredApps = apps.filter((val) => {
    return Details?.appId?.includes(val?.appId);
  });

  let activateBox = filteredApps[0]?.connectionConfig?.fields?.length >= 2;

  let validate_data = apps.filter(
    (val) => val.tabValidated == true && val?.appId == Details?.appId && Details?.tabValidated == false
  );

  const resultObject = validate_data.reduce((accumulator, currentObject) => {
    Object.assign(accumulator, currentObject);
    return accumulator;
  }, {});

  const Resusedata = useAppSelector((state) => state.account.Reusevalidation);
  let new_res = apps.map((val) => val?.appId);

  const [isChecked, setisChecked] = useState(false);
  const [index, setindex] = useState(null);
  const [showstate, setshowstate] = useState(false);
  const handleCheckboxChange = () => {
    setisChecked(!isChecked);

    if (!isChecked) {
      dispatch(setvalidatetabboolean(true));
      dispatch(setvalidateTabobject(resultObject));

      if (Details.status == StatusType.failed) {
        dispatch(setvalidatetabboolean(false));
      }
    }

    setTimeout(() => {
      if (activateBox && !Details.tabValidated) {
        setisChecked(false);
      }
    }, 5000);

    if (Details.tabValidated && Details.appSelectorType == "lookup") {
      setisChecked(!isChecked);
    }
    if (Details.tabValidated && Details.appSelectorType == "lookup" && index == Details) {
      setisChecked(!isChecked);
    }
  };
  const [state, setstate] = useState(false);

  const newclick = () => {
    if (compare_Prevsresponse && Details.appSelectorType == "lookup") {
      setstate(!state);
    }
    if (Details.tabValidated && Details.actionOrderId == index && compare_Prevsresponse) {
      setstate(!state);
    }
  };

  useEffect(() => {
    if (state) {
      dispatch(setDisablevalidation(true));
    } else {
      dispatch(setDisablevalidation(false));
    }
  }, [state]);

  useEffect(() => {
    if (
      Details.tabValidated &&
      Details.appSelectorType == "lookup" &&
      Details.actionOrderId == index &&
      compare_Prevsresponse
    ) {
      setshowstate(true);

      setstate(!state);
    }
    if (Details.tabValidated && Details.actionOrderId == index && compare_Prevsresponse) {
      setshowstate(true);

      setstate(!state);
    }
    if (Details.tabValidated && Details.actionOrderId != index && !compare_Prevsresponse) {
      setshowstate(false);

      setstate(false);
    }

    if (
      Details.appSelectorType != "lookup" &&
      !Details.actionOrderId != index &&
      Details.tabValidated &&
      !compare_Prevsresponse
    ) {
      setshowstate(false);

      setstate(false);
    }
    if (!comparebefore_PreviousResponse) {
      setshowstate(false);

      setstate(false);
    }

    if (lastIndex == index) {
      setshowstate(false);
      setstate(false);
    }
  }, [Details, comparebefore_PreviousResponse, index]);

  const renderHeaderTabsList: any = () => {
    return apps.map((app, index) => {
      return (
        <Tab
          onClick={() => {
            dispatch(setCurrentTab(index));

            setindex(index);
            if (!Details.tabValidated && !compare_Prevsresponse) {
              setisChecked(false);
            }
            setstate(false);
          }}
          key={app.id}
          className={({ selected }) =>
            classNames(
              `${
                selected ? "text-indigo-600 b-2 border-indigo-700" : "text-gray-500 border-transparent"
              } py-4  px-2 mr-8 border-b-2`
            )
          }
        >
          {app.tabTitle}
        </Tab>
      );
    });
  };

  const renderTabPanelItems = (tabType: TabType, tabItem: AppsDetails) => {
    // ;
    // ;
    switch (tabType) {
      case TabType.Webhook:
        return <Webhook appDetails={tabItem} />;
      case TabType.Creds:
        return <CredTab appDetails={tabItem} />;

      case TabType.RuleConfig:
        return <RuleConfig ruleConfig={tabItem} />;
      case TabType.Action:
        return <DataMappingTab appDetails={tabItem} />;
    }
  };

  const renderHeaderTabsPanels = () => {
    return apps.map((app, index) => {
      // setstate(false);?
      // setstate(index);
      return (
        <Tab.Panel tabIndex={index} key={app.id} className={({ selected }) => classNames(`h-full`)}>
          {renderTabPanelItems(app.tabType, app)}
        </Tab.Panel>
      );
    });
  };

  if (apps?.length === 0) {
    // ;
    return (
      <>
        <Spinner />
      </>
    );
  }

  return (
    <>
      <div>
        <nav className="flex flex-col p-4">
          <Tab.Group selectedIndex={tabIndex}>
            <Tab.List className={"flex text-sm font-medium mb-4"}>{renderHeaderTabsList}</Tab.List>
            <div>
              {activateBox &&
              !Details.tabValidated &&
              !hasDataMapping &&
              Details.status != StatusType.failed &&
              lastIndex != tabIndex ? (
                <div className="flex flex-row gap-4">
                  <input type="checkbox" checked={isChecked} className="ml-5" onChange={handleCheckboxChange} />

                  <span>Reuse Account Credentials</span>
                </div>
              ) : (
                <></>
              )}

              {showstate && Details.tabValidated ? (
                <div className="flex flex-row gap-4">
                  <input type="checkbox" checked={state} className="ml-5" onClick={newclick} />

                  <span>Reuse Account Credentials</span>
                </div>
              ) : null}
              <section className="h-[calc(70vh-5rem)]">{renderHeaderTabsPanels()}</section>
              {/* {state ? <></> : <section className="h-[calc(70vh-5rem)]">{renderHeaderTabsPanels()}</section>} */}
              {/* 
             
:<></>
            } */}
            </div>
          </Tab.Group>
        </nav>
      </div>
    </>
  );
};

export default RenderTabs;
function Disablevalidation(arg0: boolean): any {
  throw new Error("Function not implemented.");
}
