import { useAppDispatch, useAppSelector } from "state/store";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { settoasterrorBoolean } from "state/auth/slices";

const Error: React.FC<any> = ({}): JSX.Element => {
  const dispatch = useAppDispatch();
  const new_Customization_banner = useAppSelector((state) => state.auth.banner);

  const handleclose = () => {
    dispatch(settoasterrorBoolean(false));
  };

  const [shouldRedirect, setRedirect] = useState(false);
  // useEffect(() => {
  // 	setTimeout(() => {
  // 		closeNotification();
  // 	}, 3000);
  // });
  return (
    <>
      <div className="flex overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-50 justify-center items-start mt-10 h-modal md:h-full md:inset-0">
        <div className="flex-initial flex h-[70vh] items-start   w-[85vh]  -mr-12 relative">
          <div className="" style={{ width: `827px`, height: `256px` }}>
            {/* <img className=" p-5 transition-all duration-300 rounded-lg cursor-pointer filter object-cover" src={mainLogo} alt="image description"> */}
            {/* </img> */}
            <div
              className="absolute top-8 right-72  bg-white border border-gray-200 rounded-xl shadow-lg"
              style={{
                width: `${new_Customization_banner.width}px`,
                height: `${new_Customization_banner.heigth}px`,
                borderRadius: `${new_Customization_banner.radius}px`,
                borderColor: new_Customization_banner.bordercolour,
                //   background: bgColor,
                //   color: textColor,
              }}
              role="alert"
            >
              <div className="flex p-4">
                <div className="flex-shrink-0">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10 0C10.918 0 11.8034 0.117188 12.6562 0.351562C13.5091 0.585938 14.3034 0.924479 15.0391 1.36719C15.7747 1.8099 16.4486 2.33073 17.0605 2.92969C17.6725 3.52865 18.1966 4.20247 18.6328 4.95117C19.069 5.69987 19.4043 6.4974 19.6387 7.34375C19.873 8.1901 19.9935 9.07552 20 10C20 10.918 19.8828 11.8034 19.6484 12.6562C19.4141 13.5091 19.0755 14.3034 18.6328 15.0391C18.1901 15.7747 17.6693 16.4486 17.0703 17.0605C16.4714 17.6725 15.7975 18.1966 15.0488 18.6328C14.3001 19.069 13.5026 19.4043 12.6562 19.6387C11.8099 19.873 10.9245 19.9935 10 20C9.08203 20 8.19661 19.8828 7.34375 19.6484C6.49089 19.4141 5.69661 19.0755 4.96094 18.6328C4.22526 18.1901 3.55143 17.6693 2.93945 17.0703C2.32747 16.4714 1.80339 15.7975 1.36719 15.0488C0.93099 14.3001 0.595703 13.5026 0.361328 12.6562C0.126953 11.8099 0.00651042 10.9245 0 10C0 9.08203 0.117188 8.19661 0.351562 7.34375C0.585938 6.49089 0.924479 5.69661 1.36719 4.96094C1.8099 4.22526 2.33073 3.55143 2.92969 2.93945C3.52865 2.32747 4.20247 1.80339 4.95117 1.36719C5.69987 0.93099 6.4974 0.595703 7.34375 0.361328C8.1901 0.126953 9.07552 0.00651042 10 0ZM11.1035 10L14.4434 6.66016L13.3398 5.55664L10 8.89648L6.66016 5.55664L5.55664 6.66016L8.89648 10L5.55664 13.3398L6.66016 14.4434L10 11.1035L13.3398 14.4434L14.4434 13.3398L11.1035 10Z"
                      fill="#FF7F7F"
                    />
                  </svg>

                  {/* <svg className="flex-shrink-0 size-4 text-teal-500 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                    </svg> */}
                </div>
                <div className="ms-3">
                  <p className="text-sm text-gray-700 dark:text-gray-400">{new_Customization_banner.failureMsg}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div id="toast-success" className="flex grow items-center p-5 mb-4 mr-5 text-gray-500 bg-white rounded-lg shadow border-2" style={{
   flex: `0 0 ${new_Customization_banner?.width}%`,
  height: `${new_Customization_banner?.heigth}px`,
  borderRadius: `${new_Customization_banner?.radius}px`,
  borderColor: new_Customization_banner?.bordercolour,
  //   background: bgColor,
  //   color: textColor,
}} role="alert">
  <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg">
    {/* Replace with the new failure SVG */}
        {/* <svg className="w-5 h-5 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
</svg>

    <span className="sr-only">Error icon</span>
  </div>
  <div className="ms-3 text-sm font-normal">{new_Customization_banner?.failureMsg}</div>
  <button onClick={handleclose} type="button" className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8" data-dismiss-target="#toast-success" aria-label="Close">
    <span className="sr-only">Close</span>
    <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
    </svg>
  </button> */}
        {/* </div>  */}
      </div>
    </>
  );
};

export default Error;
