import React from "react";

export default function FooterContent() {
  return (
    <div className="text-sm text-gray-700">
      <p>Cloud Hosting Information :</p>
      <ul className="list-disc pl-5">
        <li>
          The Freshservice - Genesys CTI is hosted in AWS managed By {" "}
          <strong>Konnectify</strong>
        </li>
        <li>
          AWS Region : <strong>us-east-1</strong>
        </li>
        <li>AWS Service Leveraged : AWS Amplify, DynamoDB</li>
        <li>
          Data : The App stores the domain and API Key of the Freshservice
          instance in DynamoDB during the time of validation for the purpose of
          making API calls
        </li>
      </ul>
    </div>
  );
}
