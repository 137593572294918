// import { DynamicFieldsSchemaType } from './../state/new-konnector/types';
import useSWR from "swr";

import { axiosInstance, fetcher, options, setTenantId } from "./libs/fetch";
import axios from "axios";
import { ConfigurationType, DynamicFieldsSchemaType } from "state/new-konnector/types";
import { getWebhookData } from "./konnectorsApi";
import { getConnectionConfigApi } from "./configApi";
import { useAppSelector } from "state/store";

// import { getWebhookData } from './konnectorsApi';
// import { ConfigurationType } from 'pages/KonnectorPage/types';
const APP_PAGE_LIMIT = 200;

export function useApps(pageNumber = 1, currentPageLimit?) {
  currentPageLimit = 200;
  return useSWR(`/apps?pageNumber=${pageNumber}&pageSize=${currentPageLimit}`, fetcher);
}

export function getAppsList(page) {
  return axiosInstance.get(`/endusers/apps?pageNumber=${page}`);
}

export function useGetApp(appId) {
  return useSWR(`/endusers/apps/${appId}`, fetcher);
}
export function useGetportal() {
  return useSWR(`/endusers/apps/portal`, fetcher);
}
export function AppsByTypes(pageNumber = 1, appType) {
  return axiosInstance.get(`/apps/app-type/${appType}?pageNumber=${pageNumber}&pageSize=${APP_PAGE_LIMIT}`);
}

export function useApp(appId) {
  return useSWR(`/apps/${appId}`, fetcher);
}

export function getApps() {
  return axiosInstance.get(`https://konnectify-engine-3ddd3qo4lq-uc.a.run.app/api/apps`);
}

export function fetchApp(appId) {
  return axiosInstance.get(`/apps/${appId}`);
}

export function fetchOutputFieldsApi(payload) {
  return axiosInstance.post(`/apps/${payload.appId}/app-sample`, payload.data, options);
}
export function fetchOutputFieldsSchema(payload) {
  return axiosInstance.post(`/endusers/${payload.appId}/app-schema`, payload.data, options);
}

export function fetchDynamicInputFieldsApi(payload) {
  return axiosInstance.post(`/endusers/${payload.appId}/app-schema`, payload.data, options);
}

export function getAppSchemaApi(payload) {
  return axiosInstance.post(`/endusers/${payload.appId}/app-schema`, payload.data, options);
}

export function getAppsApi(apps, getConnectionConfigs = false, data) {
  const appsPromises = () =>
    apps.map(async (app) => {
      let connectionConfig = {};

      if (app.appEventId == "webhook") {
        // let payload = await getConnectionConfigApi(app.connectionId);
        //  let fields = Promise.all([getWebhookData(payload.webhookId, payload.konnectorType, payload.tenantId)]);
        // let newdata = await getWebhookData()

        let dataStore = {
          actionOrderId: 0,
          appEventId: "webhook",
          appId: "generic",
          appSelectorKey: app.appSelectorKey,
          connectionId: "generic",
          webhookId: data.webhookUrl.id,
          webhookUrl: data.webhookUrl.url,

          // appSelectorType: app.appSelectorType,
        };

        return dataStore;
      } else if (app.appEventId != "webhook") {
        if (getConnectionConfigs) {
          let payload = await getConnectionConfigApi(app.connectionId);
          connectionConfig = payload.data.data;
        }
        return {
          data: await fetchApp(app.appId),
          ...app,
          connectionConfig: connectionConfig,
        };
      }
    });
  return Promise.all(appsPromises());
}
export async function getAppsFieldsApi(apps, data, payload?) {
  const appsPromises = async () => {
    let fields: any = {};
    let actionIndex = 0,
      lookupIndex = 0;
    await fetchDynamicInputFieldsApi;
    for (let i in apps) {
      switch (apps[i].appSelectorType) {
        case ConfigurationType.Source:
          if (apps[i].appId !== "generic") {
            fields["sourceOutput"] = (
              await fetchDynamicInputFieldsApi({
                appId: apps[i].appId,
                data: {
                  schemaType: DynamicFieldsSchemaType.Output,
                  eventId: apps[i].appEventId,
                  connectionConfigId: data.triggerDefinition.connectionId,
                  eventData: payload.triggerInputFields,
                },
              })
            ).data.data;
          } else {
            fields["sourceSample"] = (
              await getWebhookData("NlPeNpTXn8", payload.konnectorType, payload.tenantId)
            ).data.data;
          }
          break;
        case ConfigurationType.Target:
          fields[`targetOutput_${actionIndex}`] = (
            await fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Input,
                eventId: apps[i].appEventId,
                connectionConfigId: data.actionItems[0].connectionId,
                eventData: payload.actionInputFields,
              },
            })
          ).data.data;
          actionIndex += 1;
          break;
        case ConfigurationType.Lookup:
          fields[`lookupInput_${lookupIndex}`] = (
            await fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Input,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            })
          ).data.data;
          fields[`lookupOutput_${lookupIndex}`] = (
            await fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Output,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            })
          ).data.data;
          lookupIndex += 1;
          break;
      }
    }
    return fields;
  };

  return await appsPromises();
}

export async function getAppsFieldsApiTest(apps, payload?) {
  const appsPromises = () => {
    let fields: any = [[], [[]], [[]]];
    let actionIndex = 0,
      lookupIndex = 0;
    for (let i in apps) {
      switch (apps[i].appSelectorType) {
        case ConfigurationType.source:
          if (apps[i].appId !== "generic") {
            if (payload.triggerInputFields) {
              fields[0].push(
                fetchDynamicInputFieldsApi({
                  appId: apps[i].appId,
                  data: {
                    schemaType: DynamicFieldsSchemaType.Input,
                    eventId: apps[i].appEventId,
                    connectionConfigId: apps[i].connectionId,
                    eventData: payload.triggerInputFields,
                  },
                })
              );
            }
            fields[0] = Promise.all([
              ...fields[0],
              fetchOutputFieldsApi({
                appId: apps[i].appId,
                data: {
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.triggerInputFields,
                },
              }),
              fetchDynamicInputFieldsApi({
                appId: apps[i].appId,
                data: {
                  schemaType: DynamicFieldsSchemaType.Output,
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.triggerInputFields,
                },
              }),
            ]);
          } else {
            // fields[0] = Promise.all([getWebhookData(payload.webhookId, payload.konnectorType, payload.tenantId)]);
          }
          break;
        case ConfigurationType.Repeater:
        case ConfigurationType.Target:
          if (payload.actionInputFields) {
            fields[2][actionIndex]?.push(
              fetchDynamicInputFieldsApi({
                appId: apps[i].appId,
                data: {
                  schemaType: DynamicFieldsSchemaType.DynamicInput,
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.actionInputFields,
                },
              })
            );
          }
          fields[2][actionIndex] = Promise?.all([
            ...fields[2][actionIndex],

            fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Input,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            }),
            fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Output,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            }),
          ]);

          actionIndex += 1;
          break;
        case ConfigurationType.lookup:
          fields[1][lookupIndex] = Promise.all([
            fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Input,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            }),
            fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Output,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            }),
          ]);

          lookupIndex += 1;
          break;
      }
    }
    fields[2] = Promise.all(fields[2]);
    fields[1] = Promise.all(fields[1]);
    return fields;
  };

  return Promise.all(appsPromises());
}

export async function getAppsFieldsApiCreate(apps, payload?) {
  const appsPromises = async () => {
    let fields: any = {};
    let actionIndex = 0,
      lookupIndex = 0;
    await fetchDynamicInputFieldsApi;
    for (let i in apps) {
      switch (apps[i].appSelectorType) {
        case ConfigurationType.Source:
          if (apps[i].appId !== "generic") {
            if (payload.triggerInputFields) {
              fields["sourceInput"] = (
                await fetchDynamicInputFieldsApi({
                  appId: apps[i].appId,
                  data: {
                    schemaType: DynamicFieldsSchemaType.Input,
                    eventId: apps[i].appEventId,
                    connectionConfigId: apps[i].connectionId,
                    eventData: payload.triggerInputFields,
                  },
                })
              ).data.data;
            }
            fields["sourceSample"] = (
              await fetchOutputFieldsApi({
                appId: apps[i].appId,
                data: {
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.triggerInputFields,
                },
              })
            ).data.data;
            fields["sourceOutput"] = (
              await fetchDynamicInputFieldsApi({
                appId: apps[i].appId,
                data: {
                  schemaType: DynamicFieldsSchemaType.Output,
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.triggerInputFields,
                },
              })
            ).data.data;
          } else {
            // fields["sourceSample"] = (
            //   await getWebhookData(payload.webhookId, payload.konnectorType, payload.tenantId)
            // ).data.data;
          }
          break;
        case ConfigurationType.Target:
          if (payload.actionInputFields) {
            fields[`targetDynamicInput_${actionIndex}`] = (
              await fetchDynamicInputFieldsApi({
                appId: apps[i].appId,
                data: {
                  schemaType: DynamicFieldsSchemaType.DynamicInput,
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.actionInputFields,
                },
              })
            ).data.data;
          }
          fields[`targetInput_${actionIndex}`] = (
            await fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Input,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            })
          ).data.data;
          fields[`targetOutput_${actionIndex}`] = (
            await fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Output,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            })
          ).data.data;
          actionIndex += 1;
          break;
        case ConfigurationType.Lookup:
          fields[`lookupInput_${lookupIndex}`] = (
            await fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Input,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            })
          ).data.data;
          fields[`lookupOutput_${lookupIndex}`] = (
            await fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Output,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            })
          ).data.data;
          lookupIndex += 1;
          break;
      }
    }
    return fields;
  };

  return await appsPromises();
}

export function getTriggerInputFieldApi(payload) {
  return Promise.all([
    fetchDynamicInputFieldsApi({
      appId: payload.appId,
      data: {
        schemaType: DynamicFieldsSchemaType.Input,
        eventId: payload.appEventId,
        connectionConfigId: payload.connectionId,
        eventData: payload.triggerInputFields,
      },
    }),
  ]);
}

export function getActionDynamicInputFieldApi(payload) {
  return Promise.all([
    fetchDynamicInputFieldsApi({
      appId: payload.appId,
      data: {
        schemaType: DynamicFieldsSchemaType.DynamicInput,
        eventId: payload.appEventId,
        connectionConfigId: payload.connectionId,
        eventData: payload.actionInputFields,
      },
    }),
  ]);
}
