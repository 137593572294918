import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AuthState, LoadingType, PlanType } from "../types";
import { stat } from "fs";

export const initialState: AuthState = {
  currentUser: {},
  isAuthenticated: false,
  error: "",
  isVerified: true,
  loading: true,
  isTrial: false,
  currentPlan: PlanType.null,
  mailSent: false,
  apiKey: null,
  email: "",
  loadingType: LoadingType.idle,
  resetPassword: false,
  forgotPasswordError: "",
  resetPasswordError: "",
  showWarning: true,
  adminDetails: {},
  customisation: {},
  accCred: [],
  Cardcustomisation: {
    backgroundcolor: "#f0f0f0",
    borderRadius: "3",
    gridValues: "1",
    heigth: "102",
    hoverColor: "#dad8d8",
    textColor: "#a65454",
    view: "Grid",
    width: "90",
    cardSnippet: "",
    cardSnippetStatus: "false",
    gridsize: "4",
    bordercolour: "#F0F0F0",
  },
  layout: {
    heigth: "20",
    width: "20",
  },
  // CodeSnippet: {},
  template: {
    bgColor: "#f0f0f0",
    borderRadius: "4",
    heigth: "85",
    hoverColor: "#e6e6e6",
    textColor: "#181616",
    width: "100",
    togglebuttoncolor: "#ea8e40",
    templateSnippet: "",
    templateSnippetStatus: "false",
  },
  banner: {
    bordercolour: "#f0f0f0",
    failureMsg: "Error in saving the konnector",
    heigth: "10",
    radius: "3",
    successMsg: "Konnector saved successfully",
    width: "40",
  },
  customization: {
    title: "<p>Embedded MarketPlace</p>",
    description:
      "<p>Enable one-click execution of your workflows through automation solutions, streamlining processes and minimizing manual effort.</p>",
    primary_button_colour: "#5f5d7e",
    primary_colour: "#ffffff",
    text_colour: "#0b0909",
    font: "Arimo",
  },

  // portalConfigration: {
  //   bodytext: "#ffffff",
  //   bodytext_color: "#0b0909",
  //   fontFamily: "Arimo",
  //   portalDescription:
  //     "<p>Enable one-click execution of your workflows through automation solutions, streamlining processes and minimizing manual effort.</p>",
  //   portalTitle: "<p>Embedded MarketPlace</p>",
  //   primary_bgColor: "#ffffff",
  //   primary_bgColor_text: "#ffffff",
  //   primary_button_Color: "#5f5d7e",
  //   primary_button_Text: "",
  //   secondary_button_Color: "#e8e8e8",
  //   secondary_button_tex: "#e8e8e8",
  // },
  productLogo: {
    url: "",
    colour: "",
    size: "6",
  },

  tenantid: "",
  toastBoolean: false,
  toasterrorBoolean: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, data) => {
      state.loading = true;
    },

    loginSuccess: (state, { payload }: PayloadAction<any>) => {
      state.currentUser = payload.payload;
      state.accCred = payload.accCred;
      state.isAuthenticated = true;
      state.loading = false;
    },

    loginError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isAuthenticated = false;
      state.loading = false;
    },

    logout: (state) => {
      state.loading = true;
    },

    logoutSuccess: (state) => {
      state.isAuthenticated = false;
      state.currentUser = {};
      state.error = "";
      state.loading = false;
    },

    isVerified: (state) => {
      state.loadingType = LoadingType.sentMail;
    },

    isMailSent: (state) => {
      state.mailSent = true;
      state.loadingType = LoadingType.idle;
    },

    isVerifiedSuccess: (state) => {
      state.isVerified = true;
      state.isTrial = true;
      state.currentPlan = PlanType.paid;
      state.loadingType = LoadingType.idle;
      state.planExpired = false;
    },

    isVerifiedError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
      state.isVerified = false;
      state.loadingType = LoadingType.idle;
    },

    signIn: (state) => {
      state.signInLoading = true;
    },

    forgotPasswordLoading: (state, data: any) => {
      state.loadingType = LoadingType.forgotPassword;
    },

    forgotPasswordSuccess: (state, { payload }: PayloadAction<string>) => {
      state.mailSent = true;
      state.forgotPasswordError = "";
      state.loadingType = LoadingType.idle;
    },

    forgotPasswordError: (state, data: PayloadAction<string>) => {
      state.forgotPasswordError = data.payload;
      state.loadingType = LoadingType.idle;
    },

    resetPasswordLoading: (state, data: any) => {
      state.loadingType = LoadingType.resetPassword;
    },

    resetPasswordSuccess: (state, { payload }: PayloadAction<object>) => {
      state.resetPassword = true;
      state.loadingType = LoadingType.idle;
    },

    resetPasswordError: (state, data: PayloadAction<string>) => {
      state.resetPasswordError = data.payload;
      state.loadingType = LoadingType.idle;
    },
    settenantid: (state, data) => {
      state.tenantid = data.payload;
    },
    setShowWarning: (state, data) => {
      state.showWarning = data.payload;
    },
    setUserData: (state, data) => {
      state.currentUser = data.payload;
      state.isAuthenticated = true;
      state.apiKey = data.payload.apiKey;
      state.email = data.payload.email;
    },
    setAdminData: (state, data) => {
      state.adminDetails = data.payload;
      state.isAuthenticated = true;
    },

    settoasttoastBoolean: (state, data) => {
      state.toastBoolean = data.payload;
    },

    settoasterrorBoolean: (state, data) => {
      state.toasterrorBoolean = data.payload;
    },
    setBackgroundColor: (state, data) => {
      state.isAuthenticated = true;
      state.customisation = {
        backgroundColor: data.payload.portalConfigration.primary_bgColor,
        fontFamily: data.payload.portalConfigration.fontFamily,
        primaryButtonColor: data.payload.portalConfigration.primary_button_Color,
        secondaryButtonColor: data.payload.portalConfigration.secondary_button_Color,
        fontColor: data.payload.portalConfigration.bodytext_color,
        portalTitle: data.payload.portalConfigration.portalTitle,
        portalDescription: data.payload.portalConfigration.portalDescription,
      };
    },
    setPortal: (state, data) => {
      state.isAuthenticated = true;
      state.Cardcustomisation = {
        backgroundcolor: data.payload.card.background,
        borderRadius: data.payload.card.radius,
        gridValues: data.payload.card.gridValues,
        heigth: data.payload.card.heigth,
        hoverColor: data.payload.card.hover,
        textColor: data.payload.card.text,
        view: data.payload.card.view,
        width: data.payload.card.width,
        cardSnippet: data.payload.card.cardSnippet,
        cardSnippetStatus: data.payload.card.cardSnippetStatus,
        gridsize: data.payload.card.gridsize,
        bordercolour: data.payload.card.bordercolour,
      };
      state.template = {
        bgColor: data.payload.template.background,
        borderRadius: data.payload.template.radius,
        heigth: data.payload.template.heigth,
        hoverColor: data.payload.template.hover,
        textColor: data.payload.template.text,
        width: data.payload.template.width,
        togglebuttoncolor: data.payload.template.togglecolour,
        templateSnippet: data.payload.template.templateSnippet,
        templateSnippetStatus: data.payload.template.templateSnippetStatus,
      };
      // state.templatesnippet = {
      //   bgColor_templatesnippet: data.payload.templatesnippetdata.background,
      //   borderRadius_templatesnippet: data.payload.templatesnippetdata.radius,
      //   heigth_templatesnippet: data.payload.templatesnippetdata.heigth,
      //   hoverColor_templatesnippet: data.payload.templatesnippetdata.hover,
      //   textColor_templatesnippet: data.payload.templatesnippetdata.text,
      //   width_templatesnippet: data.payload.templatesnippetdata.width,
      //   togglebuttoncolor_templatesnippet: data.payload.templatesnippetdata.togglecolour,
      //   templateSnippet_templatesnippet: data.payload.templatesnippetdata.templateSnippet,
      //   // templateSnippetstatus: data.payload.templatesnippetdata.templateSnippetStatus,
      // };
      state.layout = {
        heigth: data.payload.layout.heigth,
        width: data.payload.layout.width,
      };
      // state.CodeSnippet = {
      //   cardCss: data.payload.CodeSnippet.cardCss,
      //   templateCss: data.payload.CodeSnippet.templateCss,
      // };
      state.productLogo = {
        url: data.payload.logo.url,
        colour: data.payload.logo.colour,
        size: data.payload.logo.size,
      };

      state.customization = {
        title: data.payload.customization.title,
        description: data.payload.customization.description,
        primary_button_colour: data.payload.customization.primary_button_colour,
        primary_colour: data.payload.customization.primary_colour,
        text_colour: data.payload.customization.text_colour,
        font: data.payload.customization.font,
      };
      // state.portalConfigration = {
      //   bodytext: data.payload.portalConfigration.bodytext,
      //   bodytext_color: data.payload.portalConfigration.bodytext_color,
      //   fontFamily: data.payload.portalConfigration.fontFamily,
      //   portalDescription: data.payload.portalConfigration.portalDescription,
      //   portalTitle: data.payload.portalConfigration.portalTitle,
      //   primary_bgColor: data.payload.portalConfigration.primary_bgColor,
      //   primary_bgColor_text: data.payload.portalConfigration.primary_bgColor_text,
      //   primary_button_Color: data.payload.portalConfigration.primary_button_Color,
      //   primary_button_Text: data.payload.portalConfigration.primary_button_Text,
      //   secondary_button_Color: data.payload.portalConfigration.secondary_button_Color,
      //   secondary_button_tex: data.payload.portalConfigration.secondary_button_text,
      // };

      state.banner = {
        bordercolour: data.payload.banner.bordercolour,
        failureMsg: data.payload.banner.failureMsg,
        heigth: data.payload.banner.heigth,
        radius: data.payload.banner.radius,
        successMsg: data.payload.banner.successMsg,
        width: data.payload.banner.width,
      };

      // state.configurationforms = {

      //   fontsize: "",
      //   fontcolor: "",
      //   bordercolor?:"",
      //   buttoncolor?:"",
      //   buttontextcolor?:""

      // };
    },
  },
});

export const {
  login,
  loginSuccess,
  loginError,
  logout,
  logoutSuccess,
  isVerified,
  signIn,
  isVerifiedSuccess,
  isVerifiedError,
  isMailSent,
  forgotPasswordLoading,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordLoading,
  resetPasswordSuccess,
  resetPasswordError,
  setShowWarning,
  setUserData,
  setBackgroundColor,
  setPortal,
  settoasttoastBoolean,
  settoasterrorBoolean,
  settenantid,
  // setPortaltemplate,
  // setPortallayout,
  // setPortalproductLogo,
  // setPortalCodeSnippet,
} = authSlice.actions;

export default authSlice.reducer;
