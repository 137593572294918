import React, { useEffect, useState } from "react";
import MultipleSelectField from "common/components/FormFields/MultipleSelect";
import { Choice } from "common/components/FormFields/types";
import { useAppSelector } from "state/store";
import { Field } from "utils/appsUtils";
import { flatSourceOutputFields } from "utils/konnectorUtils";
import TextField from "common/components/FormFields/TextFieldWithoutLabel";
import { getEffectiveConstraintOfTypeParameter } from "typescript";
import MultipleSelectFieldv2 from "common/components/FormFields/MultipleSelectv2";
import SingleSelectField2 from "common/components/FormFields/SingleSelect2";

export default function SourceFields({
  field,
  register,
  control,
  watch,
  errors,
  inputSchema,
  getValues,
  setInputValue,
  appDetails,
  setnewObject,
  newObject,
}) {
  const sourceFields: any = "";
  let sourcefield = inputSchema.map((sol) => sol.sourceId);
  let label = field.label ? field.label : field.propName;
  const [fieldType, fieldSourceId] = watch([field.propName + "__field_type", field.propName + "__field_source_type"]);
  let fixedChoices = [];
  if (field?.propValue?.length) {
    field?.propValue?.forEach((ele) => {
      fixedChoices.push({ label: ele.label, value: ele.value });
    });
  }

  // let fixedChoices = [];
  const inputIconUrl = useAppSelector((state) => state.dataMapping.inputIconUrl);
  const getFieldOptions = () => {
    const fieldData = new Field(field).format();
    return fieldData;
  };

  // const onChangeSourceFieldsHandler = (value) => {
  //   value.map((sol) => {
  //     setInputValue(field.propName + "__field_type", "source");
  //     setInputValue(field.propName + "__field_source_type", sol.sourceId);
  //   });
  // };
  const [previousValues, setPreviousValues] = useState([]);
  // ;

  const onChangeSourceFieldsHandler = (currentValues, eventId) => {
    //
    //  setnewObject({ ...newObject, [eventId]: currentValues });
    // previousValues.map((prevValue) => {
    //   setInputValue(prevValue.field.propName + "__field_type", "source");
    //   setInputValue(prevValue.field.propName + "__field_source_type", prevValue.sol.sourceId);
    // });
    // setPreviousValues(currentValues.map((sol) => ({ field, sol })));
    // currentValues.map((sol) => {
    //   setInputValue(field.propName + "__field_type", "source");
    //   setInputValue(field.propName + "__field_source_type", sol.sourceId);
    // });
  };
  useEffect(() => {
    if (fieldType === "fixed" && fieldSourceId) {
      setInputValue(field.propName, "");
      setInputValue(field.propName + "__field_source_type", "");
    }
  }, [fieldType]);

  return (
    <div className="flex justify-center items-center min-w-[244px] w-[444px]">
      <div className=" w-full">
        <div className="flex flex-row space-x-6">
          <div className="flex justify-center items-center">
            <div
              className={`bg-white border gap-3  flex-row border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-64 p-2.5 ${
                field.isRequired ? "required-field" : ""
              }`}
            >
              <span>{label} </span>
              {field.isRequired && <span className="text-red-500 mr-9"> *</span>}
            </div>
            {/* <input
              type="text"
              disabled={true}
              value={label}
              id="first_name"
              className={`bg-white border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-64 p-2.5 ${field.isRequired ? 'required-field' : ''
                }`}
            /> */}
          </div>
          <div className="flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-move-left bottom-11"
            >
              <path d="M6 8L2 12L6 16" />
              <path d="M2 12H22" />
            </svg>
          </div>
          <div className="">
            {fixedChoices.length > 0 ? (
              <SingleSelectField2
                choices={fixedChoices}
                onChangeHandler={onChangeSourceFieldsHandler}
                {...getFieldOptions()}
                control={control}
                getValues={getValues}
                register={register}
                errors={errors}
                setInputValue={setInputValue}
                appDetails={appDetails}
              />
            ) : (
              // : fieldType == "fixed" ? (
              //   <TextField {...getFieldOptions()} register={register} errors={errors} />
              // )
              <MultipleSelectFieldv2
                choices={inputSchema}
                onChangeHandler={onChangeSourceFieldsHandler}
                control={control}
                getValues={getValues}
                {...getFieldOptions()}
                register={register}
                setInputValue={setInputValue}
                errors={errors}
                appDetails={appDetails}
                field={field}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
