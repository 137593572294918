export enum StatusType {
  idle = "idle",
  loading = "loading",
  success = "success",
  failed = "failed",
}

export enum LoadingType {
  idle = "idle",
  sentMail = "sentMail",
  forgotPassword = "forgotPassword",
  resetPassword = "resetPassword",
}
export enum PlanType {
  null = "null",
  paid = "paid",
  free = "free",
}

export interface AuthState {
  loading: boolean;
  currentUser: CurrentUser | any;
  isAuthenticated: boolean;
  apiKey: string;
  error?: string;
  isVerified?: boolean;
  isVerifiedLoading?: any;
  disableKonnector?: boolean;
  isTrial?: boolean;
  data?: any;
  signInLoading?: boolean;
  mailSent?: boolean;
  resetPassword?: boolean;
  forgotPasswordError?: string;
  resetPasswordError?: string;
  showWarning?: boolean;
  loadingType?: any;
  currentPlan?: any;
  planExpired?: boolean;
  email?: string;
  adminDetails?: any;
  customisation?: Customsation;
  Cardcustomisation?: Cardcustomisation;
  layout?: layout;
  template?: template;
  productLogo?: productLogo;
  CodeSnippet?: CodeSnippet;
  portalConfigration?: portalConfigration;
  banner?: banner;
  toastBoolean?: false;
  toasterrorBoolean?: false;
  templatesnippet?: templatesnippet;
  tenantid?: any;
  accCred: any;
  configurationforms?: configurationforms;
  customization?: customization;
}
export interface Customsation {
  backgroundColor?: string;
  fontFamily?: string;
  primaryButtonColor?: string;
  secondaryButtonColor?: string;
  fontColor?: string;
  portalTitle?: string;
  portalDescription?: string;
}
export interface customization {
  title?: string;
  description?: string;
  primary_button_colour?: string;
  primary_colour?: string;
  text_colour?: string;
  font?: string;
}
export interface banner {
  bannerdata?: string;
  bordercolour?: string;
  failureMsg?: string;
  heigth?: string;
  radius?: string;
  successMsg?: string;
  width?: string;
}

export interface configurationforms {
  fontsize?: string;
  fontcolor?: string;
  bordercolor?: string;
  buttoncolor?: string;
  buttontextcolor?: string;
}
export interface Cardcustomisation {
  backgroundcolor?: string;
  borderRadius?: string;
  gridValues?: string;
  heigth?: string;
  hoverColor?: string;
  textColor?: string;
  view?: string;
  width?: string;
  cardSnippet?: string;
  gridsize?: string;
  cardSnippetStatus?: string;
  bordercolour?: string;
}

export interface layout {
  heigth?: string;
  width?: string;
}

export interface template {
  bgColor?: string;
  borderRadius?: string;
  heigth?: string;
  hoverColor?: string;
  textColor?: string;
  width?: string;
  togglebuttoncolor?: string;
  templateSnippet?: string;
  templateSnippetStatus?: string;
}
export interface templatesnippet {
  bgColor_templatesnippet?: string;
  borderRadius_templatesnippet?: string;
  heigth_templatesnippet?: string;
  hoverColor_templatesnippet?: string;
  textColor_templatesnippet?: string;
  width_templatesnippet?: string;
  togglebuttoncolor_templatesnippet?: string;
  templateSnippet_templatesnippet?: string;
  templateSnippetstatus?: string;
}

export interface productLogo {
  url?: string;
  colour?: string;
  size?: string;
}
export interface CodeSnippet {
  cardCss?: string;
  templateCss?: string;
}
export interface portalConfigration {
  bodytext?: string;
  bodytext_color?: string;
  fontFamily?: string;
  portalDescription?: string;
  portalTitle?: string;
  primary_bgColor?: string;
  primary_bgColor_text?: string;
  primary_button_Color?: string;
  primary_button_Text?: string;
  secondary_button_Color?: string;
  secondary_button_tex?: string;
}
export interface CurrentUser {
  name: string;
  tenantId: string;
  billing: any;
  hasTrial: boolean;
  currentPlan: string;
  email: string;
  dtUpdated?: string;
  stripeId: string;
  eventsLimit: number;
  dtCreated: string;
  id: string;
  isEmailVerified: boolean;
  planExpired: boolean;
  taskExecutedCount: number;
}
