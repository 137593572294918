import { useAppSelector } from "state/store";
import { Tab } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  saveDataMapping,
  saveKonnectorv2Loading,
  updateKonnectorv2Loading,
  getDynamicFieldsLoading,
  getLookupSchemaLoading,
} from "state/data-mapping/slices";

import { useEffect, useState } from "react";
import RenderLookup from "./lookupTab";
import InputSchema from "../../DataMapping/FieldSchema";
import { StatusType } from "state/new-konnector/types";
import { Loader } from "components/icons/Loader";
import { setTabOrderId } from "state/configurationv2/slices";
import Webhook from "./webhookTab";
import { Slide, ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { fetchDynamicFieldsLoading, setDynamicInputDataMapping } from "state/account/slices";
import Dynamicinputfieldtab from "./Dynamicinputfieldstab";
const DataMappingFunc: React.FC<any> = ({ staticApp, id }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    control,
    setValue,
    watch,
  } = useForm();
  const dispatch = useDispatch();
  const appDetails = useAppSelector((state) => state.dataMapping.schema);

  const Details = useAppSelector((state) => state.configurationV2.data.apps);
  // ;
  const Dynamicvalues = useAppSelector((state) => state.dataMapping.Dynamicvalues);

  const status = useAppSelector((state) => state.dataMapping.status);
  const invalidLookup = useAppSelector((state) => state.dataMapping.invalidLookup);
  const isKonnectorLookup = useAppSelector((state) => state.dataMapping.isKonnectorLookup);
  const dataMappingState = useAppSelector((state) => state.dataMapping);
  const editTemplateStatus = useAppSelector((state) => state.editTemplate.status);
  const konnectorDetails = useAppSelector((state) => state.editTemplate?.data);
  const lookupAppDetails = useAppSelector((state) => state.editTemplate?.data?.lookups);
  const getkonnectorstate = useAppSelector((state) => state?.editTemplate?.status);
  const apiKey = useAppSelector((state) => state.auth.apiKey);
  const konType = useAppSelector((state) => state.dataMapping.konType);
  const userId = useAppSelector((state) => state.auth.email);
  const tabOrderId = useAppSelector((state) => state.configurationV2.tabOrderId);
  const [filterData, setFilterData] = useState<any>();

  const dynamic_inputfields = useAppSelector((state) => state.dataMapping.dynamicInputFields);
  const dynamicIndex = 1;
  const hasActionTrue = dynamic_inputfields.some((obj) => {
    return obj.hasOwnProperty(`action_${dynamicIndex}`) && obj[`action_${dynamicIndex}`] === true;
  });

  let find_Booleanfordynamicfields = dynamic_inputfields.find((obj) => obj.trigger === true || obj.action_0 == true);
  const data_table = useAppSelector((state) => state.dataMapping.template.konnectorApps);
  const [dynamicinputtab, setdynamicinputtab] = useState([]);
  const appdata = useAppSelector((state) => state.dataMapping.template.konnectorApps);
  const appname = useAppSelector((state) => state.editTemplate.data);
  const dynamicdatastatus = useAppSelector((state) => state.dataMapping.dynamicdatastatus);

  const findActionValue = (data) => {
    // Find the object containing the property starting with 'action_'
    const actionObj = data.find((obj) => Object.keys(obj).find((key) => key.startsWith("action_")));
    // If such object is found, return the value of the property
    if (actionObj) {
      const actionKey = Object.keys(actionObj).find((key) => key.startsWith("action_"));
      return actionObj[actionKey];
    }
    // Otherwise return undefined
    return undefined;
  };
  const actionValue = findActionValue(dynamic_inputfields);

  useEffect(() => {
    if (find_Booleanfordynamicfields?.trigger && !actionValue) {
      setdynamicinputtab(data_table.filter((dat) => dat.appSelectorType == "source"));
    }
    if (actionValue && !find_Booleanfordynamicfields?.trigger) {
      setdynamicinputtab(data_table.filter((dat) => dat.appSelectorType == "target"));
    }
    if (actionValue && find_Booleanfordynamicfields?.trigger) {
      setdynamicinputtab(
        data_table.filter((data) => {
          return data.appSelectorType == "source" || data.appSelectorType == "target";
        })
      );
    }
  }, [find_Booleanfordynamicfields?.trigger, actionValue]);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const tab = lookupAppDetails?.length && dataMappingState.isLookup ? "tab1" : "tab2";

  const [lookupTab, setLookupTab] = useState<any>(tab);
  const onSubmit = async (data) => {
    dispatch(saveDataMapping(data));
    konType === "Konnector"
      ? dispatch(updateKonnectorv2Loading({ apiKey, userId }))
      : dispatch(saveKonnectorv2Loading({ apiKey, userId }));
  };
  const targetid = useAppSelector((state) => state.configurationV2.data);
  let compare_id = targetid;
  useEffect(() => {
    if (actionValue && !find_Booleanfordynamicfields?.trigger) {
      appdata.forEach((res) => {
        //   if (tab == "tab1") {
        if (res.appSelectorType === "target") {
          //     setLookupTab("tab1")
          dispatch(
            getDynamicFieldsLoading({
              hasActionFields: actionValue,
              appId: res.appId,
              event: res.appEventId,
              connectionId:
                res.appId == targetid.targetAppId ? targetid.targetAppConnectionId : targetid.sourceAppConnectionId,
              currentActionId: res.actionOrderId,
            })
          );
          //   } else {
        }
        //     setLookupTab("tab2")
      });
      //   }
    }
    if (find_Booleanfordynamicfields?.trigger && !actionValue) {
      appdata.forEach((res) => {
        if (res.appSelectorType === "source") {
          dispatch(
            getDynamicFieldsLoading({
              hasActionFields: find_Booleanfordynamicfields?.trigger,
              appId: res.appId,
              event: res.appEventId,
              connectionId:
                res.appId == targetid.targetAppId ? targetid.targetAppConnectionId : targetid.sourceAppConnectionId,
              currentActionId: res.actionOrderId,
            })
          );
        }
      });
      // }
    }
    if (find_Booleanfordynamicfields?.trigger && actionValue) {
      appdata.forEach((res) => {
        if (res.appSelectorType === "source") {
          dispatch(
            getDynamicFieldsLoading({
              hasActionFields: find_Booleanfordynamicfields?.trigger,
              appId: res.appId,
              event: res.appEventId,
              connectionId:
                res.appId == targetid.targetAppId ? targetid.targetAppConnectionId : targetid.sourceAppConnectionId,
              currentActionId: res.actionOrderId,
            })
          );
        }
        if (res.appSelectorType === "target") {
          dispatch(
            getDynamicFieldsLoading({
              hasActionFields: find_Booleanfordynamicfields?.trigger,
              appId: res.appId,
              event: res.appEventId,
              connectionId:
                res.appId == targetid.targetAppId ? targetid.targetAppConnectionId : targetid.sourceAppConnectionId,
              currentActionId: res.actionOrderId,
            })
          );
        }
      });
      // }
    }
  }, [actionValue, find_Booleanfordynamicfields?.trigger]);

  // const handleSearchChange = (event) => {
  //   const searchValue = event.target.value;
  //   ;
  //   const inputSchemaArray = appDetails.input.data.filter((value: any) => {
  //     return value.propName.toLowerCase().includes(searchValue.toLowerCase());
  //   });
  //   setFilterData(inputSchemaArray);
  // };
  // const handleHideShow = (tab) => {
  //   if (tab == "tab1") {
  //     setLookupTab("tab1")
  //   } else {
  //     setLookupTab("tab2")
  //   }
  // }

  const renderHeaderTabsList = () => {
    let tabsArray = lookupAppDetails.filter((app) => app.appId !== id && app.appId !== staticApp);
    if (
      lookupAppDetails &&
      lookupAppDetails?.length > 0 &&
      editTemplateStatus == StatusType.success &&
      invalidLookup?.length > 0
    ) {
      let tabs = lookupAppDetails
        .filter((app) => app.appId !== id && app.appId !== staticApp)
        .map((app, index) => (
          <Tab
            onClick={() => {
              dispatch(setTabOrderId(index));
            }}
            key={index}
            className={({ selected }) =>
              classNames(
                `${
                  selected ? "text-indigo-600 b-2 border-indigo-700" : "text-gray-500 border-transparent"
                } py-4 px-2 mr-8 border-b-2`
              )
            }
          >
            {app.appName}
          </Tab>
        ));
      tabs.push(
        <Tab
          onClick={() => {
            dispatch(setTabOrderId(tabs?.length - 1));
          }}
          key={tabs?.length - 1}
          className={({ selected }) =>
            classNames(
              `${
                selected ? "text-indigo-600 b-2 border-indigo-700" : "text-gray-500 border-transparent"
              } py-4 px-2 mr-8 border-b-2`
            )
          }
        >
          Field Mapping
        </Tab>
      );
      return tabs;
    }
    if (dynamicinputtab || (dynamicinputtab && lookupAppDetails)) {
      let tabss = (
        <Tab
          onClick={() => {
            dispatch(setTabOrderId(0));
          }}
          key={0}
          className={({ selected }) =>
            classNames(
              `${
                selected ? "text-indigo-600 b-2 border-indigo-700" : "text-gray-500 border-transparent"
              } py-4 px-2 mr-8 border-b-2`
            )
          }
        >
          Field Mapping
        </Tab>
      );

      return tabss;
    } else {
      if (konnectorDetails.trigger.eventId == "webhook") {
        return (
          <>
            <Tab
              onClick={() => {
                dispatch(setTabOrderId(0));
              }}
              key={0}
              className={({ selected }) =>
                classNames(
                  `${
                    selected ? "text-indigo-600 b-2 border-indigo-700" : "text-gray-500 border-transparent"
                  } py-4 px-2 mr-8 border-b-2`
                )
              }
            >
              {`Webhook`}
            </Tab>
            <Tab
              onClick={() => {
                dispatch(setTabOrderId(1));
              }}
              key={1}
              className={({ selected }) =>
                classNames(
                  `${
                    selected ? "text-indigo-600 b-2 border-indigo-700" : "text-gray-500 border-transparent"
                  } py-4 px-2 mr-8 border-b-2`
                )
              }
            >
              {`Field Mapping`}
            </Tab>
          </>
        );
      } else {
        return (
          <Tab
            onClick={() => {}}
            key={0}
            className={({ selected }) =>
              classNames(
                `${
                  selected ? "text-indigo-600 b-2 border-indigo-700" : "text-gray-500 border-transparent"
                } py-4 px-2 mr-8 border-b-2`
              )
            }
          >
            {`Field Mapping`}
          </Tab>
        );
      }
    }
  };

  useEffect(() => {
    if (status == "success") {
      toast.success("Konnector saved successfully", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [status]);
  const renderMapping = () => {
    return (
      <>
        {dynamicdatastatus === "loading" && (
          <div className="flex justify-center items-center bottom-10 h-64">
            <div role="status">
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-black"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          </div>
        )}

        {editTemplateStatus === "loading" && (
          <div className="flex justify-center items-center bottom-10 h-64">
            <div role="status">
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-black"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          </div>
        )}

        {editTemplateStatus !== StatusType.loading && dynamicdatastatus !== "loading" && (
          <>
            <br />

            <form onSubmit={handleSubmit(onSubmit)} className="overflow-scroll h-72">
              {dynamicinputtab?.map(
                (app, tabIndex) =>
                  editTemplateStatus === StatusType.success &&
                  Dynamicvalues.filter((data1) => app.appId === data1.appId && data1.fields) // Filter data by matching appId and ensuring fields exist
                    .flatMap((data) =>
                      data.fields.map((field, index) => (
                        <Dynamicinputfieldtab key={index} appDetails={app} index={index} field={field} data={data} />
                      ))
                    )
              )}
              {appDetails?.input?.data?.length > 0 && appDetails?.output?.length > 0 && (
                <>
                  <div className="">
                    <InputSchema
                      appDetails={appDetails}
                      filterData={filterData}
                      register={register}
                      errors={errors}
                      handleSubmit={handleSubmit}
                      reset={reset}
                      getValues={getValues}
                      control={control}
                      setValue={setValue}
                      watch={watch}
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={status === StatusType.loading}
                    className="text-white w-20 h-8 hover:bg-[#0E1A2E] bg-komp-secondary-color font-normal rounded-sm text-sm p-1 text-center disabled:opacity-50"
                  >
                    {status === StatusType.loading ? <Loader /> : "Save"}
                  </button>
                </>
              )}
            </form>
          </>
        )}
      </>
    );
  };

  const renderHeaderTabsPanels = () => {
    let tabsArray = lookupAppDetails.filter((app) => app.appId !== id && app.appId !== staticApp);

    if (!actionValue && !find_Booleanfordynamicfields?.trigger) {
      if (lookupAppDetails && lookupAppDetails?.length > 0 && editTemplateStatus == StatusType.success) {
        let tabs = lookupAppDetails
          .filter((app) => app.appId !== id && app.appId !== staticApp)
          .map((app, index) => (
            <Tab.Panel tabIndex={index} key={app.id} className={({ selected }) => classNames(`h-full`)}>
              <RenderLookup lookupAppDetails={app} />
            </Tab.Panel>
          ));
        tabs.push(
          <Tab.Panel tabIndex={tabs.length - 1} key={2} className={({ selected }) => classNames(`h-full`)}>
            <ToastContainer />
            {renderMapping()}
          </Tab.Panel>
        );
        return tabs;
      }
    }

    if (
      (konnectorDetails.trigger.eventId != "webhook" &&
        dynamicinputtab &&
        dynamicdatastatus !== "loading" &&
        editTemplateStatus == StatusType.success) ||
      lookupAppDetails
    ) {
      let tab = (
        <Tab.Panel tabIndex={0} key={0} className={({ selected }) => classNames(`h-full`)}>
          {renderMapping()}
        </Tab.Panel>
      );

      return tab;
    } else {
      if (konnectorDetails.trigger.eventId == "webhook") {
        return (
          <>
            <Tab.Panel tabIndex={0} key={0} className={({ selected }) => classNames(`h-full`)}>
              <Webhook appDetails={konnectorDetails.trigger}></Webhook>
            </Tab.Panel>
            <Tab.Panel tabIndex={1} key={1} className={({ selected }) => classNames(`h-full`)}>
              <ToastContainer transition={Slide} />
              {renderMapping()}
            </Tab.Panel>
          </>
        );
      } else {
        return (
          <Tab.Panel tabIndex={0} key={2} className={({ selected }) => classNames(`h-full`)}>
            <ToastContainer />
            {renderMapping()}
          </Tab.Panel>
        );
      }
    }
  };

  // Dynamicvalues.filter((data) => {
  //   data.fields?.map((field, index) => (
  //     <Dynamicinputfieldtab key={index} appDetails={app} index={index} field={field} />
  //   ))
  // })

  return (
    <div>
      <Tab.Group selectedIndex={tabOrderId}>
        <Tab.List className={"flex text-sm font-medium mb-4"}>{renderHeaderTabsList()}</Tab.List>
        <div>
          <section>{renderHeaderTabsPanels()}</section>
        </div>
      </Tab.Group>
    </div>
  );
};
export default DataMappingFunc;
