import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { serializeDataMapping, serializeLookupDataMapping } from "utils/appsUtils";
import { v4 as uuidv4 } from "uuid";
import {
  InitialState,
  InputFields,
  UpdateTargetEventPayload,
  StatusType,
  AppRole,
  UpdateSourceAppPayload,
  ValidateAccountSuccessResponse,
  CreateAccountSuccessResponse,
  UpdateSourceAccountPayload,
  UpdateTargetAppPayload,
  UpdateTargetAccountPayload,
  KonnectorAppState,
  ActionMode,
  ConfigurationType,
  UpdateLookupEventPayload,
  KonnectorType,
} from "../types";

export const initialState: InitialState = {
  data: {
    id: "",
    name: "Edit Konnector",
    konnectorType: KonnectorType.Initial,
    webhookUrl: {
      url: "",
      id: "",
    },

    trigger: {
      triggerType: "",
      appSelectorKey: "",
      appName: "",
      iconUrl: "",
      appSelectorType: AppRole.Source,
      formFields: [],
      formSettings: {},
      appId: "",
      actionOrderId: 0,
      connectionId: "",
      connectionName: "",
      eventId: "",

      isAccountValidated: false,
      outputFields: {
        hasData: false,
        fields: {},
        schema: {},
      },
      inputFields: { appId: "", data: [], eventId: "", sourceId: "", dataMapping: {}, hasData: false },
      appDetails: {},
    },
    lookups: [],
    actions: [],
    ruleEngine: [],
    parseEngine: [],
    sourceTemplate: "",
    adminRequiredData: undefined,
    workflowAppId: undefined,
  },
  currentActionId: "",
  hasConfigurationSaved: true,
  configurationType: ConfigurationType.Source,
  actionMode: ActionMode.View,
  status: StatusType.idle,
  resetWebhook: StatusType.idle,
  generateUrl: StatusType.idle,
  resetFormStatus: StatusType.idle,
  tabValidated: false,
  isFormGenerated: false,
  errorMessage: "",
  created: false,
  saveButtonStatus: false,
  editRule: false,
  edit: false,
  editParse: false,
  savedDataMapping: false,
  updatewebhookdata: {},
};

const editKonnectorSlice = createSlice({
  name: "editKonnector",
  initialState,
  reducers: {
    // getKonnectorLoading: (state, data) => {
    //   ;
    //   state.status = StatusType.loading;
    // },
    saveDataMapping: (state, data: PayloadAction<any>) => {
      const sourceId = state?.data?.trigger?.outputFields?.schema?.sourceId;
      // const findActionIndex = state.data.actions.findIndex((action) => action.id === state.currentActionId);
      // state.data.actions[findActionIndex].actionMode = ActionMode.View;
      const serializeData = serializeDataMapping(state.data.actions[0].inputFields.data, data.payload, sourceId);
      state.data.actions[0].dataMapping = serializeData;
      state.data.actions[0].rawDataMapping = data.payload;
      state.data.actions[0].shouldReconfigure = false;
      state.actionMode = ActionMode.Save;
      state.savedDataMapping = true;
      state.currentActionId = "";
    },
    editWorkflowSaveKonnector: (state) => {
      state.status = StatusType.loading;
      state.saveButtonStatus = true;
    },
    setKonnectorLoading: (state, data) => {
      state.status = StatusType.loading;
    },
    getKonnectorSuccess: (state, data: PayloadAction<any>) => {
      state.data = data.payload.data;

      state.status = StatusType.success;
      state.generateUrl = StatusType.success;
    },
    getKonnectorFailed: (state) => {
      state.status = StatusType.failed;
    },
    updateName: (state, data: PayloadAction<string>) => {
      state.data.name = data.payload;
      state.edit = true;
    },
    setupdatewebhookur: (state, data: PayloadAction<any>) => {
      state.updatewebhookdata = data.payload.data;
    },
    saveKonnectorSuccess: (state) => {
      state.status = StatusType.success;
      state.created = true;
    },
    resetEditKonnector: () => initialState,
  },
});

export const {
  setKonnectorLoading,
  getKonnectorSuccess,
  getKonnectorFailed,
  updateName,
  saveDataMapping,
  editWorkflowSaveKonnector,
  saveKonnectorSuccess,
  resetEditKonnector,
  setupdatewebhookur,
} = editKonnectorSlice.actions;

export default editKonnectorSlice.reducer;
