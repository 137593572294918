import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AccountName, Password, TextField } from "common/components/FormFields";
import { useAppDispatch, useAppSelector } from "state/store";
import { StatusType } from "state/new-konnector/types";
import { generateJSONForAccountValidation, parseOauthUrl } from "utils/config";
import {
  setAccountLoading,
  setOAuthAccountLoading,
  setUpdateLoading,
  setUpdateOAuthAccountLoading,
} from "state/configurationv2/slices";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "components/icons/Loader";
import { getDefaultState } from "react-query/types/core/mutation";
import { ToastSucess } from "common/components/toast/Toast";
import { switchTab } from "state/account/slices";
import { useDispatch } from "react-redux";

const RenderLookup: React.FC<any> = ({ lookupAppDetails }) => {
  const [accessToken, setAccessToken] = useState(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    control,
    setValue,
    watch,
  } = useForm();
  const dispatch = useDispatch();
  const [authData, setAuthData] = useState(null);
  const lookupAppStatus = useAppSelector((state) => state.configurationV2.lookupAppStatus);
  const konType = useAppSelector((state) => state.dataMapping.konType);
  const v2 = useAppSelector((state) => state.configurationV2.lookupAppDetails);
  const getDefaultState = (data) => {
    const defaultState = {};
    data.filter((field) => {
      defaultState[field.name] = field.value;
    });
    return defaultState;
  };
  var v3;

  const dat = v2.filter((app, index) => {
    if (app.appId == lookupAppDetails.appDetails.id) {
      return (v3 = app.body.data);
    }
  });

  const renderField = (field) => {
    switch (field.type) {
      case "textbox | textarea":
      case "textbox": {
        return (
          <TextField
            {...field}
            key={field.name}
            register={register}
            // isDisabled={accountValidated}
            errors={errors}
            placeholder={field.placeholder}
          />
        );
      }
      case "Password": {
        return (
          <Password
            {...field}
            key={field.name}
            register={register}
            // isDisabled={accountValidated}
            errors={errors}
          />
        );
      }
      case "accountName": {
        return <AccountName {...field} key={field.name} register={register} isDisabled={true} errors={errors} />;
      }
      default:
        // return "<p>Field not found</p>";
        return <></>;
    }
  };
  const renderForm = () => {
    const fields = [...lookupAppDetails?.appDetails?.connectionConfig?.fields];
    return fields.map((field) => renderField(field));
  };
  const onSubmit = (data) => {
    if (lookupAppDetails.appDetails.appAuthType == "OAuth") {
      const { ["Account name"]: connectionName, ...fields } = data;
      const requestAccountBody = {
        connectionName,
        fields,
        ...lookupAppDetails.appDetails,
      };
      let oauthUrl = parseOauthUrl(lookupAppDetails.appDetails.connectionConfig.authUrl, data);

      const handleMessage = (event) => {
        if (event.data.access_token) {
          setAccessToken(event.data.access_token);
          setAuthData(event.data);
          var windowFunc;
          if (!accessToken) {
            windowFunc = window.open(oauthUrl, "OAuth Window", "height=600,width=800");
          }
          const requestAccountData = generateJSONForAccountValidation(requestAccountBody, event.data);
          //   if (accountValidated) {
          //     return dispatch(
          //       setUpdateOAuthAccountLoading({
          //         accountDetails: requestAccountData,
          //         appId: lookupAppDetails?.appDetails?.id,
          //         type,
          //       })
          //     );
          //   }
          dispatch(
            setOAuthAccountLoading({
              accountDetails: requestAccountData,
              appId: lookupAppDetails?.appDetails?.id,
            })
          );
          window.removeEventListener("message", handleMessage);
          windowFunc.close();
        }
      };
      window.addEventListener("message", handleMessage);
      window.addEventListener("message", handleMessage);
      window.open(oauthUrl, "OAuth Window", "height=600,width=800");
    } else {
      const { ["Account name"]: connectionName, ...fields } = data;
      const requestAccountBody = {
        connectionName,
        fields,
        ...lookupAppDetails.appDetails,
      };
      const requestAccountData = generateJSONForAccountValidation(requestAccountBody);
      //   if (accountValidated) {
      //     return dispatch(
      //       setUpdateLoading({
      //         accountDetails: requestAccountData,
      //         appId: appDetails.id,
      //         type,
      //       })
      //     );
      //   }
      dispatch(
        setAccountLoading({
          accountDetails: requestAccountData,
          appId: lookupAppDetails?.appDetails?.id,
          isLookup: true,
          eventId: lookupAppDetails.eventId,
        })
      );
    }
  };
  useEffect(() => {
    if (konType == "konnector") {
    } else {
      if (v3?.fields?.length) {
        // reset(getDefaultState([]));

        reset(getDefaultState(v3.fields));
      }
    }
  }, []);

  return (
    <div className="p-4 h-[calc(80vh-5rem)] w-full">
      <ToastContainer />
      <div className="flex flex-col space-y-3">
        <form className="space-y-6 flex flex-col items-start" onSubmit={handleSubmit(onSubmit)}>
          {renderForm()}

          <button
            type="submit"
            disabled={lookupAppStatus == StatusType.loading}
            className="w-auto text-white hover:bg-[#0e1a2e] bg-komp-secondary-color hover:transition-all focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 text-center disabled:opacity-45"
          >
            {lookupAppStatus == StatusType.loading ? (
              <>
                <Loader />
              </>
            ) : (
              <>{`Save`}</>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};
export default RenderLookup;
