import { AxiosResponse } from "axios";
import { takeEvery, put, call, all, select, take } from "redux-saga/effects";
import {
  fetchAllAppSchemanewLoading,
  fetchDynamicFieldindatamapLoading,
  fetchDynamicFieldindatamapstatus,
  getDynamicFieldsLoading,
  getLookupSchemaLoading,
  saveKonnectorName,
  saveKonnectorNameSuccess,
  saveKonnectorSucces,
  saveKonnectorv2Loading,
  setDynamicValuesFromTemplate,
  setInvalidLookup,
  setSchemaLoading,
  setSchemaSuccess,
  setdynamicvalues,
  setdynamicworkshhetvalues,
  updateKonnectorv2Loading,
} from "./slices";
import { KonnectifyApiResponse } from "./types";
import { fetchDynamicInputFieldsApi, getAppSchemaApi } from "api/appsApi";
import { saveKonnectorApi, saveKonnectorDisplayName, updateKonnectorApi } from "api/konnectorsApi";
import { serializeCreateKonnectorData } from "utils/configurationV2Utils";
import { getEditTemplate } from "./selectors";
import { serializeDynamicInputFields } from "utils/configrationv2";
import { getConfigurationDetails } from "../configurationv2/selectors";
import { getConnectionConfigApi } from "api/configApi";
// import { createLookupAccountSuccess } from "../configurationv2/slices";
import {
  createAccountSuccess,
  createLookupAccountSuccess,
  setcurrenttabindex,
  setTargeAppFailed,
  setTargetAppAndAccountSuccess,
  setTargetAppValidated,
} from "../configurationv2/slices";
import { setAppSchema, setDyanmicFieldsLoading, setDyanmicFieldsSuccess } from "../account/slices";
import { createAccountFailed } from "../edit-template-settings/slices";
import { DynamicFieldsSchemaType, InputFields, ValidateAccountSuccessResponse } from "../new-konnector/types";
import { useAppSelector } from "../store";
import {
  serializeAppSchemaFields,
  serializeAppSchemaFieldsForState,
  serializeDataForAppSchema,
} from "utils/stateUtils";
import { getAllAppsSchemaApi } from "api/allAppsApi";

export function* getSchema() {
  try {
    const templateData = yield select((state) => state.dataMapping.template);
    const configData = yield select((state) => state.configurationV2.data);
    const result = yield call(serializeDynamicInputFields, templateData.konnectorApps);
    for (const app of templateData.konnectorApps) {
      if (app.appSelectorType == "source") {
        var payload;
        if (app.appId == configData.sourceAppId) {
          payload = {
            appId: app.appId,
            data: {
              connectionConfigId: configData.sourceAppConnectionId,
              eventData: {},
              eventId: app.appEventId,
              schemaType: "output",
            },
          };
        } else {
          payload = {
            appId: app.appId,
            data: {
              connectionConfigId: configData.targetAppConnectionId,
              eventData: {},
              eventId: app.appEventId,
              schemaType: "output",
            },
          };
        }
        try {
          const result: AxiosResponse<any> = yield call(getAppSchemaApi, payload);
          console.log(result, "SDSDSD");
          if ([401, 403].includes(result?.data.data.status)) {
            // yield put(setTargetAppValidated(false));
            yield put(setTargeAppFailed(true));
            return yield put(setcurrenttabindex(1));
          }
          if (result.data.data.error.includes("expired")) {
            yield put(setTargeAppFailed(true));
            return yield put(setcurrenttabindex(1));
          }
          if (!result.data.data.eventResponse.data.error)
            yield put(setSchemaSuccess({ type: "output", data: result.data.data }));
        } catch (err) {}
      } else {
        var payload;
        if (app.appId == configData.sourceAppId) {
          payload = {
            appId: app.appId,
            data: {
              connectionConfigId: configData.sourceAppConnectionId,
              eventData: {},
              eventId: app.appEventId,
              schemaType: "input",
            },
          };
        } else {
          payload = {
            appId: app.appId,
            data: {
              connectionConfigId: configData.targetAppConnectionId,
              eventData: {},
              eventId: app.appEventId,
              schemaType: "input",
            },
          };
        }
        try {
          const result: AxiosResponse<any> = yield call(getAppSchemaApi, payload);
          if ([401, 403].includes(result?.data.data.status)) {
            // yield put(setTargetAppValidated(false));
            yield put(setTargeAppFailed(true));
            return yield put(setcurrenttabindex(1));
          }
          // if (result.data.data.error.includes("expired")) {
          //   yield put(setTargeAppFailed(true));
          //   return yield put(setcurrenttabindex(1));
          // }
          yield put(setSchemaSuccess({ type: "input", data: result.data.data }));
        } catch (err) {}
      }
    }
  } catch (e) {}
}
export function* saveKonnector(data) {
  try {
    const templateData = yield select((state) => state.dataMapping.template);
    const getTemplate = yield select(getEditTemplate);
    const rawDataMapping = yield select((state) => state.dataMapping.rawDataMapping);
    const datamapping = yield select((state) => state.dataMapping);
    const accountDetails = yield select((state) => state.configurationV2.data);
    const lookup = yield select((state) => state.configurationV2);
    const DynamicInputDataMapping = yield select((state) => state.dataMapping.DynamicInputDataMapping);
    const webhook = yield select((state) => state.konnectors.storewebhook.data);
    const serializeData = serializeCreateKonnectorData(
      templateData,
      getTemplate,
      accountDetails,
      rawDataMapping,
      datamapping,
      lookup,
      DynamicInputDataMapping,
      webhook,
    );
    const result: AxiosResponse<any> = yield call(saveKonnectorApi, serializeData);
    yield put(saveKonnectorSucces(result.data.data));
  } catch (err) {}
}
export function* updateKonnector(data) {
  try {
    const templateData = yield select((state) => state.dataMapping.template);
    const getTemplate = yield select(getEditTemplate);
    const rawDataMapping = yield select((state) => state.dataMapping.rawDataMapping);

    const datamapping = yield select((state) => state.dataMapping);

    const DynamicInputDataMappingedit = yield select(
      (state) => state.editTemplate.data.actions[0].dynamicInputFields.dataMapping,
    );
    const DynamicInputDataMappingcreate = yield select((state) => state.dataMapping.DynamicInputDataMapping);
    const Kontype = yield select((state) => state.dataMapping.konType);
    const dynamicinputstate = yield select((state) => state.dataMapping.dynamicinputstate);
    let DynamicInputDataMapping =
      Kontype == "Konnector" && !dynamicinputstate ? DynamicInputDataMappingedit : DynamicInputDataMappingcreate;
    const accountDetails = yield select((state) => state.configurationV2.data);
    const lookup = yield select((state) => state.configurationV2);
    // const DynamicInputDataMapping = yield select((state) => state.dataMapping.DynamicInputDataMapping);
    const webhook = yield select((state) => state.konnectors.storewebhook.data);
    const serializeData = serializeCreateKonnectorData(
      templateData,
      getTemplate,
      accountDetails,
      rawDataMapping,
      datamapping,
      lookup,
      DynamicInputDataMapping,
      webhook,
    );
    const result: AxiosResponse<any> = yield call(updateKonnectorApi, templateData.id, serializeData);

    yield put(saveKonnectorSucces("new"));
  } catch (err) {}
}

export function* getLookupKonnector(data) {
  try {
    const configuationState = yield select(getConfigurationDetails);
    const konType = yield select((state) => state.dataMapping.konType);
    var connectionId;
    if (konType == "Konnector") {
      const payload = {
        appId: data.payload.appId,
        data: {
          connectionConfigId: data.payload.connectionId,
          eventData: {},
          eventId: data.payload.appEventId,
          schemaType: "output",
        },
      };
      const result1: AxiosResponse<any> = yield call(getAppSchemaApi, payload);
      const result12: AxiosResponse<any> = yield call(getConnectionConfigApi, payload.data.connectionConfigId);
      yield put(createLookupAccountSuccess({ appId: data.payload.appId, body: result12.data }));
      yield put(setSchemaSuccess({ type: "output_01", data: result1.data.data }));
      yield put(setInvalidLookup({ type: "Konnector", payload: data.payload }));
    } else {
      if (configuationState.data.sourceAppId == data.payload.appId) {
        connectionId = configuationState.data.sourceAppConnectionId;
      } else {
        connectionId = configuationState.data.targetAppConnectionId;
      }
      if (
        configuationState.data.sourceAppId == data.payload.appId ||
        configuationState.data.targetAppId == data.payload.appId
      ) {
        const payload = {
          appId: data.payload.appId,
          data: {
            connectionConfigId: connectionId,
            eventData: {},
            eventId: data.payload.appEventId,
            schemaType: "output",
          },
        };
        const result1: AxiosResponse<any> = yield call(getAppSchemaApi, payload);
        yield put(setSchemaSuccess({ type: "output_01", data: result1.data.data }));
      } else {
        yield put(setInvalidLookup({ type: "Template", payload: data.payload }));
      }
    }
  } catch (err) {}
}

export function* getDynamicFields(data) {
  try {
    // const find_Schematype = yield select((state) => state.editTemplate.data);
    const find_Schematypes = yield select((state) => state.dataMapping.template.triggerDefinition.appId);
    const dynamic_fields = yield select((state) => state.dataMapping.template.triggerDefinition.inputFields);
    let payload;
    if (data.payload.hastDynamicInputfields) {
      payload = {
        appId: data.payload.appId,
        data: {
          schemaType: DynamicFieldsSchemaType.Input,
          eventId: data.payload.event,
          connectionConfigId: data.payload.connectionId,
        },
      };
    } else {
      payload = {
        appId: data.payload.appId,
        data: {
          schemaType:
            data.payload.appId == find_Schematypes
              ? DynamicFieldsSchemaType.Input
              : DynamicFieldsSchemaType.DynamicInput,
          eventId: data.payload.event,
          connectionConfigId: data.payload.connectionId,
        },
      };
    }
    const result: AxiosResponse<KonnectifyApiResponse<any>> = yield call(getAppSchemaApi, payload);
    const inputFields = {
      fields: result?.data?.data?.data,
      appId: data.payload?.appId,
      eventId: data.payload?.event,
      currentActionId: data?.payload?.currentActionId,
    };

    if ([401, 403].includes(result?.data.data.status)) {
      // yield put(setTargetAppValidated(false));
      yield put(setTargeAppFailed(true));
      return yield put(setcurrenttabindex(1));
    }

    if (dynamic_fields && Object.keys(dynamic_fields).length > 0 && result.data.data?.data?.[0]?.propValue?.length) {
      yield put(setDynamicValuesFromTemplate(dynamic_fields));
    }
    yield put(setdynamicvalues({ ...inputFields }));
  } catch (e) {
    yield put(createAccountFailed());
  }
}

export function* fetchDynamicInputFields(data) {
  try {
    // yield put(fetchDynamicFieldindatamapstatus("loading"));

    let dataMapping = data.payload.mapping;

    const result: AxiosResponse<KonnectifyApiResponse<InputFields>> = yield call(fetchDynamicInputFieldsApi, {
      appId: data.payload.appDetails.appId || data.payload.appDetails.id,
      data: {
        schemaType: DynamicFieldsSchemaType.DynamicFields,
        eventId: data.payload.eventId,
        connectionConfigId: data.payload.cc,
        eventData: { ...dataMapping },
      },
    });
    // const inputFieldss = {
    //   currentActionId: data.payload.appDetails.index,
    //   value: result.data.data.data,
    //   field: data.payload.field,
    // };

    // const inputFieldss = {
    //   appId: data.payload.appDetails.id,
    //   data: {
    //     connectionConfigId: data.payload.Connectionconfigid,
    //     eventResponse: result?.data?.data?.data,
    //     eventId: data.payload.eventId,
    //     schemaType: "input",
    //   },
    // };
    yield put(
      setdynamicworkshhetvalues({
        appId: data.payload.appDetails.appId,
        currentActionId: data.payload.appDetails.index,
        value: result.data.data.data,
        field: data.payload.field,
      }),
    );
    // yield put(fetchDynamicFieldindatamapstatus("success"));
    const dynamic_inputfields = yield select((state) => state.dataMapping.dynamicInputFields);
    let find_Booleanfordynamicfields = dynamic_inputfields.find((obj) => obj.trigger === true || obj.action_0 == true);
    let targetAppDetails = result.data.data.data;

    // yield put(setSchemaSuccess({ type: "input", data: result.data.data.data }));
    // yield put(setSchemaSuccess({ type: "input", ...inputFieldss }));
  } catch (e) {
    yield put(createAccountFailed());
  }
}

export function* fetchAllAppSchemanew(data) {
  yield put(fetchDynamicFieldindatamapstatus("loading"));

  // ;
  try {
    const templateData = yield select((state) => state.dataMapping.template);
    const DynamicInputDataMapping = yield select((state) => state.dataMapping.DynamicInputDataMapping);
    const Kontype = yield select((state) => state.dataMapping.konType);

    const Dynamicvals = yield select((state) => state.editTemplate.data.actions[0].dynamicInputFields.dataMapping);
    const newdatavalue = yield select((state) => state.dataMapping.Dynamicvalues);
    // const eventId = newdatavalue.filter((id) => {
    //
    //   if (id.appId == data.payload.appId) {
    //
    //     return id[0].fields[0].eventId
    //   }

    // })

    const eventId = newdatavalue
      .filter((data1) => data.payload.appId === data1.appId && data1.fields)
      .flatMap((data) => {
        return data.eventId;
      });

    const configData = yield select((state) => state.configurationV2.data);
    const result = yield call(serializeDynamicInputFields, templateData.konnectorApps);
    const dynamicinputstate = yield select((state) => state.dataMapping.dynamicinputstate);
    const dynamic_inputfields = yield select((state) => state.dataMapping.dynamicInputFields);
    const Schematype = yield select((state) => state.editTemplate.data.trigger);
    const triggerValue = dynamic_inputfields[0]?.trigger;
    const actionvalue = dynamic_inputfields[1]?.action_0;
    // const eventdata = yield select((state) => state.datamapping.template.konnectorApps);
    // const filteredEventData = eventdata.filter((res) => res.appId === data.payload.id);
    var payload;
    // filteredEventData.forEach((res) => {
    payload = {
      appId: data.payload.id || data.payload.appId,
      data: {
        connectionConfigId:
          data.payload.id || data.payload.appId == configData.sourceAppId
            ? configData.sourceAppConnectionId
            : configData.targetAppConnectionId,
        eventData: Kontype == "Konnector" && !dynamicinputstate ? Dynamicvals : DynamicInputDataMapping,
        eventId: eventId[0],
        schemaType: data.payload.id || data.payload.appId == Schematype.appId ? "output" : "input",
      },
    };
    try {
      const result: AxiosResponse<any> = yield call(getAppSchemaApi, payload);
      if ([401, 403].includes(result?.data.data.status)) {
        // yield put(setTargetAppValidated(false));
        yield put(setTargeAppFailed(true));
        return yield put(setcurrenttabindex(1));
      }
      if (result.data.data?.error && result.data.data?.error.includes("expired")) {
        yield put(setTargeAppFailed(true));
        return yield put(setcurrenttabindex(1));
      }
      yield put(
        setSchemaSuccess({ type: payload?.appId == Schematype.appId ? "output" : "input", data: result.data.data }),
      );
      yield put(fetchDynamicFieldindatamapstatus("success"));
    } catch (err) {
      console.log(err, `fetchAllAppSchemanew`);
    }
  } catch (e) {
    console.log(e, `fetchAllAppSchemanew`);
  }
}

export function* saveKonnectorNames(data) {
  try {
    const result: AxiosResponse<any> = yield call(saveKonnectorDisplayName, data);
    yield put(saveKonnectorNameSuccess());
  } catch (err) {}
}

export default function* rootSaga() {
  yield takeEvery(setSchemaLoading.type, getSchema);
  yield takeEvery(saveKonnectorv2Loading.type, saveKonnector);
  yield takeEvery(updateKonnectorv2Loading.type, updateKonnector);
  yield takeEvery(getLookupSchemaLoading.type, getLookupKonnector);

  yield takeEvery(getDynamicFieldsLoading.type, getDynamicFields);
  yield takeEvery(fetchDynamicFieldindatamapLoading.type, fetchDynamicInputFields);
  // yield takeEvery(fetchDynamicInputnewFieldsloading.type, getAllAppsSchema);
  yield takeEvery(fetchAllAppSchemanewLoading.type, fetchAllAppSchemanew);
  yield takeEvery(saveKonnectorName.type, saveKonnectorNames);
}
