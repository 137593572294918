import * as React from "react";
import { useAppSelector } from "state/store";

export const TabSelector = ({
  disabled,
  isActive,
  children,
  onClick,
}: {
  disabled: boolean;
  isActive: boolean;
  children: React.ReactNode;
  onClick: () => void;
}) => {
  const customisation = useAppSelector((state) => state.auth.customisation);
  const new_Customization = useAppSelector((state) => state.auth.portalConfigration);
  return (
    <button
      disabled={disabled}
      className={`mr-8 group inline-flex items-center px-2 py-4 border-b-2 font-medium text-sm leading-5 cursor-pointer whitespace-nowrap disabled:opacity-50  ${
        isActive
          ? "border-indigo-500 text-indigo-600 focus:outline-none focus:text-indigo-800 focus:border-indigo-700"
          : "border-transparent text-gray-500 hover:text-gray-600 hover:border-gray-300 focus:text-gray-600 focus:border-gray-300"
      }`}
      style={new_Customization?.bodytext_color ? { color: new_Customization?.bodytext_color } : {}}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
