import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { getAppsList, useApps } from "api/appsApi";
import Spinner from "components/Spinner";
import AppList from "./AppList";
import qs from "qs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "state/store";
import { useCurrentUserApi } from "api/authApi";

import "react-quill/dist/quill.bubble.css";
import ReactQuill from "react-quill";

const Apps: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [tenantId, setTenantId] = useState("");
  const tenantid = searchParams.get("apiKey");
  const [page, setPage] = useState(0);
  const [endofList, setEndofList] = useState(false);
  const [items, setItems] = useState([]);
  const [catageory, setcatageory] = useState([]);
  const [selectedApp, setSelectedApp] = useState("");
  const navigate = useNavigate();
  const apiKey = useAppSelector((state) => state.auth.apiKey);
  const userId = useAppSelector((state) => state.auth.email);
  const appCategory = useAppSelector((state) => state.auth.currentUser.admin.appCategory);
  const activeApps = useAppSelector((state) => state.auth.currentUser.admin.activeApps);
  const [showState, setShowState] = useState(true);
  const customisation = useAppSelector((state) => state.auth.customisation);
  const new_Customization = useAppSelector((state) => state.auth.portalConfigration);
  const Card_Customization = useAppSelector((state) => state.auth.Cardcustomisation);
  const newcustomization = useAppSelector((state) => state.auth.customization);
  const url = window.location.href;
  const userIndex = url.indexOf("user=");
  var cc;
  if (userIndex !== -1) {
    const userEndIndex = url.indexOf("&", userIndex);
    if (userEndIndex !== -1) {
      cc = url.substring(userEndIndex + 1);
    } else {
      cc = "";
    }
  } else {
  }

  //   const {

  //     fontFamily,

  //     portalDescription,

  //     portalTitle,

  //     primary_bgColor,

  //     primary_bgColor_text,

  // } = new_Customization;

  const { data: apps, error: error } = useCurrentUserApi();

  const { data: app, error: appError } = useApps();
  useEffect(() => {
    // fetchData();
    setTenantId(tenantid);
  }, []);

  useEffect(() => {
    if (apps) {
      setcatageory(appCategory);
    }
  }, [apps]);

  useEffect(() => {
    if (app) {
      let newApps: any = [];
      // const res = await getAppsList(page + 1);
      if (app?.data?.items.length) {
        newApps = app?.data?.items?.filter((app) => {
          return activeApps?.some((item, index) => app.id === item);
        });
        // newApps = app?.data.items.filter((newApp) => {
        //   return activeApps?.some((existingApp) => existingApp === newApp.id);
        // });
        setPage(page + 1);
        setEndofList(true);
      } else {
        setEndofList(true);
      }
      setItems([...newApps]);
      if (newApps.length > 0) {
        setShowState(true);
      } else {
        setShowState(false);
      }
    }
  }, [app]);

  const bgColor = customisation.backgroundColor;
  const onChangeAppHandler = (event) => {
    // setSelectedApp(event.target.value);
    navigate(`/apps/${event.value}?apiKey=${apiKey}&user=${userId}`);
  };
  const renderApps = () => {
    return items?.map((app) => ({
      label: app.appName,
      value: app.id,
    }));
  };

  return (
    <div
      style={{
        background: newcustomization?.primary_colour,
        fontFamily: newcustomization?.font,
        color: newcustomization?.text_colour,
        height: "100vh",
      }}
      className={`   ${
        newcustomization?.primary_colour ? `bg-[${newcustomization?.primary_colour} ] w-full` : "bg-gray-100"
      } `}
    >
      {showState ? (
        <div
          className={`  flex flex-col ${
            newcustomization?.primary_colour ? `bg-[${newcustomization?.primary_colour}] ` : "bg-gray-100"
          } `}
        >
          <div
            className={`p-5 sticky w-full h-full  ${
              newcustomization?.primary_colour ? `bg-[${newcustomization?.primary_colour}] h-full` : "bg-gray-100"
            } flex flex-col z-[100] `}
            style={{
              background: newcustomization?.primary_colour,
              fontFamily: newcustomization?.font,
              color: newcustomization?.text_colour,
            }}
          >
            <span className="font-normal text-sm cursor-pointer leading-4">
              <span className="line-clamp-1">
                <ReactQuill
                  value={
                    newcustomization.title != "<p><br></p>" &&
                    newcustomization.title != "<h1><br></h1>" &&
                    newcustomization.title != "<h2><br></h2>" &&
                    newcustomization.title != "<h3><br></h3>" &&
                    newcustomization.title != "<h4><br></h4>" &&
                    newcustomization.title != "<h5><br></h5>" &&
                    newcustomization.title != "<h6><br></h6>" &&
                    newcustomization.title != "<p></p>" &&
                    newcustomization.title != "<h1></h1>" &&
                    newcustomization.title != "<h2></h2>" &&
                    newcustomization.title != "<h3></h3>" &&
                    newcustomization.title != "<h4></h4>" &&
                    newcustomization.title != "<h5></h5>" &&
                    newcustomization.title != "<h6></h6>"
                      ? newcustomization?.title
                      : "MarketPlace"
                  }
                  readOnly
                  theme="bubble"
                />
              </span>
            </span>

            <span className="text-2xl font-bold inline-block">
              <span>
                <ReactQuill
                  value={
                    newcustomization.description != "<p><br></p>" &&
                    newcustomization.description != "<h1><br></h1>" &&
                    newcustomization.description != "<h2><br></h2>" &&
                    newcustomization.description != "<h3><br></h3>" &&
                    newcustomization.description != "<h4><br></h4>" &&
                    newcustomization.description != "<h5><br></h5>" &&
                    newcustomization.description != "<h6><br></h6>" &&
                    newcustomization.description != "<p></p>" &&
                    newcustomization.description != "<h1></h1>" &&
                    newcustomization.description != "<h2></h2>" &&
                    newcustomization.description != "<h3></h3>" &&
                    newcustomization.description != "<h4></h4>" &&
                    newcustomization.description != "<h5></h5>" &&
                    newcustomization.description != "<h6></h6>"
                      ? newcustomization?.description
                      : "Get more out of your Konnectify product"
                  }
                  readOnly
                  theme="bubble"
                />
              </span>
            </span>
            <div className="relative mt-7 ">
              <div className="appearance-none cursor-pointer inline absolute left-4 top-4 bg-transparent border-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>

              <Select
                options={renderApps()}
                value={renderApps().filter(function (option) {
                  return option.value === selectedApp;
                })}
                onChange={onChangeAppHandler}
              />
            </div>
          </div>
          <div
            className="relative top-5 bg-gray-100 p-5"
            style={{
              background: newcustomization?.primary_colour,
              fontFamily: newcustomization?.font,
              color: newcustomization?.text_colour,
            }}
          >
            {catageory?.map((type) => (
              <>
                <div>
                  <div className="block mt-3">
                    <h2
                      className="inline-block text-xl h-full font-bold"
                      style={{
                        background: newcustomization?.primary_colour,
                        fontFamily: newcustomization?.font,
                        color: newcustomization?.text_colour,
                      }}
                    >
                      {type.label}
                    </h2>
                    {/* <button className="text-blue-600 text-xs font-normal hover:underline float-right cursor-pointer">
              View More
            </button> */}
                  </div>
                  <section
                    className={`flex ${
                      newcustomization?.primary_colour ? `bg-[${newcustomization?.primary_colour}]` : "bg-gray-100"
                    } items-start h-[calc(100vh-5rem)] h-auto`}
                  >
                    <div
                      className=" overflow-scroll-y mt-5 w-full flex flex-col justify-between h-full w-full bg-grey-100 "
                      style={{
                        background: newcustomization?.primary_colour,
                        fontFamily: newcustomization?.font,
                        color: newcustomization?.text_colour,
                      }}
                    >
                      <AppList apps={type.value} eof={endofList} isTrue={false} />
                    </div>
                  </section>
                </div>
              </>
            ))}
            <div>
              <div className="block mt-3">
                <h2
                  className="inline-block text-xl font-bold "
                  style={{
                    background: newcustomization?.primary_colour,
                    fontFamily: newcustomization?.font,
                    color: newcustomization?.text_colour,
                  }}
                >
                  {"All Apps"}
                </h2>
                {/* <button className="text-blue-600 text-xs font-normal hover:underline float-right cursor-pointer">
                    View More
                  </button> */}
              </div>
              <section
                className={`flex ${
                  newcustomization?.primary_colour ? `bg-[${newcustomization?.primary_colour}] h-full` : "bg-gray-100"
                } items-start  h-auto`}
              >
                <div className="mt-5 relative w-full flex flex-col justify-between h-full w-full">
                  <AppList apps={items} eof={endofList} isTrue={true} />
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex  overflow-scroll w-full h-[calc(100vh)] justify-center items-center flex-col bg-gray-100"
          style={{
            background: newcustomization?.primary_colour,
            fontFamily: newcustomization?.font,
            color: newcustomization?.text_colour,
          }}
        >
          <p className="mb-3">No Active Apps </p>
        </div>
      )}
    </div>
  );
};
export default Apps;
