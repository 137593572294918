import { Tab } from "@headlessui/react";
import Spinner from "components/Spinner";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "state/store";
import CredTab from "./CredTabs";
import { TabType } from "state/account/types";
import RuleConfig from "./RuleConfig";
import {
  saveConfigurationLoading,
  setTargeAppFailed,
  setTargetAppAndAccountLoading,
  setcurrenttabindex,
  updateConfigurationLoading,
} from "state/configurationv2/slices";
import Webhook from "./Webhook";
import WorkflowListing from "./WorkflowIntegration";
import { ToastContainer, toast } from "react-toastify";
import { ToastError } from "common/components/toast/Toast";

const ConfigurationItem: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  let [urlParams, setUrlParams] = useSearchParams();
  let apiKey = urlParams.get("apiKey");
  let user = urlParams.get("user");

  const { id } = useParams<any>();

  useEffect(() => {
    dispatch(setTargetAppAndAccountLoading({ id, apiKey, user }));
  }, [id]);

  const sourceAppValidated = useAppSelector((state) => state.configurationV2.data.sourceAppValidated);
  const targetAppValidated = useAppSelector((state) => state.configurationV2.data.targetAppValidated);
  const istargeAppFailed = useAppSelector((state) => state.configurationV2.data.istargeAppFailed);

  const sourceApp = useAppSelector((state) => state.configurationV2.data.sourceAppDetails);
  const apps = useAppSelector((state) => state.configurationV2.data.apps);

  const isUpdate = useAppSelector((state) => state.configurationV2.data.isConfigured);
  const indexTab = useAppSelector((state) => state.configurationV2.data.currenttabindex);

  const renderHeaderTabsList: any = () => {
    return apps.map((app, index) => {
      return app.id == "templates" ? (
        <Tab
          onClick={() => {
            dispatch(setcurrenttabindex(index));
          }}
          key={app.index}
          // key={app.index}
          className={({ selected }) =>
            classNames(
              `${
                selected ? "text-indigo-600 border-indigo-700" : "text-gray-500 border-transparent"
              } py-4  px-2 mr-8 border-b-2`,
            )
          }
        >
          Workflows
        </Tab>
      ) : (
        <Tab
          onClick={() => {
            dispatch(setcurrenttabindex(index));
          }}
          key={app.index}
          className={({ selected }) =>
            classNames(
              `${
                selected ? "text-indigo-600 border-indigo-700" : "text-gray-500 border-transparent"
              } py-4  px-2 mr-8 border-b-2`,
            )
          }
        >
          {app.appName}
        </Tab>
      );
    });
  };
  const renderTabPanelItems = (tabType: TabType, tabItem: any) => {
    if (tabItem.id == "templates") {
      tabType = TabType.Templates;
    }
    if (tabItem.id !== "templates") {
      tabType = TabType.Creds;
    }
    if (tabItem.id == sourceApp.id) {
      var isSource = true;
    }
    switch (tabType) {
      case TabType.Webhook:
        return <Webhook appDetails={tabItem} />;
      case TabType.Creds:
        return (
          <>
            <CredTab appDetails={tabItem} type={isSource ? "source" : "target"} />
          </>
        );
      case TabType.RuleConfig:
        return <RuleConfig ruleConfig={tabItem} />;
      case TabType.Templates:
        return <WorkflowListing />;
    }
  };

  const saveAppConfig = () => {
    if (sourceAppValidated && targetAppValidated) {
      // dispatch(nexttab(indexTab));
      if (isUpdate) {
        // dispatch(nexttab(indexTab));
        dispatch(updateConfigurationLoading());
        toast.success("Configuration has been saved successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        dispatch(saveConfigurationLoading());

        toast.success("Configuration has been saved successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  };

  const renderHeaderTabsPanels = () => {
    return apps.map((app, index) => {
      return (
        <Tab.Panel tabIndex={index} key={app.index} className={() => classNames(`h-full`)}>
          {renderTabPanelItems(app.tabType, app)}
        </Tab.Panel>
      );
    });
  };

  useEffect(() => {
    if (istargeAppFailed) {
      ToastError("Account expired, Please Reauthorize");
      // dispatch(setTargeAppFailed(false));
    }
  }, [istargeAppFailed]);

  if (apps?.length === 0) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  return (
    <>
      {apps.length && (
        <>
          <div className="relative h-full">
            <ToastContainer />
            <Tab.Group selectedIndex={indexTab}>
              <div className="sticky top-0 pb-3 z-50 bg-white ">{renderHeaderTabsList()}</div>
              <div className=" h-full">{renderHeaderTabsPanels()}</div>
            </Tab.Group>
            <div className="w-full flex justify-end items-end">
              <div>
                <button
                  onClick={saveAppConfig}
                  className="w-auto text-white mr-4 hover:bg-[#0e1a2e] bg-komp-secondary-color hover:transition-all focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 text-center disabled:opacity-45 bottom-0 right-0"
                >
                  Save Configuration
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ConfigurationItem;
