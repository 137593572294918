import axios from "axios";

export const options = { headers: { "content-type": "application/json" } };

export function setTenantId(tenantId) {

  axiosInstance.interceptors.request.use((config) => {
    config.headers["tenantId"] = tenantId;
    // config.headers["x-api-key"] = apiKey.api;
    return config;
  });
}

export function setApikey(apiKey) {
  authRequest.interceptors.request.use((config) => {
    config.headers["tenantId"] = apiKey.api;
    config.headers["x-api-key"] = apiKey.api;
    config.headers["user"] = apiKey.user;
    return config;
  });
}

export const axiosInstance = axios.create({
  baseURL: "/api",
});

export const authRequest = axios.create({
  baseURL: "/auth",
});

export const planaxiosInstance = axios.create({
  baseURL: "/billing",
});

export const fetcher = (...args) => axiosInstance.get(...args).then((res) => res.data);
