import useSWR from "swr";
import { axiosInstance, fetcher, options, setTenantId } from "./libs/fetch";
import { AppSchemaType } from "pages/AppDetails/types";

const APP_ACCOUNT_PAGE_LIMIT = 20;
const ACCOUNT_PAGE_LIMIT = 16;

// export function useAccounts(pageNumber = 1) {
//   return useSWR([`/connection-config?pageNumber=${pageNumber}&pageSize=${ACCOUNT_PAGE_LIMIT}`], fetcher);
// }

export function useAccounts(id) {
  return useSWR([`/endusers/connection-config/${id}`], fetcher);
}

// export function fetchAccount(accountId) {
//   return axiosInstance.get(`/connection-config/${accountId}`);
// }

export function createAccountApi(data) {
  return axiosInstance.post(`/endusers/connection-config/${data.appId}`, data, options);
}

export async function createAccountApiv2(data) {
  return await axiosInstance.post(
    `/endusers/connection-config/v2/${data.payload.appId}`,

    data.payload.accountDetails,
    options,
  );
}

export async function saveAppConfigv2(data) {
  return await axiosInstance.post(`/endusers/connection-config/v2/app-config/${data.targetAppId}`, data, options);
}

export async function getAppConfigv2(data) {
  return await axiosInstance.post(`/endusers/connection-config/v2/get-app-config/${data.targetAppId}`, data, options);
}

export async function updateAppConfigv2(data) {
  return await axiosInstance.put(`/endusers/connection-config/v2/update-app-config/${data.id}`, data, options);
}

export function updateAccountApi(accountId, data) {
  return axiosInstance.put(`/endusers/connection-config/${data.appId}/${accountId}`, data.accountDetails, options);
}

export function updateAccountApiv2(accountId, data) {
  return axiosInstance.put(`/endusers/connection-config/v2/${data.appId}/${accountId}`, data.accountDetails, options);
}

export function validateAccountApi(data) {
  return axiosInstance.post(`/connection-config/validate/${data.appId}`, data, options);
}

export function getConnectionConfigApi(id) {
  return axiosInstance.get(`/endusers/connection-config/${id}`, options);
}

export function getFieldMapping(payload) {
  return axiosInstance.post(`/endusers/${payload.appId}/app-schema`, payload.data, options);
}
export function fetchAppSchemaItemApi(data) {
  return axiosInstance.post(`/endusers/${data.appId}/app-schema`, data.data, options);
}

export function fetchAppSchemaApi(payload) {
  let schema = [AppSchemaType.Input, AppSchemaType.Output];
  return Promise.all(
    schema.map((i) =>
      fetchAppSchemaItemApi({
        appId: payload.appId,
        data: {
          schemaType: i,
          eventId: payload.eventId,
          connectionConfigId: payload.connectionConfigId,
          eventData: payload.actionInputFields,
        },
      }),
    ),
  );
}
