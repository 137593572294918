import { TemplateRootObject } from "interfaces/modules/template";
import {
  ActionMode,
  AppRole,
  ConfigurationType,
  DataMappingObject,
  InitialState,
  TemplateAppState,
  KonnectorType,
  StatusType,
} from "state/edit-template-settings/types";
import { v4 as uuidv4 } from "uuid";

export const deserializeTempateToEditTemplateState = (
  template: TemplateRootObject,
  appsDetails,
  appsDynamicFields,
  type
): InitialState => {
  let triggerAppDetails,
    outputFields,
    outputFieldsSchema,
    triggerData,
    triggerInputSchema,
    dynamicInputFields,
    actionInputFields;
  const inputFields = getTargetInputFields(appsDynamicFields, "targetInput");
  const actionAppsDetails = appsDetails.slice(1);
  switch (template.data.konnectorType) {
    case KonnectorType.WebHook:
    case KonnectorType.Form:
      outputFields = appsDynamicFields["sourceSample"]?.fields;
      outputFieldsSchema = appsDynamicFields["sourceSample"]?.schema;
      triggerData = deserializeTriggerFromWebhookKonnector(
        template,
        template.data.konnectorType,
        outputFields,
        outputFieldsSchema
      );

      break;
    default:
      actionInputFields = template.data.actionItems.slice(-1)[0].dynamicInputFields || null;
      triggerAppDetails = appsDetails[0]?.data?.data || "";
      outputFields = appsDynamicFields["sourceSample"];
      triggerInputSchema = appsDynamicFields["sourceInput"] || null;
      outputFieldsSchema = appsDynamicFields["sourceOutput"];
      dynamicInputFields = getTargetInputFields(appsDynamicFields, "targetDynamicInput");
      triggerData = deserializeTriggerFromKonnector(
        template,
        triggerAppDetails,
        outputFields,
        outputFieldsSchema,
        triggerInputSchema
      );
      break;
  }
  return {
    data: {
      id: template.data.id,
      name: template.data.name,
      konnectorType: template.data.konnectorType || KonnectorType.Regular,
      webhookUrl: { url: template.data.webhookUrl?.url || "", id: template.data.webhookUrl?.id || "" },
      trigger: triggerData,
      lookups: deserializeLookupsFromKonnector(template, appsDetails, outputFields, appsDynamicFields),
      actions: deserializeActionsFromKonnector(
        template,
        actionAppsDetails,
        inputFields,
        dynamicInputFields,
        actionInputFields
      ),
      ruleEngine: template.data.ruleConfig ? template.data.ruleConfig : [],
      parseEngine: template.data.parseConfig ? template.data.parseConfig : [],
      sourceTemplate: template.data.konnectorSource,
      workflowDescription: template.data.workflowDescription,
      workflowInstruction: template.data.workflowInstruction,
      workflowAppId: type === "template" ? template.data.workflowAppId : template.data.konnectorAppId,
    },
    hasConfigurationSaved: true,
    status: StatusType.idle,
    created: true,
    generateUrl: StatusType.success,
    actionMode: ActionMode.View,
    currentActionId: "",
    configurationType: ConfigurationType.Source,
    errorMessage: "",
  };
};

const deserializeTriggerFromWebhookKonnector = (
  konnector: TemplateRootObject,
  konnectorType: KonnectorType,
  outputFields,
  outputFieldsSchema
): TemplateAppState<AppRole.Source> => {
  return {
    id: konnector.data.id,
    triggerType: konnectorType,
    appSelectorType: AppRole.Source,
    actionMode: ActionMode.View,
    appName: "generic",
    // formFields: konnector.data.formFields?.length > 0 ? deserializeFormFields(konnector.data.formFields) : [],
    // formSettings: konnector.data.formSettings,
    connectionName: konnector.data.konnectorApps[0].connectionId,
    actionOrderId: konnector.data.konnectorApps[0].actionOrderId,
    appSelectorKey: konnector.data.konnectorApps[0].appSelectorKey,
    iconUrl: "",
    appId: konnector.data.konnectorApps[0].appId,
    eventId: konnector.data.konnectorApps[0].appEventId,
    connectionId: konnector.data.konnectorApps[0].connectionId,
    isAccountValidated: true,
    appDetails: {

    },
    outputFields: {
      hasData: true,
      fields: outputFields,
      schema: outputFieldsSchema,
    },
  };
};


//Lookup
const deserializeLookupsFromKonnector = (
  template: TemplateRootObject,
  appsDetails,
  inputFields,
  outputFieldsSchema
): TemplateAppState<AppRole.Lookup>[] => {
  if (template.data.konnectorApps[1]?.appSelectorType !== "lookup") {
    return [];
  }
  const lookup = template.data.konnectorApps.filter((lookup) => lookup?.appSelectorType === "lookup");
  return lookup.map((app, index) => {
    const appDetails = appsDetails[index + 1].data.data;
    const dataSourceProperties: any = template.data.actionItems.find(
      (lookup) => lookup.actionOrderId === app.actionOrderId
    ).eventProps;
    const rawDataMapping = deserializeDataMapping(dataSourceProperties);
    return {
      id: uuidv4(),
      actionOrderId: app.actionOrderId,
      appSelectorType: AppRole.Lookup,
      appName: appDetails?.appName,
      connectionName: app.connectionId,
      appSelectorKey: app.appSelectorKey,
      iconUrl: appDetails?.iconUrl,
      appId: app.appId,
      eventId: app.appEventId,
      actionMode: ActionMode.View,
      connectionId: app.connectionId,
      isAccountValidated: true,
      appDetails: appDetails,
      inputFields: {
        sourceId: "",
        eventId: "",
        appId: "string",
        data: [
          {
            propName: dataSourceProperties[0].propName,
            propType: "string",
            isRequired: true,
          },
        ],
      },
      outputFields: {
        hasData: true,
        schema: outputFieldsSchema[`lookupOutput_${index}`],
      },
      rawDataMapping: rawDataMapping,
      dataMapping: dataSourceProperties,
    };
  });
};

//Trigger
const deserializeTriggerFromKonnector = (
  template: TemplateRootObject,
  appDetails,
  outputFields,
  outputFieldsSchema,
  triggerInputSchema
): TemplateAppState<AppRole.Source> => {
  return {
    id: template.data.id,
    triggerType: getEventSourceType(template.data.konnectorApps[0].appEventId, appDetails),
    appSelectorType: AppRole.Source,
    actionMode: ActionMode.View,
    appName: appDetails?.appName,
    connectionName: template.data.konnectorApps[0].connectionId,
    actionOrderId: template.data.konnectorApps[0].actionOrderId,
    appSelectorKey: template.data.konnectorApps[0].appSelectorKey,
    iconUrl: appDetails?.iconUrl,
    appId: template.data.konnectorApps[0].appId,
    eventId: template.data.konnectorApps[0].appEventId,
    connectionId: template.data.konnectorApps[0].connectionId,
    isAccountValidated: true,
    appDetails: appDetails,
    inputFields: {
      hasData: triggerInputSchema ? true : false,
      dataMapping: triggerInputSchema ? template.data.triggerDefinition.inputFields : {},
      data: triggerInputSchema?.data || [],
      appId: template.data.konnectorApps[0].appId,
      eventId: "",
      sourceId: "",
    },
    outputFields: {
      hasData: true,
      fields: outputFields,
      schema: outputFieldsSchema,
    },
  };
};

export const deserializeDataMapping = (targetActionProperties) => {
  const rawDataMapping = {};
  targetActionProperties.forEach((field: DataMappingObject) => {
    rawDataMapping[field.propName + "__field_type"] = field.staticValue ? "fixed" : "source";
    rawDataMapping[field.propName + "__field_source_type"] = field.source || "";
    rawDataMapping[field.propName] = field.sourceProp;
  });
  return rawDataMapping;
};

//actions
const deserializeActionsFromKonnector = (
  template: TemplateRootObject,
  appsDetails,
  inputFields,
  dynamicInputFields,
  actionInputFields
): TemplateAppState<AppRole.Target>[] => {
  const konnectorApps = template.data.konnectorApps;
  const actionKonnectorApps = konnectorApps
    .map((konn, index) => ({
      konn,
      index,
    }))
    .filter((konnIndex) => konnIndex.konn.appSelectorType === "target");
  return actionKonnectorApps.map((app, index) => {
    const appDetails = appsDetails[app.index - 1]?.data?.data;
    const appInputFields = inputFields[`targetInput_${index}`];
    const appDynamicFields = dynamicInputFields ? dynamicInputFields[`targetDynamicInput_${index}`] : null;
    const actionsProperties: any = template.data.targetActionProperties[index].actionProperties;
    const rawDataMapping = deserializeDataMapping(actionsProperties);
    return {
      id: uuidv4(),
      appSelectorType: AppRole.Target,
      actionOrderId: app.konn.actionOrderId,
      actionMode: ActionMode.View,
      appName: appDetails?.appName,
      connectionName: app.konn.connectionId,
      appSelectorKey: app.konn.appSelectorKey,
      iconUrl: appDetails?.iconUrl,
      appId: app.konn.appId,
      eventId: app.konn.appEventId,
      connectionId: app.konn.connectionId,
      isAccountValidated: true,
      appDetails: appDetails,
      inputFields: appInputFields,
      dynamicInputFields: {
        data: appDynamicFields?.data || [],
        dataMapping: actionInputFields || [],
        hasData: actionInputFields ? true : false,
        hasDynamicInputFields: actionInputFields ? true : false,
      },
      rawDataMapping: rawDataMapping,
      dataMapping: actionsProperties,
    };
  });
};

const getTargetInputFields = (appsDynamicFields: any, type: string) => {
  let targetFields = {};
  for (let i in appsDynamicFields) {
    if (i.includes(type)) {
      targetFields[i] = appsDynamicFields[i];
    }
  }
  return targetFields;
};

const getEventSourceType = (eventId, appDetails) => {
  return appDetails?.triggers?.find((trigger) => trigger?.id === eventId)?.eventSource;
};


const setFilterData = (ruleConfig) => {
  return ruleConfig.filters.map((filter) => {
    return { name: filter.name, match_type: filter.match_type, conditions: setConditionData(filter) };
  });
};
const setConditionData = (filter) => {
  return filter.conditions.map((condition) => {
    return {
      data_type: condition.data_type,
      field_type: condition.field_type,
      field_name: condition.field_name,
      operator: condition.operator,
      value: condition.value,
      name: condition.name,
    };
  });
};

export const setRawDataMapping = (state) => {
  const data = JSON.parse(JSON.stringify(state));
  data.rawDataMapping = {};
  const filterLen = data.filters.length;
  for (let i = 0; i < filterLen; i++) {
    const conditionLen = state.filters[i].conditions.length;
    for (let j = 0; j < conditionLen; j++) {
      data.rawDataMapping[`filter_${i}_condition_${j}___field_source_type`] = data.filters[i].conditions[j].field_type;
      data.rawDataMapping[`filter_${i}_condition_${j}___field_source_field`] = data.filters[i].conditions[j].field_name;
      data.rawDataMapping[`filter_${i}_condition_${j}___field_operator_type`] = data.filters[i].conditions[j].operator;
      data.rawDataMapping[`filter_${i}_condition_${j}___field_value`] = data.filters[i].conditions[j].value;
    }
    data.rawDataMapping[`filter_${i}___match_type`] = data.filters[i].match_type;
  }
  return data.rawDataMapping;
};