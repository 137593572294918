import { deserializeDataMapping, serializeDataMapping } from "./appsUtils";
import { FormFieldDataVm, KonnectorRootObject, SelectOptionVm } from "interfaces/modules/konnectors/index";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import {
  RootObject,
  KonnectorApp,
  TriggerDefinition,
  TargetActionData,
  ActionItem,
  ActionItemDataType,
  LookupDataType,
  ActionType,
  RuleDataType,
  ActionDataType,
  ParseDataType,
  FormFields,
} from "interfaces/pages/create-new-konnector";
import {
  InitialState,
  KonnectorAppState,
  AppRole,
  StatusType,
  ActionMode,
  ConfigurationType,
  KonnectorType,
  NewKonnectorState,
} from "state/create-konnector/types";
import { AppsDetails, TabType } from "state/account/types";
import { setRuleConfig } from "./ruleEngineUtils";
import { takeEvery, put, call, all, select, delay } from "redux-saga/effects";
import { useAppSelector } from "@/state/store";
export const serializeCreateKonnectorData = (
  state: InitialState,
  opt: InitialState,
  tabItem: AppsDetails[],
  showWebhook: any,
  webhookid: any
): RootObject => {
  try {
    const res = {
      name: opt.data.name,
      description: "",
      iconUrl: "",
      status: "inactive",
      konnectorSource: opt.data.sourceTemplate ? opt.data.sourceTemplate : "Konnectify",
      konnectorType: tabItem[0].eventId == "webhook" ? KonnectorType.WebHook : KonnectorType.Regular,
      webhookUrl: { url: showWebhook, id: webhookid },
      konnectorApps: formatDataToKonnectorApp(opt, tabItem),
      ruleConfig: opt.data?.ruleEngine,
      parseConfig: opt.data?.parseEngine,
      formFields: [],
      formSettings: {},
      targetActionData: formatDatatoTargetActionData(tabItem),
      actionItems: formDatatoActionItemsTest(tabItem, opt.data),
      triggerDefinition: formatDataToTriggerDefinition(tabItem, opt.data.trigger),
      workflowDescription: opt.data.workflowDescription,
      workflowInstruction: opt.data.workflowInstruction,
      konnectorAppId: opt.data.workflowAppId,
      adminRequiredData: opt.data.actions[0].dataMapping,
      apiKey: state.apiKey,
      userId: state.userId,
      workflowId: opt.data.id,
    };
    return res;
  } catch (err) {}
};

// export const serializeUpdateKonnectorData = (state: InitialState): RootObject => {
//   return {
//     name: state.data.name,
//     description: "",
//     iconUrl: "",
//     status: "inactive",
//     konnectorSource: state.data.sourceTemplate ? state.data.sourceTemplate : "Konnectify",
//     konnectorType: state.data.konnectorType,
//     webhookUrl: { url: state.data.webhookUrl.url, id: state.data.webhookUrl.id },
//     konnectorApps: formatDataToKonnectorApp(state),
//     ruleConfig: {},
//     parseConfig: {},
//     formFields: state.data.trigger.formFields?.length > 0 ? [] : [],
//     formSettings: state.data.trigger.formSettings,
//     targetActionData: formatDatatoTargetActionData(state.data.actions),
//     actionItems: formDatatoActionItemsTests(state.data),
//     triggerDefinition: formatDataToTriggerDefinition(state.data.trigger),
//     workflowDescription: state.data.workflowDescription,
//     workflowInstruction: state.data.workflowInstruction,
//     konnectorAppId: state.data.trigger.appId,
//   };
// };

export const formDatatoActionItemsTest = (
  tabItem: AppsDetails[],
  opt: NewKonnectorState
): ActionItem<ActionItemDataType>[] => {
  let actionItems: ActionItem<ActionItemDataType>[] = [];
  const lookupItem = seraializeLookupDataActionItem(opt.lookups);
  const ruleConfig = seraializeRuleConfigDataActionItem(opt.ruleEngine);
  const parseConfig = seraializeParseConfigDataActionItem(opt.parseEngine);
  let actionCred = tabItem.find((tab) => tab.appSelectorType === "target" && tab.tabType === TabType.Creds);
  let actionTab = tabItem.filter((tab) => tab.tabType === TabType.Action);
  let connectionId = actionCred.connectionConfig.id;
  const actionItem = seraializeActionDataActionItem(actionCred, actionTab, opt.actions, connectionId);
  actionItems = [...lookupItem, ...ruleConfig, ...parseConfig, ...actionItem];
  actionItems.sort((a, b) => {
    return a.actionOrderId - b.actionOrderId;
  });
  return actionItems;
};
const seraializeRuleConfigDataActionItem = (ruleEngine): ActionItem<RuleDataType>[] => {
  return ruleEngine.map((ruleData): ActionItem<RuleDataType> => {
    return {
      actionOrderId: ruleData.actionOrderId,
      actionType: ActionType.RuleConfig,
      data: {
        name: "",
        operator: ruleData.operator,
        filters: ruleData.filters,
      },
    };
  });
};

const seraializeParseConfigDataActionItem = (parseEngine): ActionItem<ParseDataType>[] => {
  return parseEngine.map((parseData): ActionItem<ParseDataType> => {
    return {
      actionOrderId: parseData.actionOrderId,
      actionType: ActionType.ParseConfig,
      data: {
        id: parseData.id,
        source_value: parseData.source_value,
        pattern_type: parseData.pattern_type,
        patterns: parseData.patterns,
        conditions: !parseData.conditions ? [] : parseData.conditions,
        outputSchema: parseData.outputSchema,
        source_id: parseData.source_id,
      },
    };
  });
};

const setParseItem = (parsedData): any => {
  return parsedData.parseConfigration.parsingItems.map((name) => name.name);
};

export const formatDataToKonnectorApp = (state: InitialState, tabItem: AppsDetails[]): KonnectorApp[] => {
  const data = tabItem.filter((item) => item.tabType === TabType.Creds || item.tabType === TabType.Webhook);
  return data.map((app): KonnectorApp => {
    return {
      actionOrderId: app.actionOrderId,
      appSelectorType: app.appSelectorType,
      appSelectorKey: app.appSelectorType,
      appId: app.appId,
      appEventId: app.eventId,
      appDetail: app.data,
      connectionId: app.connectionConfig?.id,
      connectionName: app.connectionConfig?.connectionName,
    };
  });
};
const formatDataToTriggerDefinition = (
  tabItem: AppsDetails[],
  opt: KonnectorAppState<AppRole.Source>
): TriggerDefinition => {
  let trigger = tabItem[0];
  return {
    appId: opt?.appId,
    eventId: opt?.eventId,
    // appId: trigger.appId == "generic"?trigger.appId: opt?.appId,
    // eventId:trigger.appId == "generic"?trigger.eventId: opt?.eventId,
    triggerType: opt?.triggerType,
    triggerProps: getTriggerProps(opt?.triggerType),
    inputFields: trigger?.dynamicFields?.dataMapping,
    connectionId: trigger?.connectionConfig?.id,
  };
};
const formatDatatoTargetActionData = (tabItem: AppsDetails[]): TargetActionData[] => {
  let actions = tabItem.filter((tab) => tab.tabType === TabType.Action);
  return actions.map((action): TargetActionData => {
    const inputFields = action.outputSchema;
    return {
      sourceId: inputFields.sourceId,
      appEventId: inputFields.eventId,
      appId: inputFields.appId,
      inputSchema: inputFields.data,
    };
  });
};

const getTriggerProps = (triggerType) => {
  if (triggerType === "webhook") {
    return {
      subscriptionType: "auto",
    };
  }
  return {
    recurringType: "minutely",
    minute: 5,
  };
};

// Edit Konnector
export const deserializeKonnectorToEditKonnectorState = (
  konnector: KonnectorRootObject,
  appsDetails,
  appsDynamicFields
): InitialState => {
  let triggerAppDetails,
    outputFields,
    outputFieldsSchema,
    triggerData,
    triggerInputSchema,
    dynamicInputFields,
    actionInputFields;
  const inputFields = getTargetInputFields(appsDynamicFields, "targetOutput");
  const actionAppsDetails = appsDetails.slice(1);
  switch (konnector.data.konnectorType) {
    case KonnectorType.WebHook:
    case KonnectorType.Form:
      // outputFields = appsDynamicFields["sourceSample"].fields;
      // outputFieldsSchema = appsDynamicFields["sourceSample"].schema;
      // triggerData = deserializeTriggerFromWebhookKonnector(
      //   konnector,
      //   konnector.data.konnectorType,
      //   outputFields,
      //   outputFieldsSchema
      // );
      break;
    default:
      actionInputFields = konnector.data.actionItems.slice(-1)[0].dynamicInputFields || null;
      triggerAppDetails = appsDetails[0].data.data;
      outputFields = appsDynamicFields["sourceSample"];
      triggerInputSchema = appsDynamicFields["sourceInput"] || null;
      outputFieldsSchema = appsDynamicFields["sourceOutput"];
      dynamicInputFields = getTargetInputFields(appsDynamicFields, "targetDynamicInput");
      triggerData = deserializeTriggerFromKonnector(
        konnector,
        triggerAppDetails,
        outputFields,
        outputFieldsSchema,
        triggerInputSchema
      );
      break;
  }
  return {
    data: {
      id: konnector.data.id,
      name: konnector.data.name,
      konnectorType: konnector.data.konnectorType || KonnectorType.Regular,
      webhookUrl: { url: konnector.data.webhookUrl?.url || "", id: konnector.data.webhookUrl?.id || "" },
      trigger: triggerData,
      lookups: deserializeLookupsFromKonnector(konnector, appsDetails, outputFields, appsDynamicFields),
      actions: deserializeActionsFromKonnector(
        konnector,
        actionAppsDetails,
        inputFields,
        dynamicInputFields,
        actionInputFields
      ),
      ruleEngine: konnector.data.ruleConfig ? konnector.data.ruleConfig : [],
      parseEngine: konnector.data.parseConfig ? [] : [],
      sourceTemplate: konnector.data.konnectorSource,
      workflowDescription: konnector.data.workflowDescription,
      workflowInstruction: konnector.data.workflowInstruction,
      adminRequiredData: konnector.data.adminRequiredData,
    },
    hasConfigurationSaved: true,
    status: StatusType.idle,
    created: true,
    generateUrl: StatusType.success,
    actionMode: ActionMode.View,
    currentActionId: "",
    configurationType: ConfigurationType.Source,
    errorMessage: "",
    konnectorAppId: "",
  };
};
const getEventSourceType = (eventId, appDetails) => {
  return appDetails.triggers?.find((trigger) => trigger.id === eventId)?.eventSource;
};

const getTargetInputFields = (appsDynamicFields: any, type: string) => {
  let targetFields = {};
  for (let i in appsDynamicFields) {
    if (i.includes(type)) {
      targetFields[i] = appsDynamicFields[i];
    }
  }
  return targetFields;
};

const deserializeTriggerFromKonnector = (
  konnector: KonnectorRootObject,
  appDetails,
  outputFields,
  outputFieldsSchema,
  triggerInputSchema
): KonnectorAppState<AppRole.Source> => {
  return {
    id: konnector.data.id,
    triggerType: getEventSourceType(konnector.data.konnectorApps[0].appEventId, appDetails),
    appSelectorType: AppRole.Source,
    actionMode: ActionMode.View,
    appName: appDetails?.appName,
    connectionName: konnector.data.konnectorApps[0].connectionId,
    actionOrderId: konnector.data.konnectorApps[0].actionOrderId,
    appSelectorKey: konnector.data.konnectorApps[0].appSelectorKey,
    iconUrl: appDetails?.iconUrl,
    appId: konnector.data.konnectorApps[0].appId,
    eventId: konnector.data.konnectorApps[0].appEventId,
    connectionId: konnector.data.triggerDefinition.connectionId,
    isAccountValidated: true,
    appDetails: appDetails,
    inputFields: {
      hasData: triggerInputSchema ? true : false,
      dataMapping: triggerInputSchema ? konnector.data.triggerDefinition.inputFields : {},
      data: triggerInputSchema?.data || [],
      appId: konnector.data.konnectorApps[0].appId,
      eventId: "",
      sourceId: "",
    },
    outputFields: {
      hasData: true,
      fields: outputFields,
      schema: outputFieldsSchema,
    },
  };
};

const deserializeActionsFromKonnector = (
  konnector: KonnectorRootObject,
  appsDetails,
  inputFields,
  dynamicInputFields,
  actionInputFields
): KonnectorAppState<AppRole.Target>[] => {
  const konnectorApps = konnector.data.konnectorApps;
  const actionKonnectorApps = konnectorApps
    .map((konn, index) => ({
      konn,
      index,
    }))
    .filter((konnIndex) => konnIndex.konn.appSelectorType === "target");
  return actionKonnectorApps.map((app, index) => {
    const appDetails = appsDetails[app.index - 1]?.data?.data;
    const appInputFields = inputFields[`targetOutput_${index}`];
    const appDynamicFields = dynamicInputFields ? dynamicInputFields[`targetDynamicInput_${index}`] : null;
    const actionsProperties: any = konnector.data.targetActionProperties[index].actionProperties;
    const rawDataMapping = deserializeDataMapping(actionsProperties);
    return {
      id: uuidv4(),
      appSelectorType: AppRole.Target,
      actionOrderId: app.konn.actionOrderId,
      actionMode: ActionMode.View,
      appName: appDetails?.appName,
      connectionName: app.konn.connectionId,
      appSelectorKey: app.konn.appSelectorKey,
      iconUrl: appDetails?.iconUrl,
      appId: app.konn.appId,
      eventId: app.konn.appEventId,
      connectionId: konnector.data.actionItems[0].connectionId,
      isAccountValidated: true,
      appDetails: appDetails,
      inputFields: appInputFields,
      dynamicInputFields: {
        data: appDynamicFields?.data || [],
        dataMapping: actionInputFields || [],
        hasData: actionInputFields ? true : false,
        hasDynamicInputFields: actionInputFields ? true : false,
      },
      rawDataMapping: rawDataMapping,
      dataMapping: actionsProperties,
    };
  });
};

const deserializeLookupsFromKonnector = (
  konnector: KonnectorRootObject,
  appsDetails,
  inputFields,
  outputFieldsSchema
): KonnectorAppState<AppRole.Lookup>[] => {
  if (konnector.data.konnectorApps[1].appSelectorType !== "lookup") {
    return [];
  }
  const lookup = konnector.data.konnectorApps.filter((lookup) => lookup.appSelectorType === "lookup");
  return lookup.map((app, index) => {
    const appDetails = appsDetails[index + 1].data.data;
    const dataSourceProperties: any = konnector.data.actionItems.find(
      (lookup) => lookup.actionOrderId === app.actionOrderId
    ).eventProps;
    const rawDataMapping = deserializeDataMapping(dataSourceProperties);
    return {
      id: uuidv4(),
      actionOrderId: app?.actionOrderId,
      appSelectorType: AppRole?.Lookup,
      appName: appDetails?.appName,
      connectionName: app?.connectionId,
      appSelectorKey: app?.appSelectorKey,
      iconUrl: appDetails?.iconUrl,
      appId: app?.appId,
      eventId: app?.appEventId,
      actionMode: ActionMode?.View,
      connectionId: app?.connectionId,
      isAccountValidated: true,
      appDetails: appDetails,
      inputFields: {
        sourceId: "",
        eventId: "",
        appId: "string",
        data: [
          {
            propName: dataSourceProperties[0].propName,
            propType: "string",
            isRequired: true,
          },
        ],
      },
      outputFields: {
        hasData: true,
        schema: outputFieldsSchema[`lookupOutput_${index}`],
      },
      rawDataMapping: rawDataMapping,
      dataMapping: dataSourceProperties,
    };
  });
};

export const flatSourceOutputFields = (outputFields): any[] => {
  const getMembers = (member) => {
    if (!member.propChildren || !member.propChildren.length) {
      return member;
    }
    return [member, _.flatMapDeep(member.propChildren, getMembers)];
  };
  return _.flatMapDeep(outputFields, getMembers);
};

const formatDataToRuleConfig = (ruleEngine) => {
  const ruleConfig = ruleEngine.map((ruleData) => {
    const setRuleConfigData = setRuleConfig(ruleData, "");
    return {
      name: "",
      operator: setRuleConfigData.operator,
      filters: setRuleConfigData.filters,
    };
  });
  return ruleConfig;
};

//ActionItems
export const setActionOrderId = (lookups, ruleConfig, parseConfig, actions?) => {
  let actionOrderId = 0;
  if (lookups.length === 0 && ruleConfig.length === 0 && parseConfig.length === 0) {
    actionOrderId = 1;
    return actionOrderId;
  } else {
    if (actions) {
      actionOrderId = ruleConfig.length + lookups.length + parseConfig.length + actions.length;
      return actionOrderId;
    }
    actionOrderId = ruleConfig.length + parseConfig.length + lookups.length;
    return actionOrderId;
  }
};

const seraializeLookupDataActionItem = (lookups): ActionItem<LookupDataType>[] => {
  return lookups.map((lookup): ActionItem<LookupDataType> => {
    const schema = lookup.outputFields.schema;

    const eventProps = lookup.dataMapping;
    return {
      actionOrderId: lookup.actionOrderId,
      actionType: ActionType.Lookup,
      data: {
        sourceId: `${lookup.appId}:` + lookup.eventId,
        appEventId: lookup.eventId,
        appId: lookup.appId,
        actionOrderId: lookup.actionOrderId,
        outputSchema: schema,
        eventProps,
      },
    };
  });
};

const seraializeActionDataActionItem = (
  actionCred: any,
  actions: AppsDetails[],
  opt: KonnectorAppState<AppRole.Target>[],
  connectionId: string
): ActionItem<ActionDataType>[] => {
  return actions.map((action): any => {
    let actionDataMapping = serializeDataMapping(action.outputSchema.data, action.rawDataMapping, "");

    return {
      actionOrderId: action.actionOrderId,
      actionType: ActionType.Action,
      data: {
        appId: action.appId,
        actionOrderId: action.actionOrderId,
        appEventId: action.eventId,
        actionProperties: [...actionDataMapping],
        connectionId: connectionId,
        dynamicInputFields: actionCred.dynamicFields.dataMapping,
      },
    };
  });
};

const seraializeActionDataActionItems = (
  actions: KonnectorAppState<AppRole.Target>[],
  opt: KonnectorAppState<AppRole.Target>[]
): ActionItem<ActionDataType>[] => {
  return actions.map((action): ActionItem<ActionDataType> => {
    const { dataMapping } = action;
    return {
      actionOrderId: opt[0].actionOrderId,
      actionType: ActionType.Action,
      data: {
        appId: opt[0].appId,
        actionOrderId: opt[0].actionOrderId,
        appEventId: opt[0].eventId,
        actionProperties: [...dataMapping],
        connectionId: opt[0].connectionId,
        dynamicInputFields: opt[0].dynamicInputFields?.dataMapping || {},
      },
    };
  });
};

export const setTriggerData = (konnectorType: KonnectorType, triggerData: KonnectorAppState<AppRole.Source> | any) => {
  switch (konnectorType) {
    case KonnectorType.WebHook:
      triggerData = {
        ...triggerData,
        appId: "generic",
        eventId: "webhook",
        iconUrl: "",
        appName: "generic",
        connectionId: "generic",
        connectionName: "generic",
        outputFields: { fields: {}, schema: {}, hasData: false },
        appDetails: {},
      };
      break;
    case KonnectorType.Form:
      triggerData = {
        ...triggerData,
        appId: "generic",
        iconUrl: "",
        eventId: "form-data",
        appName: "generic",
        connectionId: "generic",
        connectionName: "generic",
        outputFields: { fields: {}, schema: {}, hasData: false },
        appDetails: {},
      };
      break;
    default:
      triggerData = {
        ...triggerData,
        appId: "",
        eventId: "",
        appName: "",
        connectionId: "",
        connectionName: "",
      };
      break;
  }
  return triggerData;
};
