import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AccountName, Password, TextField } from "common/components/FormFields";
import { useAppDispatch, useAppSelector } from "state/store";
import { StatusType } from "state/new-konnector/types";
import {
  resetAccount,
  setAccountApp,
  setAccountLoading,
  setAccountModal,
  setOAuthAccountLoading,
  switchTab,
} from "state/account/slices";
import { toast } from "react-toastify";
import { generateJSONForAccountValidation } from "utils/config";
import { Loader } from "components/icons/Loader";
import { AppsDetails, TabType } from "state/account/types";
import { getDynamicAppSchemaLoading } from "state/account/slices";
import { ToastError, ToastSucess } from "common/components/toast/Toast";
import SelectInputSchema from "./SelectInputSchema";
interface CredTab {
  appDetails: AppsDetails;
  hastDynamicInputfields: boolean;
  hasActionDynamicInputfields: boolean;
}

const DynamicFieldMapping: React.FC<CredTab> = ({
  appDetails,
  hastDynamicInputfields,
  hasActionDynamicInputfields,
}) => {
  const inputFields = appDetails.dynamicFields.fields;
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (hastDynamicInputfields && inputFields?.length === 0) {
      dispatch(
        getDynamicAppSchemaLoading({
          hastDynamicInputfields: hastDynamicInputfields,
          appId: appDetails.appId,
          event: appDetails.eventId,
          connectionId: appDetails.connectionConfig.id,
          currentActionId: appDetails.id,
        })
      );
    }
  }, [appDetails]);
  useEffect(() => {
    if (hasActionDynamicInputfields && inputFields?.length === 0) {
      dispatch(
        getDynamicAppSchemaLoading({
          hasActionFields: hasActionDynamicInputfields,
          appId: appDetails.appId,
          event: appDetails.eventId,
          connectionId: appDetails.connectionConfig.id,
          currentActionId: appDetails.id,
        })
      );
    }
  }, [appDetails]);

  const renderFields = (): JSX.Element[] => {
    return inputFields?.map((field, index) => {
      return <SelectInputSchema field={field} index={index} appDetails={appDetails} dynamicInputfields={inputFields} />;
    });
  };

  return <div className="w-full">{inputFields?.length > 0 ? renderFields() : "loading"}</div>;
};

export default DynamicFieldMapping;
