import { setDynamicInputField } from "state/data-mapping/slices";
import { fetchApp } from "api/appsApi"
import { AxiosResponse } from "axios";
import { takeEvery, put, call, select } from "redux-saga/effects";

export function* serializeDynamicInputFields(konnectorApps) {
    const finalAppValues = []
    const appDetailsV2 = []
    for (let apps of konnectorApps) {
        try {
            const result: AxiosResponse<any> = yield call(fetchApp, apps.appId);
            appDetailsV2.push(result.data.data)
            if (apps.appSelectorType == "source") {
                const matchedTrigger = appDetailsV2[0].triggers.find(trigger => trigger.id === apps.appEventId);
                if (matchedTrigger && matchedTrigger.dynamicInputFields) {
                    finalAppValues.push({ trigger: true })
                } else {
                    finalAppValues.push({ trigger: false })
                }
            } else {
                appDetailsV2.slice(1).filter((app, index) => {
                    app.actions.filter((app1) => {
                        if (app1.id == apps.appEventId) {
                            if (app1.dynamicInputFields) {
                                finalAppValues.push({ [`action_${index}`]: true })
                            } else {
                                finalAppValues.push({ [`action_${index}`]: false })
                            }
                        }
                    })

                })

            }
        } catch (err) {
        }
    }
    yield put(setDynamicInputField(finalAppValues));
}