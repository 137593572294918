import { serializeDataMapping } from "./appsUtils";
import _ from "lodash";
import {
  RootObject,
  KonnectorApp,
  TriggerDefinition,
  ActionItem,
  ActionItemDataType,
  LookupDataType,
  ActionType,
  RuleDataType,
  ActionDataType,
  ParseDataType,
} from "interfaces/pages/create-new-konnector";
import {
  InitialState,
  KonnectorAppState,
  AppRole,
  KonnectorType,
  NewKonnectorState,
} from "state/create-konnector/types";
export const serializeCreateKonnectorData = (
  state: any,
  opt: InitialState,
  accountDetails: any,
  rawdatamapping: any,
  datamapping: any,
  lookup: any,
  DynamicInputDataMapping: any,
  webhook: any
): RootObject => {
  try {
    const res = {
      name: datamapping.template.name,
      description: "",
      iconUrl: "",
      status: "inactive",
      konnectorSource: opt.data.sourceTemplate ? opt.data.sourceTemplate : "Konnectify",
      konnectorType: state.konnectorApps[0].appEventId == "webhook" ? KonnectorType.WebHook : KonnectorType.Regular,
      webhookUrl: { url: webhook?.webhookUrl, id: webhook?.webhookId },
      konnectorApps: formatDataToKonnectorApp(state, opt, accountDetails, lookup),
      ruleConfig: opt.data?.ruleEngine,
      parseConfig: opt.data?.parseEngine,
      formFields: [],
      formSettings: {},
      targetActionData: formatDatatoTargetActionData(state, opt, accountDetails, datamapping),
      actionItems: formDatatoActionItemsTest(
        accountDetails,
        opt.data,
        rawdatamapping,
        datamapping,
        DynamicInputDataMapping
      ),
      triggerDefinition: formatDataToTriggerDefinition(
        accountDetails,
        opt.data.trigger,
        DynamicInputDataMapping,
        datamapping
      ),
      workflowDescription: opt.data.workflowDescription,
      workflowInstruction: opt.data.workflowInstruction,
      konnectorAppId: opt.data.workflowAppId,
      adminRequiredData:
        datamapping.konType == "Template" ? opt.data.actions[0].dataMapping : datamapping.template.adminRequiredData,
      apiKey: accountDetails.apiKey,
      userId: accountDetails.userId,
      workflowId: datamapping.konType == "Konnector" ? datamapping.template.workflowId : opt.data.id,
    };
    return res;
  } catch (err) {}
};

export const formDatatoActionItemsTest = (
  tabItem: any,
  opt: NewKonnectorState,
  rawdatamapping,
  datamapping,
  DynamicInputDataMapping
): ActionItem<ActionItemDataType>[] => {
  let actionItems: ActionItem<ActionItemDataType>[] = [];
  const lookupItem = seraializeLookupDataActionItem(opt.lookups);
  const ruleConfig = seraializeRuleConfigDataActionItem(opt.ruleEngine);
  const parseConfig = seraializeParseConfigDataActionItem(opt.parseEngine);
  const actionItem = seraializeActionDataActionItem(
    tabItem,
    opt.actions,
    rawdatamapping,
    datamapping,
    DynamicInputDataMapping
  );
  actionItems = [...lookupItem, ...ruleConfig, ...parseConfig, ...actionItem];
  actionItems.sort((a, b) => {
    return a.actionOrderId - b.actionOrderId;
  });
  return actionItems;
};
const seraializeRuleConfigDataActionItem = (ruleEngine): ActionItem<RuleDataType>[] => {
  return ruleEngine.map((ruleData): ActionItem<RuleDataType> => {
    return {
      actionOrderId: ruleData.actionOrderId,
      actionType: ActionType.RuleConfig,
      data: {
        name: "",
        operator: ruleData.operator,
        filters: ruleData.filters,
      },
    };
  });
};

const seraializeParseConfigDataActionItem = (parseEngine): ActionItem<ParseDataType>[] => {
  return parseEngine.map((parseData): ActionItem<ParseDataType> => {
    return {
      actionOrderId: parseData.actionOrderId,
      actionType: ActionType.ParseConfig,
      data: {
        id: parseData.id,
        source_value: parseData.source_value,
        pattern_type: parseData.pattern_type,
        patterns: parseData.patterns,
        conditions: !parseData.conditions ? [] : parseData.conditions,
        outputSchema: parseData.outputSchema,
        source_id: parseData.source_id,
      },
    };
  });
};

export const formatDataToKonnectorApp = (state: any, opt: any, accountDetails: any, lookup: any): KonnectorApp[] => {
  const mappedKonnectorApps = state.konnectorApps.map((app, index) => {
    let appdetails, connectionIds;

    if (app.appId === accountDetails.sourceAppId) {
      appdetails = accountDetails.sourceAppDetails;
      connectionIds = accountDetails.sourceAppConnectionId;
    } else if (app.appSelectorType === "lookup" && app.appId != accountDetails.sourceAppId) {
      const lookupFilter = lookup.lookupAppDetails.filter((lookupv1) => app.appId === lookupv1.appId);
      if (lookupFilter) {
        connectionIds = lookupFilter?.body?.data?.id;
        appdetails = app;
      }
    } else {
      appdetails = accountDetails.targetAppDetails;
      connectionIds = accountDetails.targetAppConnectionId;
    }
    return {
      actionOrderId: index,
      appSelectorType: app.appSelectorType,
      appSelectorKey: app.appSelectorType,
      appId: app.appId,
      appEventId: app.appEventId,
      appDetail: appdetails,
      connectionId: connectionIds,
      connectionName: app.connectionConfig?.connectionName,
    };
  });
  return mappedKonnectorApps;
};
const formatDataToTriggerDefinition = (
  tabItem: any,
  opt: KonnectorAppState<AppRole.Source>,
  DynamicInputDataMapping,
  datamapping
): TriggerDefinition => {
  let trigger = tabItem[0];
  var connectionId;
  if (opt?.appId == tabItem.sourceAppId) {
    connectionId = tabItem.sourceAppConnectionId;
  } else {
    connectionId = tabItem.targetAppConnectionId;
  }
  const isTrigger = datamapping.dynamicInputFields[0].trigger ? DynamicInputDataMapping : {};
  return {
    appId: opt?.appId,
    eventId: opt?.eventId,
    // appId: trigger.appId == "generic"?trigger.appId: opt?.appId,
    // eventId:trigger.appId == "generic"?trigger.eventId: opt?.eventId,
    triggerType: opt?.triggerType,
    triggerProps: getTriggerProps(opt?.triggerType),
    inputFields: isTrigger,
    connectionId: connectionId,
  };
};
const formatDatatoTargetActionData = (state, opt, tabItem: any, datamapping): any => {
  let actions = opt.data.actions;
  return actions.filter((action): any => {
    const inputFields = datamapping?.schema?.input;
    return {
      sourceId: inputFields?.sourceId,
      appEventId: inputFields?.eventId,
      appId: inputFields?.appId,
      inputSchema: inputFields?.data,
    };
  });
};

const getTriggerProps = (triggerType) => {
  if (triggerType === "webhook") {
    return {
      subscriptionType: "auto",
    };
  }
  return {
    recurringType: "minutely",
    minute: 5,
  };
};

export const flatSourceOutputFields = (outputFields): any[] => {
  const getMembers = (member) => {
    if (!member.propChildren || !member.propChildren.length) {
      return member;
    }
    return [member, _.flatMapDeep(member.propChildren, getMembers)];
  };
  return _.flatMapDeep(outputFields, getMembers);
};

//ActionItems
export const setActionOrderId = (lookups, ruleConfig, parseConfig, actions?) => {
  let actionOrderId = 0;
  if (lookups.length === 0 && ruleConfig.length === 0 && parseConfig.length === 0) {
    actionOrderId = 1;
    return actionOrderId;
  } else {
    if (actions) {
      actionOrderId = ruleConfig.length + lookups.length + parseConfig.length + actions.length;
      return actionOrderId;
    }
    actionOrderId = ruleConfig.length + parseConfig.length + lookups.length;
    return actionOrderId;
  }
};

const seraializeLookupDataActionItem = (lookups): ActionItem<LookupDataType>[] => {
  return lookups.map((lookup): ActionItem<LookupDataType> => {
    const schema = lookup.outputFields.schema;

    const eventProps = lookup.dataMapping;
    return {
      actionOrderId: lookup.actionOrderId,
      actionType: ActionType.Lookup,
      data: {
        sourceId: `${lookup.appId}:` + lookup.eventId,
        appEventId: lookup.eventId,
        appId: lookup.appId,
        actionOrderId: lookup.actionOrderId,
        outputSchema: schema,
        eventProps,
      },
    };
  });
};

const seraializeActionDataActionItem = (
  tabItem,
  opt,
  rawdatamapping,
  datamapping,
  DynamicInputDataMapping
): ActionItem<ActionDataType>[] => {
  return opt.map((action, index): any => {
    const inputFields = datamapping?.schema?.input;
    let actionDataMapping = serializeDataMapping(inputFields?.data, rawdatamapping, "");
    var connectionId;
    if (inputFields?.appId == tabItem.sourceAppId) {
      connectionId = tabItem.sourceAppConnectionId;
    } else {
      connectionId = tabItem.targetAppConnectionId;
    }
    return {
      actionOrderId: action.actionOrderId,
      actionType: ActionType.Action,
      data: {
        appId: action.appId,
        actionOrderId: action.actionOrderId,
        appEventId: action.eventId,
        actionProperties: [...actionDataMapping],
        connectionId: connectionId,
        dynamicInputFields: DynamicInputDataMapping,
      },
    };
  });
};

export const setTriggerData = (konnectorType: KonnectorType, triggerData: KonnectorAppState<AppRole.Source> | any) => {
  switch (konnectorType) {
    case KonnectorType.WebHook:
      triggerData = {
        ...triggerData,
        appId: "generic",
        eventId: "webhook",
        iconUrl: "",
        appName: "generic",
        connectionId: "generic",
        connectionName: "generic",
        outputFields: { fields: {}, schema: {}, hasData: false },
        appDetails: {},
      };
      break;
    case KonnectorType.Form:
      triggerData = {
        ...triggerData,
        appId: "generic",
        iconUrl: "",
        eventId: "form-data",
        appName: "generic",
        connectionId: "generic",
        connectionName: "generic",
        outputFields: { fields: {}, schema: {}, hasData: false },
        appDetails: {},
      };
      break;
    default:
      triggerData = {
        ...triggerData,
        appId: "",
        eventId: "",
        appName: "",
        connectionId: "",
        connectionName: "",
      };
      break;
  }
  return triggerData;
};
