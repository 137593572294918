import useSWR from "swr";
import { fetcher, axiosInstance, options, setTenantId } from "./libs/fetch";

const PAGE_LIMIT = 200;

export default function useWorkflow(appId, page, sortOrder = "", filterBy) {
  const queryValue = sortOrder.split("-")[0] || "";
  const sortId = sortOrder.split("-")[1] || "";
  return useSWR(
    `/embedded/apps/${appId}/all?pageNumber=${page}&pageSize=${PAGE_LIMIT}${
      filterBy !== "active" ? "&filterBy=status" + "&sortValue=" + queryValue + "&sortOrder=" + sortId : ""
    }${filterBy === "active" ? "&filterBy=status" : ""}`,
    fetcher
  );
}
export function useWorkflowApi(data) {
  return axiosInstance.get(`/embedded/apps/${data.appId}/${data.templateId}`, options);
}
export function saveWorkflowApi(payload) {
  const appId = payload.triggerDefinition.appId;
  return axiosInstance.post(`/embedded/apps/${appId}`, payload, options);
}

export function updatingAppDescription(payload) {
  return axiosInstance.put(`/embedded/apps/description`, payload, options);
}

export function updateWorkflowApi(appId, workflowId, payload) {
  return axiosInstance.put(`/embedded/apps/${appId}/${workflowId}`, payload, options);
}

export function getWorkflowApi(payload) {
  return axiosInstance.get(`/embedded/apps/${payload.appId}/${payload.id}`, options);
}

export function useAppDescription(appId) {
  return useSWR(`/embedded/apps/description/${appId}`, fetcher);
}

export function useParticularWorkflow(appId, id) {
  return useSWR(`/endusers/${appId}/workflows/${id}`, fetcher);
}

export function useWorkflowForApps() {
  return useSWR(`/embedded/apps/workflows`, fetcher);
}

export function useAllWorkflows(appId) {
  return useSWR(`/embedded/apps/${appId}/all`, fetcher);
}

export function activateKonnectorApi(payload) {
  return axiosInstance.post(`/embedded/apps/${payload.id}/${payload.workflowId}/activate`, options);
}
export function deactivateKonnectorApi(payload) {
  return axiosInstance.post(`/embedded/apps/${payload.id}/${payload.workflowId}/deactivate`, options);
}

export function deleteKonnectorApi(workflowId) {
  return axiosInstance.delete(`/embedded/apps/${workflowId}`, options);
}
export function getWebhookData(webhookId, konnectorType, tenantId) {
  const requestOptions = {
    ...options,
    headers: {
      ...options.headers,
      tenantId: tenantId,
    },
  };

  return axiosInstance.get(`/konnectors/webhook/${webhookId}?konnectorType=${konnectorType}`, requestOptions);
}
