import { Choices, FilterType, RuleConfigData } from "interfaces/modules/ruleConfig";
import { TabPanel, useTabs } from "react-headless-tabs";
import { TabType } from "state/account/types";
import { useAppSelector } from "state/store";

const RuleConfig: React.FC<any> = ({ ruleConfi }) => {
  const tabs = useAppSelector((state) => state.account.tabItems);
  const accoutTabs = tabs.filter((tab) => tab.tabType === TabType.Creds);

  const findSourceApp = (eventId: string) => {
    let iconUrl, event;
    let app = eventId.split(":");
    iconUrl = accoutTabs.find((i) => i.data.id === app[0]).data.iconUrl;
    event = app[1].split("-")[1];

    return { iconUrl, event };
  };

  let ruleConfig: RuleConfigData = {
    actionType: "ruleConfig",
    actionOrderId: 1,
    filters: [
      {
        name: "filter_set_1",
        match_type: "all",
        conditions: [
          {
            name: "",
            data_type: "string",
            field_type: "AMVr3a4T9G:new-contact-trigger",
            value: "HenriettaM@mh80.onmicrosoft.com",
            operator: "CONTAINS",
            field_name: "first_name",
          },
          {
            name: "",
            data_type: "string",
            field_type: "AMVr3a4T9G:new-contact-trigger",
            value: "konnectify.co",
            operator: "DOES_NOT_ENDS_WITH",
            field_name: "last_name",
          },
        ],
      },
      {
        name: "filter_set_2",
        match_type: "all",
        conditions: [
          {
            name: "",
            data_type: "string",
            field_type: "AMVr3a4T9G:new-contact-trigger",
            value: "qqq",
            operator: "EXACTLY_MATCHES",
            field_name: "address",
          },
        ],
      },
    ],
    operator: "filter_set_1 AND filter_set_2",
  };
  const renderConditions = (filter: FilterType) => {
    return filter.conditions.map((condition) => {
      return (
        <div className="border-b-[1px] text-xs flex flex-col h-1/2 p-2 space-y-2">
          <div className="flex space-x-2 h-1/2">
            <div className="w-1/4 h-auto p-2 border-[1px] rounded flex items-center justify-start space-x-2 bg-white">
              <div className="h-5 w-5">
                <img src={findSourceApp(condition.field_type).iconUrl} alt="" />
              </div>
              <div className="text-gray-600 flex space-x-1">
                <p>In</p>
                <p className="font-bold">{findSourceApp(condition.field_type).event}</p>
              </div>
            </div>
            <div className="w-1/4 h-auto p-2 border-[1px] rounded flex items-center justify-start space-x-2 bg-white">
              <div className="text-gray-600 flex space-x-1">
                <p>If</p>
                <p className="font-bold">{condition.field_name}</p>
              </div>
            </div>
            <div className="w-1/4 h-auto p-2 border-[1px] rounded flex items-center justify-start space-x-2 bg-white">
              <div className="text-gray-600 flex space-x-1">
                <p className="font-bold">
                  {Choices[condition.data_type].find((val) => val.value === condition.operator)?.label}
                </p>
              </div>
            </div>
          </div>

          <div className="w-1/2 h-1/2 p-2 border-[1px] rounded flex items-center justify-start space-x-2 bg-gray-50">
            <div className="text-gray-600 flex space-x-1">
              <p>In</p>
              <p className="font-bold">{findSourceApp(condition.field_type).event}</p>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderFilters = () => {
    return ruleConfig.filters.map((filter) => {
      return (
        <div className=" rounded-sm flex flex-col w-full border-[1px] h-1/2 border-gray-200">
          <div className="border-b-[1px] h-1/4 p-3 text-xs text-gray-600 flex items-center space-x-2">
            Match <span className="font-bold mx-2">{`${filter.match_type.toUpperCase()}`}</span> of the below
          </div>
          <div className="h-3/4 bg-gray-100 flex flex-col">{renderConditions(filter)}</div>
        </div>
      );
    });
  };

  return (
    <div className="flex flex-col space-y-2 w-full h-full">
      <h1>Rule for Ticket Creation</h1>
      <div className="flex flex-col h-4/5 space-y-6">{renderFilters()}</div>
    </div>
  );
};

export default RuleConfig;
