import { StatusType } from "state/new-konnector/types";

export enum FilterType {
  ALL = "all",
  ACTIVE = "active",
  freshdesk = "freshdesk",
}

export enum SortByType {
  DEFAULT = "",
  STATUS = "status",
  ACTIVE = "active",
  INACTIVE = "inactive",
  CREATED = "dtCreated",
  LATEST = "latest",
  OLDEST = "oldest",
}

export interface InitialState {
  sortBy: SortByType;
  filterBy: FilterType;
  status: StatusType;
  currentPage: number;
  appType: string;
  templateState: TemplateState;
  hasActivated: boolean;
  currentActionId: string;
  hasDeleted: boolean;
  errorMessage: string;
  selectedKonnectorId: any;
}

export enum TemplateState {
  listAllTemplateState = "TemplateList",
  EditTemplateState = "editTemplate",
  NewKon = "createNewKon",
}
export const ErrorStatus = [400, 401, 500];
