import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getKonnectorsSlice } from "state/selectors";
import { useAppDispatch, useAppSelector } from "state/store";
import { StatusType } from "state/new-konnector/types";
import { getKonnectorsDeleted } from "state/konnector-list/selectors";
import {
  activateKonnectorLoading,
  deactivateKonnectorLoading,
  deleteKonnectorLoading,
  konectorStatus,
} from "state/konnector-list/slices";
import { useNavigate } from "react-router-dom";
import { green } from "@mui/material/colors";
import { grey } from "@material-ui/core/colors";

interface ControlsProps {
  konnector: any;
  status: string;
  name: string;
}
const notificationMessage = {
  true: "Konnector is Enabled",
  false: "Konnector is Disabled",
};
const Controls: React.FC<ControlsProps> = ({ konnector, status, name }) => {
  const [showModal, setShowModal] = useState(false);
  const [konnectorStatus, setKonnectorStatus] = useState(status);
  const getKonnectors = useAppSelector(getKonnectorsSlice);
  const konnStatus = useAppSelector((state) => state.konnectors.status);
  const currentActionId = useAppSelector((state) => state.konnectors.currentActionId);
  const customization_template = useAppSelector((state) => state.auth.template);
  const hasDeleted = useAppSelector(getKonnectorsDeleted);
  const dispatch = useAppDispatch();
  const closeModalHandler = () => {
    setShowModal(false);
  };
  const new_Customization_portalCustomization = useAppSelector((state) => state.auth.template);
  const handleOnChange = (e) => {
    const checked = e.target.checked;
    if (checked) {
      return dispatch(activateKonnectorLoading(konnector.id));
    }

    dispatch(deactivateKonnectorLoading(konnector.id));
  };
  useEffect(() => {
    if (konnStatus === "success" && currentActionId === konnector.id && !hasDeleted) {
      setKonnectorStatus(getKonnectors.hasActivated ? "active" : "inactive");
      // toast.success(notificationMessage[getKonnectors.hasActivated], {
      //   position: "bottom-center",
      //   autoClose: 2000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      // dispatch(konectorStatus())
    }
  }, [getKonnectors.status]);
  useEffect(() => {
    setKonnectorStatus(status);
  }, [status]);

  return (
    <div className="flex">
      {/* {showModal && (
        <ConfirmModal
          text={`konnector "${name}"`}
          visible={showModal}
          closeModalHandler={closeModalHandler}
          confirmHandler={confirmModalHandler}
        />
      )} */}
      <label htmlFor={konnector.id} className="inline-flex items-center space-x-4 w-full cursor-pointer">
        {/* <h1 className="text-sm ml-4 mr-10 "> Enable konnector </h1> */}
        <span className="ml-3 relative">
          <input
            id={konnector.id}
            onChange={handleOnChange}
            disabled={currentActionId === konnector.id && konnStatus === StatusType.loading}
            checked={konnectorStatus === "active" ? true : false}
            type="checkbox"
            className="hidden peer"
          />
          <div className="absolute bg-white inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto  peer-disabled:bg-gray-500 "></div>
          <div
            className={`w-10 h-6 bg-gray-500 peer-checked rounded-full shadow-inner peer-disabled:bg-gray-200 ${konnectorStatus == "active" ? `bg-green-700` : `bg-slate-600`}`}
          // style={{
          //   backgroundColor: konnectorStatus == "active" ? green : grey,
          // }}
          ></div>
        </span>
      </label>
    </div>
  );
};

export default Controls;
