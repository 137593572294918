import { AccountData, StatusType } from "state/new-konnector/types";
export interface AccountInitialState {
  tabItems: AppsDetails[];
  currentPage: number;
  data: any;
  status: StatusType;
  currentActionId: number;
  allTabValidated: boolean;
  isTabValidated: boolean;
  currentTab: number;
  error?: string;
  activeAccount?: any;
  showModal: boolean;
  hasPlan?: boolean;
  isTrial: boolean;
  isFreePlan: boolean;
  currentState: CurrentState;
  isWorkflowSaved: StatusType;
  showUpdateModal: boolean;
  updateTabStatus: StatusType;
  tab1Success: boolean;
  tab2Success: boolean;
  targetTab: StatusType;
  sourceUpdate: StatusType;
  targetUpdate: StatusType;
  value: string;
  Reusevalidation?: any;
  Reusevalue?: boolean;
  ReuseAppdetails?: any;
  validatetabboolean?: boolean;
  validateTabobject?: any;
  disablevalidation?: boolean;
  hasdatamapping?: boolean;
}

export enum CurrentState {
  Konnectors = "konnectors",
  Templates = "templates",
}

export interface Choice {
  label: string;
  value: string;
}

export interface AppsDetails {
  id: string;
  tabTitle: string;
  data: any;
  appSelectorType: string;
  actionOrderId: number;
  appId: string;
  triggerType?: string;
  tabValidated: boolean;
  status: StatusType;
  fieldsRetrived?: boolean;
  inputSchemas?: any[];
  outputSchema?: any;
  eventId: string;
  tabType: TabType;
  rawDataMapping: any;
  connectionConfig: AccountData;
  dynamicFields: {
    hasData: Boolean;
    fields: any[];
    status: StatusType;
    dataMapping: any;
    formState: any;
  };
}

export enum TabType {
  Creds = "creds",
  RuleConfig = "ruleConfig",
  ParseConfig = "parseConfig",
  DataMapping = "dataMapping",
  Action = "action",
  Webhook = "webhook",
  Templates = "templates",
}
