import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Options } from "../types";

const AccountName: React.FC<Options> = ({ name, required, register, errors }) => {
  return (
    <div>
      <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 :text-gray-300">
        {name}
      </label>
      <input
        type="textbox"
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-color focus:border-primary-color block w-full p-2.5 :bg-gray-600 :border-gray-500 :placeholder-gray-400 :text-white"
        placeholder={"Enter " + name}
        {...register(name, {
          required: { value: required.value, message: required.message },
        })}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className="mt-2 text-sm text-red-600 :text-red-500">{message}</p>}
      />
    </div>
  );
};

export default AccountName;
