import { useAppSelector } from "state/store";
import { getTemplatesFilterType } from "state/template-list/selectors";
import { FilterType } from "state/template-list/types";
import ListAllDetails from "./Templates/TemplateList/ListBothTheDetails";

const Templates: React.FC<any> = ({ data, konnectors }) => {
  const filterBy = useAppSelector(getTemplatesFilterType);
  return (
    <>
      {data?.length > 0 || konnectors?.items?.length > 0 ? (
        <>
          <div className={"flex h-[calc(100vh-5rem)] w-full px-10 pb-10 justify-between flex-col"}>
            <ListAllDetails data={data} konnectors={konnectors?.items} />
          </div>
        </>
      ) : (
        <div className="flex w-full h-[calc(100vh-5rem)] justify-center items-center flex-col">
          {filterBy === FilterType.ALL ? (
            <>
              <p className="mb-3">Admin haven't created a Template yet!</p>
            </>
          ) : (
            <>
              {filterBy === FilterType.ACTIVE ? (
                <p className="mb-3">Admin haven't activated a Template yet!</p>
              ) : (
                <p className="mb-3">There is no freshdesk Konnector!</p>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};
export default Templates;
