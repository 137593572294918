import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Options } from "../types";

const SingleSelectField2: React.FC<any> = ({
  choices,
  placeholder,
  pattern,
  name,
  required,
  register,
  errors,
  isSelected,
  value,
}) => {
  let isReqDeps = choices[0]?.value === "kf_req_deps";
  const getFormConfigs = () => {
    if (required) {
      return {
        required: { value: required.value, message: required.message },
        pattern: {
          value: new RegExp(pattern.value, pattern.flags),
          message: pattern.message,
        },
      };
    }
    return {
      pattern: {
        value: new RegExp(pattern.value, pattern.flags),
        message: pattern.message,
      },
    };
  };

  const renderOptions = (): JSX.Element[] => {
    return choices.map((choice) => (
      <option selected={choice.isSelected} value={choice.value}>
        {choice.label}
      </option>
    ));
  };

  return (
    <div>
      <select
        defaultValue={value}
        // value={value}
        className={`lex w-64 border-[1px] border-gray-300 p-2 pb-2 m-1 ml-0 rounded-lg h-10 gap-2 items-center overflow-hidden
					m-0
					${errors[name] ? "border-red-600 focus:border-red-600" : "border-gray-300 focus:border-blue-600"}
					focus:text-gray-700 focus:bg-white"
				aria-label="Default select example`}
        {...register(name, getFormConfigs())}
      >
        <option value="">{placeholder ? placeholder : isReqDeps ? choices[0]?.label : "Choose an option"}</option>
        {!isReqDeps && renderOptions()}
      </select>
      {/* <ErrorMessage
				errors={errors}
				name={name}
				render={({ message }) => <p className="mt-2 text-sm text-red-600 dark:text-red-500">{message}</p>}
			/> */}
    </div>
  );
};

export default SingleSelectField2;