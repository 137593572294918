import { FormIcon } from "components/icons/FormIcon";
import { ParseEngineIcon } from "components/icons/ParseEngineIcon";
import { RuleEngineIcon } from "components/icons/RuleEngineIcon";
import { WebhookIcon } from "components/icons/WebhookIcon";
import { KonnectorApp, KonnectorType } from "pages/TemplateList/types";
import React from "react";

interface KonnectorAppsProps {
  apps: KonnectorApp[] | any;
  ruleConfig: number;
  template: any;
}

const Apps: React.FC<KonnectorAppsProps> = ({ apps, ruleConfig, template }) => {
  const sourceApp = template?.triggerDefinition?.appId;
  const targetApps = template.actionItems.map((e) => {
    if (e.actionType === "ruleConfig") {
      return "ruleConfig";
    }
    if (e.actionType === "parseConfig") {
      return "parseConfig";
    } else return e.appId;
  });
  var appslist = [sourceApp, ...targetApps];
  appslist = appslist.map((e) => {
    if (e === "generic") {
      return e;
    }
    const appurl = apps.filter((app) => {
      if (app.appId === e) {
        return app.iconUrl;
      }
    });
    return appurl.length ? appurl[0].iconUrl : e;
  });

  apps = appslist;

  const renderConnectedApps = () => {
    const apps1 = apps.filter(item => item !== "parseConfig" && item !== "ruleConfig")
    return apps1.map((app, i) => (
      <div className="flex items-center mr-2" key={i}>
        {app === "generic" ? (
          template.konnectorType === KonnectorType.WebHook || template.konnectorType === undefined ? (
            <WebhookIcon url={template.webhookUrl.url} />
          ) : (
            <WebhookIcon url={template.webhookUrl.url} />
          )
        ) : app !== "ruleConfig" && app !== "parseConfig" ? (
          <img className="min-w-[30px] max-w-[30px] mr-2" src={app} alt="" />
        ) : app === "ruleConfig" ? (
          <RuleEngineIcon className="h-8 w-8 text-gray-500" />
        ) : app === "parseConfig" ? (
          <ParseEngineIcon className="h-8 w-8 text-gray-500" />
        ) : null}
        {i === 0 && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 lg:inline-block"
            viewBox="0 0 20 20"
            fill="#1e2431"
          >
            <path
              fillRule="evenodd"
              d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
            <path
              fillRule="evenodd"
              d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </div>
    ));
  };

  return <div className="flex items-center">{renderConnectedApps()}</div>;
};

export default Apps;
