import React, { useState } from "react";
import ScreenshotCarousel from "./carousal";
import FooterContent from "./FooterContent";
// import { GoArrowBoth } from "react-icons/go";
import { LuArrowRightLeft } from "react-icons/lu";
// import { BsArrowRepeat } from "react-icons/bs";
// import { RiArrowLeftRightFill } from "react-icons/ri";
const CopyableUrl = ({ url }) => {
  const [isCopy, setIsCopy] = useState(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then(
      () => {
        setTimeout(() => {
          setIsCopy(false);
        }, 1000);
        setIsCopy(true);
      },
      (err) => {
        console.error("Failed to copy URL: ", err);
      }
    );
  };

  return (
    <div className="ml-10 mr-10 mt-10">
      <div className="font-bold	mb-10 text-3xl flex">
        Freshservice Genesys Integration
        <div className="ml-5 flex gap-2 ">
          <img src="/icons/freshservice.png"></img>

          <LuArrowRightLeft size={30} />

          <img src="/icons/Genesys.png"></img>
        </div>
      </div>
      <div className="text-sm text-gray-700">
        Please follow the below steps to install{" "}
        <strong>Freshserivce CTI intergration</strong> in your{" "}
        <strong>Freshservice instance</strong>
      </div>
      <div className="text-sm text-gray-700">
        <p>Configure Softphone Iframe</p>
        <ul className="list-disc pl-5">
          <li>
            Log into Freshservice, go to <strong>Admin {">"} Apps</strong>.
          </li>
          <li>
            Click the <strong>CTI settings</strong> icon.
          </li>
          <li>
            Turn ON the <strong>Enable Softphone Iframe toggle</strong>.
          </li>
          <li>
            Please select height : <strong>600</strong> and width :{" "}
            <strong>420</strong>
          </li>
          <li>Please click on install</li>
          <li>Follow the format as shown in the example below:</li>
        </ul>
      </div>

      <div className="space-x-2  p-4">
        <label htmlFor="URL">Iframe URL</label>
        <div className="flex items-center">
          <input
            type="text"
            readOnly
            className="form-input border-gray-300 text-gray-700 block w-full pl-2 pr-7 py-2 text-base focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm border-2 rounded-md"
            value={url}
          />
          {isCopy ? (
            <button
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              onClick={copyToClipboard}
            >
              Copeid!
            </button>
          ) : (
            <button
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              onClick={copyToClipboard}
            >
              Copy
            </button>
          )}
        </div>
      </div>
      <div>
        <FooterContent />
      </div>
      <br />
      <div className="text-sm text-gray-700">App Screenshots :</div>
      <ScreenshotCarousel />
    </div>
  );
};

export default CopyableUrl;
