import { ConfigurationType, DynamicFieldsSchemaType } from "state/new-konnector/types";
import { fetchDynamicInputFieldsApi, fetchOutputFieldsApi } from "./appsApi";

export async function getAllAppsSchemaApi(apps) {
  const appsPromises = () => {
    let fields: any = [[], [[]], [[]]];
    let actionIndex = 0,
      lookupIndex = 0;

    for (let i in apps) {
      switch (apps[i].appSelectorType) {
        case ConfigurationType.source:
          if (apps[i].hasDynamicSchema) {
            fields[0].push(
              fetchDynamicInputFieldsApi({
                appId: apps[i].appId,
                data: {
                  schemaType: DynamicFieldsSchemaType.Input,
                  eventId: apps[i].eventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: apps[i].dynamicDataMapping,
                },
              })
            );
          }
          fields[0] = Promise.all([
            ...fields[0],
            fetchOutputFieldsApi({
              appId: apps[i].appId,
              data: {
                eventId: apps[i].eventId,
                connectionConfigId: apps[i].connectionId,
                eventData: apps[i].dynamicDataMapping,
              },
            }),
            fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Output,
                eventId: apps[i].eventId,
                connectionConfigId: apps[i].connectionId,
                eventData: apps[i].dynamicDataMapping,
              },
            }),
          ]);
          break;
        case ConfigurationType.Target:
          fields[2][actionIndex] = Promise?.all([
            ...fields[2][actionIndex],

            fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Input,
                eventId: apps[i].eventId,
                connectionConfigId: apps[i].connectionId,
                eventData: apps[i].dynamicDataMapping,
              },
            }),
            fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Output,
                eventId: apps[i].eventId,
                connectionConfigId: apps[i].connectionId,
                eventData: apps[i].dynamicDataMapping,
              },
            }),
          ]);

          actionIndex += 1;
          break;
        case ConfigurationType.lookup:
          fields[1][lookupIndex] = Promise.all([
            fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Input,
                eventId: apps[i].eventId,
                connectionConfigId: apps[i].connectionId,
                eventData: {},
              },
            }),
            fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Output,
                eventId: apps[i].eventId,
                connectionConfigId: apps[i].connectionId,
                eventData: {},
              },
            }),
          ]);

          lookupIndex += 1;
          break;
      }
    }
    fields[2] = Promise.all(fields[2]);
    fields[1] = Promise.all(fields[1]);
    return fields;
  };

  return Promise.all(appsPromises());
}
