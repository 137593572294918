// Multiple select latest code
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "components/ui/accordion";
import { MultiOptions } from "../types";
import { useAppSelector } from "state/store";
import { Webhook } from "lucide-react";
import TextField from "../TextField";
import { Field } from "utils/appsUtils";
const MultipleSelectFieldv2Moengage: React.FC<any> = ({
  choices,
  control,
  name,
  required,
  pattern,
  onChangeHandler,
  register,
  getValues,
  errors,
  setInputValue,
  placeholder,
  appDetails,
  field,
}) => {
  const getFormConfigs = () => {
    if (required) {
      return {
        required: { value: required.value, message: required.message },
        pattern: {
          value: new RegExp(pattern.value, pattern.flags),
          message: pattern.message,
        },
      };
    }
    return {
      pattern: {
        value: new RegExp(pattern.value, pattern.flags),
        message: pattern.message,
      },
    };
  };

  const [filterData, setFilterData] = useState<any>(choices);
  const outputIconUrl: any = useAppSelector((state) => state.dataMapping.outputIconurls);
  const formState = getValues()?.[name]
    ? [
        {
          values: getValues()?.[name],
          eventId: getValues()?.[`${name}__field_source_type`]
            ? getValues()?.[`${name}__field_source_type`].split(":")[1]
            : "",
        },
      ]
    : [];
  const disable_status = useAppSelector((state) => state.dataMapping.currentstate);
  const newdatas = useAppSelector((state) => {
    if (disable_status == "template") {
      return state.editTemplate.data.actions[0]?.dataMapping;
    } else {
      return state.dataMapping.template.adminRequiredData;
    }
  });
  const newdata = newdatas?.map((res) => res.propName);

  const [selectedValues, setSelectedValues] = useState(formState);

  const [openAccordion, setOpenAccordion] = useState(false);
  const [isAccord, setAccord] = useState(false);
  const [valuess, setValue] = useState("");
  const [hideInput, setHideInput] = useState(true);
  const closeAccordion = () => {
    setOpenAccordion(false);
  };
  const [newState, setnewSatate] = useState([]);
  const [booleanstate, setbooleansttate] = useState(false);
  useEffect(() => {
    const formState = getValues()?.[name]
      ? [
          {
            values: getValues()?.[name],
            eventId: getValues()?.[`${name}__field_source_type`]
              ? getValues()?.[`${name}__field_source_type`].split(":")[1]
              : "",
          },
        ]
      : [];
    setnewSatate(formState);
    // setSelectedValues(formState);
  }, [name, getValues, selectedValues]);

  useEffect(() => {
    const formState = getValues()?.[name]
      ? [
          {
            values: getValues()?.[name],
            eventId: getValues()?.[`${name}__field_source_type`]
              ? getValues()?.[`${name}__field_source_type`].split(":")[1]
              : "",
          },
        ]
      : [];
    setnewSatate(formState);
    // setSelectedValues(formState);
  }, [booleanstate, selectedValues, booleanstate]);

  const handleAccordionChange = (headerId, selectedValue) => {
    setbooleansttate(true);
    setSelectedValues((prevSelectedValues) => {
      const existingIndex = prevSelectedValues.findIndex((item) => item.eventId === headerId);

      if (existingIndex === -1) {
        // If the headerId is not present, add a new entry
        const updatedValues = [...prevSelectedValues, { eventId: headerId, values: [selectedValue] }];
        // Clear values for other eventIds
        const clearedValues = updatedValues.filter((item) => item.eventId === headerId);

        onChangeHandler(vall(clearedValues).map((v) => v.value));
        setInputValue(
          name,
          vall(clearedValues).map((v) => v.value)
        );

        return clearedValues;
      } else {
        // If the headerId is present, update the existing entry
        const updatedValues = prevSelectedValues.map((item) => {
          if (item.eventId === headerId) {
            // If it's the correct eventId, update the values
            const newValues = [...item.values, selectedValue];

            // Clear values for other eventIds
            const clearedValues = prevSelectedValues.filter((item) => item.eventId === headerId);

            onChangeHandler(vall([...clearedValues, { eventId: headerId, values: newValues }]).map((v) => v.value));
            setInputValue(
              name,
              vall([...clearedValues, { eventId: headerId, values: newValues }]).map((v) => v.value)
            );

            return { eventId: item.eventId, values: newValues };
          }

          return item;
        });

        // Use the callback version of setSelectedValues to ensure the updatedValues are used for the next state
        setSelectedValues(() => updatedValues);
        setInputValue(
          name,
          vall(updatedValues).map((v) => v.value)
        );
        return updatedValues;
      }
    });
  };

  // useEffect(() => {
  //   // Use useEffect to update the final selected value
  //   // const updatedFormValues = vall(selectedValues).map((v) => v.value);
  //   const updatedFormValues = vall(selectedValues).map((v) => v.value);
  //   setInputValue(name, vall(selectedValues).map((v)=>v.value));
  //   // setInputValue(name, updatedFormValues); // Use setValue to update the form field
  // }, [selectedValues,]);

  // ...
  const accordionRef = useRef(null);

  const handleClickOutside = (event) => {
    if (accordionRef.current && !accordionRef.current.contains(event.target)) {
      // Clicked outside the accordion, so close it
      setOpenAccordion(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const vall = (selectedValues) => {
    const hasParsedEvent = selectedValues?.some((n) => n.eventId?.includes("Parsed"));

    if (hasParsedEvent) {
      return selectedValues.flatMap((item) =>
        item.values.map((value) => ({ label: value, value, eventId: item.eventId }))
      );
    }

    return choices
      .filter((c) => selectedValues?.some((s) => s.values?.includes(c.value) && s.eventId === c.eventId))
      .map((c) => ({ label: c.label, value: c.value, eventId: c.eventId }));
  };
  if (name === "name") {
  }

  const manualHandler = (onChange, e) => {};
  let data_res = vall(selectedValues).map((v) => v.value);

  useEffect(() => {
    const formState = getValues()?.[name];
    const newSelectedValues = formState
      ? [
          {
            values: formState,
            eventId: getValues()?.[`${name}__field_source_type`]
              ? getValues()?.[`${name}__field_source_type`].split(":")[1]
              : "",
          },
        ]
      : [];

    setSelectedValues(newSelectedValues);
    vall(selectedValues);
  }, [name, getValues]);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    const inputSchemaArray = choices?.filter((value: any) => {
      return value.label?.toLowerCase().includes(searchValue?.toLowerCase());
    });
    setFilterData(inputSchemaArray);
  };

  // useEffect(() => {
  //   const handleSearchChange = (event) => {
  //     const searchValue = event.target.value;
  //     ;
  //     const uniqueChoices = Array.from(new Set(choices.map((value: any) => value.label)));
  //     const inputSchemaArray = uniqueChoices?.filter((value: any) => {
  //       return value.label?.toLowerCase().includes(searchValue?.toLowerCase());
  //     });
  //     setFilterData(inputSchemaArray);
  //   };

  //   handleSearchChange({ target: { value: "" } }); // Call handleSearchChange initially with an empty value
  // }, [appDetails.input.data, choices]);

  const getFieldOptions = () => {
    const fieldData = new Field(field).format();
    return fieldData;
  };
  return (
    <div className="mt-5">
      <Controller
        control={control}
        {...register(name, getFormConfigs())}
        render={({ field: { onChange: hookOnChange, value, ref } }) => (
          <div className="relative pb-4">
            <div
              className={`flex w-64 border-[1px] border-gray-300 p-2 pb-2 m-1 ml-0 rounded-lg h-10 gap-2 items-center overflow-hidden ${
                newdata?.includes(name) ? "bg-gray-300" : ""
              }   ${errors[name] ? "border-red-600 focus:border-red-600" : "border-gray-300 focus:border-blue-600"}
                `}
              ref={ref}
              onClick={() => {
                if (isAccord && valuess != "") {
                  setOpenAccordion(false);
                } else {
                  setOpenAccordion(true);
                }
              }}
              onChange={(e) => manualHandler(hookOnChange, e)}
            >
              {hideInput && (
                <input
                  className={`max-w-64 w-fit`}
                  onChange={(e) => {
                    setValue(e.target.value);
                    setAccord(true);
                    setOpenAccordion(false);
                    hookOnChange(e.target.value);
                    setSelectedValues(() => [
                      {
                        values: e.target.value,
                        eventId: "",
                      },
                    ]);
                    // setInputValue(name, ve.target.valueall(selectedValues).map((v)=>v.value));
                    setInputValue(name + "__field_type", "fixed");
                    setInputValue(`${name}__field_source_type`, "fixed");
                  }}
                ></input>
              )}

              {vall(newState).map((f) => {
                return (
                  <span
                    className={`flex p-1 items-center bg-gray-100 rounded ${
                      newdata?.includes(name) ? "bg-gray-300" : ""
                    }`}
                  >
                    {f.label}
                    {!newdata?.includes(name) && (
                      <button
                        type="button"
                        className="ml-2 font-medium"
                        onClick={() => {
                          const updatedValues = selectedValues
                            .map((item) => ({
                              ...item,
                              values: item.values.filter((value) => value !== f.value),
                            }))
                            .filter((item) => item.values.length > 0);

                          if (updatedValues.length == 0) {
                            setHideInput(true);
                          }

                          setSelectedValues(updatedValues);
                          hookOnChange(vall(updatedValues).map((v) => v.value));
                        }}
                      >
                        x
                      </button>
                    )}
                  </span>
                );
              })}
            </div>
            {}
            {openAccordion && !newdata?.includes(name) ? (
              <div
                className="border-[1px] border-slate-200 rounded-lg  pr-2 pl-2 p-2 w-80 block absolute z-10 bg-white"
                ref={accordionRef}
              >
                <div className="flex justify-end p-2">
                  <div className="font-medium text-sm">Select Field</div>
                  <button
                    type="button"
                    className="text-sm font-black  hover:bg-gray-200  hover:text-gray-900 rounded-sm text-sm ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                    data-modal-toggle="account-modal"
                    onClick={closeAccordion}
                  >
                    <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <Accordion type="single" collapsible className="pb-7">
                  {appDetails?.output?.map((header, index) => (
                    <AccordionItem
                      style={{
                        textDecoration: "none",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                        borderBottom: "none",
                      }}
                      key={`${header?.eventId}-${index}`}
                      value={header?.eventId}
                    >
                      <AccordionTrigger
                        style={{
                          textDecoration: "none",
                          cursor: "pointer",
                          transition: "background-color 0.3s",
                          borderBottom: "none",
                          textTransform: "capitalize",

                          display: "flex", // Make the container a flex container
                          justifyContent: "space-between", // Adjust this value as needed
                          alignItems: "center",
                        }}
                        className="font-medium pb-9 cursor-pointer hover:bg-gray-200 rounded-lg p-3  "
                      >
                        {outputIconUrl.map((data) => {
                          if (data.eventId == "webhook") {
                            return <Webhook></Webhook>;
                          }
                          if (data?.eventId === header?.eventId) {
                            return <img key={index} src={data.icon} className="h-6 w-6" />;
                          }
                          return null;
                        })}

                        {header?.eventId.replace(/-/g, " ")}
                      </AccordionTrigger>

                      <AccordionContent className="pb-9 font-medium pb-27 cursor-pointer">
                        <input
                          type="search"
                          onChange={(e) => handleSearchChange(e)}
                          id="default-search"
                          className="block w-72 p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white"
                          placeholder="Search Fields"
                          required
                        />

                        <div className="h-[270px] p-2 overflow-auto w-72">
                          {filterData
                            .filter((choice) => choice?.eventId === header?.eventId)
                            .map((res) => (
                              <div
                                key={res.eventId}
                                onClick={() => {
                                  setHideInput(false);
                                  handleAccordionChange(header?.eventId, res.value);
                                  hookOnChange(vall(selectedValues).map((v) => v.value));
                                  // setInputValue(name, vall(selectedValues).map((v)=>v.value));
                                  setInputValue(name + "__field_type", "source");
                                  setInputValue(`${name}__field_source_type`, header?.sourceId);
                                }}
                                className={`${
                                  selectedValues.some(
                                    (s) => s?.eventId === header?.eventId && s.values?.includes(res.value)
                                  )
                                    ? "bg-gray-300 "
                                    : " hover:bg-gray-200 bg-white rounded-lg"
                                } p-2 m-1 mt-0 mb-0 cursor-pointer transition duration-300`}
                              >
                                {res.label}
                              </div>
                            ))}
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            ) : null}
          </div>
        )}
      />
    </div>
  );
};

export default MultipleSelectFieldv2Moengage;
