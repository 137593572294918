import { Item } from "pages/TemplateList/types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "state/store";
import { setKonnectorState } from "state/template-list/slices";
import { TemplateState } from "state/template-list/types";
import OutsideClickHandler from "react-outside-click-handler";
import Apps from "./Apps";
import Menu from "./Menu";
import TemplateControls from "./Controls";
import EditIcon from "components/icons/Edit";
import { setwebhookurl } from "state/konnector-list/slices";
import Tooltip from "common/components/Tooltip/Tooltip";

interface KonnectorItemProps {
  template: Item;
}

const TemplateItem: React.FC<KonnectorItemProps> = ({ template }) => {
  const [showMenu, setShowMenu] = useState(false);

  const dispatch = useAppDispatch();
  const onClickHandler = () => {
    dispatch(setKonnectorState(TemplateState.EditTemplateState));
  };
  const toggleMenu = (e) => {
    setShowMenu((value) => !value);
  };

  const apiKey = useAppSelector((state) => state.auth.apiKey);
  const userId = useAppSelector((state) => state.auth.email);
  const customisation = useAppSelector((state) => state.auth.customisation);
  const new_Customization = useAppSelector((state) => state.auth.portalConfigration);
  const newcustomization = useAppSelector((state) => state.auth.customization);
  const new_Customization_template = useAppSelector((state) => state.auth.template);
  const url = window.location.href;
  const userIndex = url.indexOf("user=");
  var cc;
  if (userIndex !== -1) {
    const userEndIndex = url.indexOf("&", userIndex);
    if (userEndIndex !== -1) {
      cc = url.substring(userEndIndex + 1);
    } else {
      cc = "";
    }
  } else {
  }
  const { bgColor, borderRadius, heigth, hoverColor, textColor, width, templateSnippet, templateSnippetStatus } =
    new_Customization_template;
  // const {
  //   bgColor_templatesnippet,
  //   borderRadius_templatesnippet,
  //   heigth_templatesnippet,
  //   hoverColor_templatesnippet,
  //   textColor_templatesnippet,
  //   width_templatesnippet,
  //   templateSnippet_templatesnippet
  // } = new_Customization_templatesnippet;
  //
  //

  const cssRule = new_Customization_template.templateSnippet;

  const [object, setobject] = useState({});
  useEffect(() => {
    // Check if the dynamicCssClass is available
    if (new_Customization_template.templateSnippet) {
      // Extract content inside curly braces
      const match = new_Customization_template.templateSnippet.match(/\.([^{]+)\s*\{([^}]+)\}/);

      if (match && match[1] && match[2]) {
        const cssProperties = match[2].trim();

        const propertiesArray = cssProperties.split(";");
        const cssObject = propertiesArray.reduce((acc, property) => {
          const [key, value] = property.split(":");
          if (key && value) {
            const camelCaseKey = key.trim().replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
            acc[camelCaseKey] = value.trim();
          }
          return acc;
        }, {});

        setobject(cssObject);
      } else {
      }
    }
  }, [new_Customization_template.templateSnippet]);

  const [isHovered, setHovered] = useState(false);

  return (
    <div className="">
      <div
        className="p-5 mb-4 rounded-lg border-[1px] border-gray-300 bg-white flex  items-center "
        style={
          templateSnippetStatus != "true"
            ? {
                borderRadius: `${new_Customization_template?.borderRadius}px`,
                height: `${new_Customization_template?.heigth}px`,
                // width:`${new_Customization_template.width}px`,

                width: `${(1420 * parseFloat(new_Customization_template?.width)) / 630}px`,
                // width: `1300*${new_Customization_template?.width}/630`,
                transition: `${new_Customization_template?.hoverColor}`,
                background: isHovered
                  ? new_Customization_template?.hoverColor
                  : `${new_Customization_template?.bgColor}`,
              }
            : object
        }
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
      >
        <div className="basis-[60%]">
          <Link
            onClick={onClickHandler}
            to={`/apps/createtemplates/?appId=${template?.triggerDefinition?.appId}&templateId=${template.id}&apiKey=${apiKey}&user=${userId}&${cc}`}
            className="text-base text-komp-color font-semibold table hover:text-komp-hover-color"
            style={new_Customization_template?.textColor ? { color: new_Customization_template?.textColor } : {}}
          >
            {template.name}
          </Link>

          <p
            className="flex text-xs flex-row  space-x-2 text-gray-500 cursor-pointer"
            style={newcustomization?.text_colour ? { color: newcustomization?.text_colour } : {}}
          >
            <span className="flex items-center">
              <Tooltip text={template.workflowDescription ? `Description:  ${template.workflowDescription}` : "" || ""}>
                <span className="w-40 truncate cursor-pointer">
                  {template.workflowDescription ? `Description:${template.workflowDescription}` : "" || ""}
                </span>
              </Tooltip>
            </span>

            <Tooltip text={template.workflowInstruction ? `Instruction:  ${template.workflowInstruction}` : "" || ""}>
              <span className="flex items-center">
                <span className="w-40 truncate cursor-pointer">
                  {" "}
                  {template.workflowInstruction ? `Instruction: ${template.workflowInstruction}` : "" || ""}
                </span>
              </span>
            </Tooltip>
          </p>
        </div>
        <div className="flex justify-between basis-[40%]">
          <Apps apps={template?.konnectorApps} ruleConfig={template?.ruleConfig?.length} template={template} />

          <div className="ml-auto flex items-center justify-center">
            <TemplateControls konnector={template} status={template.status} name={template.name} />{" "}
            <div className="ml-3 relative">
              <OutsideClickHandler
                onOutsideClick={() => {
                  setShowMenu(false);
                  dispatch(setwebhookurl(""));
                }}
              >
                <Link
                  to={`/apps/createtemplates/?appId=${template.triggerDefinition.appId}&templateId=${template.id}&apiKey=${apiKey}&user=${userId}&${cc}`}
                  type="button"
                  className="cursor-pointer p-4"
                >
                  <EditIcon />
                </Link>

                {/* {showMenu && <Menu template={template} />} */}
              </OutsideClickHandler>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateItem;
