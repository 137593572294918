import { Field } from "utils/appsUtils";

interface FieldValueTypeProps {
  propName: string;
  isRequired: boolean;
}

const FIELD_VALUE_TYPES = [
  {
    label: "Source",
    value: "source",
  },
  {
    label: "Fixed",
    value: "fixed",
  },
];
export default function FieldValueType({ field, errors, name, register }) {
  return <input type="text" hidden name={name} {...register(name)} />;
}
