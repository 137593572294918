import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AccountName, Password, TextField } from "common/components/FormFields";
import { useAppDispatch, useAppSelector } from "state/store";
import { StatusType } from "state/new-konnector/types";
import { generateJSONForAccountValidation, generateJSONForAccountValidations, parseOauthUrl } from "utils/config";
import {
  nexttab,
  setAccountLoading,
  setOAuthAccountLoading,
  setUpdateLoading,
  setUpdateOAuthAccountLoading,
} from "state/configurationv2/slices";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "components/icons/Loader";
import { getDefaultState } from "react-query/types/core/mutation";
import { ToastSucess } from "common/components/toast/Toast";
import { switchTab } from "state/account/slices";
import { CurrentState } from "state/account/types";
interface CredTab {
  appDetails: any;
  type: string;
}

const CredTab: React.FC<CredTab> = ({ appDetails, type }) => {
  let app = appDetails;
  const apiStatus = useAppSelector((state) => state.configurationV2.status);
  const [accessToken, setAccessToken] = useState(null);
  const accountValidated = useAppSelector((state) =>
    type == "source" ? state.configurationV2.data.sourceAppValidated : state.configurationV2.data.targetAppValidated
  );
  const accCred = useAppSelector((state) => state.auth.accCred);
  const currentSate = useAppSelector((state) => state.account.currentState);
  const authDetails = useAppSelector((state) => state.auth);
  const indexTab = useAppSelector((state) => state.configurationV2.data.currenttabindex);
  const sourceapp_validated = useAppSelector((state) => state.configurationV2.data.sourceAppValidated);
  const targetapp_validated = useAppSelector((state) => state.configurationV2.data.targetAppValidated);
  const filledFields = useAppSelector((state) =>
    type == "source"
      ? state.configurationV2.data.sourceAppFilledFields
      : state.configurationV2.data.targetAppFilledFields
  );
  const [authData, setAuthData] = useState(null);
  const dispatch = useAppDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const [Toastmeesage, setToastmessage] = useState(false);
  const onSubmit = (data) => {
    setToastmessage(true);
    if (app.appAuthType == "OAuth") {
      const { ["Account name"]: connectionName, ...fields } = data;
      const requestAccountBody = {
        connectionName,
        fields,
        ...app,
      };
      let oauthUrl = parseOauthUrl(app.connectionConfig.authUrl, data);

      const handleMessage = (event) => {
        if (event.data.access_token) {
          setAccessToken(event.data.access_token);
          setAuthData(event.data);
          var windowFunc;
          if (!accessToken) {
            windowFunc = window.open(oauthUrl, "OAuth Window", "height=600,width=800");
          }
          const requestAccountData = generateJSONForAccountValidation(requestAccountBody, event.data);
          if (accountValidated) {
            return dispatch(
              setUpdateOAuthAccountLoading({
                accountDetails: requestAccountData,
                appId: appDetails?.id,
                type,
              })
            );
          }
          dispatch(
            setOAuthAccountLoading({
              accountDetails: requestAccountData,
              appId: appDetails?.id,
            })
          );
          window.removeEventListener("message", handleMessage);
          windowFunc.close();
        }
      };
      window.addEventListener("message", handleMessage);
      window.addEventListener("message", handleMessage);
      window.open(oauthUrl, "OAuth Window", "height=600,width=800");
    } else {
      const { ["Account name"]: connectionName, ...fields } = data;
      const requestAccountBody = {
        connectionName,
        fields,
        ...app,
      };
      const requestAccountData = generateJSONForAccountValidation(requestAccountBody);
      if (accountValidated) {
        return dispatch(
          setUpdateLoading({
            accountDetails: requestAccountData,
            appId: appDetails.id,
            index: appDetails.index,
            type,
          })
        );
      }
      dispatch(
        setAccountLoading({
          accountDetails: requestAccountData,
          appId: appDetails?.id,
          index: appDetails.index,
        })
      );
    }
  };

  const renderField = (field) => {
    switch (field.type) {
      case "textbox | textarea":
      case "textbox": {
        return (
          <TextField
            {...field}
            key={field.name}
            register={register}
            // isDisabled={accountValidated}
            errors={errors}
            placeholder={field.placeholder}
          />
        );
      }
      case "Password": {
        return (
          <Password
            {...field}
            key={field.name}
            register={register}
            // isDisabled={accountValidated}
            errors={errors}
          />
        );
      }
      case "accountName": {
        return <AccountName {...field} key={field.name} register={register} isDisabled={true} errors={errors} />;
      }
      default:
        // return "<p>Field not found</p>";
        return <></>;
    }
  };

  const renderForm = () => {
    const fields = [...appDetails?.connectionConfig?.fields];
    return fields.map((field) => renderField(field));
  };

  useEffect(() => {
    if (
      (sourceapp_validated && Toastmeesage && indexTab == 0) ||
      (targetapp_validated && Toastmeesage && indexTab == 1)
    ) {
      ToastSucess("Account validated successfully");
      // if (Toastmeesage) {
      setTimeout(() => {
        dispatch(nexttab(indexTab));
      }, 4500);
      // }
    }
  }, [Toastmeesage, sourceapp_validated, targetapp_validated]);

  useEffect(() => {
    if (
      appDetails.id == authDetails.currentUser.admin.staticApp &&
      !sourceapp_validated &&
      app.appAuthType != "OAuth" &&
      accCred[0]?.value != ""
    ) {
      const accDetails = {};
      accCred.filter((field) => {
        accDetails[field.name] = field.value;
      });
      const requestAccountBody = {
        connectionName: "",
        accDetails,
        ...app,
      };
      const requestAccountData = generateJSONForAccountValidations(requestAccountBody);

      dispatch(
        setAccountLoading({
          accountDetails: requestAccountData,
          appId: appDetails?.id,
          index: appDetails.index,
        })
      );
    }
  }, [accountValidated]);

  const getDefaultState = (data) => {
    const defaultState = {};
    data.filter((field) => {
      defaultState[field.name] = field.value;
    });
    return defaultState;
  };

  useEffect(() => {
    if (filledFields?.length) {
      const accountNameField = {
        value: filledFields,
      };
      // reset(getDefaultState([]));

      reset(getDefaultState([accountNameField, ...filledFields]));
    }
    if (appDetails.id == authDetails.currentUser.admin.staticApp) {
      reset(getDefaultState(accCred));
    } else {
      reset({});
    }
    // if (
    //   validateobj.connectionConfig?.fields &&
    //   validatetabbool &&
    //   // validateobj.connectionConfig?.fields
    //   appDetails.appId == validateobj.appId &&
    //   appDetails.appId != authDetails.currentUser.admin.staticApp
    // ) {
    //   const accountnewfields = {
    //     name: "Account name",
    //     value: validateobj.connectionConfig.connectionName,
    //   };
    //   reset(getDefaultState([accountnewfields, ...validateobj.connectionConfig?.fields]));
    // } else {
    //
    //     appDetails.appId,
    //     authDetails.currentUser.admin.staticApp,
    //     appDetails.appId == authDetails.currentUser.admin.staticApp,
    //     "appDetails.appId == authDetails.currentUser.staticApp"
    //   );

    //   if (appDetails.appId == authDetails.currentUser.admin.staticApp) {
    //     reset(getDefaultState(accCred));
    //   } else {
    //     reset({});
    //   }
    // }
    // // return () => {
    //   reset(getDefaultState(accCred));
    // };
  }, []);

  return (
    <div className="p-4 h-[calc(80vh-5rem)] w-full">
      {/* <ToastContainer /> */}
      <div className="flex flex-col space-y-3">
        <form className="space-y-6 flex flex-col items-start" onSubmit={handleSubmit(onSubmit)}>
          {renderForm()}

          <button
            disabled={
              appDetails.id == authDetails.currentUser.admin.staticApp &&
              sourceapp_validated &&
              app.appAuthType != "OAuth" &&
              accCred[0]?.value != "" &&
              accCred[0]?.value != undefined
            }
            type="submit"
            className="w-auto text-white hover:bg-[#0e1a2e] bg-komp-secondary-color hover:transition-all focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 text-center disabled:opacity-45"
          >
            {apiStatus == StatusType.loading ? (
              <>
                <Loader />
                Loading...
              </>
            ) : (
              <>{accountValidated ? `Account Verified` : `Verify your Account`}</>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CredTab;
