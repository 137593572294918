import useWorkflow from "api/workflowApi";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "state/store";
import { resetStatus, updateFilter, updateSortBy } from "state/template-list/slices";
import Templates from "./component";
import { getTemplatesCurrentPage, getTemplatesFilterType, getTemplatesSortBy } from "state/template-list/selectors";
import EmbeddedHeader from "components/BreadCrumb";
import Spinner from "components/Spinner";
import useKonnectors from "api/konnectorsApi";
import { getKonnectorsFilterType } from "state/konnector-list/selectors";
import { getKonnectorsSlice } from "state/selectors";
import { mutate } from "swr";
import { useCurrentUserApi } from "api/authApi";

const TemplateList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const sortBy = useAppSelector(getTemplatesSortBy);
  const filterBy = useAppSelector(getTemplatesFilterType);
  const filterByForKonnector = useAppSelector(getKonnectorsFilterType);
  const { id } = useParams<any>();
  const { pathname } = useLocation();
  const currentPage = useAppSelector(getTemplatesCurrentPage);

  const apiKey = useAppSelector((state) => state.auth.apiKey);
  const userId = useAppSelector((state) => state.auth.email);
  const customisation = useAppSelector((state) => state.auth.customisation);
  const new_Customization = useAppSelector((state) => state.auth.template);
  const new_Customization_portalCustomization = useAppSelector((state) => state.auth.portalConfigration);
  const newcustomization = useAppSelector((state) => state.auth.customization);
  // var [currentFontFamily, setCurrentFontFamily] = useState<string>(customisation.fontFamily);
  const getKonnectors = useAppSelector(getKonnectorsSlice);
  const queryValue = sortBy.split("-")[0] || "";
  const sortId = sortBy.split("-")[1] || "";

  const { data: templates, error: templateError } = useWorkflow(id, currentPage, sortBy, filterBy);
  const items = templates?.data?.items;
  const { data: apps, error: error } = useCurrentUserApi();
  const activeWorkflows = useAppSelector((state) => state.auth.currentUser.admin?.activeWorkflows);
  const filter_data = activeWorkflows?.filter((res) => res.appId == id)[0]?.value;
  const final_output = items?.sort((a, b) => {
    return filter_data?.indexOf(a.id) - filter_data?.indexOf(b.id);
  });

  var currentFontFamily = customisation.fontFamily;
  const { data: konnectors, error: konnectorError } = useKonnectors(
    id,
    currentPage,
    sortBy,
    filterByForKonnector,
    userId
  );

  // useEffect(() => {
  //   const params = new URLSearchParams({ apiKey: apiKey, user: userId, sortBy: sortBy, filterBy });
  //   navigate({ pathname: location.pathname, search: params.toString() });
  // }, [sortBy, filterBy]);

  // useEffect(() => {
  //   const query = new URLSearchParams(location.search);
  //   const sortBy: any = query.get("sortBy") || "dtCreated-desc";
  //   const filterBy: any = query.get("filterBy") || "all";
  //   if (sortBy) {
  //     dispatch(updateSortBy(sortBy));
  //     dispatch(updateFilter(filterBy));
  //   }
  // }, []);
  if ((!templates?.data?.items || templateError) && (!konnectors || !konnectors?.data?.items || konnectorError))
    return (
      <div className="w-full">
        <Spinner />
      </div>
    );

  return (
    <>
      {customisation || apps?.data?.activeApps.includes(id) ? (
        <div
          className={`"bg-gray-100"}   overflow-scroll main-cona`}
          style={
            customisation
              ? {
                  background: newcustomization?.primary_colour,
                  fontFamily: newcustomization?.font,
                  color: newcustomization?.text_colour,
                }
              : {}
          }
        >
          {!pathname.endsWith("only_templates") ? (
            <EmbeddedHeader name={"Manage templates"} />
          ) : (
            <>
              <div className="mb-14"></div>
            </>
          )}
          <Templates data={final_output} konnectors={konnectors?.data} />
        </div>
      ) : (
        <>
          <div className="flex h-screen">
            <div className="m-auto">
              <span className="text-xl">Admin needs to enable this app to continue !!</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default TemplateList;
