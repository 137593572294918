import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AccountName, Password, TextField } from "common/components/FormFields";
import { useAppDispatch, useAppSelector } from "state/store";
import { StatusType } from "state/new-konnector/types";
import {
  fetchAllAppSchemaLoading,
  fetchDynamicFieldsLoading,
  fetchInputFieldsLoading,
  getDynamicAppSchemaLoading,
  resetAccount,
  setAccountApp,
  setAccountLoading,
  setAccountModal,
  setDyanmicFieldsLoading,
  setDynamicInputDataMapping,
  setOAuthAccountLoading,
  switchTab,
} from "state/account/slices";
import { toast } from "react-toastify";
import { generateJSONForAccountValidation } from "utils/config";
import { Loader } from "components/icons/Loader";
import { AppsDetails, TabType } from "state/account/types";
import { getAppSchemaLoading } from "state/edit-template-settings/slices";
import { ToastError, ToastSucess } from "common/components/toast/Toast";
import Select from "react-select";
import { Field } from "utils/appsUtils";
import { setFieldType } from "utils/ruleEngineUtils";
import { button } from "@material-tailwind/react";
import TextField1 from "common/components/FormFields/TextField1";

const SelectInputSchema: React.FC<any> = ({ field, index, appDetails, dynamicInputfields }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const dispatch = useAppDispatch();
  const propValues = field.propValues?.length > 0 ? field.propValues : field.propValue;

  const fieldValue = appDetails.dynamicFields.dataMapping[field.propName];
  const tablength: any = useAppSelector((state) => state.account.tabItems);
  const mapping = appDetails.dynamicFields.dataMapping;
  // ;

  const dependentField = field.dependentTo;
  let tabsLength = useAppSelector((state) => state.account.tabItems);
  const dependentFieldValue = appDetails.dynamicFields.dataMapping[dependentField] || "";

  const accCred = useAppSelector((state) => state.auth.accCred);
  const renderFields = propValues?.find((val) => val.value === fieldValue) || { label: fieldValue, value: fieldValue };
  const app = appDetails.data;
  const hasActionDynamicInputfields = app.actions.find((t) => t.id === appDetails.eventId)?.dynamicInputFields;
  var options;
  let dispatchData;
  if (accCred.length > 2) {
    if (index === appDetails.dynamicFields.fields.length - 1) {
      options = {
        label: accCred[3].value,
        value: accCred[3].value,
      };
      dispatchData = {
        field: field.propName,
        currentActionId: appDetails.id,
        value: accCred[3].value,
      };

      // dispatch(switchTab(appDetails.id));
    } else {
      const renderFields = propValues?.find((val) => val.label === accCred[2].value) || {
        label: accCred[2].value,
        value: accCred[2].value,
      };
      options = renderFields;
      const fieldPropName = renderFields.name || field.propName;
      dispatchData = {
        field: fieldPropName,
        currentActionId: appDetails.id,
        value: renderFields.value,
      };
    }
  } else {
    options = propValues?.find((val) => val.value === fieldValue) || { label: fieldValue, value: fieldValue };
  }

  useEffect(() => {
    if (dynamicInputfields?.length > 0 && accCred.length > 2) {
      dispatch(setDynamicInputDataMapping(dispatchData));
      // if (index === appDetails.dynamicFields.fields.length - 1) {
      //   dispatch(switchTab(appDetails.id));
      // }
    }
  }, []);

  const getFieldOptions = () => {
    const fieldData = new Field(field).format();
    if (!["array", "object"].includes(field.propType)) {
      fieldData.type = setFieldType(fieldData, field.propType);
    }
    return fieldData;
  };

  const renderEvents = () => {
    return propValues?.map((prop: any) => {
      return { label: prop.label, value: prop.value };
    });
  };

  const buttonclick = () => {
    // dispatch(getDynamicAppSchemaLoading({
    //   hasActionFields: hasActionDynamicInputfields,
    //   appId: appDetails.appId,
    //   event: appDetails.eventId,
    //   connectionId: appDetails.connectionConfig.id,
    //   currentActionId: appDetails.id,
    // }));
    if (index === appDetails.dynamicFields.fields.length - 1) {
      dispatch(fetchAllAppSchemaLoading({ currentActionId: tablength.length - 1 }));
    }
    // dispatch(switchTab(appDetails.id));
  };

  return (
    <div className="mb-3 w-96">
      <label className="block mb-2 text-sm font-medium text-gray-900">{`Select ${field.label}`}</label>
      {propValues?.length || dependentField ? (
        <Select
          // isDisabled={true}
          options={renderEvents()}
          value={options}
          onMenuOpen={() => {
            if (dependentField && dependentFieldValue && (!propValues || propValues.length === 0)) {
              dispatch(
                fetchDynamicFieldsLoading({
                  actionType: "actions",
                  eventId: field.lookupId,
                  eventData: dependentFieldValue,
                  field: field.propName,
                  mapping: mapping,
                  appDetails: appDetails,
                })
              );
            }
          }}
          onChange={(e: any) => {
            dispatch(
              setDynamicInputDataMapping({
                field: field.propName,
                currentActionId: appDetails.id,
                value: e.value,
              })
            );
            if (index === appDetails.dynamicFields.fields.length - 1) {
              dispatch(switchTab(appDetails.id));
            }
            // dispatch(switchTab(appDetails.id));
          }}
        />
      ) : (
        <>
          <TextField1
            {...getFieldOptions()}
            value={renderFields?.value}
            onChange={(e: any) => {
              dispatch(
                setDynamicInputDataMapping({
                  field: field.propName,
                  value: e.value,
                  currentActionId: appDetails.id,
                })
              );
              if (index === dynamicInputfields?.data?.length - 1) {
                dispatch(fetchAllAppSchemaLoading({ currentActionId: appDetails.id }));
              }
            }}
          />
          <button
            type="submit"
            className="w-auto text-white hover:bg-[#0e1a2e] bg-komp-secondary-color hover:transition-all focus:ring-4 focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 text-center mt-8"
            onClick={buttonclick}
          >
            Next
          </button>
        </>
      )}
    </div>
  );
};

export default SelectInputSchema;
