import React from "react";
import CopyableUrl from "./CopyableUrl";

const FreshserviceGenesys: React.FC = () => {
  const url = "https://freshservice-genesys-integration.konnectify.io?{{agent_id}}";
  return (
    <div>
      <CopyableUrl url={url} />
    </div>
  );
};

export default FreshserviceGenesys;
