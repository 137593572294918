import { ActionType, RuleDataType } from "interfaces/pages/create-new-konnector";
import { Action } from "react-query/types/core/query";
import { TabType } from "state/account/types";
import { ConfigurationType, StatusType } from "state/new-konnector/types";

export interface RuleConfigTab {
  id: number;
  tabType: TabType;
  status: StatusType;
  data: RuleConfigData;
}
export interface RuleConfigData {
  operator: string;
  actionType: string;
  actionOrderId: number;
  filters: FilterType[];
  status?: StatusType;
  saveDataMapping?: StatusType;
}

export interface FilterType {
  match_type: string;
  conditions: ConditionType[];
  name: string;
}

export interface ConditionType {
  field_type: string;
  operator: string;
  field_name: string;
  value: string;
  name: string;
  data_type: "string" | "boolean" | "date" | "number";
}

const string = [
  { label: "Contains", value: "CONTAINS" },
  { label: "Does not contains", value: "DOES_NOT_CONTAINS" },
  { label: "Ends with", value: "ENDS_WITH" },
  { label: "Does not ends with", value: "DOES_NOT_ENDS_WITH" },
  { label: "Starts with", value: "STARTS_WITH" },
  { label: "Does not starts with", value: "DOES_NOT_STARTS_WITH" },
  { label: "Exactly match", value: "EXACTLY_MATCHES" },
  { label: "Does not exactly match", value: "DOES_NOT_EXACTLY_MATCHES" },
  { label: "Is exists", value: "IS_EXISTS" },
  { label: "Is not exists", value: "IS_NOT_EXISTS" },
];
const boolean = [
  { label: "Is False", value: "IS_FALSE" },
  { label: "Is True", value: "IS_TRUE" },
  { label: "Is exists", value: "IS_EXISTS" },
  { label: "Is not exists", value: "IS_NOT_EXISTS" },
];
const number = [
  { label: "Is equal to", value: "IS_EQUALS_TO" },
  { label: "Is greater than", value: "IS_GREATER_THAN" },
  { label: "Is less than", value: "IS_LESSER_THAN" },
  { label: "Is exists", value: "IS_EXISTS" },
  { label: "Is not exists", value: "IS_NOT_EXISTS" },
];
const date = [
  { label: "Is after", value: "IS_GREATER_THAN" },
  { label: "Is before", value: "IS_LESSER_THAN" },
  { label: "Is same as", value: "IS_EUQAL_TO" },
];

const defaultValue = [
  {
    label: "none",
    value: "none",
  },
];

export const Choices = {
  string: string,
  boolean: boolean,
  numeric: number,
  date: date,
  default: defaultValue,
};
