import { AxiosResponse } from "axios";
import { takeEvery, put, call, all, select, delay } from "redux-saga/effects";
// import { validateAccountApi, createAccountApi } from "api/accountsApi";
import { AppRootObject } from "../../interfaces/modules/apps/index";
import { getAppsApi, getAppsFieldsApi, getAppsFieldsApiTest } from "api/appsApi";
import { getKonnectorApi, getWebhookData, updateKonnectorApi } from "api/konnectorsApi";
import {
  AppRole,
  CreateAccountSuccessResponse,
  DynamicFieldsSchemaType,
  DynamicInputFields,
  InputFields,
  KonnectifyApiResponse,
  KonnectorAppState,
  KonnectorType,
  UpdateSourceAppPayload,
  UpdateTargetAppPayload,
  ValidateAccountSuccessResponse,
} from "./types";
import { getTriggerDetails, getTargetDetails, getLookupDetails, getEditKonnectorDetails } from "./selectors";
import { setKonnectorLoading, getKonnectorSuccess } from "./actions";
import {
  deserializeKonnectorToEditKonnectorState,
  serializeCreateKonnectorData,
  // serializeUpdateKonnectorData,
} from "utils/konnectorUtils";
import { getKonnectorFailed, editWorkflowSaveKonnector, saveKonnectorSuccess, setupdatewebhookur } from "./slices";
import { serializeUpdateKonnectorData } from "utils/updateKonnectorUtils";
import { serializeAppFields, serializeTabItems, serializeTabItemsForAppDetails } from "utils/appsUtils";
import { deserializeTempateToEditTemplateState } from "utils/templateUtils";
import { konnectorLoading, konnectorSuccess, setAccountApp } from "state/account/slices";
import { useAppSelector } from "state/store";
import { getWebhookresponse, setwebhookresponseStore, setwebhookurl } from "state/konnector-list/slices";
import { settoasterrorBoolean } from "../auth/slices";

export function* getKonnector(data) {
  try {
    const result: AxiosResponse<KonnectifyApiResponse<any>> = yield call(getKonnectorApi, data.payload);
    const userdata = yield select((state) => state.auth.apiKey);

    yield put(setupdatewebhookur(result));

    const result1 = yield call(getWebhookData, result.data.data.webhookUrl?.id, "webhook", userdata.apikey);
    yield put(setwebhookresponseStore(result1.data));
    yield put(setwebhookurl(result.data));
    if (result.data.status !== 500) {
      const appsDetails = yield call(getAppsApi, result.data.data.konnectorApps, true, result.data.data);
      const payload = {
        webhookId: result.data.data.webhookUrl?.id || "",
        konnectorType: result.data.data.konnectorType,
        // tenantId: user.tenantId,
        triggerInputFields: result.data.data.triggerDefinition.inputFields || null,
        actionInputFields: result.data.data.actionItems.slice(-1)[0].dynamicInputFields || null,
      };
      let triggerInputFields = result.data.data.triggerDefinition.inputFields || null;
      let actionInputField = result.data.data.actionItems.slice(-1)[0].dynamicInputFields || null;
      let serializeTabItemsState: any = serializeTabItemsForAppDetails(
        appsDetails,
        triggerInputFields,
        actionInputField,
        true
      );
      serializeTabItemsState.push(...serializeTabItems(serializeTabItemsState, result.data?.data, true));
      yield put(setAccountApp({ data: serializeTabItemsState, allTabValidated: true }));
      let appDetailsItem = appsDetails.map((app) => app.data);
      let appsDynamicFields = yield call(getAppsFieldsApiTest, result.data.data.konnectorApps, payload);
      const serializedappsFields = serializeAppFields(appsDynamicFields);

      const state = deserializeTempateToEditTemplateState(
        result.data,
        appDetailsItem,
        serializedappsFields,
        "konnector"
      );
      yield put(getKonnectorSuccess(state));
    } else {
    }
  } catch (e) {
    yield put(getKonnectorFailed());
    // yield put(createAccountFailed());
  }
}

export function* saveKonnector() {
  try {
    const updateKonnectorState = yield select(getEditKonnectorDetails);
    const tabItems = yield select((state) => state.account.tabItems);
    const showWebhook = yield select((state) => state.konnectors.storewebhook);
    const webhookurl = showWebhook?.data?.webhookUrl.url;
    const webhookid = showWebhook?.data?.webhookUrl.id;

    const serializeData = serializeCreateKonnectorData(
      updateKonnectorState,
      updateKonnectorState,
      tabItems,
      webhookurl,
      webhookid
    );
    const result: AxiosResponse<any> = yield call(updateKonnectorApi, updateKonnectorState.data.id, serializeData);
    yield put(konnectorLoading());
    yield delay(3000);
    yield put(saveKonnectorSuccess());

    yield put(konnectorSuccess());
  } catch (e) {
    yield put(settoasterrorBoolean(true));
    // yield put(createAccountFailed());
  }
}

export default function* rootSaga() {
  yield takeEvery(setKonnectorLoading.type, getKonnector);
  yield takeEvery(editWorkflowSaveKonnector.type, saveKonnector);
}
