import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getKonnectorsSlice } from "state/selectors";
import { useAppDispatch, useAppSelector } from "state/store";
import { StatusType } from "state/new-konnector/types";
import { getKonnectorsDeleted } from "state/konnector-list/selectors";
import { deleteKonnectorLoading } from "state/konnector-list/slices";
import { useNavigate } from "react-router-dom";

interface ControlsProps {
  konnector: any;
  status: string;
  name: string;
}
// const notificationMessage = {
//   true: "Konnector is Enabled",
//   false: "Konnector is Disabled",
// };
const TemplateControls: React.FC<ControlsProps> = ({ konnector, status, name }) => {
  const [showModal, setShowModal] = useState(false);
  const [konnectorStatus, setKonnectorStatus] = useState(status);
  const getKonnectors = useAppSelector(getKonnectorsSlice);
  const konnStatus = useAppSelector((state) => state.konnectors.status);
  const currentActionId = useAppSelector((state) => state.konnectors.currentActionId);
  const customization_template = useAppSelector((state) => state.auth.template);
  const navigate = useNavigate();
  const apiKey = useAppSelector((state) => state.auth.apiKey);
  const userId = useAppSelector((state) => state.auth.email);
  const hasDeleted = useAppSelector(getKonnectorsDeleted);
  const dispatch = useAppDispatch();
  const closeModalHandler = () => {
    setShowModal(false);
  };
  const confirmModalHandler = () => {
    setShowModal(false);
    dispatch(deleteKonnectorLoading(konnector.id));
    if (deleteKonnectorLoading(konnector.id)) {
      toast.success("Konnector deleted successfully", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // dispatch(reset());
  };

  const handleOnChange = (e) => {
    const checked = e.target.checked;
    // if (checked) {
    // navigate(
    //   `/apps/createtemplates/?appId=${konnector.triggerDefinition.appId}&templateId=${konnector.id}&apiKey=${apiKey}&user=${userId}`
    // );
    // }
  };

  useEffect(() => {
    if (konnStatus === "success" && getKonnectors.currentActionId === konnector.id && !hasDeleted) {
      setKonnectorStatus(getKonnectors.hasActivated ? "active" : "inactive");
      // toast.success(notificationMessage[getKonnectors.hasActivated], {
      //   position: "bottom-center",
      //   autoClose: 3000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      // dispatch(konectorStatus())
    }
  }, [getKonnectors.status]);
  useEffect(() => {
    setKonnectorStatus(status);
  }, [status]);

  return (
    <div className="flex">
      {/* {showModal && (
        <ConfirmModal
          text={`konnector "${name}"`}
          visible={showModal}
          closeModalHandler={closeModalHandler}
          confirmHandler={confirmModalHandler}
        />
      )} */}
      <label htmlFor={konnector.id} className="inline-flex items-center space-x-4 w-full cursor-pointer">
        {/* <h1 className="text-sm ml-4 mr-10 "> Enable konnector </h1> */}
        <span className="ml-3 relative" onClick={handleOnChange}>
          <input
            id={konnector.id}
            // onChange={handleOnChange}
            disabled={true}
            type="checkbox"
            className="hidden peer"
          />
          <div className="absolute bg-white inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto  peer-disabled:bg-gray-500"></div>
          <div className="w-10 h-6 bg-gray-500 peer-checked:bg-green-700 rounded-full shadow-inner peer-disabled:bg-gray-200"></div>
        </span>
      </label>
    </div>
  );
};

export default TemplateControls;
