import React, { useEffect, useState } from "react";
import { useGetApp } from "api/appsApi";
import Spinner from "components/Spinner";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { TabPanel, useTabs } from "react-headless-tabs";
import { TabSelector } from "../TabSelector";
import { useAppSelector } from "state/store";

import { BreadCrumbIcon } from "components/icons/BreadCrumbIcon";
import useWorkflow from "api/workflowApi";
import useKonnectors from "api/konnectorsApi";
import { FilterType, SortByType } from "state/konnector-list/types";
import { LoadingIcon } from "components/icons/Loading";
import "react-quill/dist/quill.bubble.css";
import ReactQuill from "react-quill";

const AppsDetails: React.FC = () => {
  const [selectedTab, setSelectedTab] = useTabs(["overview", "howToInstall"]);
  const { id } = useParams<any>();
  const location = useLocation();
  const { data: app, error: appError } = useGetApp(id);
  const apiKey = useAppSelector((state) => state.auth.apiKey);
  const userId = useAppSelector((state) => state.auth.email);
  const customisation = useAppSelector((state) => state.auth.customisation);
  const url = window.location.href;
  const userIndex = url.indexOf("user=");
  var cc;
  if (userIndex !== -1) {
    const userEndIndex = url.indexOf("&", userIndex);
    if (userEndIndex !== -1) {
      cc = url.substring(userEndIndex + 1);
    } else {
      cc = "";
    }
  } else {
  }
  const new_Customization = useAppSelector((state) => state.auth.portalConfigration);
  const newcustomization = useAppSelector((state) => state.auth.customization);
  const query = new URLSearchParams(location.search);
  const [onlyApp, SetOnlyApp] = useState(query.get("onlyApp"));
  const { data: konnectors, error: konnectorError } = useKonnectors(id, 1, SortByType.DEFAULT, FilterType.ALL, userId);

  if (!app || !konnectors) {
    return <Spinner />;
  }
  return (
    <div
      className=" h-[100vh]"
      // className={` items-start h-full  ${
      //   customisation.backgroundColor ? `bg-[${customisation.backgroundColor}]` : "bg-gray-100"
      // }  p-3`}
      style={{
        background: newcustomization?.primary_colour,
        fontFamily: newcustomization?.font,
        color: newcustomization?.text_colour,
      }}
    >
      <div
        style={{
          background: newcustomization?.primary_colour,
          fontFamily: newcustomization?.font,
          color: newcustomization?.text_colour,
        }}
        className=" w-full  flex flex-col justify-between"
      >
        <>
          <div className="flex items-center whitespace-nowrap min-w-0 my-4 ml-8" aria-label="Breadcrumb">
            <h4 className=" flex items-center justify-center  cursor-pointer text-sm mx-2 text-primary-color font-medium">
              {!onlyApp && (
                <>


                  <nav className="flex" aria-label="Breadcrumb">
                    <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">

                      <li>
                        <Link to={`/apps/?apiKey=${apiKey}&user=${userId}&${cc}`}>
                          <div className="flex items-center">
                            <a href="#" className=" text-md text-primary-color "
                              style={{
                                background: newcustomization?.primary_colour,
                                fontFamily: newcustomization?.font,
                                color: newcustomization?.text_colour,
                              }}>Apps</a>
                          </div>
                        </Link>
                      </li>
                      <li aria-current="page">
                        <div className="flex items-center">
                          <svg className="rtl:rotate-180 w-3 h-3 text-gray-900 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                          </svg>
                          <span className=" text-md text-primary-color "
                            style={{
                              background: newcustomization?.primary_colour,
                              fontFamily: newcustomization?.font,
                              color: newcustomization?.text_colour,
                            }}> {app?.data[0]?.appName}</span>
                        </div>
                      </li>
                    </ol>
                  </nav>
                </>
              )}
            </h4>
          </div>
        </>
        <div className="flex pb-12 space-x-6 pt-4">
          <div className="px-9">
            <img className="w-16 h-16" src={app?.data[0]?.iconUrl}></img>
          </div>
          <div className="w-9/12">
            <div className="text-xl font-bold">{app?.data[0]?.appName}</div>
            <ReactQuill className="-ml-4" value={app?.data[0]?.description} readOnly theme="bubble" />
            {/* <h2
              dangerouslySetInnerHTML={{
                __html: app?.data[0]?.description == "undefined" ? "" : app?.data[0]?.description,
              }}
            ></h2> */}

            <div
              className="mt-1 text-sm"
              style={{
                background: newcustomization?.primary_colour,
                fontFamily: newcustomization?.font,
                color: newcustomization?.text_colour,
              }}
            >
              {konnectors ? (
                konnectors?.data?.items?.length > 0 ? (
                  ""
                ) : (
                  `
              By clicking on "Install", you acknowledge and agree that your access and use of this application will be
              governed by the developer's terms of service and privacy policy. Freshworks may share your contact and
              usage information with the developer.
              `
                )
              ) : (
                <div className="w-96 h-8 bg-gray-300 rounded animate-pulse"></div>
              )}
            </div>
            <div className="mt-4">
              <Link to={`/apps/${id}/templates?apiKey=${apiKey}&user=${userId}&${cc}`}>
                <button
                  style={{
                    background: newcustomization?.primary_button_colour,
                    fontFamily: newcustomization?.font,
                    color: newcustomization?.text_colour,
                  }}
                  className={`p-2  bg-primary-color
                 rounded text-white w-auto`}
                // style={{
                //   background: new_Customization?.primary_button_Color,
                //   fontFamily: new_Customization?.fontFamily,
                //   color: new_Customization?.primary_bgColor_text,
                // }}
                >
                  {konnectors ? (
                    konnectors?.data?.items?.length > 0 ? (
                      "Go to Settings"
                    ) : (
                      "Install App"
                    )
                  ) : (
                    <div className="p-2 w-24 rounded animate-pulse"></div>
                  )}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div
          className="border-b border-gray-300 "
          style={{
            background: newcustomization?.primary_colour,
            fontFamily: newcustomization?.font,
            color: newcustomization?.text_colour,
          }}
        >
          <nav className="border-b border-gray-300 ml-10">
            <TabSelector
              disabled={false}
              isActive={selectedTab === "overview"}
              onClick={() => setSelectedTab("overview")}
            >
              Overview
            </TabSelector>
            <TabSelector
              disabled={false}
              isActive={selectedTab === "howToInstall"}
              onClick={() => setSelectedTab("howToInstall")}
            >
              How to Install?
            </TabSelector>
          </nav>
        </div>

        <div className="mt-3  w-[85%] items-start flex-auto ml-10">
          <TabPanel hidden={selectedTab !== "overview"}>
            <ReactQuill value={app?.data[0]?.appOverview} readOnly theme="bubble" />
          </TabPanel>
          <TabPanel hidden={selectedTab !== "howToInstall"}>
            <ReactQuill value={app?.data[0]?.appInstall} readOnly theme="bubble" />
            {/* <h1 dangerouslySetInnerHTML={{ __html: app?.data[0]?.appInstall }}></h1> */}
          </TabPanel>
        </div>
      </div>
    </div>
  );
};
export default AppsDetails;
