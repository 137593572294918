import { combineReducers } from "redux";

import account from "./account/slices";
import editKonnector from "./edit-workflow/slices";
import createKonnector from "./create-konnector/slices";
import auth from "./auth/slices";
import template from "./template-list/slices";
import editTemplate from "./edit-template-settings/slices";
import konnectors from "./konnector-list/slices";
import dataMapping from "./data-mapping/slices";
import configurationV2 from "./configurationv2/slices";

const rootReducer = combineReducers({
  account,
  editKonnector,
  createKonnector,
  auth,
  editTemplate,
  template,
  konnectors,
  dataMapping,
  configurationV2,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
